import React, { useEffect, useState } from "react";
import DefaultInput from "../../../../components/Elements/DefaultInput";
import DefaultCalendar from "../../../../components/Elements/DefaultCalendar";
import DefaultRadioButtons from "../../../../components/Elements/DefaultRadioButtons";
import { Row, Button } from "react-bootstrap";
import api from "../../../../services/api";
import { toast } from "react-toastify";
const EmbalmingForm = ({
  onAuthorizationRelease,
  folioId,
  secuencia,
  usuarioId,
}) => {
  const sex = [
    { label: "Famale", value: "1", id: "sex" },
    { label: "Male", value: "2", id: "sex" },
    { label: "Not Yet Determined", value: "3", id: "sex" },
  ];
  const haveInfectious = [
    { label: "Yes", value: "1", id: "sex" },
    { label: "No", value: "2", id: "sex" },
  ];
  const [formData, setFormData] = useState({
    secuencia: secuencia,
    folio: folioId,
    usuario_id: usuarioId,

    decreasedName: "",
    dateOfDeath: "",
    placeOfDeath: "",
    sex: "",
    age: "",

    haveInfectiuos: "",
    ifYesIndentify: "",

    prothesisOrRadoictive: "",
    descritionProthesis: "",
    secondaryDescriptionProthesis: "",

    finalDispositionOneMark: "",
    finalDispositionOneDate: "",
    finalDispositionTwoMark: "",
    finalDispositionTwoName: "",

    livingNextDeceases: "",
    capacityOf: "",

    printedName1: "",
    relationship1: "",
    phone1: "",
    address1: "",

    printedName2: "",
    relationship2: "",
    phone2: "",
    address2: "",
  });
  const handleInputChange = (name, value) => {
    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    setFormData(updatedFormData);
    onAuthorizationRelease(updatedFormData);
  };
  const handleSubmit = () => {
    console.log(formData);
    add_cremation_authorization(formData);
  };

  useEffect(() => {
    get_crematoy_info(folioId);
  }, []);
  useEffect(() => {
    if (formData.decreasedName) {
      onAuthorizationRelease(formData);
    }
  }, [formData]);
  const get_decedent_info = async (secuencia) => {
    try {
      const response = await api.get(
        `documents/form1/GetDecreasedInfo.php?id=${secuencia}`
      );
      const haveData = response.data[0];
      if (haveData) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          decreasedName: response.data[0].Name,
          age: response.data[0].Age,
          dateOfDeath: response.data[0].DateOfDeath,
          placeOfDeath: response.data[0].PlaceOfDeath,
          sex: response.data[0].Sex,
        }));
      }
    } catch (error) {
    }
  };
  const get_crematoy_info = async (folioId) => {
    try {
      const response = await api.get(
        `documents/form6/CrematoryAuthorizationController.php?id=${folioId}`
      );
      const haveData = response.data[0];
      if (haveData) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          decreasedName: response.data[0].decreasedName,
          dateOfDeath: response.data[0].dateOfDeath,
          placeOfDeath: response.data[0].placeOfDeath,
          sex: response.data[0].sex,
          age: response.data[0].age,

          haveInfectiuos: response.data[0].haveInfectiuos,
          ifYesIndentify: response.data[0].ifYesIndentify,

          prothesisOrRadoictive: response.data[0].prothesisOrRadoictive,
          descritionProthesis: response.data[0].descritionProthesis,
          secondaryDescriptionProthesis:
            response.data[0].secondaryDescriptionProthesis,

          finalDispositionOneMark: response.data[0].finalDispositionOneMark,
          finalDispositionOneDate: response.data[0].finalDispositionOneDate,
          finalDispositionTwoMark: response.data[0].finalDispositionTwoMark,
          finalDispositionTwoName: response.data[0].finalDispositionTwoName,

          livingNextDeceases: response.data[0].livingNextDeceases,
          capacityOf: response.data[0].capacityOf,

          printedName1: response.data[0].printedName1,
          relationship1: response.data[0].relationship1,
          phone1: response.data[0].phone1,
          address1: response.data[0].address1,

          printedName2: response.data[0].printedName2,
          relationship2: response.data[0].relationship2,
          phone2: response.data[0].phone2,
          address2: response.data[0].address2,
        }));
      } else {
        get_decedent_info(secuencia);
      }
    } catch (error) {
    }
  };
  const add_cremation_authorization = async (formData) => {
    try {
      const response = await api.post(
        "documents/form6/CrematoryAuthorizationController.php",
        formData,
        { params: { action: "POST" } }
      );
      toast.success("Crematory Authorization info successfully added", {
        className: "toast-success",
        theme: "colored",
      });
    } catch (error) {
      toast.error("Error adding Crematory Authorization info", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };
  return (
    <>
      <div className="main-card">
        <DefaultInput
          placeholder={"DECEASED NAME"}
          onChange={(value) => handleInputChange("decreasedName", value)}
          valueData={formData.decreasedName}
        />
        <DefaultCalendar
          placeholder={"DATE OF DEATH"}
          onChange={(value) => handleInputChange("dateOfDeath", value)}
          valueData={formData.dateOfDeath}
        />
        <DefaultInput
          placeholder={"PLACE OF DEATH"}
          onChange={(value) => handleInputChange("placeOfDeath", value)}
          valueData={formData.placeOfDeath}
        />
        <DefaultRadioButtons
          label={"SEX"}
          options={sex}
          onChange={(value) => handleInputChange("sex", value)}
          valueData={formData.sex}
        />

        <DefaultInput
          placeholder={"AGE"}
          type={"number"}
          onChange={(value) => handleInputChange("age", value)}
          valueData={formData.age}
        />

        <br></br>
        <div style={{ borderBottom: "4px solid #d9d9d9" }}></div>
        <DefaultRadioButtons
          label={"Was the death due to an infectious disease".toUpperCase()}
          options={haveInfectious}
          onChange={(value) => handleInputChange("haveInfectiuos", value)}
          valueData={formData.haveInfectiuos}
        />
        <DefaultInput
          placeholder={"If yes, please identify".toUpperCase()}
          onChange={(value) => handleInputChange("ifYesIndentify", value)}
          valueData={formData.ifYesIndentify}
        />
        <DefaultRadioButtons
          label={" Did the Deceased’s remains contain a pacemaker, prosthesis, or radioactive device that may be harmful to the crematory?".toUpperCase()}
          options={haveInfectious}
          onChange={(value) =>
            handleInputChange("prothesisOrRadoictive", value)
          }
          valueData={formData.prothesisOrRadoictive}
        />
        <DefaultInput
          placeholder={"The following describes all existing devices or implants in or attached to the Deceased that should be removed priorto cremation:".toUpperCase()}
          onChange={(value) => handleInputChange("descritionProthesis", value)}
          valueData={formData.descritionProthesis}
        />
        <DefaultInput
          placeholder={"The following identifcation marks are to be used as secondary identifcation:".toUpperCase()}
          onChange={(value) =>
            handleInputChange("secondaryDescriptionProthesis", value)
          }
          valueData={formData.secondaryDescriptionProthesis}
        />

        <br></br>
        <div style={{ borderBottom: "4px solid #d9d9d9" }}></div>
        <div style={{ justifyContent: "space-between", display: "flex" }}>
          <span
            style={{
              marginTop: "40px",
              marginRight: "10px",
              marginLeft: "10px",
            }}
          >
            1.
          </span>
          <DefaultInput
            placeholder={"".toUpperCase()}
            onChange={(value) =>
              handleInputChange("finalDispositionOneMark", value)
            }
            valueData={formData.finalDispositionOneMark}
          />
          <span
            style={{
              marginTop: "30px",
              marginRight: "10px",
              marginLeft: "10px",
            }}
          >
            To be received by a family member or personal representative by this
            date
          </span>

          <DefaultCalendar
            placeholder={"DATE OF DEATH"}
            onChange={(value) =>
              handleInputChange("finalDispositionOneDate", value)
            }
            valueData={formData.finalDispositionOneDate}
          />
        </div>
        <div style={{ justifyContent: "space-between", display: "flex" }}>
          <span
            style={{
              marginTop: "40px",
            }}
          >
            2.
          </span>
          <DefaultInput
            placeholder={"".toUpperCase()}
            onChange={(value) =>
              handleInputChange("finalDispositionTwoMark", value)
            }
            valueData={formData.finalDispositionTwoMark}
          />
          <span
            style={{
              marginTop: "40px",
            }}
          >
            To be given to
          </span>
          <DefaultInput
            placeholder={"".toUpperCase()}
            onChange={(value) =>
              handleInputChange("finalDispositionTwoName", value)
            }
            valueData={formData.finalDispositionTwoName}
          />
          <span
            style={{
              marginTop: "30px",
            }}
          >
            for disposition.
          </span>
        </div>

        <br></br>
        <div style={{ borderBottom: "4px solid #d9d9d9" }}></div>
        <DefaultInput
          placeholder={"The undersigned hereby certify that I/We are the closest living next of kin * of the Deceased and that we are related to the Deceased as".toUpperCase()}
          onChange={(value) => handleInputChange("livingNextDeceases", value)}
          valueData={formData.livingNextDeceases}
        />
        <DefaultInput
          placeholder={"or otherwise serve(d) in the capacity of".toUpperCase()}
          onChange={(value) => handleInputChange("capacityOf", value)}
          valueData={formData.capacityOf}
        />
        <br></br>
        <div style={{ borderBottom: "4px solid #d9d9d9" }}></div>
        <DefaultInput
          placeholder={"Printed Name".toUpperCase()}
          onChange={(value) => handleInputChange("printedName1", value)}
          valueData={formData.printedName1}
        />
        <DefaultInput
          placeholder={"Relationship".toUpperCase()}
          onChange={(value) => handleInputChange("relationship1", value)}
          valueData={formData.relationship1}
        />
        <DefaultInput
          placeholder={"Phone".toUpperCase()}
          onChange={(value) => handleInputChange("phone1", value)}
          valueData={formData.phone1}
        />
        <DefaultInput
          placeholder={"Address".toUpperCase()}
          onChange={(value) => handleInputChange("address1", value)}
          valueData={formData.address1}
        />

        <br></br>
        <div style={{ borderBottom: "4px solid #d9d9d9" }}></div>
        <DefaultInput
          placeholder={"Printed Name".toUpperCase()}
          onChange={(value) => handleInputChange("printedName2", value)}
          valueData={formData.printedName2}
        />
        <DefaultInput
          placeholder={"Relationship".toUpperCase()}
          onChange={(value) => handleInputChange("relationship2", value)}
          valueData={formData.relationship2}
        />
        <DefaultInput
          placeholder={"Phone".toUpperCase()}
          onChange={(value) => handleInputChange("phone2", value)}
          valueData={formData.phone2}
        />
        <DefaultInput
          placeholder={"Address".toUpperCase()}
          onChange={(value) => handleInputChange("address2", value)}
          valueData={formData.address2}
        />
      </div>
      <Row>
        <div className="d-flex justify-content-end">
          <Button onClick={handleSubmit}>SAVE CREMATORY AUTHORIZATION</Button>
        </div>
      </Row>
    </>
  );
};

export default EmbalmingForm;
