import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";

const DefaultCheckboxInput = ({
  numberRow,
  options,
  label,
  inputType = "text",
  onChange,
  valueData = [],
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    if (Array.isArray(valueData) && valueData.length > 0) {
      const initialSelectedOptions = valueData.map((option) => option.value);
      const initialInputValues = valueData.reduce((acc, option) => {
        acc[option.value] = option.inputValue;
        return acc;
      }, {});

      setSelectedOptions(initialSelectedOptions);
      setInputValues(initialInputValues);
    }
  }, [valueData]);

  const handleInputChange = (e, option) => {
    const { value } = e.target;
    const updatedInputValues = { ...inputValues, [option.value]: value };
    setInputValues(updatedInputValues);

    const selectedValues = selectedOptions.map((selectedOption) => {
      if (selectedOption === option.value) {
        return { value: selectedOption, inputValue: value || '' };
      }
      return { value: selectedOption, inputValue: updatedInputValues[selectedOption] || '' };
    });

    onChange(selectedValues);
  };

  const handleOptionChange = (option) => {
    let updatedOptions;

    if (selectedOptions.includes(parseInt(option.value))) {
      updatedOptions = selectedOptions.filter((value) => value !== parseInt(option.value));
    } else {
      updatedOptions = [...selectedOptions, parseInt(option.value)];
    }

    setSelectedOptions(updatedOptions);

    const selectedValues = updatedOptions.map((value) => {
      return { value, inputValue: inputValues[value] || '' };
    });

    onChange(selectedValues);
  };

  return (
    <div>
      <Form.Group>
        <Form.Label style={{ fontWeight: "700" }}>{numberRow}</Form.Label>
        <Form.Label>{label}</Form.Label>
        {options.map((option, index) => (
          <div key={index}>
            <Row className="align-items-center">
              <Col>
                <Form.Check
                  type="checkbox"
                  label={option.label}
                  id={`${numberRow}${index}`}
                  checked={selectedOptions.includes(parseInt(option.value))}
                  onChange={() => handleOptionChange(option)}
                />
              </Col>
              {selectedOptions.includes(parseInt(option.value)) && option.enableInput && (
                <Col>
                  <Form.Control
                    type={inputType}
                    value={inputValues[option.value] || ""}
                    onChange={(e) => handleInputChange(e, option)}
                    className="mb-2"
                  />
                </Col>
              )}
            </Row>
          </div>
        ))}
      </Form.Group>
    </div>
  );
};

export default DefaultCheckboxInput;
