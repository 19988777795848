import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import CustomTable from "../../../components/Elements/Table";
import { toast } from "react-toastify";
import api from "../../../services/api"
const Users = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);

  useEffect(() => {
    Get();
  }, []);

  const Get = async () => {
    try {
      const response = await api.get(
        'settings/archiveController.php',
        { params: { action: "GET" } }
      );
      setUsuarios(response.data);
    } catch (error) {
      console.error("Error when searching for users", error);
      toast.error("Error when searching for users", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };
  const Add = async (newUserData) => {
    try {
      const response = await api.post(
        'settings/archiveController.php',
        newUserData,
        { params: { action: "POST" } }
      );
      console.log(newUserData);
      Get();
      toast.success("Archive successfully added", {
        className: "toast-success",
        theme: "colored",
      });
    } catch (error) {
      console.error("Error adding archive", error);
      toast.error("Error adding archive", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };
  const Edit = async (editedUserData) => {
    console.log(editedUserData)
    try {
      const response = await api.put(
        'settings/archiveController.php',
        editedUserData,
        { params: { action: "PUT" } }
      );
      Get();
      console.log(response)
      toast.success("Archive modified Successfully", {
        className: "toast-success",
        theme: "colored",
      });
    } catch (error) {
      console.error("Error al editar usuario:", error);
      toast.error("Error al editar usuario", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };

  const Delete = async (ID) => {
    try {
      const response = await api.delete(
        `settings/archiveController.php?id=${ID}`
      );
      Get();
      toast.success("Archive deleted successfully", {
        className: "toast-success",
        theme: "colored",
      });
    } catch (error) {
      console.error("Error al eliminar usuario:", error);
      toast.error("an error occurred when deleting archive", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="main-card" id="tableArchive">
        <Row className="p-2">
          <CustomTable
            data={usuarios}
            perPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onDelete={Delete}
            onEdit={Edit}
            onAdd={Add}
          />
        </Row>
      </div>
    </>
  );
};

export default Users;
