import React, { useState, useEffect } from "react";
import DefaultInput from "../../../../components/Elements/DefaultInput";
import { Row, Button } from "react-bootstrap";
import getUserDataFromCookies from "../../Form1/backend/GetUserFromCookies";
import api from "../../../../services/api";
import { toast } from "react-toastify";
const EmbalmingForm = ({
  onAuthorizationRelease,
  folioId,
  folio,
}) => {
  const [formData, setFormData] = useState({
    secuencia: folio,
    folio: folioId,
    usuario_id: getUserDataFromCookies().id_usuario,
    witnessName: "",
    decreasedName: "",
    decreasedNameFormal: "",
    transportTo: "",
  });

  const handleInputChange = (name, value) => {
    const updatedFormData = {
      ...formData,
      [name]: value.toUpperCase(),
    };

    setFormData(updatedFormData);
    onAuthorizationRelease(updatedFormData);
  };

  const handleSubmit = () => {
    add_AllInfo(formData);
  };

  const FoundData = false;
  useEffect(() => {
    get_all_info(folio);
  }, []);

  useEffect(() => {
    onAuthorizationRelease(formData);
  }, [formData]);

  const get_decedent_info = async (folio) => {
    try {
      const response = await api.get(
        `documents/form1/GetDecreasedInfo.php?id=${folio}`
      );
      const decedent_info = response.data[0];
      if (response.data[0] != null && FoundData == false) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          decreasedName: decedent_info.Name,
          decreasedNameFormal: decedent_info.TitledName,
        }));
      }
    } catch {
      console.log("Error get info");
    }
  };

  const get_all_info = async (folio) => {
    try {
      const response = await api.get(
        `documents/form3/EmbalmingController.php?id=${folio}`
      );
      const decedent_info = response.data[0];
      if (response.data[0] != null) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          decreasedName: decedent_info.decreasedName,
          decreasedNameFormal: decedent_info.decreasedFormalName,
          witnessName: decedent_info.witnessName,
          transportTo: decedent_info.transportTo,
        }));
        FoundData = true;
      } else {
        get_decedent_info(folio);
      }
    } catch {}
  };

  useEffect(() => {
    get_all_info(folio);
  }, []);

  const add_AllInfo = async (formData) => {
    try {
      const response = await api.post(
        "documents/form3/EmbalmingController.php",
        formData,
        { params: { action: "POST" } }
      );

      toast.success("Authorization of release info successfully added", {
        className: "toast-success",
        theme: "colored",
      });
    } catch (error) {
      toast.error("Error adding Authorization of release info", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };
  return (
    <>
      <div className="main-card">
        <DefaultInput
          placeholder={"WITNESS NAME"}
          onChange={(value) => handleInputChange("witnessName", value)}
          valueData={formData.witnessName}
        />
        <DefaultInput
          placeholder={"DECREASED NAME"}
          onChange={(value) => handleInputChange("decreasedName", value)}
          valueData={formData.decreasedName}
        />
        <DefaultInput
          placeholder={"DECREASED FORMAL NAME"}
          onChange={(value) => handleInputChange("decreasedNameFormal", value)}
          valueData={formData.decreasedNameFormal}
        />
        <DefaultInput
          placeholder={"Transport to"}
          onChange={(value) => handleInputChange("transportTo", value)}
          valueData={formData.transportTo}
        />
      </div>
      <Row>
        <div className="d-flex justify-content-end">
          <Button onClick={handleSubmit}>SAVE AUTORIZATION OF RELEASE</Button>
        </div>
      </Row>
    </>
  );
};

export default EmbalmingForm;
