import React, { useState, useEffect } from "react";
import { Button, Row } from "react-bootstrap";
import DefaultInput from "../../../../components/Elements/DefaultInput";
import DefaultCalendar from "../../../../components/Elements/DefaultCalendar";
import DefaultRadioButtons from "../../../../components/Elements/DefaultRadioButtons";
import GerUsersFromCookies from "../backend/GetUserFromCookies";
import api from "../../../../services/api";
import { toast } from "react-toastify";
const FuneralInfo = ({ onFuneralDataChange, folioId, folio}) => {
  const [formData, setFormData] = useState({
    secuencia: folio,
    folio: folioId,
    usuario_id: GerUsersFromCookies().id_usuario,
    methodDispositionOptions: "",
    methodDispositionValue: "",
    dateDisposition: "",
    placeDispositionFirstFacility: "",
    placeDispositionSecondFacility: "",
    nameFuneralDirector: "",
    licenceNumber: "",
    nameFuneralHome: "",
    addressFuneralHome: "",
    otherResponsableRelationship: "",
  });
  const MethodDisposition = [
    { label: "Burial", value: "1" },
    { label: "Creamtion", value: "2" },
    { label: "Donation", value: "3" },
    { label: "Entombment", value: "4" },
    { label: "Donation/Burial", value: "5" },
    { label: "Donation/Cremation", value: "6" },
    { label: "Donation/Entombment", value: "7" },
    { label: "From State", value: "8" },
    { label: "Burial", value: "9" },
    { label: "Creamtion", value: "10" },
    { label: "Donation", value: "11" },
    { label: "Entombment", value: "12" },
    { label: "Donation/Burial", value: "13" },
    { label: "Donation/Cremation", value: "14" },
    { label: "Donation/Entombment", value: "15" },
    { label: "Unknown", value: "16" },
    { label: "Other", value: "17" },
  ];
  const handleInputChange = (name, value) => {
    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    setFormData(updatedFormData);
    onFuneralDataChange(updatedFormData);
  };
  useEffect(() => {
    get_funeral_info(folioId);
  }, []);
  const GetFuneralHomeInformation = async () => {
    try {
      const response = await api.get(
        "documents/form1/funeral_info_Controller.php",
        { params: { action: "GET" } }
      );
      const funeral_info = response.data[0];
      if (response.data[0] != null) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          nameFuneralDirector: funeral_info.nameFuneralDirector,
          licenceNumber: funeral_info.licenceNumber,
          nameFuneralHome: funeral_info.nameFuneralHome,
          addressFuneralHome: funeral_info.adressFuneralHome,
        }));
      }
    } catch (error) {
    }
  };
  const get_funeral_info = async (id) => {
    try {
      const response = await api.get(
        `documents/form1/FuneralCompanyController.php?id=${id}`
      );
      const funeral_info = response.data[0];
      if (response.data[0] != null) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          methodDispositionOptions: funeral_info.methodDispositionOptions,
          methodDispositionValue: funeral_info.methodDispositionValue,
          dateDisposition: funeral_info.dateDisposition,
          placeDispositionFirstFacility:
            funeral_info.placeDispositionFirstFacility,
          placeDispositionSecondFacility:
            funeral_info.placeDispositionSecondFacility,
          nameFuneralDirector: funeral_info.nameFuneralDirector,
          licenceNumber: funeral_info.licenceNumber,
          nameFuneralHome: funeral_info.nameFuneralHome,
          addressFuneralHome: funeral_info.addressFuneralHome,
          otherResponsableRelationship:
            funeral_info.otherResponsableRelationship,
        }));
      } else {
        GetFuneralHomeInformation();
      }
    } catch {}
  };
  const handleSubmit = () => {
    add_funeral_info(formData);
  };
  const add_funeral_info = async (formData) => {
    try {
      const response = await api.post(
        "documents/form1/FuneralCompanyController.php",
        formData,
        { params: { action: "POST" } }
      );
      toast.success("Funeral info added successfully", {
        className: "toast-success",
        theme: "colored",
      });
    } catch (error) {
      toast.error("Error to add fuenral info", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };
  useEffect(() => {
    if (Object.keys(formData).some(key => formData[key] !== "")) {
      onFuneralDataChange(formData);
    }
  }, [formData, onFuneralDataChange]);
  return (
    <>
      <div className="main-card">
        <DefaultRadioButtons
          numberRow={"19A.-"}
          label="METHOD OF DISPOSITION"
          options={MethodDisposition}
          onChange={(formData) =>
            handleInputChange("methodDispositionOptions", formData)
          }
          valueData={formData.methodDispositionOptions}
        />
        <DefaultInput
          placeholder={"Other (Specify)"}
          type={"text"}
          onChange={(value) =>
            handleInputChange("methodDispositionValue", value)
          }
          valueData={formData.methodDispositionValue}
        />
        <DefaultCalendar
          numberRow={"19B.-"}
          placeholder={"DATE OF DISPOSITION"}
          onChange={(value) => handleInputChange("dateDisposition", value)}
          valueData={formData.dateDisposition}
        />

        <DefaultInput
          numberRow={"20A.-"}
          placeholder={
            "PLACE OF DISPOSITION - NAME OF FIRST DISPOSITION FACILITY"
          }
          type={"text"}
          onChange={(value) =>
            handleInputChange("placeDispositionFirstFacility", value)
          }
          valueData={formData.placeDispositionFirstFacility}
        />
        <DefaultInput
          numberRow={"20B.-"}
          placeholder={
            "PLACE OF DISPOSITION - NAME OF SECOND DISPOSITION FACILITY"
          }
          type={"text"}
          onChange={(value) =>
            handleInputChange("placeDispositionSecondFacility", value)
          }
          valueData={formData.placeDispositionSecondFacility}
        />
        <DefaultInput
          numberRow={"21A.-"}
          placeholder={"NAME OF FUNERAL DIRECTOR (first, middle, last, suffix)"}
          type={"text"}
          onChange={(value) => handleInputChange("nameFuneralDirector", value)}
          valueData={formData.nameFuneralDirector}
        />
        <DefaultInput
          numberRow={"21B.-"}
          placeholder={"LICENSE NUMBER"}
          type={"text"}
          onChange={(value) => handleInputChange("licenceNumber", value)}
          valueData={formData.licenceNumber}
        />
        <DefaultInput
          numberRow={"21B.-"}
          placeholder={"NAME OF FUNERAL HOME"}
          type={"text"}
          onChange={(value) => handleInputChange("nameFuneralHome", value)}
          valueData={formData.nameFuneralHome}
        />
        <DefaultInput
          numberRow={"22.-"}
          placeholder={"ADDRESS OF FUNERAL HOME OR OTHER RESPONSIBLE PARTY"}
          type={"text"}
          onChange={(value) => handleInputChange("addressFuneralHome", value)}
          valueData={formData.addressFuneralHome}
        />
        <DefaultInput
          numberRow={"23.-"}
          placeholder={"OTHER RESPONSIBLE PARTY RELATIONSHIP"}
          type={"text"}
          onChange={(value) =>
            handleInputChange("otherResponsableRelationship", value)
          }
          valueData={formData.otherResponsableRelationship}
        />
      </div>
      <div className="d-flex justify-content-end mt-3">
        <Button onClick={handleSubmit}>SAVE FUNERAL INFO</Button>
      </div>
    </>
  );
};

export default FuneralInfo;
