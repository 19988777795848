import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

const DefaultInputNoLabel = ({ numberRow, type, placeholder, onChange, valueData }) => {
  const [inputValue, setInputValue] = useState(valueData);

  useEffect(() => {
    if (valueData === 0) {
      setInputValue("");
    } else {
      setInputValue(valueData);
    }
  }, [valueData]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onChange(newValue);
  };

  return (
    <Form.Group className="mt-2 mb-2">
      <Form.Control
        type={type}
        onChange={handleChange}
        value={inputValue}
      />
    </Form.Group>
  );
};

export default DefaultInputNoLabel;
