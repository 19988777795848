import api from "../../../../services/api";

const GetInfo_Form5_Responsible = async ({ folio }) => {
  try {
    const response = await api.get(
      `documents/form5/ResponsibleInfoController.php?id=${folio}`
    );
    const haveData = response.data[0];

    return {
      folio: haveData?.folio || "",
      consecutivo: haveData?.secuencia || "",
      NameOfResponsibleParty_1: haveData?.nameRes1 || "",
      Relationship_1: haveData?.relationRes1 || "",
      Address_1: haveData?.addressRes1 || "",
      Phone_1: haveData?.phoneRes1 || "",
      NameOfResponsibleParty_2: haveData?.nameRes2 || "",
      Relationship_2: haveData?.relationRes2 || "",
      Address_2: haveData?.addressRes2 || "",
      Phone_2: haveData?.phoneRes2 || "",
    };
  } catch (error) {
    console.log("Error get info", error);
    return {
      folio: "",
      consecutivo: "",
      NameOfResponsibleParty_1: "",
      Relationship_1: "",
      Address_1: "",
      Phone_1: "",
      NameOfResponsibleParty_2: "",
      Relationship_2: "",
      Address_2: "",
      Phone_2: "",
    };
  }
};

export default GetInfo_Form5_Responsible;
