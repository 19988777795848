import React, { useState, useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendar } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
const DefaultDatePicker = ({ numberRow, placeholder, onChange, valueData }) => {
  const [date, setDate] = useState(null);
  const [showYearDropdown, setShowYearDropdown] = useState(false);
  const [showMonthDropdown, setShowMonthDropdown] = useState(false);
  const [startYear, setStartYear] = useState(new Date().getFullYear());
  useEffect(() => {
    if (valueData) {
      const parsedDate = parseDate(valueData);
      if (parsedDate && parsedDate !== date) {
        setDate(parsedDate);
      }
    }
  }, [valueData]);

  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
    onChange(formatDate(selectedDate));
  };

  const formatDate = (date) => {
    if (!date) return "";
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const parseDate = (dateString) => {
    if (dateString.includes("-")) {
      const [year, month, day] = dateString.split("-").map(Number);
      const isoDate = new Date(year, month - 1, day);
      if (!isNaN(isoDate.getTime())) {
        return isoDate;
      }
    }
    const [month, day, year] = dateString.split("/").map(Number);
    const fallbackDate = new Date(year, month - 1, day);
    return !isNaN(fallbackDate.getTime()) ? fallbackDate : null;
  };
  const handlePrev = () => {
    setStartYear(startYear - 12);
  };

  const handleNext = () => {
    setStartYear(startYear + 12);
  };
  const handlePrevOnOne = () => {
    setStartYear(startYear - 1);
  };

  const handleNextOnOne = () => {
    setStartYear(startYear + 1);
  };
  const YearDropdown = ({ year, onYearChange }) => {
    const endYear = startYear - 11;
    const years = [];

    for (let i = startYear; i >= endYear; i--) {
      years.push(i);
    }

    const handleYearClick = (year) => {
      onYearChange(year);
      setShowYearDropdown(false);
      setShowMonthDropdown(!showMonthDropdown);
    };

    return (
      <>
        <div className="year-navigation">
          <a onClick={handlePrev}>
          <IoIosArrowBack />

          </a>
          <span style={{ marginLeft: "10px", marginRight: "10px",fontWeight: "bold",fontSize:"18px" }}>
            {startYear - 11} - {startYear}
          </span>
          <a onClick={handleNext}>
          <IoIosArrowForward />
          </a>
        </div>
        <hr></hr>
        <div className="year-grid">
          {years.map((yearOption, index) => (
            <div
              key={index}
              className={`year-option ${yearOption === year ? "selected" : ""}`}
              onClick={() => handleYearClick(yearOption)}
              style={{ padding: "20px",fontSize:"16px" }}
            >
              {yearOption}
            </div>
          ))}
        </div>
      </>
    );
  };

  const MonthDropdown = ({ month, onMonthChange, currentYear }) => {
    const months = Array.from({ length: 12 }, (_, index) => ({
      value: index,
      label: new Date(0, index).toLocaleString("default", { month: "long" }),
    }));

    const handleMonthClick = (monthIndex) => {
      onMonthChange(monthIndex);
      setShowMonthDropdown(false);
    };

    return (
      <>
        <div className="month-grid">
          {months.map((monthOption) => (
            <div
              key={monthOption.value}
              className={`month-option ${
                monthOption.value === month ? "selected" : ""
              }`}
              onClick={() => handleMonthClick(monthOption.value)}
              style={{fontSize:"16px"}}
            >
              {monthOption.label}
            </div>
          ))}
        </div>
      </>
    );
  };

  const changeMonthToYear = () => {
    setShowMonthDropdown(false);
    setShowYearDropdown(!showYearDropdown);
  };
  const showModalDates = () => {
    setShowMonthDropdown(!showMonthDropdown);
  };
  return (
    <Form.Group>
      <Form.Label style={{ fontWeight: "700" }}>{numberRow}</Form.Label>
      <Form.Label>{placeholder}</Form.Label>
      <br />
      <InputGroup>
        <div style={{ display: "flex", width: "100%" }}>
          <InputGroup.Text
            id="basic-addon1"
            style={{ borderRadius: "10px 0px 0px 10px" }}
          >
            <FaCalendar />
          </InputGroup.Text>

          <DatePicker
            className="form-control input-date"
            selected={date}
            onChange={handleDateChange}
            dateFormat="MM/dd/yyyy"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div className="custom-header">
                <a onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                  <IoIosArrowBack />
                </a>

                <div
                  onClick={() => showModalDates()}
                  className="year-option"
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "18px",
                  }}
                >
                  {new Date(0, date.getMonth()).toLocaleString("default", {
                    month: "long",
                  })}{" "}
                  {date.getFullYear()}
                </div>

                <a onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                  <IoIosArrowForward />
                </a>
                {showYearDropdown && (
                  <div className="date-dropdown">
                    <YearDropdown
                      year={date.getFullYear()}
                      onYearChange={changeYear}
                    />
                  </div>
                )}

                {showMonthDropdown && (
                  <div className="date-dropdown">
                    <div className="year-navigation d-flex justify-content-center">
                      <a
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                          cursor: "pointer",
                          fontWeight: "bold"
                        }}
                        onClick={() => changeMonthToYear()}
                      >
                        {date.getFullYear()}
                      </a>
                    </div>
                    <hr></hr>
                    <MonthDropdown
                      month={date.getMonth()}
                      onMonthChange={changeMonth}
                      currentYear={startYear}
                    />
                  </div>
                )}
              </div>
            )}
          />
        </div>
      </InputGroup>

      <style jsx>{`
        .custom-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.5rem;
          background-color: #f1f1f1;
          position: relative;
        }
        .custom-header a {
          font-size: 24px;
          padding: 5px;
          border-radius: 10px;
          transition: all 0.3s;
        }
        .custom-header a:hover {
          font-size: 24px;
          background-color: #d3d3d3;
        }

        .date-dropdown {
          display: flex;
          flex-direction: column;
          padding: 0.5rem;
          background-color: white;
          border: 1px solid #ccc;
          z-index: 1000;
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 10px;
        }

        .year-navigation,
        .month-grid {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .year-grid,
        .month-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 15px;
        }

        .year-option,
        .month-option {
          padding: 10px;
          cursor: pointer;
          text-align: center;
          border-radius: 10px;
        }

        .year-option.selected,
        .month-option.selected {
          background-color: #007bff;
          color: white;
        }

        .year-option:hover,
        .month-option:hover {
          background-color: #d3d3d3;
        }
      `}</style>
    </Form.Group>
  );
};

export default DefaultDatePicker;
