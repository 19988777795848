import React, { useState, useEffect } from "react";
import { Col, Row, Form, Button, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Detail from "./ProfileComponents/ProfileDetail";
import Comments from "./ProfileComponents/Comments";
import GetUsersFromCookies from "../../components/GetUserFromCookies";
import AddDocumentation from "./ProfileComponents/AddDocumentation";
import ViewFiles from "./ProfileComponents/Files";
import api from "../../services/api";
import { toast } from "react-toastify";
const Profile = () => {
  const userID = GetUsersFromCookies().id_usuario;
  const { folio } = useParams();
  const [newComment, setNewComment] = useState("");
  const [profileDetail, setProfileDetail] = useState([]);
  const [commentsProfileDetail, setCommentsProfileDetail] = useState([]);
  const [consecutivo, setConsecutivo] = useState();
  const [recargarDocumentos, setRecargarDocumentos] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    getArchiveDetail();
    getCommentsProfile();
  }, []);

  const getArchiveDetail = async () => {
    try {
      const response = await api.get(
        `archive/profile/ProfileDetailController.php?id=${folio}`,
        { params: { action: "GET" } }
      );
      if (response.data != null) {
        setProfileDetail(response.data);
        setConsecutivo(response.data[0]['consecutivo']);
      } else {
        setProfileDetail([]);
      }
    } catch (error) {
      console.error("Error al buscar detalles del archivo:", error);
    }
  };

  const getCommentsProfile = async () => {
    try {
      const response = await api.get(
        `archive/profile/CommentDetailController.php?id=${folio}`,
        { params: { action: "GET" } }
      );
      if (response.data != null) {
        setCommentsProfileDetail(response.data);
      } else {
        setCommentsProfileDetail([]);
      }
    } catch (error) {
      console.error("Error al buscar comentarios del perfil:", error);
    }
  };

  const AddComment = async () => {
    if (newComment == "") {
      console.log("is empty")
      setError("Comment cannot be empty");
      toast.error("Comment cannot be empty", {
        className: "toast-error",
        theme: "colored",
      });
      return;
    }
    else {
      console.log("no empty")

      setError("");
      const newCommentData = {
        folio_id: folio,
        usuario_id: userID,
        commentary: newComment,
        isDelete: false,
      };
      try {
        const response = await api.post(
          'archive/profile/CommentDetailController.php',
          { comments: [newCommentData] },
          { params: { action: "POST" } }
        );
        getCommentsProfile();
        setNewComment("");
      } catch (error) {
        console.error("Error adding comment", error);
      }
    };
  }

  const recargaDocumentos = (value) => {
    setRecargarDocumentos(value);
  };

  return (
    <>
      <Row>
        <AddDocumentation folio={folio} recargaDocumentos={recargaDocumentos} />
      </Row>
      <Row>
        <Col lg="9" md="12">
          <Row>
            <Col>
              <ViewFiles folio={folio} consecutivo={consecutivo} recargarDocumentos={recargarDocumentos} recargaDocumentos={recargaDocumentos} />
            </Col>
          </Row>
        </Col>
        <Col lg="3" md="12">
          <Row>
            <Col>
              <div className="main-card" style={{ height: "50vh" }}>
                <div>
                  <Detail data={profileDetail} />
                  <Comments data={commentsProfileDetail} userID={userID} />
                </div>
              </div>
              <div style={{ padding: "10px", width: "100%" }}>
                <div>
                  <div >
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      placeholder="Write a comment here..."
                    />
                    <div className="d-flex justify-content-end mt-2">
                      <Button className="w-100" onClick={AddComment}>
                        Post comment
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Profile;
