import React, { useState, useEffect } from "react";
import { Col, Row, Button, Form, Modal } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import { SlPencil } from "react-icons/sl";
import { SketchPicker } from "react-color";

function CustomLabels({
  data,
  perPage,
  currentPage,
  onPageChange,
  onDelete,
  onEdit,
  onAdd,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [deletingItemId, setDeletingItemId] = useState(null);
  const [newUserData, setNewUserData] = useState({});

  const totalPages = Math.ceil(data && data.length ? data.length / perPage : 0);

  useEffect(() => {
    if (editingItem !== null) {
      // Si estamos editando un elemento, insertar su información en los inputs
      setNewUserData(editingItem);
    } else {
      // Si no estamos editando, limpiar los inputs
      setNewUserData({});
    }
  }, [editingItem]);

  const goToPage = (page) => {
    onPageChange(page);
  };

  const filteredData = data
    ? data.filter((item) =>
        Object.values(item).some((value) =>
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
    : [];

  const editItem = (item) => {
    setEditingItem(item);
    setShowModal(true);
  };

  const deleteItem = (itemId) => {
    setDeletingItemId(itemId);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setDeletingItemId(null);
    setNewUserData({});
    setEditingItem(null); // Limpiar el elemento en edición
  };

  const handleSaveChanges = () => {
    if (deletingItemId !== null) {
      console.log("Delete:", deletingItemId);
      onDelete(deletingItemId); // Llamar a la función onDelete con el ID del elemento a eliminar
    } else if (editingItem) {
      console.log("Save changes:", editingItem);
      onEdit(newUserData); // Enviar los datos editados al componente principal
    } else {
      console.log("Add item:", newUserData);
      onAdd(newUserData); // Enviar los datos del nuevo usuario al componente principal
    }
    setShowModal(false);
    setDeletingItemId(null);
    setNewUserData({});
  };

  const generateAddUserForm = () => {
    const keys = data && data.length ? Object.keys(data[0]) : [];
    return keys.map((key) => {
      if (key !== "ID") {
        if (key.toLowerCase().includes("color")) {
          // Si la clave contiene la palabra "color"
          return (
            <Form.Group key={key} controlId={key}>
              <Form.Label>{key}</Form.Label>
              <div
                style={{
                  backgroundColor: newUserData[key] || "#ffffff",
                  width: "40px",
                  height: "40px",
                  border: "1px solid #ced4da",
                }}
              ></div>
              <SketchPicker
                color={newUserData[key] || "#ffffff"} // Valor inicial del color, blanco por defecto
                onChange={(color) => {
                  setNewUserData((prevData) => ({
                    ...prevData,
                    [key]: color.hex, // Actualizar el estado con el color en formato hexadecimal
                  }));
                }}
              />
            </Form.Group>
          );
        } else {
          return (
            <Form.Group key={key} controlId={key}>
              <Form.Label>{key}</Form.Label>
              <Form.Control
                type="text"
                value={newUserData[key] || ""}
                onChange={(e) => {
                  setNewUserData((prevData) => ({
                    ...prevData,
                    [key]: e.target.value,
                  }));
                }}
              />
            </Form.Group>
          );
        }
      }
      return null;
    });
  };

  return (
    <div>
      <div>
        {filteredData
          .slice((currentPage - 1) * perPage, currentPage * perPage)
          .map((item, index) => {
            const itemIndex = index + 1 + (currentPage - 1) * perPage;
            return (
              <div key={index}>
                
                {Object.keys(item)
                  .slice(1)
                  .map((key, index) => (
                    <div key={index}>
                      <Row className="justify-content-end">
                        <Col className="d-flex mb-1 col-6">
                          <label
                            className="d-flex"
                            style={{ width: "100%",fontWeight:"700",fontSize:"16px" }}
                          >
                            {key}
                          </label>
                        </Col>
                        <Col className="col-6">
                          <label className="text-right"
                           style={{ width: "100%",fontSize:"16px" }}
                           >{item[key]}</label>
                        </Col>
                      </Row>
                    </div>
                  ))}
                  <Row className="mt-5 justify-content-center align-items-center">
                  <Button
                    variant="info"
                    onClick={() => editItem(item)}
                    className="table_button"
                    style={{width:"200px"}}
                  >
                    <SlPencil color="#fff"></SlPencil>
                    <span style={{marginLeft:"10px",color:"#fff"}}>Edit</span>
                  </Button>
                </Row>
              </div>
            );
          })}
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {deletingItemId !== null
              ? "Confirm"
              : editingItem
              ? "Edit item"
              : "Add item"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deletingItemId !== null ? (
            <p>Are you sure you want to delete this item?</p>
          ) : editingItem ? (
            generateAddUserForm()
          ) : (
            generateAddUserForm()
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            variant={deletingItemId !== null ? "light" : "primary"}
            onClick={handleSaveChanges}
          >
            {deletingItemId !== null
              ? "Delete"
              : editingItem
              ? "Save changes"
              : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CustomLabels;
