import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import getUserDataFromCookies from "../../components/GetUserFromCookies";
import { PDFViewer } from "@react-pdf/renderer";
import Cookies from "js-cookie";
import {
  GetDecedentInfo,
  GetCertifierInfo,
  GetDecedentOcupationInfo,
  GetFatherInfo,
  GetFuneralInfo,
  GetInformantInfo,
  GetMotherInfo,
  GetRecindenceInfo,
  GetSpouceInfo,
} from "./GetInfoDocuments/form1-death-worksheet/GetInfo_Form1";

import GetInfoForm2 from "./GetInfoDocuments/form2-invoice/GetInfo_Form2";
import GetInfoForm3 from "./GetInfoDocuments/form3-embalming/GetInfo_Form3";
//FORM 5
import GetInfo_Form5_DeceasedInfo from "./GetInfoDocuments/form5-notice-purchaser/GetInfo_Form5-DeceasedInfo";
import GetInfo_Form5_PackageSelected from "./GetInfoDocuments/form5-notice-purchaser/GetInfo_Form5-PackageSelected";
import GetInfo_Form5_Responsible from "./GetInfoDocuments/form5-notice-purchaser/GetInfo_Form5-Responsible";
import GetInfoForm6 from "./GetInfoDocuments/form6-crematory/GetInfo_Form6";
import GetInfoForm8 from "./GetInfoDocuments/form8-tucson-authorization/GetInfo_Form8"

import PDF_Form1 from "../documents/Form1/PDF_Form1";
import PDF_Form3 from "../documents/Form3-Embalming/PDF_Form3";
import PDF_Form2 from "../documents/Form2-Invoice/PDF_Form2";
import PDF_Form5 from "../documents/Form5-NoticePurchaser/PDF_Form5";
import PDF_Form6 from "../documents/Form6-CrematoryAuthorization/PDF_Form6";
import PDF_Form3_1 from "../documents/Form3-Embalming/PDF_Form3_1";
import PDF_Form8 from "../documents/Form8-CrematoryTucson/PDF_Form8"

import SignDocumentModalBoxExterno from "../../components/ComponentSign/SignDocumentModalBoxExterno";

const SelectDocument = ({ toSign }) => {
  toSign = true;
  const { folio, consecutivo, numForm } = useParams();
  console.log(numForm);
  const [numDocument, setNumDocument] = useState(null);
  const [info, setInfo] = useState(null);
  const [form5_Responsible, setForm5_Responsible] = useState(null);
  const [form5_PackageSelected, setForm5_PackageSelected] = useState(null);
  const [form5_DeceasedInfo, setForm5_DeceasedInfo] = useState(null);
  const [signatures, setSignatures] = useState([]);
  const [rolID, setRolID] = useState(4);
  const [id_usuario, setUsuarioID] = useState(0);
  const [decedentInfo, setDecedent] = useState(null);
  const [certifierInfo, setCertifierInfo] = useState(null);
  const [decedentOcupation, setDecedentOcupation] = useState(null);
  const [fatherInfo, setFatherInfo] = useState(null);
  const [funeralInfo, setFuneralInfo] = useState(null);
  const [informantInfo, setInformantInfo] = useState(null);
  const [recidenceInfo, setRecidenceInfo] = useState(null);
  const [spouceInfo, setSpouceInfo] = useState(null);
  const [motherInfo, setMotherInfo] = useState(null);

  const userData = getUserDataFromCookies();

  const fetchData = async (formFetcher, docNumber) => {
    const result = await formFetcher({ folio });
    setNumDocument(docNumber);
    setInfo(result);
    console.log(info, docNumber);
  };
  const fetchDataSecuencia = async (formFetcher, docNumber) => {
    const result = await formFetcher({ consecutivo });
    setNumDocument(docNumber);
    setInfo(result);
  };
  const onSignatureComplete = (signature, type) => {
    if (type === "success") {
      updateSignature(signature);
      console.log("Guardo con exito la firma");
    }
  };
  const updateSignature = (newSignature) => {
    setSignatures(newSignature);
  };
  const fetchDataForm5 = async () => {
    const DeceasedInfo = await GetInfo_Form5_DeceasedInfo({ consecutivo });
    const PackageSelected = await GetInfo_Form5_PackageSelected({
      consecutivo,
    });
    const ResponsibleParty = await GetInfo_Form5_Responsible({ folio });

    setForm5_DeceasedInfo(DeceasedInfo);
    setForm5_PackageSelected(PackageSelected);
    setForm5_Responsible(ResponsibleParty);
    setNumDocument(5);
  };

  useEffect(() => {
    const userData = getUserDataFromCookies();
    if (!userData) {
      console.log("entro al metodo");
      const newUserData = {
        id_usuario: id_usuario,
        nombre: "guest_name",
        apellido: "guest_lastname",
        usuario: "guest",
        rol_id: rolID,
      };
      Cookies.set("userData", JSON.stringify(newUserData));
      console.log("getCookies", newUserData);
    } else {
      setRolID(userData.rol_id);
      setUsuarioID(userData.id_usuario);
    }
  }, []);
  useEffect(() => {
    switch (numForm) {
      case "1":
        fetchDataForm1();
        break;
      case "2":
        fetchData(GetInfoForm2, 2);
        break;
      case "3":
        fetchDataSecuencia(GetInfoForm3, 3);
        break;
      case "4":
        fetchData(GetInfoForm2, 1);
        break;
      case "5":
        fetchDataForm5();
        console.log("entro");
        break;
      case "6":
        fetchData(GetInfoForm6, 6);
        console.log("entro al 6");
        break;
      case "7":
        fetchDataSecuencia(GetInfoForm3, 7);
        console.log("entro al 6");
      case "8":
        fetchData(GetInfoForm8, 8);
        break;
    }
  }, [numForm]);
  const fetchDataForm1 = async () => {
    try {
      const results = await Promise.all([
        GetCertifierInfo({ folio }),
        GetDecedentInfo({ folio }),
        GetDecedentOcupationInfo({ folio, consecutivo }),
        GetFatherInfo({ folio }),
        GetFuneralInfo({ folio }),
        GetInformantInfo({ folio }),
        GetRecindenceInfo({ folio }),
        GetSpouceInfo({ folio }),
        GetMotherInfo({ folio })
      ]);

      const [
        certifierResult,
        decedentResult,
        occupationResult,
        fatherResult,
        funeralResult,
        informantResult,
        residenceResult,
        spouseResult,
        motherResult
      ] = results;
      console.log(results)
      if (results.every(result => result !== null && result !== undefined)) {
        setCertifierInfo(certifierResult);
        setDecedent(decedentResult);
        setDecedentOcupation(occupationResult);
        setFatherInfo(fatherResult);
        setFuneralInfo(funeralResult);
        setInformantInfo(informantResult);
        setRecidenceInfo(residenceResult);
        setSpouceInfo(spouseResult);
        setMotherInfo(motherResult);
        setNumDocument(1);
      } else {
        console.error("Uno o más fetch no devolvieron resultados válidos");
      }
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
  };

  const userName = rolID === 4 ? "user" : userData?.nombre;

  return (
    <>
      <Container className="signContainer">
        <Row className="header">
          <Col md={6} sm={12} style={{ display: "flex", justifyContent: "center", alignItems: "start", flexDirection: "column" }}>
            <h2>Document for Signature</h2>
            <h5>Folio: {folio}</h5>
            <h5>Deceased: {decedentInfo?.firstName} {decedentInfo?.middleName} {decedentInfo?.lastName}</h5>
            <div style={{ marginTop: "30px" }}>
              <p>Dear {userName},</p>
              <p className="signMargin">
                Please review the attached documents and sign where indicated. These documents are necessary for the internal processes of Ramirez Funeral Home.
                We ensure that all the information provided will be treated with the utmost confidentiality and will only be used for the specified purposes.
                <br />
                <br />
                Ramirez Funeral Home guarantees that the data and signature provided will not be misused. The information will be used exclusively for internal purposes and in accordance with our privacy policies.
                <br />
                <br />
                If you have any questions or need more information, do not hesitate to contact us.
                <br />
                <br />
                Press the button to sign:
              </p>
            </div>
            <div style={{ marginBottom: "30px" }}>
              {toSign && (
                <SignDocumentModalBoxExterno
                  documentNumber={numDocument}
                  folioID={folio}
                  userData={userData}
                  onSignatureComplete={onSignatureComplete}
                  id_usuario={id_usuario}
                  rolID={rolID}
                />
              )}
            </div>
          </Col>
          <Col md={6} sm={12}>
            <PDFViewer style={{ width: "100%", height: "800px" }}>
              {numDocument === 1 && (
                <PDF_Form1
                  certifierData={certifierInfo}
                  data={decedentInfo}
                  dataResidence={recidenceInfo}
                  fatherData={fatherInfo}
                  funeralData={funeralInfo}
                  informantData={informantInfo}
                  motherData={motherInfo}
                  occupationData={decedentOcupation}
                  spouseData={spouceInfo}
                  folio={folio}
                  usuarioID={userData.id_usuario}
                />
              )}
              {numDocument == 2 && <PDF_Form2 data={info} />}
              {numDocument == 3 && <PDF_Form3 data={info} />}
              {numDocument == 4 && <PDF_Form2 data={info} />}
              {numDocument == 5 && (
                <PDF_Form5
                  DecreacedInfo={form5_DeceasedInfo}
                  PackageInfo={form5_PackageSelected}
                  ResponsibleInfo={form5_Responsible}
                />
              )}
              {numDocument == 6 && <PDF_Form6 data={info} />}
              {numDocument === 7 && <PDF_Form3_1 data={info} />}
              {numDocument === 8 && <PDF_Form8 data={info}/>}
            </PDFViewer>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SelectDocument;
