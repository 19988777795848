import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";

const DefaultCountBtn = ({
  numberRow,
  placeholder,
  onChange,
  valueData,
  disabled,
}) => {
  const [count, setCount] = useState(valueData);

  useEffect(() => {
    if (valueData > 0) {
      setCount(valueData);
    } else {
      setCount(0);
    }
  }, [valueData]);

  const handleIncrement = () => {
    const newCount = count + 1;
    setCount(newCount);
    onChange(newCount);
  };
  let decrementBtn = "";

  const handleDecrement = () => {
    if (count > 0) {
      const newCount = count - 1;
      setCount(newCount);
      onChange(newCount);
      decrementBtn = "";
    } else {
      decrementBtn = "disable";
    }
  };
  return (
    <Form.Group className="mt-2 mb-2">
      <div className="d-flex align-items-center">
        <Form.Label style={{ fontWeight: "700" }}>{numberRow}</Form.Label>
        <Form.Label>{placeholder}</Form.Label>
      </div>
      <div className="d-flex">
        <Button variant="btn btn-primary" onClick={handleDecrement}>
          -
        </Button>
        <Form.Control
          type="text"
          readOnly
          value={count}
          style={{ textAlign: "center", width: "50px", margin: "0 10px" }}
        />
        <Button
          variant="btn btn-primary"
          onClick={handleIncrement}
          disabled={decrementBtn}
        >
          +
        </Button>
      </div>
    </Form.Group>
  );
};

export default DefaultCountBtn;
