import React, { useState, useEffect } from "react";
import DefaultInput from "../../../../components/Elements/DefaultInput";
import DefaultDatePicker from "../../../../components/Elements/DefaultCalendar";
import { Row, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import api from "../../../../services/api"
const InvoiceForm = ({ onInvoiceInfo, folioId, secuencia, usuarioId }) => {
  const [formData, setFormData] = useState({
      folio: folioId,
      secuencia: secuencia,
      usuarioID: usuarioId,
      AccountName: "",
      Amount: "",
      Date: "",
      DetailedExplanation: "",
      Total: "",
      Wires: "",
      Zelle: "",
      accountBankMexico: "",
      accountNameBankUSA: "",
      accountNumberUSA: "",
      bankNameMexico: "",
      bankNameUSA: "",
      clabeBankMexico: "",
      emailAddress: "",
      faxNumber: "",
      funeralDirectorName: "",
      funeralHomeAddress: "",
      funeralHomeName: "",
      licenceNumber: "",
      nameBankAccountMexico: "",
      paymentVoucher: "",
      phoneNumber: "",
      routingNumberUSA: "",
    }
  );

  const handleInputChange = (name, value) => {
    const updatedFormData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedFormData);
    onInvoiceInfo(updatedFormData);
  };
  useEffect(() => {
    onInvoiceInfo(formData);
  }, [formData]);

  useEffect(() => {
    getInvoice();
  }, []);

  const GetCompanyInfo = async () => {
    try {
      const response = await api.get(
        "company/CompanyController.php",
        { params: { action: "GET" } }
      );
      const bankInfo = response.data[0];
      if (response.data[0] != null) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          accountBankMexico: bankInfo["Account bank mexico"],
          accountNameBankUSA: bankInfo["Account name bank USA"],
          accountNumberUSA: bankInfo["Account number USA"],
          bankNameUSA: bankInfo["Bank Name USA"],
          bankNameMexico: bankInfo["Bank name mexico"],
          clabeBankMexico: bankInfo["CLABE bank mexico"],
          emailAddress: bankInfo["Email address"],
          faxNumber: bankInfo["Fax number"],
          funeralDirectorName: bankInfo["Funeral director name"],
          funeralHomeAddress: bankInfo["Funeral home address"],
          funeralHomeName: bankInfo["Funeral home name"],
          licenceNumber: bankInfo["Licence number"],
          nameBankAccountMexico: bankInfo["Name bank account mexico"],
          phoneNumber: bankInfo["Phone number"],
          routingNumberUSA: bankInfo["Routing number USA"],
          Wires: bankInfo["Wires"],
          Zelle: bankInfo["Zelle"],
        }));
      }
    } catch (error) {
      toast.error("Error when searching for company info", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };
  const GetTotalInvoice = async () => {
    try {
      const response = await api.get(
        `documents/form5/PackageInfoController.php?id=${secuencia}`
      );
      const invoiceData = response.data[0];
      if (response.data[0] != null) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          DetailedExplanation: invoiceData.packageSelected,
          Amount: invoiceData.Total,
          Total: invoiceData.Total,
        }));
      }
    } catch (error) {
      toast.error("Error when searching for company info", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };
  const getInvoice = async () => {
    try {
      const response = await api.get(
        `documents/form2/InvoiceController.php?id=${folioId}`
      );
      const invoiceData = response.data[0];
      console.log(response)
      if (response.data[0] != null) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          Date: invoiceData.Date,
          AccountName: invoiceData.AccountName,
          DetailedExplanation: invoiceData.DetailedExplanation,
          Amount: invoiceData.Amount,
          Total: invoiceData.Total,
          accountBankMexico: invoiceData.accountBankMexico,
          accountNameBankUSA: invoiceData.accountNameBankUSA,
          accountNumberUSA: invoiceData.accountNumberUSA,
          bankNameUSA: invoiceData.bankNameUSA,
          bankNameMexico: invoiceData.bankNameMexico,
          clabeBankMexico: invoiceData.clabeBankMexico,
          emailAddress: invoiceData.emailAddress,
          faxNumber: invoiceData.faxNumber,
          funeralDirectorName: invoiceData.funeralDirectorName,
          funeralHomeAddress: invoiceData.funeralHomeAddress,
          funeralHomeName: invoiceData.funeralHomeName,
          licenceNumber: invoiceData.licenceNumber,
          nameBankAccountMexico: invoiceData.nameBankAccountMexico,
          phoneNumber: invoiceData.phoneNumber,
          routingNumberUSA: invoiceData.routingNumberUSA,
          Wires: invoiceData.Wires,
          Zelle: invoiceData.Zelle,
        }));
      }
      else{
        GetCompanyInfo();
        GetTotalInvoice();
      }
    } catch (error) {
      toast.error("Error when searching for company info", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };
  const saveData = () => {
    console.log(formData);
    addInvoice(formData);
  };
  const addInvoice = async (formData) => {
    try {
      const response = await api.post(
        "documents/form2/InvoiceController.php",
        formData,
        { params: { action: "POST" } }
      );
      console.log(response);
      toast.success("Invoice info added successfully", {
        className: "toast-success",
        theme: "colored",
      });
    } catch (error) {
      toast.error("Error to add invoice info", {
        className: "toast-error",
        theme: "colored",
      });
      console.error("Error adding archive", error);
  
    }
  };
  return (
    <>
      <div className="main-card">
        <DefaultInput
          placeholder={"PAYMENT VOUCHER NO."}
          disabled={true}
          valueData={formData.folio}
        />
        <DefaultDatePicker
          placeholder={"DATE"}
          onChange={(value) => handleInputChange("Date", value)}
          valueData={formData.Date}
        />
        <DefaultInput
          placeholder={"ACCOUNT NAME"}
          onChange={(value) => handleInputChange("AccountName", value)}
          valueData={formData.AccountName}
        />
        <DefaultInput
          placeholder={"DETAILED EXPLANATION FOR CLAIM:"}
          onChange={(value) => handleInputChange("DetailedExplanation", value)}
          valueData={formData.DetailedExplanation}
        />
        <DefaultInput
          placeholder={"AMOUNT"}
          onChange={(value) => handleInputChange("Amount", value)}
          valueData={formData.Amount}
        />
        <DefaultInput
          placeholder={"TOTAL"}
          onChange={(value) => handleInputChange("Total", value)}
          valueData={formData.Total}
        />
      </div>
      <Row>
        <div className="d-flex justify-content-end">
          <Button onClick={saveData}>SAVE INVOICE</Button>
        </div>
      </Row>
    </>
  );
};

export default InvoiceForm;
