import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomLabels from "../../../components/Elements/LabelDeploy";
import api from "../../../services/api"
const Company = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);

  useEffect(() => {
    Get();
  }, []);

  const Get = async () => {
    try {
      const response = await api.get(
        'company/CompanyController.php',
        { params: { action: "GET" } }
      );
      setUsuarios(response.data);
    } catch (error) {
      toast.error("Error when searching for company info", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };
  const Edit = async (editedUserData) => {
    try {
      const response = await api.put(
        'company/CompanyController.php',
        editedUserData,
        { params: { action: "PUT" } }
      );
      Get();
      toast.success("Company information successfully modified.", {
        className: "toast-success",
        theme: "colored",
      });
    } catch (error) {
      toast.error("Unable to modify company information.", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="main-card">
        <Row className="p-2">
          <CustomLabels
            data={usuarios}
            perPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onEdit={Edit}
          />
        </Row>
      </div>
    </>
  );
};

export default Company;
