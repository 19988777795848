import React, { useState,useEffect } from "react";
import { Button, Col, Row} from "react-bootstrap";
import DefaultInput from "../../../../components/Elements/DefaultInput";
import DefaultRadioButtons from "../../../../components/Elements/DefaultRadioButtons";
import GerUsersFromCookies from "../backend/GetUserFromCookies"
import api from "../../../../services/api";
import { toast } from "react-toastify";

const SpouseInfo = ({ 
  onSpouseDataChange,
  folioId,
  folio}) => {
  const marital_status = [
    { label: "Married", value: "1" },
    { label: "Widowed", value: "2" },
    { label: "Divorced", value: "3" },
    { label: "Never Married", value: "4" },
    { label: "Married but Separated", value: "5" },
    { label: "Not Obtainable", value: "6" },
    { label: "Unknown", value: "7" },
    { label: "Never Married, Single", value: "8" },
  ];

  const [formData, setFormData] = useState({
    secuencia :folio,
    folio :folioId,
    usuario_id : GerUsersFromCookies().id_usuario,

    maritalStatus: "",
    firstNameSpouse: "",
    middleNameSpouse: "",
    lastNameSpouse: "",
    suffixSpouse: "",
    lastNameSurvivingSpouse: "",
  });
  const handleInputChange = (name, value) => {
    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    setFormData(updatedFormData);
    onSpouseDataChange(updatedFormData);
  };
  const handleSubmit = () => {
    add_spouceInfo(formData);
  };

  const add_spouceInfo = async (formData) => {
    try {
      const response = await api.post(
        "documents/form1/SpouceController.php",
        formData,
        { params: { action: "POST" } }
      );
      toast.success("Spouce info successfully added", {
        className: "toast-success",
        theme: "colored",
      });

    } catch (error) {
      toast.error("Error adding spouce info", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };
  useEffect(() => {
    get_spouce_info_with_ID(folioId);
  }, []);
  const get_spouce_info_with_ID = async (folio) =>{
    try{
      const response = await api.get(
        `documents/form1/SpouceController.php?id=${folio}`
      );
      const spouceInfo = response.data[0];
      if(response.data[0] != null){
        setFormData(prevFormData => ({
          ...prevFormData,
          maritalStatus: spouceInfo.maritalStatus,
          firstNameSpouse: spouceInfo.name,
          middleNameSpouse: spouceInfo.middle,
          lastNameSpouse: spouceInfo.lastname,
          suffixSpouse: spouceInfo.suffix,
          lastNameSurvivingSpouse: spouceInfo.lastnameSurvivingSpouce
      }));
      }
    }
    catch{}
  }
  useEffect(() => {
    if (Object.keys(formData).some(key => formData[key] !== "")) {
      onSpouseDataChange(formData);
    }
  }, [formData, onSpouseDataChange]);
  return (
    <>
      <div className="main-card">
        <DefaultRadioButtons
          numberRow={"14.-"}
          label={"MARITAL STATUS"}
          options={marital_status}
          onChange={(value) => handleInputChange("maritalStatus", value)}
          valueData={formData.maritalStatus}
        />
        <DefaultInput
          numberRow={"15A.-"}
          placeholder={"FIRST NAME OF SURVIVING SPOUSE"}
          type={"text"}
          onChange={(value) => handleInputChange("firstNameSpouse", value)}
          valueData={formData.firstNameSpouse}

        />
        <DefaultInput
          numberRow={"15B.-"}
          placeholder={"MIDDLE NAME OF SURVIVING SPOUSE"}
          type={"text"}
          onChange={(value) => handleInputChange("middleNameSpouse", value)}
          valueData={formData.middleNameSpouse}

        />
        <DefaultInput
          numberRow={"15C.-"}
          placeholder={"LAST NAME OF SURVIVING SPOUSE PRIOR TO FIRST MARRIAGE"}
          type={"text"}
          onChange={(value) => handleInputChange("lastNameSpouse", value)}
          valueData={formData.lastNameSpouse}

        />
        <DefaultInput
          numberRow={"15D.-"}
          placeholder={"SUFFIX"}
          type={"text"}
          onChange={(value) => handleInputChange("suffixSpouse", value)}
          valueData={formData.suffixSpouse}

        />
        <DefaultInput
          numberRow={"15E.-"}
          placeholder={"LAST NAME OF SURVIVING SPOUSE"}
          type={"text"}
          onChange={(value) =>
            handleInputChange("lastNameSurvivingSpouse", value)
          }
          valueData={formData.lastNameSurvivingSpouse}

        />
      </div>
      <div className="d-flex justify-content-end mt-3">
        <Button onClick={handleSubmit}>SAVE SPOUSE INFO</Button>
      </div>
    </>
  );
};

export default SpouseInfo;
