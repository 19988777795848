import { React, useState, useEffect } from "react";
import {
  Document,
  Page,
  Image,
  Text,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import { createContext, useContext, Children, cloneElement } from "react";
import api from "../../../services/api";
import { render } from "@testing-library/react";

const PDF_Form5 = ({ DecreacedInfo, PackageInfo, ResponsibleInfo }) => {
  console.log(PackageInfo)
  const folioID = PackageInfo["folio"];
  const [signatureInfo, setSignatureData] = useState(dataSignatureInfo);
  var userID = PackageInfo.usuarioID;

  var dataSignatureInfo = {
    usuario_id: userID,
    folio: folioID,
    num_form: "",
    documento: "",
    nameSign: ""
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  };

  useEffect(() => {
    const fetchSignatures = async () => {
      try {
        const response = await api.get(
          `sign/SignController.php?folio=${folioID}&num_form=5`
        );
        const data = response.data;
        setSignatureData(data);
      } catch (error) {
        console.error("Error fetching signatures info:", error);
        setSignatureData(prevState => ({ ...prevState, signatures: [] }));
      }
    };

    fetchSignatures();
  }, [folioID]);

  const getSignatureInfo = (name) => {
    if (signatureInfo && Array.isArray(signatureInfo)) {
      for (let i = 0; i < signatureInfo.length; i++) {
        const signature = signatureInfo[i];
        if (signature.nameSign === name) {
          const formattedDate = formatDate(signature.timeSign);
          return {
            date: formattedDate,
            image: signature.documento
          };
        }
      }
    }
    return null;
  };

  const renderSignature = (name) => {
    const signature = getSignatureInfo(name);
    if (signature) {
      return (
        <Image src={`data:image/png;base64,${signature.image}`} style={styles.signature} />
      );
    }
    return null;
  };

  const TableContext = createContext({});

  const Table = ({ children, sizes }) => (
    <TableContext.Provider value={{ sizes }}>
      <View style={{ borderBottom: 1, borderRight: 1 }}>{children}</View>
    </TableContext.Provider>
  );
  const Row = ({ children }) => {
    const { sizes } = useContext(TableContext);
    if (!sizes) throw Error("add sizes prop to Table component");
    return (
      <View style={{ flexDirection: "row", fontSize: 7 }}>
        {Children.map(children, (child, index) =>
          cloneElement(child, { size: sizes[index] })
        )}
      </View>
    );
  };

  const Cell = ({ children, size }) => (
    <View
      style={{
        padding: "1 1",
        flex: `0 0 ${size}%`,
        borderTop: 1,
        borderLeft: 1,
      }}
    >
      <Text>{children}</Text>
    </View>
  );

  const responsibleSignatureInfo = getSignatureInfo("Responsable Party");
  const funeralDirectorSignatureInfo = getSignatureInfo("Funeral Director");
  const FuneralArrangementCounselorSignatureInfo = getSignatureInfo("Funeral Arrangement Counselor");
  return (
    <Document>
      <Page style={styles.document}>
        <div style={styles.lines}></div>
        <div
          style={{
            flexDirection: "row",
            marginLeft: "40px",
            marginTop: "40px",
          }}
        >
          <Image
            source={"/img/logo/logo.png"}
            style={{ width: " 120px ", height: "120px" }}
          ></Image>
          <div
            style={{
              textAlign: "center",
              color: "#2f5496",
              flex: "1",
              alignItems: "center",
              marginTop: "30px",
              marginLeft: "-100px",
            }}
          >
            <Text>Ramirez Funeral Home LLC</Text>
            <Text>4595 S Palo Verde Rd. Suite 503, Tucson Arizona 85714</Text>
            <Text>Email: ramirezfuneralhome@gmail.com</Text>
            <Text>Phone: (520) 372-2479 Fax: (520) 842-3889</Text>
          </div>
        </div>
        <div style={styles.container}>
          <Text style={styles.title}>Notice to Purchaser</Text>
          <div
            style={{
              fontSize: 10,
              fontWeight: "normal",
              textAlign: "justify",
              fontFamily: "Roboto",
              color: "#000",
            }}
          >
            <Text>
              Charges are only for those items you select or are required. If we
              are required by law, by a cemetery or crematory, to use any items,
              we will explain the reason in writing below. If you selected a
              funeral service that requires embalming, such as a funeral
              viewing, you may have to pay for embalming. You do not have to pay
              for embalming you did not approve. If you selected arrangements
              such as direct cremation or immediate burial. If we charged for
              embalming, we will explain why below You do not have to pay for
              embalming if you disapprove. If you selected arrangements such as
              direct cremation or immediate burial. If we charged you for
              embalming, we would explain why below:
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <View style={{ flex: 1, alignItems: "center" }}>
                <Text style={{ fontSize: 15 }}>•</Text>
              </View>
              <View style={{ flex: 12 }}>
                <Text>
                  The deceased will be held at Ramirez Funeral Home, 4595 S Palo
                  Verde Rd. Suite 503 Tucson, Arizona 85714.
                </Text>
              </View>
            </View>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <View style={{ flex: 1, alignItems: "center" }}>
                <Text style={{ fontSize: 15 }}>•</Text>
              </View>
              <View style={{ flex: 12 }}>
                <Text>
                  The deceased will be embalmed at Ramirez Funeral Home LLC,
                  4595 S Palo Verde Rd. Suite 503 Tucson, Arizona 85714.
                </Text>
              </View>
            </View>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <View style={{ flex: 1, alignItems: "center" }}>
                <Text style={{ fontSize: 15 }}>•</Text>
              </View>
              <View style={{ flex: 12 }}>
                <Text>
                  Cremation will take place at Tucson Cremation Services, 4275 S
                  Randolph Ave, Tucson, AZ 85714.
                </Text>
              </View>
            </View>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <View style={{ flex: 1, alignItems: "center" }}>
                <Text style={{ fontSize: 15 }}>•</Text>
              </View>
              <View style={{ flex: 12 }}>
                <Text>
                  Ramirez Funeral Home Requires embalming for public viewing.
                </Text>
              </View>
            </View>

            <Text>
              The only warranty on the casket and/or burial container sold in
              connection with this service is the express written warranty, if
              any, granted by the manufacturer. This funeral home makes no
              warranty, express or implied, concerning the casket and or burial
              container. This establishment offers for sale caskets and outer
              burial containers, which may be purchased and used, at your
              option, in connection with funeral services and the final
              disposition of human remains. However, except under certain public
              health circumstances under A.R.S. 36-136, state law does not
              require the use or purchase of a casket or containers. Ramirez
              Funeral Home LLC is owned and opened by Marisol Ramirez; 4595 S
              Palo Verde Rd. Suite. 503; Tucson, AZ 85714.
            </Text>
            <div style={{ marginTop: "10px" }}>
              <Text style={styles.title}>Disclosure:</Text>
            </div>
            <Text>
              Ramirez Funeral Home agrees to provide all services, merchandise,
              and cash-advanced items described above. I/we, having read above,
              accept, and approve the same. I/we acknowledge that this contract
              is a binding legal document and understand that full payment is
              due 24 hours before the initial service event; unless other
              arrangements are made in advance and agreed upon in writing.
            </Text>
          </div>

          <View style={{ flexDirection: "row", marginTop: "10px" }}>
            <Text style={{ flex: 3, fontWeight: "bold", color: "#2f5496" }}>
              Name Of Deceased:
            </Text>
            <div style={{ flex: "6" }}>
              <Text>{DecreacedInfo["nameDeceased"]}</Text>
              <View
                style={{ borderBottom: "1px solid #000", marginTop: -4 }}
              ></View>
            </div>
            <Text
              style={{
                flex: 1,
                marginLeft: "10px",
                fontWeight: "bold",
                color: "#2f5496",
              }}
            >
              Age:
            </Text>
            <div style={{ flex: "3" }}>
              <Text>{DecreacedInfo["age"]}</Text>
              <View
                style={{ borderBottom: "1px solid #000", marginTop: -4 }}
              ></View>
            </div>
          </View>

          <View style={{ flexDirection: "row", marginTop: "10px" }}>
            <Text style={{ flex: 2, fontWeight: "bold", color: "#2f5496" }}>
              Contract #
            </Text>
            <div style={{ flex: "4" }}>
              <Text>{DecreacedInfo["folio"]}</Text>
              <View
                style={{ borderBottom: "1px solid #000", marginTop: -4 }}
              ></View>
            </div>
            <Text
              style={{
                flex: 2,
                marginLeft: "10px",
                fontWeight: "bold",
                color: "#2f5496",
              }}
            >
              Today’s Date:
            </Text>
            <div style={{ flex: "4" }}>
              <Text>{DecreacedInfo["todayDate"]}</Text>
              <View
                style={{ borderBottom: "1px solid #000", marginTop: -4 }}
              ></View>
            </div>
          </View>

          <View style={{ flexDirection: "row", marginTop: "10px" }}>
            <Text style={{ flex: 2, fontWeight: "bold", color: "#2f5496" }}>
              Date of Birth:
            </Text>
            <div style={{ flex: "4" }}>
              <Text>{DecreacedInfo["dateOfBirth"]}</Text>
              <View
                style={{ borderBottom: "1px solid #000", marginTop: -4 }}
              ></View>
            </div>
            <Text
              style={{
                flex: 2,
                marginLeft: "10px",
                fontWeight: "bold",
                color: "#2f5496",
              }}
            >
              Date of Death:
            </Text>
            <div style={{ flex: "4" }}>
              <Text>{DecreacedInfo["dateOfDeath"]}</Text>
              <View
                style={{ borderBottom: "1px solid #000", marginTop: -4 }}
              ></View>
            </div>
          </View>

          <View style={{ flexDirection: "row", marginTop: "10px" }}>
            <Text style={{ flex: 3, fontWeight: "bold", color: "#2f5496" }}>
              Social Security (SSN):
            </Text>
            <div style={{ flex: "3" }}>
              <Text>{DecreacedInfo["ssn"]}</Text>
              <View
                style={{ borderBottom: "1px solid #000", marginTop: -4 }}
              ></View>
            </div>
            <Text
              style={{
                flex: 2,
                marginLeft: "10px",
                fontWeight: "bold",
                color: "#2f5496",
              }}
            >
              Place of Death:
            </Text>
            <div style={{ flex: "3" }}>
              <Text>{DecreacedInfo["placeDeath"]}</Text>
              <View
                style={{ borderBottom: "1px solid #000", marginTop: -4 }}
              ></View>
            </div>
          </View>

          <View style={{ flexDirection: "row", marginTop: "10px" }}>
            <Text style={{ flex: 2, fontWeight: "bold", color: "#2f5496" }}>
              Weight:
            </Text>
            <div style={{ flex: "4" }}>
              <Text>{DecreacedInfo["weight"]}</Text>
              <View
                style={{ borderBottom: "1px solid #000", marginTop: -4 }}
              ></View>
            </div>
            <Text
              style={{
                flex: 2,
                marginLeft: "10px",
                fontWeight: "bold",
                color: "#2f5496",
              }}
            >
              Height:
            </Text>
            <div style={{ flex: "4" }}>
              <Text>{DecreacedInfo["height"]}</Text>
              <View
                style={{ borderBottom: "1px solid #000", marginTop: -4 }}
              ></View>
            </div>
            <Text
              style={{
                flex: 1,
                marginLeft: "10px",
                fontWeight: "bold",
                color: "#2f5496",
              }}
            >
              Sex:
            </Text>
            <div style={{ flex: "4" }}>
              {DecreacedInfo["sex"] == 1 && <Text>Famale</Text>}
              {DecreacedInfo["sex"] == 2 && <Text>Male</Text>}
              {DecreacedInfo["sex"] == 3 && <Text>Not Yet Determined</Text>}
              <View
                style={{ borderBottom: "1px solid #000", marginTop: -4 }}
              ></View>
            </div>
          </View>
        </div>
      </Page>

      <Page style={styles.document}>
        <div style={styles.lines}></div>
        <div style={styles.containerPage2}>
          <div style={{ flexDirection: "row" }}>
            <Text style={{ flex: 3, fontWeight: "bold", color: "#2f5496" }}>
              Packages Selected:
            </Text>
            <div style={{ flex: "11" }}>
              <Text>{PackageInfo["PackageSelected"]}</Text>
              <View
                style={{ borderBottom: "1px solid #000", marginTop: -4 }}
              ></View>
            </div>
          </div>

          <div style={{ flexDirection: "row" }}>
            <div style={{ flex: 8 }}>
              <Text style={{ fontWeight: "bold", color: "#2f5496", marginBottom: "5px", marginTop: "5px", fontSize: "7px" }}>All packages include basic services of the funeral director and staff and undesignated overhead.</Text>

              <View style={{ alignItems: "flex-start" }}>
                <Table sizes={[80, 20]}>
                  <Row>
                    <Cell>Basic Service of Funeral Director and Staff</Cell>
                    <Cell>${PackageInfo["basicService"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Embalming</Cell>
                    <Cell>${PackageInfo["embalming"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Refrigeration</Cell>
                    <Cell>${PackageInfo["refigeration"]}</Cell>
                  </Row>
                </Table>
                <Text style={{ fontWeight: "bold", color: "#2f5496", marginBottom: "3px", marginTop: "3px", fontSize: "6px" }}>OTHER PREPARATION OF THE BODY:</Text>
                <Table sizes={[80, 20]}>
                  <Row>
                    <Cell>Cosmetics, Dressing, or Casketing</Cell>
                    <Cell>${PackageInfo["cosmetics"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Temporary preservation procedures, i.e., out un-embalmed remain.</Cell>
                    <Cell>${PackageInfo["preservation"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Refrigeration, Cleansing, handling, and care of un-embalmed remains (out/in-state)</Cell>
                    <Cell>${PackageInfo["regriferation"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Washing and cleaning (required for ID viewing)</Cell>
                    <Cell>${PackageInfo["washing"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Other</Cell>
                    <Cell>${PackageInfo["otherBasic"]}</Cell>
                  </Row>
                </Table>
                <Text style={{ fontWeight: "bold", color: "#2f5496", marginBottom: "3px", marginTop: "3px", fontSize: "6px" }}>FACILITIES:</Text>
                <Table sizes={[80, 20]}>
                  <Row>
                    <Cell>Use of Facilities and Staff for Viewing</Cell>
                    <Cell>${PackageInfo["viewing"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Use of Facilities and Staff for Funeral Ceremony</Cell>
                    <Cell>${PackageInfo["ceremony"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Use of Equipment and Staff to Funeral Ceremony</Cell>
                    <Cell>${PackageInfo["equimentCeremony"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>At another location</Cell>
                    <Cell>${PackageInfo["otherLocation"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Use of Facilities and Staff for Memorial Service</Cell>
                    <Cell>${PackageInfo["memorialService"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Use of Equipment and Staff for Graveside Service</Cell>
                    <Cell>${PackageInfo["gravesideService"]}</Cell>
                  </Row>
                </Table>
                <Text style={{ fontWeight: "bold", color: "#2f5496", marginBottom: "3px", marginTop: "3px", fontSize: "6px" }}>AUTOMOTIVE:</Text>
                <Table sizes={[80, 20]}>
                  <Row>
                    <Cell>Transfer of Remains to Funeral Home</Cell>
                    <Cell>${PackageInfo["transferFuneralHome"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Transfer of Remains to Airport</Cell>
                    <Cell>${PackageInfo["transferAirport"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Hearse</Cell>
                    <Cell>${PackageInfo["hearse"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Service/Utility Vehicle</Cell>
                    <Cell>${PackageInfo["serviceVehicle"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Additional mileage</Cell>
                    <Cell>${PackageInfo["additionalMileage"]}</Cell>
                  </Row>
                </Table>

                <Text style={{ fontWeight: "bold", color: "#2f5496", marginBottom: "3px", marginTop: "3px", fontSize: "6px" }}>MISCELLANEOUS CREMATION CHARGES:</Text>
                <Table sizes={[80, 20]}>
                  <Row>
                    <Cell>Cremation Fee</Cell>
                    <Cell>${PackageInfo["otherBasic"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Packing and Mailing of Cremains - USPS</Cell>
                    <Cell>${PackageInfo["cremation"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Cremains delivered (25-mile radius)</Cell>
                    <Cell>${PackageInfo["packing"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Um personalization (engraving)</Cell>
                    <Cell>${PackageInfo["umPresonalization"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Jewelry – ({PackageInfo["JewelryCount"]} )</Cell>
                    <Cell>${PackageInfo["Jewelry"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Urn</Cell>
                    <Cell>${PackageInfo["urn"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Archival Fee</Cell>
                    <Cell>${PackageInfo["archival"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Account Set-Up Fee</Cell>
                    <Cell>${PackageInfo["accountSetup"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Other</Cell>
                    <Cell>${PackageInfo["Other"]}</Cell>
                  </Row>
                </Table>
                <Text style={{ fontWeight: "bold", color: "#2f5496", marginBottom: "3px", marginTop: "3px", fontSize: "6px" }}>CASH ADVANCES: (CERTAIN CHARGES MAY BE ESTIMATED)</Text>
                <Table sizes={[80, 20]}>
                  <Row>
                    <Cell>Death Certificate – Copier ({PackageInfo["deathCertificateCount"]})</Cell>
                    <Cell>${PackageInfo["deathCertificate"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Arizona State Disposition Permit</Cell>
                    <Cell>${PackageInfo["statePermit"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Pima County Medical Examiners Cremation Fee</Cell>
                    <Cell>${PackageInfo["pimaMedicalExaminers"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Consulate Fee</Cell>
                    <Cell>${PackageInfo["consulateFee"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Apostille Death Certificate</Cell>
                    <Cell>${PackageInfo["apostilleDeathCertificate"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Decent air transportation Fee</Cell>
                    <Cell>${PackageInfo["decentAirTransportation"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Prayer cards ({PackageInfo["prayerCardsCount"]})</Cell>
                    <Cell>${PackageInfo["prayerCards"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Flowers, Crucifix, or Register Book</Cell>
                    <Cell>${PackageInfo["flowersCrucifixBook"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Casket or Alternative Container</Cell>
                    <Cell>${PackageInfo["casket"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Description</Cell>
                    <Cell>${PackageInfo["description"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Other Burial Container</Cell>
                    <Cell>${PackageInfo["otherBurial"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Other</Cell>
                    <Cell>${PackageInfo["otherCharges1"]}</Cell>
                  </Row>
                  <Row>
                    <Cell>Other</Cell>
                    <Cell>${PackageInfo["otherCharges2"]}</Cell>
                  </Row>
                </Table>
              </View>
              <div style={{ flexDirection: "row", marginLeft: "175px" }}>
                <div >
                  <Text style={{ fontWeight: "bold", color: "#2f5496", fontSize: 8 }}>Sales Tax:   </Text>
                </div>
                <div>
                  <Text style={{ fontWeight: "bold", fontSize: 10 }}>${PackageInfo["TotalValue"]}</Text>
                </div>
              </div>
              <div style={{ flexDirection: "row" }}>
                <Text style={{ flex: 6, fontWeight: "bold", color: "#2f5496", fontSize: 8 }}>DOWN PAYMENT / ADJUSTMENTS:</Text>

              </div>
              <div style={{ flexDirection: "row" }}>
                <div style={{ flex: "12" }}>
                  <Text style={{ fontSize: "9" }}>{PackageInfo["downPaymentAdjustmentsDescription"]}</Text>
                  <View
                    style={{ borderBottom: "1px solid #000", marginTop: -4 }}
                  ></View>
                </div>
              </div>
              <div style={{ flexDirection: "row", marginTop: "5px", fontSize: "8px" }}>

                <Text style={{ position: "absolute", marginTop: "-1px", marginLeft: "2px", fontSize: "10px", fontWeight: "bold", width: "200px",}} >
                {PackageInfo["typePayment"] === 1 && "X"}</Text>
                <div style={{marginRight: "5px",border: " 1px solid #000",width: "10px",height: "10px",}}></div>
                <Text style={{ marginRight: "5px" }}>CASH</Text>


                <Text style={{ position: "absolute", marginTop: "-1px", marginLeft: "43px", fontSize: "10px", fontWeight: "bold", width: "200px",}} >
                {PackageInfo["typePayment"] === 2 && "X"}</Text>
                <div style={{marginRight: "5px",border: " 1px solid #000",width: "10px",height: "10px",}}></div>
                <Text style={{ marginRight: "5px" }}>CARD</Text>


                <Text style={{ position: "absolute", marginTop: "-1px", marginLeft: "83px", fontSize: "10px", fontWeight: "bold", width: "200px",}} >
                {PackageInfo["typePayment"] === 3 && "X"}</Text>
                <div style={{marginRight: "5px",border: " 1px solid #000",width: "10px",height: "10px",}}></div>
                <Text style={{ marginRight: "5px" }}>INSURANCE</Text>


                <Text style={{ fontWeight: "bold", color: "#2f5496", fontSize: 8 }}>OTHER:</Text>
                <div>
                  <Text style={{ fontSize: "9",textTransform:"uppercase" }}>{PackageInfo["Field1Text"]}</Text>
                  <View
                    style={{ borderBottom: "1px solid #000", marginTop: -4 }}
                  ></View>
                </div>


              </div>
              <div style={{ flexDirection: "row", marginTop: 5 }}>
                <Text style={{ flex: 2, fontWeight: "bold", color: "#2f5496", fontSize: 8 }}>PAYMENT:</Text>
                <div style={{ flex: "4" }}>
                  <Text style={{ fontWeight: "bold", fontSize: 10 }}>{PackageInfo["payment"]}</Text>
                  <View
                    style={{ borderBottom: "1px solid #000", marginTop: -4 }}
                  ></View>
                </div>
                <Text style={{ flex: 3, fontWeight: "bold", color: "#2f5496", fontSize: 8 }}>BALANCE DUE:</Text>
                <div style={{ flex: "3" }}>
                  <Text style={{ fontWeight: "bold", fontSize: 10, color: "red" }}>${PackageInfo["blanceDue"]}.00</Text>
                  <View
                    style={{ borderBottom: "1px solid #000", marginTop: -4 }}
                  ></View>
                </div>
              </div>
            </div>

            <div style={{ marginLeft: "20px", flex: "6", fontSize: "8", marginTop: "20px" }}>
              <Text>
                The foregoing statement has been read by (to) me (us) and I (we)
                hereby agree to pay according to the plan checked, the above
                funeral account, and for such additional services and materials
                as ordered by me as stated above. The liability assumed is in
                addition to the liability imposed by the law upon the state and
                others and shall not constitute a release thereof. INTEREST AT
                THE RATE OF 1.5% MONTHLY, EQUAL TO AN ANNUAL PERCENTAGE RATE OF
                18% WILL BE ADDED TO DELINQUENT ACCOUNTS WHEN PAYMENTS ARE NOT
                MADE ACCORDING TO THIS AGREEMENT. In the event of default of
                payment.
              </Text>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <View style={{ flex: 1, alignItems: "center" }}>
                  <Text style={{ fontSize: 15 }}>•</Text>
                </View>
                <View style={{ flex: 12 }}>
                  <Text>
                    I (we) agree to pay reasonable attorney fees,
                    collection costs, and court costs.
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <View style={{ flex: 1, alignItems: "center" }}>
                  <Text style={{ fontSize: 15 }}>•</Text>
                </View>
                <View style={{ flex: 12 }}>
                  <Text>
                    I (we) agree to pay reasonable attorney fees,
                    collection costs, and court costs.
                  </Text>
                </View>
              </View>
              <Text style={{ fontWeight: "bold", color: "#2f5496", fontSize: 12, marginTop: "20px" }}>Name of responsible party:</Text>
              <div style={{ flexDirection: "row" }}>
                <div style={{ flex: "4" }}>
                  <Text style={{ fontSize: "9" }}>{ResponsibleInfo["NameOfResponsibleParty_1"]}</Text>
                  <View
                    style={{ borderBottom: "1px solid #000", marginTop: -4 }}
                  ></View>
                </div>
              </div>
              <div style={{ flexDirection: "row", marginTop: "5px" }}>
                <Text style={{ flex: 3, fontWeight: "bold", color: "#2f5496", fontSize: 8 }}>Relationship:</Text>
                <div style={{ flex: "9" }}>
                  <Text style={{ fontSize: "9" }}>{ResponsibleInfo["Relationship_1"]}</Text>
                  <View
                    style={{ borderBottom: "1px solid #000", marginTop: -4 }}
                  ></View>
                </div>
              </div>
              <div style={{ flexDirection: "row", marginTop: "5px" }}>
                <Text style={{ flex: 2, fontWeight: "bold", color: "#2f5496", fontSize: 8 }}>Address:</Text>
                <div style={{ flex: "10" }}>
                  <Text style={{ fontSize: "9", textDecoration: "underline" }}>{ResponsibleInfo["Address_1"]}</Text>
                </div>
              </div>

              <div style={{ flexDirection: "row", marginTop: "5px" }}>
                <Text style={{ flex: 2, fontWeight: "bold", color: "#2f5496", fontSize: 8 }}>Phone:</Text>
                <div style={{ flex: "10" }}>
                  <Text style={{ fontSize: "9" }}>{ResponsibleInfo["Phone_1"]}</Text>
                  <View
                    style={{ borderBottom: "1px solid #000", marginTop: -4 }}
                  ></View>
                </div>
              </div>
              <Text style={{ fontWeight: "bold", color: "#2f5496", fontSize: 12, marginTop: "20px" }}>Name of responsible party:</Text>
              <div style={{ flexDirection: "row" }}>
                <div style={{ flex: "4" }}>
                  <Text style={{ fontSize: "9" }}>{ResponsibleInfo["NameOfResponsibleParty_2"]}</Text>
                  <View
                    style={{ borderBottom: "1px solid #000", marginTop: -4 }}
                  ></View>
                </div>
              </div>
              <div style={{ flexDirection: "row", marginTop: "5px" }}>
                <Text style={{ flex: 3, fontWeight: "bold", color: "#2f5496", fontSize: 8 }}>Relationship:</Text>
                <div style={{ flex: "9" }}>
                  <Text style={{ fontSize: "9" }}>{ResponsibleInfo["Relationship_2"]}</Text>
                  <View
                    style={{ borderBottom: "1px solid #000", marginTop: -4 }}
                  ></View>
                </div>
              </div>
              <div style={{ flexDirection: "row", marginTop: "5px" }}>
                <Text style={{ flex: 3, fontWeight: "bold", color: "#2f5496", fontSize: 8 }}>Address:</Text>
                <div style={{ flex: "11" }}>
                  <Text style={{ fontSize: "9", textDecoration: "underline" }}>{ResponsibleInfo["Address_2"]}</Text>
                </div>
              </div>

              <div style={{ flexDirection: "row", marginTop: "5px" }}>
                <Text style={{ flex: 3, fontWeight: "bold", color: "#2f5496", fontSize: 8 }}>Phone:</Text>
                <div style={{ flex: "10" }}>
                  <Text style={{ fontSize: "9" }}>{ResponsibleInfo["Phone_2"]}</Text>
                  <View
                    style={{ borderBottom: "1px solid #000", marginTop: -4 }}
                  ></View>
                </div>
              </div>
              <Text style={{ fontWeight: "bold", color: "#2f5496", fontSize: 12, marginTop: "20px" }}>Signature of the responsible party:</Text>
              <div style={{ flexDirection: "row", marginTop: "20px" }}>
                <div style={{ flex: "4" }}>
                  <Text style={{ fontSize: "9" }}>{ResponsibleInfo["signatureOfResposibleParty"]}</Text>
                  {renderSignature("Responsable Party")}
                  <View
                    style={{ borderBottom: "1px solid #000", paddingTop: -4 }}
                  ></View>
                </div>
              </div>
              <div style={{ flexDirection: "row", marginTop: "5px" }}>
                <Text style={{ flex: 3, fontWeight: "bold", color: "#2f5496", fontSize: 8 }}>Date:</Text>
                <div style={{ flex: "10" }}>
                  <Text style={{ fontSize: "9" }}>{ResponsibleInfo["dateSignatureResposibleParty"]}</Text>
                  <div style={styles.divDate}>
                    <Text>{responsibleSignatureInfo?.date}</Text>
                  </div>
                  <View
                    style={{ borderBottom: "1px solid #000", marginTop: 10 }}
                  ></View>
                </div>
              </div>
              <Text style={{ fontWeight: "bold", color: "#2f5496", fontSize: 12, marginTop: "20px" }}>Funeral Director:</Text>
              <div style={{ flexDirection: "row", marginTop: "20px" }}>
                <div style={{ flex: "4" }}>
                  <Text style={{ fontSize: "9" }}>{ResponsibleInfo["funeralDirectorSign"]}</Text>
                  {renderSignature("Funeral Director")}
                  <View
                    style={{ borderBottom: "1px solid #000", marginTop: -4 }}
                  ></View>
                </div>
              </div>
              <div style={{ flexDirection: "row", marginTop: "5px" }}>
                <Text style={{ flex: 3, fontWeight: "bold", color: "#2f5496", fontSize: 8 }}>Date:</Text>
                <div style={{ flex: "10" }}>
                  <Text style={{ fontSize: "9" }}>{ResponsibleInfo["dateFuneralDirectorSign"]}</Text>
                  <div style={styles.divDate}>
                    <Text>{funeralDirectorSignatureInfo?.date}</Text>
                  </div>
                  <View
                    style={{ borderBottom: "1px solid #000", marginTop: 10 }}
                  ></View>
                </div>
              </div>
              <Text style={{ fontWeight: "bold", color: "#2f5496", fontSize: 12, marginTop: "20px" }}>Funeral Arrangement counselor:</Text>
              <div style={{ flexDirection: "row", marginTop: "20px" }}>
                <div style={{ flex: "4" }}>
                  <Text style={{ fontSize: "9" }}>{ResponsibleInfo["funeralArrangementCounselor"]}</Text>
                  {renderSignature("Funeral Arrangement Counselor")}
                  <View
                    style={{ borderBottom: "1px solid #000", marginTop: -4 }}
                  ></View>
                </div>
              </div>
              <div style={{ flexDirection: "row", marginTop: "5px" }}>
                <Text style={{ flex: 3, fontWeight: "bold", color: "#2f5496", fontSize: 8 }}>Date:</Text>
                <div style={{ flex: "10" }}>
                  <Text style={{ fontSize: "9" }}>{ResponsibleInfo["dateFuneralArrangementCounselor"]}</Text>
                  <div style={styles.divDate}>
                    <Text>{FuneralArrangementCounselorSignatureInfo?.date}</Text>
                  </div>
                  <View
                    style={{ borderBottom: "1px solid #000", marginTop: 10 }}
                  ></View>
                </div>
              </div>

            </div>
          </div>
        </div>
      </Page>
    </Document>
  );
};
const styles = {
  title: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: "bold",
    color: "#2f5496",
  },
  lines: {
    position: "absolute",
    border: "2px solid #2f5496",
    width: "93%",
    height: "93%",
    margin: 20,
  },
  mt100: {
    marginTop: 20,
    fontWeight: "bold",
  },

  mt10: {
    marginTop: 10,
  },
  mt30: {
    marginTop: 40,
    fontWeight: "bold",
  },
  container: {
    marginTop: 20,
    marginRight: 50,
    marginLeft: 50,
  },
  containerPage2: {
    marginTop: 30,
    marginRight: 50,
    marginLeft: 50,
  },
  document: {
    fontSize: 11,
    lineHeight: 1.5,
    textAlign: "justify",
    fontFamily: "Roboto",
  },

  header: {
    color: "#2f5496",
    textAlign: "center",
    marginTop: 80,
  },
  black: {
    fontWeight: 700,
  },
  mt50: {
    marginTop: 60,
  },

  mt5: {
    marginTop: 8,
  },
  signature: {
    height: "30px",
    width: "60px",
    position: "absolute",
    top: "-25px",
    right: "85px",
  },
  divDate: {
    position: "absolute",
    top: "0px",
    right: "85px",
  }
};
export default PDF_Form5;
