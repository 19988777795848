import React, { useState, useEffect } from "react";
import { Document, Page, Image, Text } from "@react-pdf/renderer";
import api from "../../../services/api";
const PDF_Form6 = ({ data }) => {
  const folioID = data.folio;
  var userID = data.usuario_id;
  const [signatureInfo, setSignatureData] = useState(dataSignatureInfo);

  var dataSignatureInfo = {
    usuario_id: userID,
    folio: folioID,
    num_form: "",
    documento: "",
    nameSign: ""
  };

  console.log("arreglo datos", dataSignatureInfo);

  useEffect(() => {
    const fetchSignatures = async () => {
      try {
        const response = await api.get(
          `sign/SignController.php?folio=${folioID}&num_form=6`
        );
        const data = response.data;
        setSignatureData(data);
      } catch (error) {
        console.error("Error fetching signatures info:", error);
        setSignatureData(prevState => ({ ...prevState, signatures: [] }));
      }
    };

    fetchSignatures();
  }, [folioID]);

  const getSignatureInfo = (name) => {
    if (signatureInfo && Array.isArray(signatureInfo)) {
      for (let i = 0; i < signatureInfo.length; i++) {
        const signature = signatureInfo[i];
        if (signature.nameSign === name) {
          return {
            image: signature.documento
          };
        }
      }
    }
    return null;
  };

  const renderSignature = (name) => {
    const signature = getSignatureInfo(name);
    if (signature) {
      return (
        <Image src={`data:image/png;base64,${signature.image}`} style={styles.signature} />
      );
    }
    return null;
  };

  const AuthorizingAgentignatureInfo = getSignatureInfo("Authorizing Agent");
  const AuthorizingAgentignature2Info = getSignatureInfo("Authorizing Agent2");

  return (
    <Document style={styles.document}>
      <Page size="Letter">
        <div style={styles.lines}></div>
        <div
          style={{
            flexDirection: "row",
            marginLeft: "40px",
            marginTop: "40px",
          }}
        >
          <Image
            source={"/img/logo/logo.png"}
            style={{ width: " 120px ", height: "120px" }}
          ></Image>
          <div
            style={{
              textAlign: "center",
              color: "#2f5496",
              flex: "1",
              alignItems: "center",
              marginTop: "30px",
              marginLeft: "-100px",
            }}
          >
            <Text>Ramirez Funeral Home LLC</Text>
            <Text>4595 S Palo Verde Rd. Suite 503, Tucson Arizona 85714</Text>
            <Text>Email: ramirezfuneralhome@gmail.com</Text>
            <Text>Phone: (520) 372-2479 Fax: (520) 842-3889</Text>
          </div>
        </div>
        <div style={styles.container}>
          <Text style={styles.title}>
            CREMATORY AUTHORIZATION AND DISPOSITION FORM{" "}
          </Text>
          <Text
            style={{ fontWeight: "bold", fontSize: "9px", color: "#2f5496" }}
          >
            NOTICE:
            <Text
              style={{ fontSize: "9px", fontWeight: "normal", color: "#000" }}
            >
              THIS IS A LEGAL DOCUMENT. IT CONTAINS IMPORTANT PROVISIONS
              CONCERNING CREMATION. CREMATION IS A FINAL AND IRREVERSIBLE ACT.
              PLEASE READ THIS DOCUMENT CAREFULLY BEFORE SIGNING.{" "}
            </Text>
          </Text>

          <Text
            style={{
              marginTop: "30px",
              color: "#2f5496",
              fontSize: "9px",
              fontWeight: "bold",
            }}
          >
            AUTHORITY TO CREMATE:
          </Text>
          <Text
            style={{ color: "#000", fontSize: "9px", fontWeight: "normal" }}
          >
            I/We, the undersigned (“Authorizing Agent”) hereby authorize(s) and
            request(s) Ramirez Funeral Home LLC to cremate the human remains of:{" "}
            <br></br>
            <Text
              style={{
                color: "#000",
                fontSize: "10px",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              {data["decreasedName"]}
            </Text>
            <br></br>
            <Text>
              , the “Deceased and to arrange for the final disposition of the
              cremated remains as stated on this form”.{" "}
            </Text>
          </Text>
          <div style={{ marginTop: "10px" }}>
            <div style={{ flexDirection: "row" }}>
              <div>
                <Text
                  style={{
                    position: "absolute",
                    marginTop: "-2px",
                    marginLeft: "80px",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  {data["dateOfDeath"]}
                </Text>
                <Text
                  style={{
                    color: "#000",
                    fontSize: "9px",
                    fontWeight: "normal",
                  }}
                >
                  Date of Death :
                </Text>
              </div>
              <div>
                <Text
                  style={{
                    position: "absolute",
                    marginTop: "-2px",
                    marginLeft: "230px",
                    fontSize: "10px",
                    fontWeight: "bold",
                    width: "200px",
                  }}
                >
                  {data["placeOfDeath"]}
                </Text>
                <Text
                  style={{
                    color: "#000",
                    fontSize: "10px",
                    fontWeight: "normal",
                  }}
                >
                  _________________________________
                </Text>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <Text
                  style={{
                    color: "#000",
                    fontSize: "9px",
                    fontWeight: "normal",
                  }}
                >
                  Place of Death :
                </Text>
              </div>
              <div>
                <Text
                  style={{
                    color: "#000",
                    fontSize: "10px",
                    fontWeight: "normal",
                  }}
                >
                  ______________________________________
                </Text>
              </div>
            </div>

            <div style={{ flexDirection: "row" }}>
              <div>
                <Text
                  style={{
                    color: "#000",
                    fontSize: "9px",
                    fontWeight: "normal",
                  }}
                >
                  Sex :
                </Text>
                <Text
                  style={{
                    position: "absolute",
                    marginTop: "-2px",
                    marginLeft: "40px",
                    fontSize: "10px",
                    fontWeight: "bold",
                    width: "200px",
                  }}
                >
                  {data["sex"] === 1 && (
                    <Text
                      style={{
                        position: "absolute",
                        marginTop: "-2px",
                        marginLeft: "40px",
                        fontSize: "10px",
                        fontWeight: "bold",
                        width: "200px",
                      }}
                    >
                      FAMALE
                    </Text>
                  )}
                  {data["sex"] === 2 && (
                    <Text
                      style={{
                        position: "absolute",
                        marginTop: "-2px",
                        marginLeft: "40px",
                        fontSize: "10px",
                        fontWeight: "bold",
                        width: "200px",
                      }}
                    >
                      MALE
                    </Text>
                  )}
                  {data["sex"] === 3 && (
                    <Text
                      style={{
                        position: "absolute",
                        marginTop: "-2px",
                        marginLeft: "40px",
                        fontSize: "10px",
                        fontWeight: "bold",
                        width: "200px",
                      }}
                    >
                      NOT YET DETERMINED
                    </Text>
                  )}
                </Text>
              </div>
              <div>
                <Text
                  style={{
                    position: "absolute",
                    marginTop: "-2px",
                    marginLeft: "230px",
                    fontSize: "10px",
                    fontWeight: "bold",
                    width: "200px",
                  }}
                >
                  {data["age"]}
                </Text>
                <Text
                  style={{
                    color: "#000",
                    fontSize: "10px",
                    fontWeight: "normal",
                  }}
                >
                  __________________________________________
                </Text>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <Text
                  style={{
                    color: "#000",
                    fontSize: "9px",
                    fontWeight: "normal",
                  }}
                >
                  Age :
                </Text>
              </div>
              <div>
                <Text
                  style={{
                    color: "#000",
                    fontSize: "10px",
                    fontWeight: "normal",
                  }}
                >
                  _______________________________________________
                </Text>
              </div>
            </div>

            <div style={{ flexDirection: "row" }}>
              <Text
                style={{ color: "#000", fontSize: "9px", fontWeight: "normal" }}
              >
                Was the death due to an infectious disease?
                <br></br>
              </Text>
              <Text
                style={{
                  marginLeft: "10px",
                  color: "#000",
                  fontSize: "10px",
                  fontWeight: "normal",
                }}
              >
                YES
              </Text>
              <Text
                style={{
                  position: "absolute",
                  marginTop: "-1px",
                  marginLeft: "210px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  width: "200px",
                }}
              >
                {data["haveInfectiuos"] === 1 && "X"}
              </Text>
              <div
                style={{
                  marginLeft: "5px",
                  border: " 1px solid #000",
                  width: "10px",
                  height: "10px",
                }}
              ></div>

              <Text
                style={{
                  marginLeft: "10px",
                  color: "#000",
                  fontSize: "10px",
                  fontWeight: "normal",
                }}
              >
                NO
              </Text>
              <Text
                style={{
                  position: "absolute",
                  marginTop: "-1px",
                  marginLeft: "249px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  width: "200px",
                }}
              >
                {data["haveInfectiuos"] === 2 && "X"}
              </Text>
              <div
                style={{
                  marginLeft: "5px",
                  border: " 1px solid #000",
                  width: "10px",
                  height: "10px",
                }}
              ></div>
            </div>
            <div style={{ flexDirection: "row" }}>
              <div>
                <Text
                  style={{
                    color: "#000",
                    fontSize: "9px",
                    fontWeight: "normal",
                  }}
                >
                  If yes, please identify
                </Text>
              </div>
              <div>
                <Text
                  style={{
                    position: "absolute",
                    marginTop: "-2px",
                    marginLeft: "10px",
                    fontSize: "10px",
                    fontWeight: "bold",
                    width: "200px",
                  }}
                >
                  {data["ifYesIndentify"]}
                </Text>
                <Text
                  style={{
                    color: "#000",
                    fontSize: "9px",
                    fontWeight: "normal",
                  }}
                >
                  ___________________________________________________________________________________________
                </Text>
              </div>
            </div>
            <Text
              style={{ color: "#000", fontSize: "9px", fontWeight: "normal" }}
            >
              PACEMAKERS PROSTHESIS OR RADIOACTIVE DEVICES: Did the Deceased’s
              remains contain a pacemaker, prosthesis, or radioactive device
              that may be harmful to the crematory?
            </Text>

            <div
              style={{
                flexDirection: "row",
                marginLeft: "265px",
                marginTop: "-13px",
              }}
            >
              <Text
                style={{
                  marginLeft: "10px",
                  color: "#000",
                  fontSize: "10px",
                  fontWeight: "normal",
                }}
              >
                YES
              </Text>
              <Text
                style={{
                  position: "absolute",
                  marginTop: "-1px",
                  marginLeft: "35px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  width: "200px",
                }}
              >
                {data["prothesisOrRadoictive"] === 1 && "X"}
              </Text>
              <div
                style={{
                  marginLeft: "5px",
                  border: " 1px solid #000",
                  width: "10px",
                  height: "10px",
                }}
              ></div>

              <Text
                style={{
                  marginLeft: "10px",
                  color: "#000",
                  fontSize: "10px",
                  fontWeight: "normal",
                }}
              >
                NO
              </Text>
              <Text
                style={{
                  position: "absolute",
                  marginTop: "-1px",
                  marginLeft: "74px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  width: "200px",
                }}
              >
                {data["prothesisOrRadoictive"] === 2 && "X"}
              </Text>
              <div
                style={{
                  marginLeft: "5px",
                  border: " 1px solid #000",
                  width: "10px",
                  height: "10px",
                }}
              ></div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Text
                style={{ color: "#000", fontSize: "9px", fontWeight: "normal" }}
              >
                The following describes all existing devices or implants in or
                attached to the Deceased that should be removed prior to
                cremation:
              </Text>
              <div>
                <Text
                  style={{
                    position: "absolute",
                    marginTop: "-2px",
                    marginLeft: "10px",
                    fontSize: "10px",
                    fontWeight: "bold",
                    width: "200px",
                  }}
                >
                  {data["descritionProthesis"]}
                </Text>
                <Text
                  style={{
                    color: "#000",
                    fontSize: "9px",
                    fontWeight: "normal",
                  }}
                >
                  ________________________________________________________________________________________________________________
                </Text>
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Text
                style={{ color: "#000", fontSize: "9px", fontWeight: "normal" }}
              >
                The following identification marks are to be used as secondary
                identification:
              </Text>
              <div>
                <Text
                  style={{
                    position: "absolute",
                    marginTop: "-2px",
                    marginLeft: "10px",
                    fontSize: "10px",
                    fontWeight: "bold",
                    width: "200px",
                  }}
                >
                  {data["secondaryDescriptionProthesis"]}
                </Text>
                <Text
                  style={{
                    color: "#000",
                    fontSize: "9px",
                    fontWeight: "normal",
                  }}
                >
                  ________________________________________________________________________________________________________________
                </Text>
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Text
                style={{
                  color: "#2f5496",
                  fontSize: "9px",
                  fontWeight: "bold",
                }}
              >
                FINAL DISPOSITION:
              </Text>
              <Text
                style={{ color: "#000", fontSize: "9px", fontWeight: "normal" }}
              >
                The Authorizing Agent(s) agree to hold Ramirez Funeral Home LLC
                their agents, employees, and their affiliates harmless from any
                loss, liability employees, Ons (including attorney’s fee and
                expenses of litigation) in connection with the cremation and
                disposition of the Deceased, as authorized herein, or with the
                possession or permanent arrangements and the disposition of such
                cremated remains. After the cremation has taken place and the
                cremated remains have been processed and placed in the
                designated container, receptacle, or urn, the Authorizing
                Agent(s) authorizes) Ramirez Funeral Home LLC is to release,
                deliver or transport the cremated remains as follows:
              </Text>
            </div>
            <div>
              <Text
                style={{
                  position: "absolute",
                  marginTop: "8px",
                  marginLeft: "15px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  width: "200px",
                }}
              >
                {data["finalDispositionOneMark"]}
              </Text>
              <Text
                style={{
                  position: "absolute",
                  marginTop: "8px",
                  marginLeft: "340px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  width: "200px",
                }}
              >
                {data["finalDispositionOneDate"]}
              </Text>
              <Text
                style={{
                  marginTop: "10px",
                  color: "#000",
                  fontSize: "9px",
                  fontWeight: "normal",
                }}
              >
                1. ____ To be received by a family member or personal
                representative by this date______________________________.{" "}
              </Text>
            </div>
            <div>
              <Text
                style={{
                  position: "absolute",
                  marginTop: "8px",
                  marginLeft: "15px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  width: "200px",
                }}
              >
                {data["finalDispositionTwoMark"]}
              </Text>
              <Text
                style={{
                  position: "absolute",
                  marginTop: "8px",
                  marginLeft: "100px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  width: "200px",
                }}
              >
                {data["finalDispositionTwoName"]}
              </Text>
              <Text
                style={{
                  marginTop: "10px",
                  color: "#000",
                  fontSize: "9px",
                  fontWeight: "normal",
                }}
              >
                2. ____ To be given to
                _______________________________________________________for
                disposition.
              </Text>
              <Text
                style={{
                  marginTop: "10px",
                  color: "#000",
                  fontSize: "9px",
                  fontWeight: "normal",
                }}
              >
                I /We agree to assume all liability that may arise from such
                release and to hold the Crematory harmless from all claims that
                may arise from the release, delivery, or transport.{" "}
              </Text>
            </div>
          </div>
        </div>
      </Page>
      <Page size="Letter">
        <div style={styles.lines}></div>
        <div style={styles.container}>
          <Text
            style={{ color: "#2f5496", fontSize: "9px", fontWeight: "bold" }}
          >
            UNCLAIMED REMAINS:
          </Text>
          <Text
            style={{
              marginTop: "10px",
              color: "#000",
              fontSize: "9px",
              fontWeight: "normal",
            }}
          >
            In the event the cremated remains are unclaimed for a period of 30
            days, Ramirez Funeral Home LLC will notify, in writing, the
            person(s) who signed the Cremation Authorization Form to take
            possession of the cremated remains, and hold Ramirez Funeral Home
            LLC, its agents, and employees harmless from all loss, damages,
            liability or causes of action in connection with the disposition of
            unclaimed cremated remains. You may direct Ramirez Funeral Home LLC
            to maintain the cremated remains for an extended period for a charge
            of thirty dollars ($30.00) per month which must be paid three months
            in advance and noted in the Cremation Authorization Form. In the
            event cremated remains have not reached their final disposition
            within 30 days of cremation, the undersigned will be responsible for
            payment of a charge of thirty dollars ($30.00) per month for each
            month, or portion thereof, the Ramirez Funeral Home LLC maintains
            the cremated.
          </Text>

          <div style={{ marginTop: "10px" }}>
            <Text
              style={{ color: "#2f5496", fontSize: "9px", fontWeight: "bold" }}
            >
              AUTHORITY OF AUTHORIZING AGENTS:
            </Text>
            <div style={{ marginTop: "10px" }}>
              <Text
                style={{
                  position: "absolute",
                  marginTop: "10px",
                  marginLeft: "80px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  width: "200px",
                }}
              >
                {data["livingNextDeceases"]}
              </Text>
              <Text
                style={{
                  color: "#000",
                  fontSize: "9px",
                  fontWeight: "normal",
                }}
              >
                The undersigned hereby certify that I/We are the closest living
                next of kin * of the Deceased and that we are related to the
                Deceased as
                ______________________________________________________________________________________________
              </Text>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Text
                style={{
                  position: "absolute",
                  marginTop: "-2x",
                  marginLeft: "165px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  width: "200px",
                }}
              >
                {data["capacityOf"]}
              </Text>
              <Text
                style={{
                  color: "#000",
                  fontSize: "9px",
                  fontWeight: "normal",
                }}
              >
                or otherwise serve(d) in the capacity of
                <Text>
                  ________________________________________________________________________
                </Text>
                <Text>
                  for the Deceased, that I/We have charge of the remains of the
                  Deceased and as such possess full legal authority and power,
                  according to the laws of Arizona, to execute this
                  authorization form and to arrange for the cremation and
                  disposition of the cremated remains of the Deceased.
                </Text>
                <Text
                  style={{
                    color: "#2f5496",
                    fontSize: "9px",
                    fontWeight: "bold",
                  }}
                >
                  IN ADDITION, I/WE ARE AWARE OF NO OBJECTIONS TO THIS CREMATION
                  BY ANY SPOUSE, PARENT, CHILD, OR SIBLING.
                </Text>
              </Text>
            </div>
          </div>
          <div style={{ marginTop: "5px" }}>
            <Text
              style={{
                marginTop: "10px",
                color: "#000",
                fontSize: "9px",
                fontWeight: "normal",
              }}
            >
              * The next of kin is the person(s) described below in the
              following order:
            </Text>
            <div
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <div>
                <Text
                  style={{
                    marginTop: "10px",
                    color: "#000",
                    fontSize: "9px",
                    fontWeight: "normal",
                  }}
                >
                  (a) Surviving spouse
                </Text>
              </div>
              <div>
                <Text
                  style={{
                    marginTop: "10px",
                    color: "#000",
                    fontSize: "9px",
                    fontWeight: "normal",
                  }}
                >
                  (b) Surviving children
                </Text>
              </div>
              <div>
                <Text
                  style={{
                    marginTop: "10px",
                    color: "#000",
                    fontSize: "9px",
                    fontWeight: "normal",
                  }}
                >
                  (c) Surviving parents
                </Text>
              </div>
            </div>
            <div
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <div>
                <Text
                  style={{
                    marginTop: "10px",
                    color: "#000",
                    fontSize: "9px",
                    fontWeight: "normal",
                  }}
                >
                  (d) Surviving brothers and sisters
                </Text>
              </div>
              <div>
                <Text
                  style={{
                    marginTop: "10px",
                    color: "#000",
                    fontSize: "9px",
                    fontWeight: "normal",
                  }}
                >
                  (e) Surviving uncles and aunts
                </Text>
              </div>
              <div>
                <Text
                  style={{
                    marginTop: "10px",
                    color: "#000",
                    fontSize: "9px",
                    fontWeight: "normal",
                  }}
                >
                  (f) Surviving first cousins etc.
                </Text>
              </div>
            </div>
            <Text
              style={{
                marginTop: "10px",
                color: "#000",
                fontSize: "9px",
                fontWeight: "normal",
              }}
            >
              If the next of kin is a child, or brother or sister, it is
              recommended that all children or all brothers and sisters sign.{" "}
            </Text>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Text
              style={{ color: "#2f5496", fontSize: "9px", fontWeight: "bold" }}
            >
              LIMITATION OF LIABILITY:{" "}
            </Text>
            <Text
              style={{ color: "#000", fontSize: "9px", fontWeight: "normal" }}
            >
              As the Authorizing Agent(s), I/We agree to indemnify and hold
              Ramirez Funeral Home LLC, its officers, agents, and employees
              harmless of and from any and all claims, demands, or causes of
              action, and suits of any description, in law or equity, including
              legal fees, costs and expenses of litigation arising as a result
              of this authorization, cremation, the processing and final
              disposition of the Deceased’s cremated remains, or the failure to
              take possession or to make proper arrangements for the final
              disposition or to make proper arrangements for the final
              disposition of the cremated remains, or any damage due to harmful
              or explosive implants, claims, brought by any other person(s)
              claiming the right to control the disposition of the Deceased or
              the Deceased’s cremated remains, or any other acts performed by
              Ramirez Funeral Home LLC pursuant to this authorization.{" "}
            </Text>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Text
              style={{ color: "#2f5496", fontSize: "9px", fontWeight: "bold" }}
            >
              AUTHORIZING AGENT’S SIGNATURES:
            </Text>
            <Text
              style={{ color: "#000", fontSize: "9px", fontWeight: "normal" }}
            >
              *Funeral Home and Crematory are not responsible for any loss or
              damage of cremated remains shipped via Registered Mail with the
              United States Postal Service.
            </Text>
          </div>
          <div style={{ flexDirection: "row", justifyContent: "space-evenly" }}>
            <div>
              <div>
                <Text
                  style={{
                    position: "absolute",
                    marginTop: "-2x",
                    marginLeft: "60px",
                    fontSize: "8px",
                    fontWeight: "bold",
                    width: "200px",
                  }}
                >
                  {data["printedName1"]}
                </Text>
                <Text
                  style={{
                    color: "#000",
                    fontSize: "9px",
                    fontWeight: "normal",
                  }}
                >
                  Printed Name : ______________________________________
                </Text>
              </div>
              <div>
                <Text
                  style={{
                    position: "absolute",
                    marginTop: "-2x",
                    marginLeft: "60px",
                    fontSize: "8px",
                    fontWeight: "bold",
                    width: "200px",
                  }}
                >
                  {data["relationship1"]}
                </Text>
                <Text
                  style={{
                    color: "#000",
                    fontSize: "9px",
                    fontWeight: "normal",
                  }}
                >
                  Relationship : ________________________________________
                </Text>
              </div>
              <div>
                <Text
                  style={{
                    position: "absolute",
                    marginTop: "-2x",
                    marginLeft: "50px",
                    fontSize: "8px",
                    fontWeight: "bold",
                    width: "200px",
                  }}
                >
                  {data["phone1"]}
                </Text>
                <Text
                  style={{
                    color: "#000",
                    fontSize: "9px",
                    fontWeight: "normal",
                  }}
                >
                  Phone : _____________________________________________{" "}
                </Text>
              </div>
              <div>
                <Text
                  style={{
                    position: "absolute",
                    marginTop: "0",
                    marginLeft: "40px",
                    fontSize: "8px",
                    fontWeight: "bold",
                    width: "180px",
                    textDecoration: "underline",
                  }}
                >
                  {data["address1"]}
                </Text>
                <Text
                  style={{
                    color: "#000",
                    fontSize: "9px",
                    fontWeight: "normal",
                  }}
                >
                  Address : ___________________________________________
                </Text>
              </div>
              <div>
                <div style={styles.textSign}>
                  {renderSignature("Authorizing Agent")}
                </div>
                <Text
                  style={{
                    color: "#000",
                    fontSize: "9px",
                    fontWeight: "normal",
                    marginTop: "10px",
                  }}
                >
                  Signature:___________________________________________
                </Text>
              </div>
            </div>
            <div style={{ marginLeft: "20px" }}>
              <div>
                <div>
                  <Text
                    style={{
                      position: "absolute",
                      marginTop: "-2x",
                      marginLeft: "60px",
                      fontSize: "8px",
                      fontWeight: "bold",
                      width: "200px",
                    }}
                  >
                    {data["printedName2"]}
                  </Text>
                  <Text
                    style={{
                      color: "#000",
                      fontSize: "9px",
                      fontWeight: "normal",
                    }}
                  >
                    Printed Name : ______________________________________
                  </Text>
                </div>
                <div>
                  <Text
                    style={{
                      position: "absolute",
                      marginTop: "-2x",
                      marginLeft: "60px",
                      fontSize: "8px",
                      fontWeight: "bold",
                      width: "200px",
                    }}
                  >
                    {data["relationship2"]}
                  </Text>
                  <Text
                    style={{
                      color: "#000",
                      fontSize: "9px",
                      fontWeight: "normal",
                    }}
                  >
                    Relationship : ________________________________________
                  </Text>
                </div>
                <div>
                  <Text
                    style={{
                      position: "absolute",
                      marginTop: "-2x",
                      marginLeft: "40px",
                      fontSize: "8px",
                      fontWeight: "bold",
                      width: "200px",
                    }}
                  >
                    {data["phone2"]}
                  </Text>
                  <Text
                    style={{
                      color: "#000",
                      fontSize: "9px",
                      fontWeight: "normal",
                    }}
                  >
                    Phone : _____________________________________________
                  </Text>
                </div>
                <div>
                  <Text
                    style={{
                      position: "absolute",
                      marginTop: "0",
                      marginLeft: "40px",
                      fontSize: "8px",
                      fontWeight: "bold",
                      width: "180px",
                      textDecoration: "underline",
                    }}
                  >
                    {data["address2"]}
                  </Text>
                  <Text
                    style={{
                      color: "#000",
                      fontSize: "9px",
                      fontWeight: "normal",
                    }}
                  >
                    Address : ___________________________________________
                  </Text>
                </div>
                <div>
                  <div style={styles.textSign}>
                    {renderSignature("Authorizing Agent2")}
                  </div>
                  <Text
                    style={{
                      color: "#000",
                      fontSize: "9px",
                      fontWeight: "normal",
                      marginTop: "10px",
                    }}
                  >
                    Signature:___________________________________________
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <Text
            style={{
              color: "#000",
              fontSize: "9px",
              fontWeight: "normal",
              marginTop: "20px",
            }}
          >
            Notary Public and Legible Stamp:
          </Text>
        </div>
      </Page>
    </Document>
  );
};
const styles = {
  title: {
    textAlign: "center",
    fontSize: 9,
    fontWeight: "bold",
    color: "#2f5496",
  },
  subTitle: {
    fontSize: 9,
    color: "#2f5496",
  },
  subTitle2: {
    fontSize: 9,
    color: "#000",
  },

  Text: {
    fontSize: 8,
    color: "#000",
  },
  signInformation: {
    display: "flex",
    justifyContent: "space-between !important",
  },
  sign: {
    marginTop: 30,
  },
  lineSign: {
    width: "102%",
    border: "1px solid #000",
  },
  line1: {
    position: "absolute",
    width: "30%",
    marginTop: 52,
    marginLeft: 110,
    border: "1px solid #000",
  },
  line2: {
    position: "absolute",
    width: "24%",
    marginTop: 52,
    marginLeft: 365,
    border: "1px solid #000",
  },
  line4: {
    position: "absolute",
    width: "40%",
    marginTop: 278,
    marginLeft: 46,
    border: "1px solid #000",
  },
  line3: {
    position: "absolute",
    width: "40%",
    marginTop: 278,
    marginLeft: 298,
    border: "1px solid #000",
  },
  line: {
    width: "105%",
    marginTop: 0,
    marginLeft: 0,
    border: "1px solid #000",
  },
  lines: {
    position: "absolute",
    border: "2px solid #2f5496",
    width: "90%",
    height: "90%",
    margin: 30,
  },
  mt100: {
    marginTop: 20,
    fontWeight: "bold",
  },

  mt10: {
    marginTop: 10,
  },
  mt30: {
    marginTop: 40,
    fontWeight: "bold",
  },
  container: {
    marginTop: 40,
    marginRight: 80,
    marginLeft: 80,
  },
  document: {
    fontSize: 11,
    lineHeight: 1.5,
    textAlign: "justify",
    fontFamily: "Roboto",
  },
  header: {
    color: "#2f5496",
    textAlign: "center",
    marginTop: 80,
  },
  black: {
    fontWeight: 700,
  },
  mt50: {
    marginTop: 60,
  },

  mt5: {
    marginTop: 8,
  },
  signature: {
    position: "absolute",
    width: "60px",
    height: "30px",
    top: "-10px",
    left: "80px"
  },
};
export default PDF_Form6;
