import React, { useState, useEffect } from "react";
import DefaultInput from "../../../../components/Elements/DefaultInput";
import DefaultDatePicker from "../../../../components/Elements/DefaultCalendar";
import DefaultRadioButtons from "../../../../components/Elements/DefaultRadioButtons";
import { Row, Button } from "react-bootstrap";
import api from "../../../../services/api";
import { toast } from "react-toastify";
const DecreacedInfo = ({
  onDecreacedInfo,
  folioId,
  secuencia,
  usuarioId,
}) => {
  const [formData, setFormData] = useState({
    folio: folioId,
    secuencia: parseInt(secuencia),
    usuarioID: usuarioId,
    nameDeceased: "",
    age: "",
    todayDate: "",
    dateOfBirth: "",
    dateOfDeath: "",
    ssn: "",
    placeDeath: "",
    weight: "",
    height: "",
    sex: "",
  });

  const sexOptions = [
    { label: "Female", value: "1" },
    { label: "Male", value: "2" },
    { label: "Not Yet Determined", value: "3" },
  ];

  const saveData = () => {
    console.log(formData);
    add_DeceasedInfo(formData);
  };

  const handleInputChange = (name, value) => {
    const updatedFormData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedFormData);
    onDecreacedInfo(updatedFormData);
  };

  useEffect(() => {
    get_Form5decedent_info(secuencia);
  }, []);

  useEffect(() => {
    onDecreacedInfo(formData);
  }, [formData]);

  const get_decedent_info = async (secuencia) => {
    try {
      const response = await api.get(
        `documents/form1/GetDecreasedInfo.php?id=${secuencia}`
      );
      const decedent_info = response.data[0];
      if (decedent_info) {
        console.log(response.data);
        setFormData((prevFormData) => ({
          ...prevFormData,
          usuarioID: usuarioId,
          folio: folioId,
          secuencia: parseInt(secuencia),
          nameDeceased: decedent_info.Name,
          age: decedent_info.Age,
          dateOfBirth: decedent_info.DateOfBirth,
          dateOfDeath: decedent_info.DateOfDeath,
          ssn: decedent_info.SSN,
          placeDeath: decedent_info.PlaceOfDeath,
          weight: decedent_info.Weight,
          height: decedent_info.Height,
          sex: decedent_info.Sex,
        }));
      }
    } catch (error) {
      console.log("Error get info", error);
    }
  };
  const get_Form5decedent_info = async (secuencia) => {
    try {
      const response = await api.get(
        `documents/form5/NoticeDecedentController.php?id=${secuencia}`
      );

      const decedent_info = response.data[0];
      if (decedent_info) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          usuarioID: usuarioId,
          folio: folioId,
          secuencia: parseInt(secuencia),
          nameDeceased: decedent_info.decreasedName,
          age: decedent_info.age,
          todayDate: decedent_info.todayDate,
          dateOfBirth: decedent_info.dateBirth,
          dateOfDeath: decedent_info.dateDeath,
          ssn: decedent_info.SSN,
          placeDeath: decedent_info.placeDeath,
          weight: decedent_info.weight,
          height: decedent_info.height,
          sex: decedent_info.sex,
        }));
      } else {
        get_decedent_info(secuencia);
      }
    } catch (error) {
      console.log("Error get info", error);
    }
  };
  const add_DeceasedInfo = async (formData) => {
    try {
      const response = await api.post(
        "documents/form5/NoticeDecedentController.php",
        formData,
        { params: { action: "POST" } }
      );
      get_Form5decedent_info(secuencia);
      toast.success("Decedent info successfully added", {
        className: "toast-success",
        theme: "colored",
      });
    } catch (error) {
      toast.error("Error adding decedent info", {
        className: "toast-error",
        theme: "colored",
      });

      console.error("Error adding archive", error);
    }
  };

  return (
    <>
      <div className="main-card">
        <DefaultInput
          placeholder={"Contract #"}
          disabled={true}
          valueData={formData.folio}
        />
        <DefaultInput
          placeholder={"Name of Deceased"}
          onChange={(value) => handleInputChange("nameDeceased", value)}
          valueData={formData.nameDeceased}
        />
        <DefaultInput
          placeholder={"Age"}
          onChange={(value) => handleInputChange("age", value)}
          valueData={formData.age}
        />
        <DefaultDatePicker
          placeholder={"Today's Date"}
          onChange={(value) => handleInputChange("todayDate", value)}
          valueData={formData.todayDate}
        />
        <DefaultDatePicker
          placeholder={"Date of Birth"}
          onChange={(value) => handleInputChange("dateOfBirth", value)}
          valueData={formData.dateOfBirth}
        />
        <DefaultDatePicker
          placeholder={"Date of Death"}
          onChange={(value) => handleInputChange("dateOfDeath", value)}
          valueData={formData.dateOfDeath}
        />

        <DefaultInput
          placeholder={"Social Security (SSN)"}
          onChange={(value) => handleInputChange("ssn", value)}
          valueData={formData.ssn}
        />
        <DefaultInput
          placeholder={"Place of Death"}
          onChange={(value) => handleInputChange("placeDeath", value)}
          valueData={formData.placeDeath}
        />
        <DefaultInput
          placeholder={"Weight"}
          onChange={(value) => handleInputChange("weight", value)}
          valueData={formData.weight}
        />
        <DefaultInput
          placeholder={"Height"}
          onChange={(value) => handleInputChange("height", value)}
          valueData={formData.height}
        />
        <DefaultRadioButtons
          placeholder={"Sex"}
          options={sexOptions}
          label={"Sex"}
          onChange={(value) => handleInputChange("sex", value)}
          valueData={formData.sex}
        />
      </div>
      <Row>
        <div className="d-flex justify-content-end">
          <Button onClick={saveData}>SAVE AUTORIZATION OF RELEASE</Button>
        </div>
      </Row>
    </>
  );
};

export default DecreacedInfo;
