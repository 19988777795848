import { React, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PDF_Form6 from "./PDF_Form6";
import { Button, Col, Row, Modal } from "react-bootstrap";
import getUserDataFromCookies from "../Form1/backend/GetUserFromCookies";
import CremationForm from "./Form6/form";
import { PDFViewer } from "@react-pdf/renderer";

const CremationAuthotization = () => {
  const { folioID } = useParams();
  const { consecutivo } = useParams();
  const navigate = useNavigate();
  var userId = getUserDataFromCookies().id_usuario;

  var dataCremationAuthorization = {
    secuencia: consecutivo,
    folio: folioID,
    usuario_id: userId,

    decreasedName: "",
    dateOfDeath: "",
    placeOfDeath: "",
    sex: "",
    age: "",

    haveInfectiuos: "",
    ifYesIndentify: "",

    prothesisOrRadoictive: "",
    descritionProthesis: "",
    secondaryDescriptionProthesis: "",

    finalDispositionOneMark: "",
    finalDispositionOneDate: "",
    finalDispositionTwoMark: "",
    finalDispositionTwoName: "",

    livingNextDeceases: "",

    capacityOf: "",

    printedName1: "",
    relationship1: "",
    phone1: "",
    address1: "",

    printedName2: "",
    relationship2: "",
    phone2: "",
    address2: "",
  };

  const [pdfKey, setPdfKey] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  const [cremationInfo, setCremationInfo] = useState(
    dataCremationAuthorization
  );

  const handleAuthorizationInfo = (data) => {
    setCremationInfo(data);
  };
  const handleSignDocument = () => {
    navigate(`/documents/sign-document/${folioID}/6`, { state: { cremationInfo } });
  };
  return (
    <>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <h2 className="f-cel">{folioID}</h2>
        <h3 className="f-cel">CREMATION AUTHORIZATION</h3>
        <div><button className="btn btn-primary" onClick={handleShowModal}>View Document</button></div>
      </div>

      <Row>
        <Col lg="10">
          <CremationForm
            onAuthorizationRelease={handleAuthorizationInfo}
            secuencia={consecutivo}
            folioId={folioID}
            usuarioId={userId}
          />
        </Col>
      </Row>
      <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Body>
          <PDFViewer style={{ width: "100%", height: "800px" }}>
            <PDF_Form6 data={cremationInfo} />
          </PDFViewer>
        </Modal.Body>
        <Modal.Footer>
            {/*<Button variant="primary" onClick={handleSignDocument}>
              Sign Document
            </Button>*/}
          </Modal.Footer>
      </Modal>
    </>
  );
};

export default CremationAuthotization;
