import React, { useState, useEffect } from "react";
import { Document, Page, Image, Text } from "@react-pdf/renderer";
import api from "../../../services/api";
import getUserDataFromCookies from "../Form1/backend/GetUserFromCookies";

const PDF_Form8 = ({ data }) => {
  const folioID = data.folio;
  var userId = getUserDataFromCookies().id_usuario;
  const [signatureInfo, setSignatureData] = useState(dataSignatureInfo);

  var dataSignatureInfo = {
    usuario_id: userId,
    folio: folioID,
    num_form: "",
    documento: "",
    nameSign: ""
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  };

  useEffect(() => {
    const fetchSignatures = async () => {
      try {
        const response = await api.get(
          `sign/SignController.php?folio=${folioID}&num_form=8`
        );
        const data = response.data;
        setSignatureData(data);
      } catch (error) {
        console.error("Error fetching signatures info:", error);
        setSignatureData(prevState => ({ ...prevState, signatures: [] }));
      }
    };

    fetchSignatures();
  }, [folioID]);

  const getSignatureInfo = (name) => {
    if (signatureInfo && Array.isArray(signatureInfo)) {
      for (let i = 0; i < signatureInfo.length; i++) {
        const signature = signatureInfo[i];
        if (signature.nameSign === name) {
          const formattedDate = formatDate(signature.timeSign);
          return {
            date: formattedDate,
            image: signature.documento
          };
        }
      }
    }
    return null;
  };

  const renderSignature = (name) => {
    const signature = getSignatureInfo(name);
    if (signature) {
      return (
        <div style={styles.divSignature}>
          <Image src={`data:image/png;base64,${signature.image}`} style={styles.signature} />
        </div>
      );
    }
    return null;
  };

  const personSignatureInfo = getSignatureInfo("Person1");
  const person2SignatureInfo = getSignatureInfo("Person2");
  const person3SignatureInfo = getSignatureInfo("Person3");
  return (
    <Document style={styles.document}>
      <Page size="Letter">
        <Text style={styles.name}>{data['decreasedName']}</Text>
        <Text style={styles.dateOfDeath}>{data['dateOfDeath']}</Text>
        <Text style={styles.funeralEstablishment}>{data['funeralEstablishment']}</Text>
        <Text style={styles.parentDecreased}>{data['stateRelationship']}</Text>

        {data['knowledgeOption'] === 1 && <Text style={styles.option1}>X</Text>}
        {data['knowledgeOption'] === 2 && <Text style={styles.option2}>X</Text>}
        {data['knowledgeOption'] === 3 && <Text style={styles.option3}>X</Text>}

        <Text style={styles.authority}>{data['otherEqualAuthority']}</Text>

        {data['mechanicalDeviceOption'] === 1 && <Text style={styles.certify1}>X</Text>}
        {data['mechanicalDeviceOption'] === 2 && <Text style={styles.certify2}>X</Text>}

        <div>
          <div style={styles.sign1}>
            {renderSignature("Person1")}
          </div>
          <div style={styles.divDate1}>
            <Text>{personSignatureInfo?.date}</Text>
          </div>
          <Text style={styles.printedName1}>{data['printedName_1']}</Text>
          <Text style={styles.relationShip1}>{data['relationship_1']}</Text>
          <Text style={styles.email1}>{data['email_1']}</Text>
          <Text style={styles.phone1}>{data['phone_1']}</Text>

        </div>
        <div>
          <div style={styles.sign2}>
            {renderSignature("Person2")}
          </div>
          <div style={styles.divDate2}>
            <Text>{person2SignatureInfo?.date}</Text>
          </div>
          <Text style={styles.printedName2}>{data['printedName_2']}</Text>
          <Text style={styles.relationShip2}>{data['relationship_2']}</Text>
          <Text style={styles.email2}>{data['email_2']}</Text>
          <Text style={styles.phone2}>{data['phone_2']}</Text>

        </div>
        <div>
          <div style={styles.sign3}>
            {renderSignature("Person3")}
          </div>
          <div style={styles.divDate3}>
            <Text>{person3SignatureInfo?.date}</Text>
          </div>
          <Text style={styles.printedName3}>{data['printedName_3']}</Text>
          <Text style={styles.relationShip3}>{data['relationship_3']}</Text>
          <Text style={styles.email3}>{data['email_3']}</Text>
          <Text style={styles.phone3}>{data['phone_3']}</Text>

        </div>

        <div>
          <Text style={styles.funeralHomeRep}>{data['funeralHomeRep']}</Text>
          <Text style={styles.printedNameFH}>{data['printedName_fh']}</Text>
          <Text style={styles.dateFH}>{data['date_fh']}</Text>
        </div>
        <Image
          src="/forms/form8/Tucson_Cremation_Services.jpg"
          alt="Page 3"
          style={styles.image}
          wrap={false}
          cache={true}
        />
      </Page>
    </Document>
  );
};
const styles = {
  funeralHomeRep: {
    width: 370,
    position: "absolute",
    left: 100,
    top: 742,
  },
  printedNameFH: {
    width: 370,
    position: "absolute",
    left: 300,
    top: 742,
  },
  dateFH: {
    width: 370,
    position: "absolute",
    left: 490,
    top: 742,
  },
  signature3: {
    width: 370,
    position: "absolute",
    left: 65,
    top: 702,
  },
  printedName3: {
    width: 370,
    position: "absolute",
    left: 300,
    top: 702,
  },
  date3: {
    width: 370,
    position: "absolute",
    left: 490,
    top: 702,
  },

  relationShip3: {
    width: 370,
    position: "absolute",
    left: 90,
    top: 722,
  },
  email3: {
    width: 370,
    position: "absolute",
    left: 270,
    top: 722,
  },
  phone3: {
    width: 370,
    position: "absolute",
    left: 490,
    top: 722,
  },
  signature2: {
    width: 370,
    position: "absolute",
    left: 65,
    top: 662,
  },
  relationShip2: {
    width: 370,
    position: "absolute",
    left: 90,
    top: 682,
  },
  email2: {
    width: 370,
    position: "absolute",
    left: 270,
    top: 682,
  },
  phone2: {
    width: 370,
    position: "absolute",
    left: 490,
    top: 682,
  },
  printedName2: {
    width: 370,
    position: "absolute",
    left: 300,
    top: 662,
  },
  date2: {
    width: 370,
    position: "absolute",
    left: 490,
    top: 662,
  },
  signature1: {
    width: 370,
    position: "absolute",
    left: 65,
    top: 622,
  },
  relationShip1: {
    width: 370,
    position: "absolute",
    left: 90,
    top: 642,
  },
  email1: {
    width: 370,
    position: "absolute",
    left: 270,
    top: 642,
  },
  phone1: {
    width: 370,
    position: "absolute",
    left: 490,
    top: 642,
  },
  printedName1: {
    width: 370,
    position: "absolute",
    left: 300,
    top: 622,
  },
  date1: {
    width: 370,
    position: "absolute",
    left: 490,
    top: 622,
  },
  name: {
    width: 390,
    position: "absolute",
    textAlign: "center",

    left: 30,
    top: 150,
  },
  dateOfDeath: {
    width: 350,
    position: "absolute",
    textAlign: "center",

    left: 320,
    top: 150,
  },
  funeralEstablishment: {
    width: 370,
    position: "absolute",
    textAlign: "center",

    left: 5,
    top: 189,
  },
  parentDecreased: {
    width: 370,
    position: "absolute",
    textAlign: "center",
    left: 80,
    top: 273,
  },
  option1: {
    width: 370,
    position: "absolute",
    left: 33,
    fontSize: 15,
    top: 292,
  },
  option2: {
    width: 370,
    position: "absolute",
    left: 33,
    fontSize: 15,
    top: 307,
  },
  option3: {
    width: 370,
    position: "absolute",
    left: 33,
    fontSize: 15,
    top: 328,
  },
  authority: {
    width: 370,
    position: "absolute",
    left: 180,
    top: 362,
  },
  certify1: {
    width: 370,
    position: "absolute",
    left: 225,
    fontSize: 15,
    top: 408,
  },
  certify2: {
    width: 370,
    position: "absolute",
    left: 278,
    fontSize: 15,
    top: 408,
  },
  image: {
    width: "100%",
    marginBottom: 0,
    zIndex: 1,
  },
  document: {
    fontSize: 9,
    textTransform: "uppercase",
  },
  divDate1: {
    position: "absolute",
    top: 622,
    left: 500
  },
  sign1: {
    width: 50,
    position: "absolute",
    left: 90,
    top: 610,
  },
  divDate2: {
    position: "absolute",
    top: 662,
    left: 500
  },
  sign2: {
    width: 50,
    position: "absolute",
    left: 90,
    top: 650,
  },
  divDate3: {
    position: "absolute",
    top: 702,
    left: 500
  },
  sign3: {
    width:50,
    position: "absolute",
    left: 90,
    top: 690,
  }
};
export default PDF_Form8;
