import React, { useState, useEffect } from "react";
import { Col, Row, Table, Button, Form, Modal } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import { SlPencil } from "react-icons/sl";

function CustomTable({ data,title, perPage, currentPage, onPageChange, onDelete, onEdit, onAdd,haveControl }) {
  const [searchTerm, setSearchTerm] = useState("");
  const totalPages = Math.ceil(data && data.length ? data.length / perPage : 0);

  const goToPage = (page) => {
    onPageChange(page);
  };

  const filteredData = data
    ? data.filter((item) =>
        Object.values(item).some((value) =>
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
    : [];

  return (
    <div>
      <Col sm="6" className="mb-2">
        <Form.Group controlId="search">
          <Form.Control
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Form.Group>
      </Col>
      <Table striped bordered hover>
        <thead>
          <tr>
            {title &&
              title.length > 0 &&
              title.map((item, index) => <th key={index}>{item}</th>)}
            <th>Control</th>
          </tr>
        </thead>
        <tbody>
          {filteredData
            .slice((currentPage - 1) * perPage, currentPage * perPage)
            .map((item, index) => {
              const itemIndex = index + 1 + (currentPage - 1) * perPage;
              return (
                <tr key={index}>
                  {Object.keys(item)
                    .slice(0)
                    .map((key, index) => (
                      <td key={index}>
                        {key.toLowerCase().includes("color") ? (
                          <div
                            style={{
                              backgroundColor: item[key],
                              width: "40px",
                              height: "40px",
                              border: "1px solid #ced4da",
                            }}
                          ></div>
                        ) : (
                          item[key]
                        )}
                      </td>
                    ))}
                  <td>
                    <Button
                      variant="info"
                      onClick={() => onEdit(item)}
                      className="table_button"
                    >
                      <SlPencil color="#fff" />
                    </Button>
                    {/*<Button
                      variant="danger"
                      onClick={() => onDelete(item)}
                      className="table_button"
                    >
                      <FaIcons.FaRegTrashAlt />
                    </Button>*/}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <div className="pagination">
        <Button
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        {Array.from({ length: totalPages }, (_, i) => (
          <Button
            key={i}
            onClick={() => goToPage(i + 1)}
            disabled={currentPage === i + 1}
          >
            {i + 1}
          </Button>
        ))}
        <Button
          onClick={() => goToPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

export default CustomTable;
