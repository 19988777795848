import React, { useState, useRef } from "react";
import { Modal, Button, Form, Tabs, Tab } from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";
import api from "../../services/api";

const SignDocumentModalBox = ({ folioID, documentNumber, userID, onSignatureComplete }) => {
  const [showModal, setShowModal] = useState(false);
  const [signatureText, setSignatureText] = useState("");
  const [nameSignData, setNameSign] = useState("");
  const [key, setKey] = useState("draw");
  const sigCanvas = useRef({});

  const handleShow = (signerName) => {
    setNameSign(signerName);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const saveSignature = async () => {
    let signatureData;

    if (key === "draw") {
      signatureData = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    } else {
      signatureData = signatureText;
    }
    const data = {
      folio: folioID,
      usuario_id: userID,
      num_form: documentNumber,
      documento: signatureData,
      nameSign: nameSignData,
    };

    try {
      const response = await api.post(`sign/SignController.php`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        onSignatureComplete(signatureData, 'success');
      } else {
        console.error('Error al guardar la firma');
        onSignatureComplete('Error al guardar la firma', 'error');
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
      onSignatureComplete('Error en la solicitud', 'error');
    }

    handleClose();
  };

  const getBTNSign = () => {
    switch (documentNumber) {
      case '1':
        return (
          <Button variant="primary" onClick={() => handleShow("Director")}>
            Director's Signature
          </Button>
        );
      case '3':
        return (
          <div>
            <Button variant="primary" onClick={() => handleShow("Legal Next of Kin")}>
              Sign Legal Next of Kin
            </Button>
            <div style={{ marginTop: "10px" }}>
              <Button variant="dark" onClick={() => handleShow("Witness")}>
                Sign Witness
              </Button>
            </div>
          </div>
        );
      case '4':
        return (
          <Button variant="primary" onClick={() => handleShow("Marison Ramirez")}>
            Marison Ramirez's Signature
          </Button>
        );
      case '5':
        return (
          <div>
            <div>
              <Button variant="primary" onClick={() => handleShow("Responsable Party")}>
                Responsable Party's Signature
              </Button>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Button variant="secondary" onClick={() => handleShow("Funeral Director")}>
                Funeral Director's Signature
              </Button>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Button variant="dark" onClick={() => handleShow("Funeral Arrangement Counselor")}>
                Funeral Arrangement counselor's Signature
              </Button>
            </div>
          </div>
        );
      case '6':
        return (
          <div>
            <div>
              <Button variant="primary" onClick={() => handleShow("Authorizing Agent")}>
                Authorizing Agent’s Signatures 1
              </Button>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Button variant="secondary" onClick={() => handleShow("Authorizing Agent2")}>
                Authorizing Agent’s Signatures 2
              </Button>
            </div>
          </div>
        );
      case '8':
        return (
          <div>
            <div>
              <Button variant="primary" onClick={() => handleShow("Person1")}>
                Sirst signature
              </Button>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Button variant="secondary" onClick={() => handleShow("Person2")}>
                Second signature
              </Button>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Button variant="dark" onClick={() => handleShow("Person3")}>
                Third signature
              </Button>
            </div>
          </div>
        );
      default:
        return <div>Documento no reconocido</div>;
    }
  };

  return (
    <>
      {getBTNSign()}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Sign Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
            <Tab eventKey="draw" title="Draw">
              <div style={{ marginBottom: "10px", borderBottom: "2px solid #000" }}>
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor="black"
                  canvasProps={{
                    width: 400,
                    height: 200,
                    className: "sigCanvas",
                    style: { border: "1px solid #000" },
                  }}
                />
                <Button variant="secondary" onClick={clearSignature}>
                  Clear
                </Button>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={saveSignature}>
            Save sign
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SignDocumentModalBox;
