import React, { useState,useEffect } from "react";
import { Button, Col, Row} from "react-bootstrap";
import DefaultInput from "../../../../components/Elements/DefaultInput";
import GerUsersFromCookies from "../backend/GetUserFromCookies"
import api from "../../../../services/api";
import { toast } from "react-toastify";

const MotherInfo = ({ onMotherDataChange ,folioId,folio}) => {
  const [formData, setFormData] = useState({
    secuencia :folio,
    folio :folioId,
    usuario_id : GerUsersFromCookies().id_usuario,
    
    firstNameMother: "",
    middleNameMother: "",
    lastNameMother: "",
    suffixMother: "",
  });
  const handleInputChange = (name, value) => {
    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    setFormData(updatedFormData);
    onMotherDataChange(updatedFormData);
  };
  const handleSubmit = () => {
    add_motherInfo(formData);
  };
  const add_motherInfo = async (formData) => {
    try {
      const response = await api.post(
        "documents/form1/MotherController.php",
        formData,
        { params: { action: "POST" } }
      );
      console.log(response);
      toast.success("Mother info successfully added", {
        className: "toast-success",
        theme: "colored",
      });
      
    } catch (error) {
      console.error("Error adding archive", error);
      toast.error("Error adding mother info", {
        className: "toast-error",
        theme: "colored",
      });

    }
  };
  useEffect(() => {
    get_mother_info_with_ID(folioId);
  }, []);
  const get_mother_info_with_ID = async (folio) =>{
    try{
      const response = await api.get(
        `documents/form1/MotherController.php?id=${folio}`
      );
      const motherInfo = response.data[0];
      if(response.data[0] != null){
        setFormData(prevFormData => ({
          ...prevFormData,
          firstNameMother: motherInfo.name,
          middleNameMother: motherInfo.middle,
          lastNameMother: motherInfo.lastname,
          suffixMother: motherInfo.suffix,
      }));
      }
    }
    catch{

    }
  }
  useEffect(() => {
    if (Object.keys(formData).some(key => formData[key] !== "")) {
      onMotherDataChange(formData);
    }
  }, [formData, onMotherDataChange]);
  return (
    <>
      <div className="main-card">
        <DefaultInput
          numberRow={"17A.-"}
          placeholder={"MOTHER’S FIRST NAME"}
          type={"text"}
          onChange={(value)=> handleInputChange("firstNameMother",value)}
          valueData={formData.firstNameMother}

        />
        <DefaultInput
          numberRow={"17B.-"}
          placeholder={"MOTHER’S MIDDLE NAME"}
          type={"text"}
          onChange={(value)=> handleInputChange("middleNameMother",value)}
          valueData={formData.middleNameMother}
        />
        <DefaultInput
          numberRow={"17C.-"}
          placeholder={"MOTHER’S LAST NAME PRIOR TO FIRST MARRIAGE"}
          type={"text"}
          onChange={(value)=> handleInputChange("lastNameMother",value)}
          valueData={formData.lastNameMother}

        />
        <DefaultInput
          numberRow={"17d.-"}
          placeholder={"SUFFIX"}
          type={"text"}
          onChange={(value)=> handleInputChange("suffixMother",value)}
          valueData={formData.suffixMother}
        />
      </div>
      <div className="d-flex justify-content-end mt-3">
        <Button onClick={handleSubmit}>SAVE MOTHER INFO</Button>
      </div>
    </>
  );
};

export default MotherInfo;
