import React, { useState,useEffect } from "react";
import { Button, Row } from "react-bootstrap";
import DefaultInput from "../../../../components/Elements/DefaultInput";
import DefaultRadioButtons from "../../../../components/Elements/DefaultRadioButtons";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import getUserDataFromCookies from "../backend/GetUserFromCookies";

const CeretifierInfo = ({ onCertifierDataChange,folio,consecutivo }) => {
  const [formData, setFormData] = useState({
    consecutivo :consecutivo,
    folio :folio,
    usuario_id : getUserDataFromCookies().id_usuario,

    certifierType: "",
    certifierLicenseNumber: "",
    certifierName: "",
    certifierTitleOption: "",
    certifierTitleValue: "",
    certifierAddress: "",
    certifierZipCode: "",
    certifierCity: "",
    certifierState: "",
    certifierCountry: "",
    certifierNameAlternate: "",
    certifierTelephone: "",
    certifierFax: "",
    certifierEmail: "",
  });
  //17 fields

  const certifierType = [
    { label: "Physician", value: "1" },
    { label: "Medical Examiner", value: "2" },
    { label: "Nurse Practitioner", value: "3" },
    { label: "Physician’s Assistant Tribal", value: "4" },
    { label: "Tribal Authority", value: "5" },
    { label: "Unknown, Not Classified", value: "6" },
  ];

  const certifierTitle = [
    { label: "Doctor of Medicine", value: "1"},
    { label: "Doctor of Osteopathy", value: "2"},
    { label: "C.N.M./C.M", value: "3"},
    { label: "Physician Assistant (PA)", value: "4"},
    { label: "Tribal Law Enforcement", value: "5"},
    { label: "Naturopathic Physician", value: "6"},
    { label: "Nurse Midwife", value: "7"},
    { label: "Medical Doctor Intern/Resident", value: "8"},
    { label: "APRN", value: "9"},
  ];


  const handleInputChange = (name, value) => {
    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    setFormData(updatedFormData);
    onCertifierDataChange(updatedFormData);
  };
  const handleSubmit = () => {
    add_certifier_info(formData);
  };
  const add_certifier_info = async (formData) => {

    try {
      const response = await api.post(
        "documents/form1/CertifierInfoController.php",
        formData,
        { params: { action: "POST" } }
      );
      toast.success("Funeral info added successfully", {
        className: "toast-success",
        theme: "colored",
      });
    } catch (error) {
      console.error("Error adding archive", error);
      toast.error("Error to add fuenral info", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };
  useEffect(() => {
    get_certifier_info(folio);
  }, []);
  const get_certifier_info = async (id) => {
    try {
      const response = await api.get(
        `documents/form1/CertifierInfoController.php?id=${id}`
      );
      const certifier_info = response.data[0];
      if (response.data[0] != null) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          certifierType: certifier_info.certifierType,
          certifierLicenseNumber: certifier_info.certifierLicenseNumber,
          certifierName: certifier_info.certifierName,
          certifierTitleOption:certifier_info.certifierTitleOption,
          certifierTitleValue: certifier_info.certifierTitleValue,
          certifierAddress: certifier_info.certifierAddress,
          certifierZipCode: certifier_info.certifierZipCode,
          certifierCity: certifier_info.certifierCity,
          certifierState: certifier_info.certifierState,
          certifierCountry: certifier_info.certifierCountry,
          certifierNameAlternate: certifier_info.certifierNameAlternate,
          certifierTelephone: certifier_info.certifierTelephone,
          certifierFax: certifier_info.certifierFax,
          certifierEmail: certifier_info.certifierEmail,
        }));
      } else {
        //GetFuneralHomeInformation();
      }
    } catch {}
  };
  useEffect(() => {
    if (Object.keys(formData).some(key => formData[key] !== "")) {
      onCertifierDataChange(formData);
    }
  }, [formData, onCertifierDataChange]);
  return (
    <>
      <div className="main-card">
        <DefaultRadioButtons numberRow={"29A.-"} label={"CERTIFIER TYPE"} options={certifierType} 
        onChange={(value) => handleInputChange("certifierType", value)} 
        valueData={formData.certifierType}
        />

        <DefaultInput
          numberRow={"29B.-"}
          placeholder={"CERTIFIER’S LICENSE NUMBER"}
          type={"text"}
          onChange={(value) => handleInputChange("certifierLicenseNumber", value)}
          valueData={formData.certifierLicenseNumber}

        />
        <DefaultInput
          numberRow={"29C.-"}
          placeholder={"CERTIFIER’S NAME (first, middle, last, suffix)"}
          type={"text"}
          onChange={(value) => handleInputChange("certifierName", value)}
          valueData={formData.certifierName}

        />

        <DefaultRadioButtons numberRow={"29D.-"} label={"CERTIFIER’S TITLE"} options={certifierTitle} 
        onChange={(value) => handleInputChange("certifierTitleOption", value)} valueData={formData.certifierTitleOption}
        />

        <DefaultInput
          placeholder={"Other (Specify)"}
          type={"text"}
          onChange={(value) => handleInputChange("certifierTitleValue", value)}
          valueData={formData.certifierTitleValue}
        />
        <DefaultInput
          numberRow={"29E.-"}
          placeholder={"CERTIFIER’S ADDRESS"}
          type={"text"}
          onChange={(value) => handleInputChange("certifierAddress", value)}
          valueData={formData.certifierAddress}

        />
        <DefaultInput
          numberRow={"29F.-"}
          placeholder={"CERTIFIER’S ZIP CODE"}
          type={"text"}
          onChange={(value) => handleInputChange("certifierZipCode", value)}
          valueData={formData.certifierZipCode}

        />
        <DefaultInput
          numberRow={"29G.-"}
          placeholder={"CERTIFIER’S CITY, TOWN, OR LOCATION"}
          type={"text"}
          onChange={(value) => handleInputChange("certifierCity", value)}
          valueData={formData.certifierCity}

        />
        <DefaultInput
          numberRow={"29H.-"}
          placeholder={"CERTIFIER’S STATE"}
          type={"text"}
          onChange={(value) => handleInputChange("certifierState", value)}
          valueData={formData.certifierState}

        />
        <DefaultInput
          numberRow={"29I.-"}
          placeholder={"CERTIFIER’S COUNTRY"}
          type={"text"}
          onChange={(value) => handleInputChange("certifierCountry", value)}
          valueData={formData.certifierCountry}

        />
        <DefaultInput
          numberRow={"30A.-"}
          placeholder={"NAME OF ALTERNATE CERTIFIER"}
          type={"text"}
          onChange={(value) => handleInputChange("certifierNameAlternate", value)}
          valueData={formData.certifierNameAlternate}

        />
        <DefaultInput
          numberRow={"30B.-"}
          placeholder={"TELEPHONE NUMBER"}
          type={"text"}
          onChange={(value) => handleInputChange("certifierTelephone", value)}
          valueData={formData.certifierTelephone}

        />
        <DefaultInput
          numberRow={"30C.-"}
          placeholder={"FAX NUMBER"}
          type={"text"}
          onChange={(value) => handleInputChange("certifierFax", value)}
          valueData={formData.certifierFax}

        />
        <DefaultInput
          numberRow={"30D.-"}
          placeholder={"EMAIL ADDRESS"}
          type={"text"}
          onChange={(value) => handleInputChange("certifierEmail", value)}
          valueData={formData.certifierEmail}

        />
      </div>
      <div className="d-flex justify-content-end mt-3">
        <Button onClick={handleSubmit}>SAVE RESIDENCE INFO</Button>
      </div>
    </>
  );
};

export default CeretifierInfo;
