import api from "../../../../services/api";

const GetInfo_Form5_PackageSelected = async ({ consecutivo }) => {
  try {
    const response = await api.get(
      `documents/form5/PackageInfoController.php?id=${consecutivo}`
    );
    const packageInfo = response.data[0];

    return {
      folio: packageInfo?.folio || "",
      PackageSelected: packageInfo?.packageSelected || "",
      basicService: packageInfo?.basicService || "",
      embalming: packageInfo?.embalming || "",
      refigeration: packageInfo?.regrigeration || "",
      cosmetics: packageInfo?.cosmetics || "",
      preservation: packageInfo?.temporaryPreservation || "",
      regriferation: packageInfo?.regrigerationCleansing || "",
      washing: packageInfo?.washingCleaning || "",
      otherBasic: packageInfo?.otherPreparation || "",
      viewing: packageInfo?.facilitiesStaff || "",
      ceremony: packageInfo?.facilitiesCeremony || "",
      equimentCeremony: packageInfo?.equipmentCeremony || "",
      otherLocation: packageInfo?.anotherLocation || "",
      memorialService: packageInfo?.facilitiesMemorial || "",
      gravesideService: packageInfo?.equipmentGraveside || "",
      transferFuneralHome: packageInfo?.transferFuneralHome || "",
      transferAirport: packageInfo?.transferAirport || "",
      hearse: packageInfo?.hearse || "",
      serviceVehicle: packageInfo?.utilityVehicle || "",
      additionalMileage: packageInfo?.additionalMileage || "",
      cremation: packageInfo?.cremationFee || "",
      packing: packageInfo?.packingUSPS || "",
      cremainsDelivered: packageInfo?.delivered25miles || "",
      umPresonalization: packageInfo?.umPersonalization || "",
      Jewelry: packageInfo?.jewlry || "",
      JewelryCount: packageInfo?.jewlryCount || "",
      urn: packageInfo?.urn || "",
      archival: packageInfo?.archivalFee || "",
      accountSetup: packageInfo?.accountSetup || "",
      Other: packageInfo?.otherCremation || "",
      deathCertificate: packageInfo?.deathCertificate || "",
      deathCertificateCount: packageInfo?.deathCertificateCount || "",
      statePermit: packageInfo?.arizonaDisposition || "",
      pimaMedicalExaminers: packageInfo?.pimaMedical || "",
      consulateFee: packageInfo?.consulateFee || "",
      apostilleDeathCertificate: packageInfo?.apostilleDeath || "",
      decentAirTransportation: packageInfo?.transportationFee || "",
      prayerCards: packageInfo?.prayerCards || "",
      prayerCardsCount: packageInfo?.prayerCardsCount || "",
      flowersCrucifixBook: packageInfo?.flowers || "",
      casket: packageInfo?.casket || "",
      description: packageInfo?.descriptionCash || "",
      otherBurial: packageInfo?.otherBurialContainer || "",
      otherCharges1: packageInfo?.otherCash1 || "",
      otherCharges2: packageInfo?.otherCash2 || "",
      TotalValue: packageInfo?.Total || "",
      downPaymentAdjustmentsDescription: packageInfo?.downPaymentDescriprition || "",
      downPaymentAdjustmentsNumber: packageInfo?.downPaymentNumber || "",
      typePayment: packageInfo?.paymentType || "",
      payment: packageInfo?.payment || "",
      blanceDue: packageInfo?.BalanceDue || "",
    };
  } catch (error) {
    console.log("Error get info", error);
    return {
      folio: "",
      PackageSelected: "",
      basicService: "",
      embalming: "",
      refigeration: "",
      cosmetics: "",
      preservation: "",
      regriferation: "",
      washing: "",
      otherBasic: "",
      viewing: "",
      ceremony: "",
      equimentCeremony: "",
      otherLocation: "",
      memorialService: "",
      gravesideService: "",
      transferFuneralHome: "",
      transferAirport: "",
      hearse: "",
      serviceVehicle: "",
      additionalMileage: "",
      cremation: "",
      packing: "",
      cremainsDelivered: "",
      umPresonalization: "",
      Jewelry: "",
      JewelryCount: "",
      urn: "",
      archival: "",
      accountSetup: "",
      Other: "",
      deathCertificate: "",
      deathCertificateCount: "",
      statePermit: "",
      pimaMedicalExaminers: "",
      consulateFee: "",
      apostilleDeathCertificate: "",
      decentAirTransportation: "",
      prayerCards: "",
      prayerCardsCount: "",
      flowersCrucifixBook: "",
      casket: "",
      description: "",
      otherBurial: "",
      otherCharges1: "",
      otherCharges2: "",
      TotalValue: "",
      downPaymentAdjustmentsDescription: "",
      downPaymentAdjustmentsNumber: "",
      typePayment: "",
      payment: "",
      blanceDue: "",
    };
  }
};

export default GetInfo_Form5_PackageSelected;
