import React, { useState, useEffect } from "react";
const Detail = ({ data }) => {
  const [detailData, setDetailData] = useState(null);
  useEffect(() => {
    if (data && data.length > 0) {
      setDetailData(data[0]);
    } else {
      setDetailData(null);
    }
  }, [data]);

  if (!detailData) {
    return <h1>No data available</h1>;
  }
  const { Name, Sex, dateOfBirth, dateOfDeath, total, balance, comments,From } =
    detailData;

  return (
    <>
      <span style={{ fontWeight: "bold" }}>Name: </span>
      <span>{Name}</span>
      <br />
      <span style={{ fontWeight: "bold" }}>From: </span>
      <span>{From}</span>
      <br />
      <span style={{ fontWeight: "bold" }}>Sex: </span>
      <span>{Sex}</span>
      <br />
      <span style={{ fontWeight: "bold" }}>Date of Birth: </span>
      <span>{dateOfBirth}</span>
      <br />
      <span style={{ fontWeight: "bold" }}>Date of Death: </span>
      <span>{dateOfDeath}</span>
      <br />
      <span style={{ fontWeight: "bold" }}>Total Recipe: </span>
      <span>${total}</span>
      <br />
      <span style={{ fontWeight: "bold" }}>Balance Due: </span>
      <span>${balance}</span>
    </>
  );
};

export default Detail;
