import { React, useState } from "react";
import { useParams } from "react-router-dom";
import PDF_Form3 from "./PDF_Form4";
import { Button, Col, Row, Modal } from "react-bootstrap";
import getUserDataFromCookies from "../Form1/backend/GetUserFromCookies";
import EmbalmingForm from "./Form4/form";
import { PDFViewer } from "@react-pdf/renderer";

const PurchaseReceipt = () => {
  const { folioID } = useParams();
  const { consecutivo } = useParams();
  var userID = getUserDataFromCookies().id_usuario;

  var dataReceipt = {
    consecutivo: parseInt(consecutivo),
    folio: folioID,
    usuario_id: getUserDataFromCookies().id_usuario,
    Date: "",
    AccountNumber: "",
    DetailedClaim: "",
    Payment: "",
    BalanceDue: "",
  };
  const [pdfKey, setPdfKey] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(dataReceipt);

  const setReceiptData = (value) => {
    setFormData(value);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <h2 className="f-cel">{folioID}</h2>
        <h3 className="f-cel">PURCHASE RECEIPT</h3>
        <div><button className="btn btn-primary" onClick={handleShowModal}>View Document</button></div>
      </div>

      <Row>
        <Col lg="10">
          <EmbalmingForm  consecutivo={consecutivo} folio={folioID} setPurchaseData={setReceiptData}/>
        </Col>
      </Row>
      <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Body>
          <PDFViewer style={{ width: "100%", height: "800px" }}>
            <PDF_Form3 data={formData}/>
          </PDFViewer>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PurchaseReceipt;
