import { React, useState, useEffect } from "react";
import { Document, Page, Image, Text } from "@react-pdf/renderer";
import getUserDataFromCookies from "../Form1/backend/GetUserFromCookies";
import api from "../../../services/api";
const PDF_Form3 = ({ data }) => {
  const folioID = data['folio'];
  const [signatureInfo, setSignatureData] = useState(dataSignatureInfo);
  var userID = getUserDataFromCookies().id_usuario;

  var dataSignatureInfo = {
    usuario_id: userID,
    folio: folioID,
    num_form: "",
    documento: "",
    nameSign: ""
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  };

  useEffect(() => {
    const fetchSignatures = async () => {
      try {
        const response = await api.get(
          `sign/SignController.php?folio=${folioID}&num_form=3`
        );
        const data = response.data;
        setSignatureData(data);
      } catch (error) {
        console.error("Error fetching signatures info:", error);
        setSignatureData(prevState => ({ ...prevState, signatures: [] }));
      }
    };

    fetchSignatures();
  }, [folioID]);

  const getSignatureInfo = (name) => {
    if (signatureInfo && Array.isArray(signatureInfo)) {
      for (let i = 0; i < signatureInfo.length; i++) {
        const signature = signatureInfo[i];
        if (signature.nameSign === name) {
          const formattedDate = formatDate(signature.timeSign);
          return {
            date: formattedDate,
            image: signature.documento
          };
        }
      }
    }
    return null;
  };

  const renderSignature = (name) => {
    const signature = getSignatureInfo(name);
    if (signature) {
      return (
        <Image src={`data:image/png;base64,${signature.image}`} style={styles.signature} />
      );
    }
    return null;
  };

  const LegalNextOfKinSignatureInfo = getSignatureInfo("Legal Next of Kin");
  const WitnessSignatureInfo = getSignatureInfo("Witness");

  return (
    <Document style={styles.document}>
      <Page size="Letter">
        <div style={styles.lines}></div>
        <div
          style={{
            flexDirection: "row",
            marginLeft: "40px",
            marginTop: "40px",
          }}
        >
          <Image
            source={"/img/logo/logo.png"}
            style={{ width: " 120px ", height: "120px" }}
          ></Image>
          <div
            style={{
              textAlign: "center",
              color: "#2f5496",
              flex: "1",
              alignItems: "center",
              marginTop: "30px",
              marginLeft: "-100px",
            }}
          >
            <Text>Ramirez Funeral Home LLC</Text>
            <Text>4595 S Palo Verde Rd. Suite 503, Tucson Arizona 85714</Text>
            <Text>Email: ramirezfuneralhome@gmail.com</Text>
            <Text>Phone: (520) 372-2479 Fax: (520) 842-3889</Text>
          </div>
        </div>
        <div style={styles.container}>
          <Text style={styles.title}>Authorization of Release / Embalming.</Text>
          <Text style={styles.mt100}>I,                     {data["witnessName"]} </Text>
          <div style={styles.line}></div>
          <Text style={styles.mt5}>
            authorized Ramirez Funeral Home LLC, the embalming and care. For the
            prepare for the final disposition.
          </Text>
          <Text style={styles.mt5}>Of                      {data["decreasedName"]}</Text>
          <div style={styles.line}></div>

          <Text style={styles.mt100}>
            In accordance with customary practices and as provided by the rules
            regulations and laws of the State of Arizona.
          </Text>
          <div style={styles.sign}>
            <div style={styles.flex}>
              <div style={styles.textSign}>
                {renderSignature("Legal Next of Kin")}
              </div>
              <div style={styles.dateSign}>
                <Text>{LegalNextOfKinSignatureInfo?.date}</Text>
              </div>
            </div>
            <div style={styles.lineSign}></div>
            <Text style={styles.mt5}>Legal Next of Kin                                                                         Date</Text>
          </div>

          <div style={styles.signW}>
            <div style={styles.flex}>
              <div style={styles.textSign}>
                {renderSignature("Witness")}
              </div>
              <div style={styles.dateSign}>
                <Text>{WitnessSignatureInfo?.date}</Text>
              </div>
            </div>
            <div style={styles.lineSign}></div>
            <Text style={styles.mt5}>Witness                                                                                       Date</Text>
          </div>
        </div>
      </Page>
    </Document>
  );
};
const styles = {
  title: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: "bold"
  },
  signInformation: {
    display: "flex",
    justifyContent: "space-between !important",
  },
  sign: {
    position: "absolute",
    top: "200px"
  },
  signW: {
    position: "absolute",
    top: "300px"
  },
  textSign: {
    position: "absolute",
    top: "0px",
  
  },
  dateSign: {
    position: "absolute",
    top: "-10px",
    right: "0",
    marginRight: -20,
  },
  flex: {
    display: "flex",
  },
  lineSign: {
    width: "150%",
    border: "1px solid #000",
  },
  line: {
    width: "100%",
    marginTop: -5,
    marginLeft: 10,
    border: "1px solid #000",
  },
  lines: {
    position: "absolute",
    border: "2px solid #2f5496",
    width: "90%",
    height: "90%",
    margin: 30,
  },
  mt100: {
    marginTop: 20,
  },
  container: {
    marginTop: 50,
    marginRight: 80,
    marginLeft: 80,
  },
  document: {
    fontSize: 11,
    lineHeight: 1.5,
    textAlign: "justify",
    fontFamily: "Roboto",
  },
  header: {
    color: "#2f5496",
    textAlign: "center",
    marginTop: 80,
  },
  black: {
    fontWeight: 700,
  },
  mt50: {
    marginTop: 60,
  },

  mt5: {
    marginTop: 8,
  },
  signature: {
    position: "absolute",
    width: "60px",
    height: "30px",
    top: "-30px"
  },
  signature2: {
    position: "absolute",
    width: "60px",
    height: "30px",
    top: "-30px",
    zIndex: "100"
  },
  flex: {
    display: "flex"
  }
};
export default PDF_Form3;
