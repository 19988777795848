import api from "../../../../services/api";

const GetInfoForm8 = async ({ folio }) => {
  try {
    const response = await api.get(
      `documents/form8/CrematoryTucsonController.php?id=${folio}`
    );
    const haveData = response.data[0];

    return {
      folio: haveData?.folio || "",
      consecutivo: haveData?.secuencia || "",
      dateOfDeath: response?.data[0].dateOfDeath || "",
      date_fh: response?.data[0].date_fh || "",
      decreasedName: response?.data[0].decreasedName || "",
      email_1: response?.data[0].email_1 || "",
      email_2: response?.data[0].email_2 || "",
      email_3: response?.data[0].email_3 || "",
      funeralEstablishment: response?.data[0].funeralEstablishment || "",
      funeralHomeRep: response?.data[0].funeralHomeRep || "",
      knowledgeOption: response?.data[0].knowledgeOption || "",
      mechanicalDeviceOption: response?.data[0].mechanicalDeviceOption || "",
      otherEqualAuthority: response?.data[0].otherEqualAuthority || "",
      phone_1: response?.data[0].phone_1 || "",
      phone_2: response?.data[0].phone_2 || "",
      phone_3: response?.data[0].phone_3 || "",
      printedName_1: response?.data[0].printedName_1 || "",
      printedName_2: response?.data[0].printedName_2 || "",
      printedName_3: response?.data[0].printedName_3 || "",
      printedName_fh: response?.data[0].printedName_fh || "",
      relationship_1: response?.data[0].relationship_1 || "",
      relationship_2: response?.data[0].relationship_2 || "",
      relationship_3: response?.data[0].relationship_3 || "",
      stateRelationship: response?.data[0].stateRelationship || "",
    };
  } catch (error) {
    console.log("Error get info", error);
    return {
      folio: "",
      consecutivo: "",
      dateOfDeath: "",
      date_fh: "",
      decreasedName: "",
      email_1: "",
      email_2: "",
      email_3: "",
      funeralEstablishment: "",
      funeralHomeRep: "",
      knowledgeOption: "",
      mechanicalDeviceOption: "",
      otherEqualAuthority: "",
      phone_1: "",
      phone_2: "",
      phone_3: "",
      printedName_1: "",
      printedName_2: "",
      printedName_3: "",
      printedName_fh: "",
      relationship_1: "",
      relationship_2: "",
      relationship_3: "",
      stateRelationship: "",
    };
  }
};

export default GetInfoForm8;
