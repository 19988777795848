import React from "react";

// Función para convertir color hexadecimal a RGB
function hexToRgb(hex) {
  // Elimina el signo '#' si está presente
  hex = hex.replace(/^#/, '');

  // Parsea los componentes rojo, verde y azul
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Retorna la representación en formato RGB
  return `rgb(${r}, ${g}, ${b})`;
}

// Función para oscurecer un color RGB
function darkenColor(rgb, factor) {
  // Divide el color RGB en sus componentes R, G y B
  const [r, g, b] = rgb.match(/\d+/g);

  // Calcula los nuevos valores de los componentes oscurecidos
  const newR = Math.floor(r * factor);
  const newG = Math.floor(g * factor);
  const newB = Math.floor(b * factor);

  // Retorna el color oscurecido en formato RGB
  return `rgb(${newR}, ${newG}, ${newB})`;
}

function Folder({ color }) {
  // Convierte el color hexadecimal a RGB
  const backgroundColor = hexToRgb(color);
  // Oscurece el color de fondo en un 20%
  const darkenedBackgroundColor = darkenColor(backgroundColor, 0.8);

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        id="folder"
      >
        <path
          fill={darkenedBackgroundColor}
          d="M2.1,57.4h57.7c1.1,0,2.1-0.9,2.1-2.1V13.4c0-1.1-0.9-2.1-2.1-2.1H31c-0.8,0-1.5-0.4-1.8-1.1l-1.7-3.4
		c-0.4-0.7-1.1-1.1-1.8-1.1H2.1C0.9,5.7,0,6.6,0,7.8v47.5C0,56.4,0.9,57.4,2.1,57.4z"
        ></path>
        <path
          fill={darkenedBackgroundColor}
          d="M3,58.3h56.7c1.1,0,2.1-0.9,2.1-2.1V14.3c0-1.1-0.9-2.1-2.1-2.1H31c-0.8,0-1.5-0.4-1.8-1.1l-1.7-3.4
		c-0.4-0.7-1.1-1.1-1.8-1.1H2.1C0.9,6.6,0,7.6,0,8.7v46.6C0,56.9,1.3,58.3,3,58.3z"
        ></path>
        <rect width="58.6" height="21.1" x="1.6" y="14.7" fill="#f3f3f3"></rect>
        <rect width=".8" height="21.1" x="1.6" y="14.7" fill="#e2e2e2"></rect>
        <rect width="58.6" height=".9" x="1.6" y="14.7" fill="#e2e2e2"></rect>
        <path
          fill={color}
          d="M64,18.6v35.8c0,1.7-1.3,3-3,3H3c-1.7,0-3-1.3-3-3v-31c0-1.7,1.3-3,3-3h29.2c0.8,0,1.6-0.3,2.1-0.9l3-3
		c0.6-0.6,1.3-0.9,2.1-0.9H61C62.7,15.6,64,17,64,18.6z"
        ></path>
        <path
          fill={color}
          d="M64,19.5v35.8c0,1.7-1.3,3-3,3H3c-1.7,0-3-1.3-3-3v-31c0-1.7,1.3-3,3-3h29.2c0.8,0,1.6-0.3,2.1-0.9l3-3
		c0.6-0.6,1.3-0.9,2.1-0.9H61C62.7,16.5,64,17.9,64,19.5z"
        ></path>
      </svg>
    </>
  );
}

export default Folder;
