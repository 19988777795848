import React, { useState } from "react";
import Sidebar from "./components/Sidebar/Sidebar";
import Navbar from "./components/Navbar/Navbar";
import { Row,Container } from "react-bootstrap";

const Layout = ({ layout }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const urlPath = window.location.pathname;

  const pathParts = urlPath.split("/");
  const relevantParts = pathParts
    .filter((part) => part)
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1));

  return (
    <>
      {urlPath !== "/login" ? (
        <>
          <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
          <div className="contenedor">
            <Navbar
              toggleSidebar={toggleSidebar}
              className={sidebarOpen ? "contenedor-open" : ""}
            />

            <div style={{ height: "70vh" }}>

              <Container>
              <Row className="mt-3">
              <h4 className="url-title">
                {relevantParts.length >= 1 && (
                  <>
                    <span className="text-muted">
                      {decodeURIComponent(relevantParts[0])}
                    </span>{" "}
                    {relevantParts.length >= 2 && (
                      <>/ {decodeURIComponent(relevantParts[1])}</>
                    )}
                  </>
                )}
              </h4>
            </Row>
                {layout}
                
              </Container>
            </div>
          </div>
        </>
      ) : (
        <>{layout}</>
      )}
    </>
  );
};

export default Layout;
