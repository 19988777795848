import React,{useState,useEffect} from "react";
import DefaultInput from "../../../../components/Elements/DefaultInput";
import { Row, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {toast } from "react-toastify";
import api from "../../../../services/api"
const ResponsibleParty = ({onResponsibleData,folioId,usuarioId}) => {
const { consecutivo } = useParams();

const [formData, setFormData] = useState({
    usuarioID: usuarioId,
    folio: folioId,
    consecutivo: consecutivo,

    NameOfResponsibleParty_1:" ",
    Relationship_1:" ",
    Address_1:" ",
    Phone_1:" ",

    NameOfResponsibleParty_2:" ",
    Relationship_2:" ",
    Address_2:" ",
    Phone_2:" ",
  });
const saveData = () => {
  console.log(formData)
  addResponsible(formData);
}

const handleInputChange = (name, value) => {
  const updatedFormData = {
    ...formData,
    [name]: value,
  };

  setFormData(updatedFormData);
  onResponsibleData(updatedFormData);
};
const addResponsible = async (formData) => {
  try {
    const response = await api.post(
      "documents/form5/ResponsibleInfoController.php",
      formData,
      { params: { action: "POST" } }
    );
    console.log(response);
    toast.success("Responsible party added successfully", {
      className: "toast-success",
      theme: "colored",
    });
  } catch (error) {
    toast.error("Error to add responsible party info", {
      className: "toast-error",
      theme: "colored",
    });
    console.error("Error adding archive", error);

  }
};

useEffect(() => {
  getResponsibleParty(folioId);
}, []);

const getResponsibleParty = async (folio) =>{
  try{
    const response = await api.get(
      `documents/form5/ResponsibleInfoController.php?id=${folio}`
    );
    const responsibleInfo = response.data[0];
    console.log(response)
    if(response.data[0] != null){
      setFormData(prevFormData => ({
        ...prevFormData,
        NameOfResponsibleParty_1:responsibleInfo.nameRes1,
        Relationship_1:responsibleInfo.relationRes1,
        Address_1:responsibleInfo.addressRes1,
        Phone_1:responsibleInfo.phoneRes1,
    
        NameOfResponsibleParty_2:responsibleInfo.nameRes2,
        Relationship_2:responsibleInfo.relationRes2,
        Address_2:responsibleInfo.addressRes2,
        Phone_2:responsibleInfo.phoneRes2,

    }));
    }
  }
  catch{
  }
}
  return (
    <>
      <div className="main-card">
        <DefaultInput placeholder={"Name of responsible party #1"} onChange={(value => handleInputChange("NameOfResponsibleParty_1",value))} valueData={formData.NameOfResponsibleParty_1}/>
        <DefaultInput placeholder={"Relationship"} onChange={(value => handleInputChange("Relationship_1",value))} valueData={formData.Relationship_1}/>
        <DefaultInput placeholder={"Address"} onChange={(value => handleInputChange("Address_1",value))} valueData={formData.Address_1}/>
        <DefaultInput placeholder={"Phone"} onChange={(value => handleInputChange("Phone_1",value))} valueData={formData.Phone_1}/>
        <br></br>
        <div style={{borderBottom:"4px solid #d9d9d9"}}></div>
        <DefaultInput placeholder={"Name of responsible party #2"} onChange={(value => handleInputChange("NameOfResponsibleParty_2",value))} valueData={formData.NameOfResponsibleParty_2}/>
        <DefaultInput placeholder={"Relationship"} onChange={(value => handleInputChange("Relationship_2",value))} valueData={formData.Relationship_2}/>
        <DefaultInput placeholder={"Address"} onChange={(value => handleInputChange("Address_2",value))} valueData={formData.Address_2}/>
        <DefaultInput placeholder={"Phone"} onChange={(value => handleInputChange("Phone_2",value))} valueData={formData.Phone_2}/>
      </div>
      <Row>
        <div className="d-flex justify-content-end">
          <Button onClick={saveData}>SAVE RESPONSIBLE PARTY</Button>
        </div>
      </Row>
    </>
  );
};

export default ResponsibleParty;
