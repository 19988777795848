import React, { useState, useEffect } from "react";
import { Row, Table, Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../services/api";
import TableData from "../../../components/Elements/TableData";
import DefaulInput from "../../../components/Elements/DefaultInput";
import DefaultSelect from "../../../components/Elements/DefaultSelect";
import DefaultSwitch from "../../../components/Elements/DefaultSwitch";
const Users = () => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const defaultUser = {
    id_usuario: 0,
    nombre: "",
    apellido: "",
    contraseña: "",
    usuario: "",
    telefono: "",
    activo: 1,
    rol_name: 0,
  };
  const [item, setItem] = useState(defaultUser);
  const [itemToEdit, setItemEdit] = useState(defaultUser);
  const [savingItem, setSavingItem] = useState(false);
  const [editingItem, setEditingItem] = useState(false);
  const [permisos, setPermisos] = useState([]);

  const title = [
    "User ID",
    "Name",
    "Last name",
    "Username",
    "Phone",
    "DateCreate",
    "Active",
    "Permissions",
  ];

  useEffect(() => {
    GetUsers();
    GetRoles();
  }, []);

  const GetUsers = async () => {
    try {
      const response = await api.get("settings/users/UsersController.php", {
        params: { action: "GET" },
      });
      setData(response.data);
    } catch (error) {
      toast.error("Error al buscar usuarios", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };
  const getOneUser = async (itemToSearch) => {
    try {
      const response = await api.get(
        `settings/users/GetOneUser.php?id=${itemToSearch.id_usuario}`,
        {
          params: { action: "GET" },
        }
      );
      const oneUserInfo = response.data[0];
      if (response.data[0] != null) {
        setItemEdit((itemToEdit) => ({
          ...itemToEdit,
          id_usuario: oneUserInfo.id_usuario,
          nombre: oneUserInfo.nombre,
          apellido: oneUserInfo.apellido,
          usuario: oneUserInfo.usuario,
          contraseña: oneUserInfo.contraseña,
          telefono: oneUserInfo.telefono,
          activo: oneUserInfo.activo,
          permisos: oneUserInfo.permisos,
          rol_name: oneUserInfo.rol_name,
        }));
      }
    } catch (error) {
      toast.error("Error al buscar usuarios", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };
  const GetRoles = async () => {
    try {
      const response = await api.get("settings/roles/RolesController.php", {
        params: { action: "GET" },
      });
      const formattedData = response.data.map((item) => ({
        label: item.nombre,
        value: item.id_rol,
        id: "archivecountry" + item.id_rol,
      }));
      setPermisos(formattedData);
    } catch (error) {
      console.error("Error when searching for items", error);
    }
  };
  const AddUser = async () => {
    try {
      const newUserData = {
        nombre: item.nombre,
        apellido: item.apellido,
        usuario: item.usuario,
        telefono: item.telefono,
        contraseña: item.contraseña,
        activo: item.activo,
        rol_name: item.rol_name,
      };

      const response = await api.post(
        "settings/users/UsersController.php",
        newUserData,
        { params: { action: "POST" } }
      );
      console.log(response);
      GetUsers();
      toast.success("User added successfully", {
        className: "toast-success",
        theme: "colored",
      });
      handleCloseModal();
    } catch (error) {
      toast.error("Error al agregar usuario", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };
  const EditUser = async () => {
    try {
      const updatedUserData = {
        id_usuario: itemToEdit.id_usuario,
        nombre: itemToEdit.nombre,
        apellido: itemToEdit.apellido,
        usuario: itemToEdit.usuario,
        telefono: itemToEdit.telefono,
        contraseña: itemToEdit.contraseña,
        activo: itemToEdit.activo,
        rol_name: itemToEdit.rol_name,
      };

      const response = await api.put(
        "settings/users/UsersController.php",
        updatedUserData,
        { params: { action: "PUT" } }
      );
      console.log(response)
      GetUsers();
      toast.success("User updated successfully", {
        className: "toast-success",
        theme: "colored",
      });
      handleCloseModal();
    } catch (error) {
      toast.error("Error al actualizar usuario", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };
  /*
  //const [deletingItemId, setDeletingItemId] = useState(null);
  //const [deletingITem, setDeletingItem] = useState(null);
  const DeleteUser = async () => {
    try {
      const updatedUserData = {
        id_usuario: item.id_usuario,
      };
      console.log(updatedUserData);
      const response = await api.delete(
        "settings/users/UsersController.php?id=${ID}",
        updatedUserData,
        { params: { action: "DELETE" } }
      );
      console.log(response)
      GetUsers();
      toast.success("User updated successfully", {
        className: "toast-success",
        theme: "colored",
      });
      handleCloseModal();
    } catch (error) {
      toast.error("Error al actualizar usuario", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };
  const deleteItem = (item) => {
    setItem(item);
    console.log(item);
    setDeletingItem(true);
    setShowModal(true);
  };*/
  
  const add = () => {
    setItem(defaultUser);
    setSavingItem(true);
    setShowModal(true);
  };

  const editItem = (item) => {
    setItem(item);
    getOneUser(item);
    setEditingItem(true);
    setShowModal(true);
  };

  const saveItem = () => {
    if (savingItem) {
      AddUser();
    } else if (editingItem) {
      EditUser();
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setEditingItem(false);
    setSavingItem(false);
    setItem(defaultUser);
  };

  const handleInputChange = (name, value) => {
    const updatedFormData = {
      ...itemToEdit,
      [name]: value,
    };
    setItemEdit(updatedFormData);
  };

  return (
    <>
      <div className="main-card">
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button onClick={() => add()}>+ Add</Button>
        </div>
        <TableData
          data={data}
          currentPage={1}
          perPage={10}
          onEdit={editItem}
          //onDelete={deleteItem}
          title={title}
        />
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        {savingItem && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Add user information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <DefaulInput
                placeholder={"Name"}
                onChange={(value) => handleInputChange("nombre", value)}
              />
              <DefaulInput
                placeholder={"Last name"}
                onChange={(value) => handleInputChange("apellido", value)}
              />
              <DefaulInput
                placeholder={"Username"}
                onChange={(value) => handleInputChange("usuario", value)}
              />
              <DefaulInput
                placeholder={"Password"}
                type={"password"}
                onChange={(value) => handleInputChange("contraseña", value)}
              />
              <DefaulInput
                placeholder={"Phone"}
                onChange={(value) => handleInputChange("telefono", value)}
              />
              <DefaultSwitch
                label={"Active"}
                onChange={(value) => handleInputChange("activo", value)}
              />
              <DefaultSelect
                options={permisos}
                onChange={(value) => handleInputChange("rol_name", value)}
                label={"Permissions"}
                defaultValue={"Select permissions"}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button variant="success" onClick={saveItem}>
                Save
              </Button>
            </Modal.Footer>
          </>
        )}
        {editingItem && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Edit user information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <DefaulInput
                placeholder={"User id"}
                disabled={true}
                onChange={(value) => handleInputChange("id_usuario", value)}
                valueData={itemToEdit.id_usuario}
              />
              <DefaulInput
                placeholder={"Name"}
                onChange={(value) => handleInputChange("nombre", value)}
                valueData={itemToEdit.nombre}
              />
              <DefaulInput
                placeholder={"Last name"}
                onChange={(value) => handleInputChange("apellido", value)}
                valueData={itemToEdit.apellido}
              />
              <DefaulInput
                placeholder={"Username"}
                onChange={(value) => handleInputChange("usuario", value)}
                valueData={itemToEdit.usuario}
              />
              <DefaulInput
                placeholder={"Password"}
                type={"password"}
                onChange={(value) => handleInputChange("contraseña", value)}
                valueData={itemToEdit.contraseña}
              />
              <DefaulInput
                placeholder={"Phone"}
                onChange={(value) => handleInputChange("telefono", value)}
                valueData={itemToEdit.telefono}
              />
              <DefaultSwitch
                label={"Active"}
                onChange={(value) => handleInputChange("activo", value)}
                valueData={itemToEdit.activo}
              />
              <DefaultSelect
                options={permisos}
                onChange={(value) => handleInputChange("rol_name", value)}
                label={"Permissions"}
                valueData={itemToEdit.rol_name}
                defaultValue={"Select permissions"}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button variant="success" onClick={saveItem}>
                Save changes
              </Button>
            </Modal.Footer>
          </>
        )}
        {/*deletingITem && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Delete user information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to delete this item?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={() => DeleteUser()}>
                Delete
              </Button>
            </Modal.Footer>
          </>
        )*/}
      </Modal>
    </>
  );
};

export default Users;
