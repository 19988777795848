import React from "react";
import { Form } from "react-bootstrap";

const DefaultSwitch = ({ numberRow, options, label, onChange, valueData }) => {
  const handleChange = (event) => {
    const isChecked = event.target.checked;
    onChange(isChecked);
    console.log(isChecked);
  };

  const generateUniqueId = () => {
    return `${Math.random().toString(36).substr(2, 9)}`;
  };

  const uniqueId = generateUniqueId();

  return (
    <Form.Group>
       <Form.Label >{label}</Form.Label>
      <div className="checkbox-wrapper-22">
        <label className="switch" htmlFor={uniqueId}>
          <input
            type="checkbox"
            id={uniqueId}
            checked={valueData}
            onChange={handleChange}
          />
          <div className="slider round"></div>
        </label>
      </div>
    </Form.Group>
  );
};

export default DefaultSwitch;
