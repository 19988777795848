import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import api from "../../services/api";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  Cookies.remove("userData");

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!username || !password) {
      toast.error("Fill out all fields.", {
        className: "toast-error",
        theme: "colored",
      });
      return;
    }

    try {
      const response = await api.post(
        "login/Login.php",
        { usuario: username, contraseña: password }
      );
      console.log(response);
      if (response.data.id_usuario) {
        Cookies.set("userData", JSON.stringify(response.data));
        navigate("/dashboard");
        toast.success("Successful login", {
          className: "toast-success",
          theme: "colored",
        });
      } else {
        toast.error("Invalid credentials.", {
          className: "toast-error",
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      toast.error("Server error.", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };

  return (
    <div className="d-lg-flex login">
      <div className="background-login"></div>
      <div className="login-content">
        <form onSubmit={handleLogin} className="Auth-form-content">
          <div className="d-flex justify-content-center mb-5">
            <img src="/img/logo/logo.png" style={{width:"60%"}}></img>
          </div>
            <input
              type="text"
              className="form-control mt-1 mb-2"
              placeholder="User"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          <div className="d-grid gap-2 mt-3 mb-5">
            <button type="submit" className="btn btn-primary">
              Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
