import { React, useState, useEffect } from "react";
import { Document, Page, Image, Text } from "@react-pdf/renderer";
import api from "../../../services/api";
const MasterForm1 = ({ data, dataResidence, spouseData, fatherData, motherData, informantData, funeralData, occupationData, certifierData, folio, usuarioID }) => {
  const folioID = folio;
  const [signatureInfo, setSignatureData] = useState(dataSignatureInfo);

  var dataSignatureInfo = {
    usuario_id: usuarioID,
    folio: folio,
    num_form: "",
    documento: "",
    nameSign: ""
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  };

  useEffect(() => {
    const fetchSignatures = async () => {
      try {
        const response = await api.get(
          `sign/SignController.php?folio=${folioID}&num_form=1`
        );
        const data = response.data;
        setSignatureData(data);
      } catch (error) {
        console.error("Error fetching signatures info:", error);
        setSignatureData(prevState => ({ ...prevState, signatures: [] }));
      }
    };

    fetchSignatures();
  }, [folioID]);

  const getSignatureInfo = (name) => {
    if (signatureInfo && Array.isArray(signatureInfo)) {
      for (let i = 0; i < signatureInfo.length; i++) {
        const signature = signatureInfo[i];
        if (signature.nameSign === name) {
          const formattedDate = formatDate(signature.timeSign);
          return {
            date: formattedDate,
            image: signature.documento
          };
        }
      }
    }
    return null;
  };

  const renderSignature = (name) => {
    const signature = getSignatureInfo(name);
    if (signature) {
      return (
        <div style={styles.divSignature}>
          <Image src={`data:image/png;base64,${signature.image}`} style={styles.signature} />
        </div>
      );
    }
    return null;
  };

  const directorSignatureInfo = getSignatureInfo("Director");
  const informantSignatureInfo = getSignatureInfo("Informant");


  const renderSecurityNumber = (usSecurityNum) => {
    switch (usSecurityNum) {
      case 1:
        return <Text style={styles.usNumberNone}>x</Text>;
      case 2:
        return <Text style={styles.usNumberUnknown}>x</Text>;
      default:
        return <Text style={styles.usNumberHave}>{data["usSecurityNumValue"]}</Text>;
    }
  };
  const residedTribalCommunity = (residedTribalCommunity) => {
    switch (residedTribalCommunity) {
      case "2":
        return <Text style={styles.residedTribalCommunityNo}>x</Text>;
      case "3":
        return <Text style={styles.residedTribalCommunityUnknown}>x</Text>;
      default:
        return (
          <div>
            <Text style={styles.residedTribalCommunityYes}>x</Text>
            <Text style={styles.residedTribalCommunityValue}>{dataResidence["residedTribalCommunity"]}</Text>
          </div>)
    }
  };
  const methodDispositionOptions = (methodDispositionOptions) => {
    switch (methodDispositionOptions) {
      case 1:
        return <Text style={styles.methodDispositionOptions1}>x</Text>;
      case 2:
        return <Text style={styles.methodDispositionOptions2}>x</Text>;
      case 3:
        return <Text style={styles.methodDispositionOptions3}>x</Text>
      case 4:
        return <Text style={styles.methodDispositionOptions4}>x</Text>
      case 5:
        return <Text style={styles.methodDispositionOptions5}>x</Text>
      case 6:
        return <Text style={styles.methodDispositionOptions6}>x</Text>
      case 7:
        return <Text style={styles.methodDispositionOptions7}>x</Text>
      case 8:
        return <Text style={styles.methodDispositionOptions8}>x</Text>
      case 9:
        return <Text style={styles.methodDispositionOptions9}>x</Text>
      case 10:
        return <Text style={styles.methodDispositionOptions10}>x</Text>
      case 11:
        return <Text style={styles.methodDispositionOptions11}>x</Text>
      case 12:
        return <Text style={styles.methodDispositionOptions12}>x</Text>
      case 13:
        return <Text style={styles.methodDispositionOptions13}>x</Text>
      case 14:
        return <Text style={styles.methodDispositionOptions14}>x</Text>
      case 15:
        return <Text style={styles.methodDispositionOptions15}>x</Text>
      case 16:
        return (<>
          <Text style={styles.methodDispositionOptions16}>x</Text>

        </>)
      case 17:
        return (
          <>
            <Text style={styles.methodDispositionOptions17}>x</Text>
            <Text style={styles.methodDisposition}>{funeralData["methodDispositionValue"]}</Text>
          </>
        )
    }
  }

  const certifierTitle = (CertifierTitle) => {
    switch (CertifierTitle) {
      case "1":
        return <Text style={styles.certifierTitleOption1}>x</Text>;
      case "2":
        return <Text style={styles.certifierTitleOption2}>x</Text>;
      case "3":
        return <Text style={styles.certifierTitleOption3}>x</Text>;
      case "4":
        return <Text style={styles.certifierTitleOption4}>x</Text>;
      case "5":
        return <Text style={styles.certifierTitleOption5}>x</Text>;
      case "6":
        return <Text style={styles.certifierTitleOption6}>x</Text>;
      case "7":
        return <Text style={styles.certifierTitleOption7}>x</Text>;
      case "8":
        return <Text style={styles.certifierTitleOption8}>x</Text>;
      case "9":
        return <Text style={styles.certifierTitleOption9}>x</Text>;
      default:
        return (
          <div>
            <Text style={styles.certifierTitleOption10}>x</Text>
            <Text style={styles.certifierTitleWord10}>{certifierData["certifierTitleValue"]}</Text>
          </div>)
    }
  };
  return (
    <Document style={styles.document}>
      <Page size="Letter" orientation="landscape" >
        <div style={styles.firstName}>
          <Text>{data["firstName"]}</Text>
        </div>
        <div style={styles.middleName}>
          <Text>{data["middleName"]}</Text>
        </div>
        <div style={styles.lastName}>
          <Text>{data["lastName"]}</Text>
        </div>
        <div style={styles.suffix}>
          <Text>{data["suffix"]}</Text>
        </div>
        <div style={styles.aka}>
          <Text>{data["aka"]}</Text>
        </div>
        {data["sex"] === 1 && <Text style={styles.sexFamale}>x</Text>}
        {data["sex"] === 2 && <Text style={styles.sexMale}>x</Text>}
        {data["sex"] === 3 && <Text style={styles.sexUnknown}>x</Text>}


        {renderSecurityNumber(data["usSecurityNumOptions"])}
        {data["usSecurityNumOptions"] === 1 && <Text style={styles.usNumberNone}>x</Text>}
        {data["usSecurityNumOptions"] === 2 && <Text style={styles.usNumberUnknown}>x</Text>}
       <Text style={styles.usNumberHave}>{data["usSecurityNumValue"]}</Text>
     
        <div style={styles.dateDeath}>
          <Text>{data["dateOfDeath"]}</Text>
        </div>
        <div style={styles.dateBirth}>
          <Text>{data["dateOfBirth"]}</Text>
        </div>


        <Text style={styles.ageYears}>{data["years"]}</Text>
        <Text style={styles.ageMonths}>{data["months"]}</Text>
        <Text style={styles.ageDays}>{data["days"]}</Text>
        <Text style={styles.ageHours}>{data["hours"]}</Text>
        <Text style={styles.ageMinutes}>{data["minutes"]}</Text>

        <div style={styles.birthCity}>
          <Text>{data["birthCityOrTown"]}</Text>
        </div>
        <div style={styles.birthCounty}>
          <Text>{data["birthCounty"]}</Text>
        </div>
        <div style={styles.birthState}>
          <Text>{data["birthState"]}</Text>
        </div>
        <div style={styles.birthCountry}>
          <Text>{data["birthCountry"]}</Text>
        </div>

        {data["armedForces"] === 1 && <Text style={styles.armyYes}>x</Text>}
        {data["armedForces"] === 2 && <Text style={styles.armyNo}>x</Text>}
        {data["armedForces"] === 3 && (
          <Text style={styles.armyUnknown}>x</Text>
        )}

        <div style={styles.firstMirage}>
          <Text>{data["namePriorToMarriage"]}</Text>
        </div>
        {data["hrrf"] === 1 && <Text style={styles.hrrfYes}>x</Text>}
        {data["hrrf"] === 2 && <Text style={styles.hrrfNo}>x</Text>}


        <div style={styles.streetAddressResidence}>
          <Text>{dataResidence["streetAdress"]}</Text>
        </div>
        <div style={styles.streetZipCode}>
          <Text>{dataResidence["zipCode"]}</Text>
        </div>
        <div style={styles.streetResidenceCity}>
          <Text>{dataResidence["residenceCity"]}</Text>
        </div>
        <div style={styles.streetResidenceConty}>
          <Text>{dataResidence["residenceConty"]}</Text>
        </div>
        <div style={styles.streetResidenceState}>
          <Text>{dataResidence["residenceState"]}</Text>
        </div>
        <div style={styles.streetResidenceCountry}>
          <Text>{dataResidence["residenceCountry"]}</Text>
        </div>
        {dataResidence["inCityLimits"] === 1 && <Text style={styles.inCityLimitsYes}>x</Text>}
        {dataResidence["inCityLimits"] === 2 && <Text style={styles.inCityLimitsNo}>x</Text>}
        {dataResidence["inCityLimits"] === 3 && <Text style={styles.inCityLimitsUnknown}>x</Text>}
        <div style={styles.howLongInArizonaValue}>
          <Text>{dataResidence["howLongArizonaValue"]}</Text>
        </div>
        {dataResidence["howLongInArizonaOption"] === 1 && <Text style={styles.howLongInArizonaOptionDays}>x</Text>}
        {dataResidence["howLongInArizonaOption"] === 2 && <Text style={styles.howLongInArizonaOptionHours}>x</Text>}
        {dataResidence["howLongInArizonaOption"] === 3 && <Text style={styles.howLongInArizonaOptionMinutes}>x</Text>}
        {dataResidence["howLongInArizonaOption"] === 4 && <Text style={styles.howLongInArizonaOptionYears}>x</Text>}
        {dataResidence["howLongInArizonaOption"] === 5 && <Text style={styles.howLongInArizonaOptionMonths}>x</Text>}
        {dataResidence["howLongInArizonaOption"] === 6 && <Text style={styles.howLongInArizonaOptionWeeks}>x</Text>}
        {dataResidence["howLongInArizonaOption"] === 7 && <Text style={styles.howLongInArizonaOptionInTransit}>x</Text>}
        {dataResidence["howLongInArizonaOption"] === 8 && <Text style={styles.howLongInArizonaOptionUnknown}>x</Text>}

        {residedTribalCommunity(dataResidence["residedTribalCommunity"])}

        {spouseData["maritalStatus"] === 1 && <Text style={styles.maritalStatus1}>x</Text>}
        {spouseData["maritalStatus"] === 2 && <Text style={styles.maritalStatus2}>x</Text>}
        {spouseData["maritalStatus"] === 3 && <Text style={styles.maritalStatus3}>x</Text>}
        {spouseData["maritalStatus"] === 4 && <Text style={styles.maritalStatus4}>x</Text>}
        {spouseData["maritalStatus"] === 5 && <Text style={styles.maritalStatus5}>x</Text>}
        {spouseData["maritalStatus"] === 6 && <Text style={styles.maritalStatus6}>x</Text>}
        {spouseData["maritalStatus"] === 7 && <Text style={styles.maritalStatus7}>x</Text>}
        {spouseData["maritalStatus"] === 8 && <Text style={styles.maritalStatus8}>x</Text>}

        <div style={styles.maritalName}>
          <Text>{spouseData["firstNameSpouse"]}</Text>
        </div>
        <div style={styles.maritalMiddle}>
          <Text>{spouseData["middleNameSpouse"]}</Text>
        </div>
        <div style={styles.maritalLastname}>
          <Text>{spouseData["lastNameSpouse"]}</Text>
        </div>
        <div style={styles.maritalSuffix}>
          <Text>{spouseData["suffixSpouse"]}</Text>
        </div>
        <div style={styles.maritalSurviving}>
          <Text>{spouseData["lastNameSurvivingSpouse"]}</Text>
        </div>

        <div style={styles.fatherName}>
          <Text>{fatherData["firstNameFather"]}</Text>
        </div>
        <div style={styles.fatherMiddle}>
          <Text>{fatherData["middleNameFather"]}</Text>
        </div>
        <div style={styles.fatherLastname}>
          <Text>{fatherData["lastNameFather"]}</Text>
        </div>
        <div style={styles.fatherSuffix}>
          <Text>{fatherData["suffixFather"]}</Text>
        </div>

        <div style={styles.motherName}>
          <Text>{motherData["firstNameMother"]}</Text>
        </div>
        <div style={styles.motherMiddle}>
          <Text>{motherData["middleNameMother"]}</Text>
        </div>
        <div style={styles.motherLastname}>
          <Text>{motherData["lastNameMother"]}</Text>
        </div>
        <div style={styles.motherSuffix}>
          <Text>{motherData["suffixMother"]}</Text>
        </div>

        <div style={styles.informantName}>
          <Text>{informantData["firstNameInformant"]}</Text>
        </div>
        <div style={styles.informantMiddle}>
          <Text>{informantData["middleNameInformant"]}</Text>
        </div>
        <div style={styles.informantLastname}>
          <Text>{informantData["lastNameInformant"]}</Text>
        </div>
        <div style={styles.informantSuffix}>
          <Text>{informantData["suffixInformant"]}</Text>
        </div>

        <Image
          src="/forms/form1/form1_page_01.jpg"
          alt="Page 1"
          style={styles.image}
          wrap={false}
          cache={true}
        />
      </Page>
      <Page size="Letter" orientation="landscape" >
        <div style={styles.informantRelationship}>
          <Text>{informantData["relationshipInfromant"]}</Text>
        </div>
        <div style={styles.informantEmail}>
          <Text>{informantData["emailInformant"]}</Text>
        </div>
        <div style={styles.informantPhone}>
          <Text>{informantData["phoneInformant"]}</Text>
        </div>
        <div style={styles.informantMailing}>
          <Text>{informantData["mailingAddress"]}</Text>
        </div>
        <div style={styles.sign1}>
          {renderSignature("Informant")}
          <div style={styles.divDate1}>
            <Text>{informantSignatureInfo?.date}</Text>
          </div>
        </div>
        <div style={styles.otherResponsableRelationship}>
          <Text>{funeralData["otherResponsableRelationship"]}</Text>
        </div>
        <div style={styles.placeDispositionFirstFacility}>
          <Text>{funeralData["placeDispositionFirstFacility"]}</Text>
        </div>
        <div style={styles.placeDispositionSecondFacility}>
          <Text>{funeralData["placeDispositionSecondFacility"]}</Text>
        </div>

        <div style={styles.dateDisposition}>
          <Text>{funeralData["dateDisposition"]}</Text>
        </div>
        <div style={styles.nameFuneralDirector}>
          <Text>{funeralData["nameFuneralDirector"]}</Text>
        </div>
        <div style={styles.licenceNumber}>
          <Text>{funeralData["licenceNumber"]}</Text>
        </div>
        <div style={styles.nameFuneralHome}>
          <Text>{funeralData["nameFuneralHome"]}</Text>
        </div>
        <div style={styles.addressFuneralHome}>
          <Text>{funeralData["addressFuneralHome"]}</Text>
        </div>
        {methodDispositionOptions(funeralData["methodDispositionOptions"])}

        <div style={styles.decendentOccupation}>
          <Text>{occupationData["decendentOccupation"]}</Text>
        </div>
        <div style={styles.decedemtIndustry}>
          <Text>{occupationData["decedemtIndustry"]}</Text>
        </div>

        <div style={styles.placeOfDeathFacility}>
          <Text>{occupationData["placeOfDeathFacility"]}</Text>
        </div>

        {occupationData["education"] === 1 && <Text style={styles.education1}>x</Text>}
        {occupationData["education"] === 2 && <Text style={styles.education2}>x</Text>}
        {occupationData["education"] === 3 && <Text style={styles.education3}>x</Text>}
        {occupationData["education"] === 4 && <Text style={styles.education4}>x</Text>}
        {occupationData["education"] === 5 && <Text style={styles.education5}>x</Text>}
        {occupationData["education"] === 6 && <Text style={styles.education6}>x</Text>}
        {occupationData["education"] === 7 && <Text style={styles.education7}>x</Text>}
        {occupationData["education"] === 8 && <Text style={styles.education8}>x</Text>}
        {occupationData["education"] === 9 && <Text style={styles.education9}>x</Text>}
        {occupationData["education"] === 10 && <Text style={styles.education10}>x</Text>}
        {occupationData["education"] === 11 && <Text style={styles.education11}>x</Text>}
        {occupationData["education"] === 12 && <Text style={styles.education12}>x</Text>}

        {occupationData.decedentOriginOption.map((option, index) => {
          if (option.value == 1) {
            return (
              <Text key={index} style={styles.Hispanic1}>x</Text>
            )
          }
          if (option.value == 2) {
            return (
              <Text key={index} style={styles.Hispanic2}>x</Text>
            )
          }
          if (option.value == 3) {
            return (
              <>
                <Text key={index} style={styles.Hispanic3}>x</Text>
                <Text style={styles.Hispanic3Word}>{option.inputValue}</Text>
              </>
            )
          }
          if (option.value == 4) {
            return (
              <Text key={index} style={styles.Hispanic4}>x</Text>
            )
          }
          if (option.value == 5) {
            return (
              <>
                <Text key={index} style={styles.Hispanic5}>x</Text>
                <Text style={styles.Hispanic5Word}>{option.inputValue}</Text>
              </>
            )
          }
          if (option.value == 6) {
            return (
              <Text key={index} style={styles.Hispanic6}>x</Text>
            )
          }
          if (option.value == 7) {
            return (
              <Text key={index} style={styles.Hispanic7}>x</Text>
            )
          }
          if (option.value == 8) {
            return (
              <Text key={index} style={styles.Hispanic8}>x</Text>
            )
          }
          if (option.value == 9) {
            return (
              <>
                <Text key={index} style={styles.Hispanic9}>x</Text>
                <Text style={styles.Hispanic9Word}>{option.inputValue}</Text>
              </>
            )
          }
        })}

        {occupationData.decedentRaceOption.map((option, index) => {
          if (option.value == 1) {
            return (
              <Text key={index} style={styles.Race1}>x</Text>
            )
          }
          if (option.value == 2) {
            return (
              <Text key={index} style={styles.Race2}>x</Text>
            )
          }
          if (option.value == 3) {
            return (
              <>
                <Text key={index} style={styles.Race3}>x</Text>
                <div style={styles.Race3Word}>
                  <Text key={index}>{option.inputValue}</Text>
                </div>
              </>
            )
          }
          if (option.value == 4) {
            return (
              <Text key={index} style={styles.Race4}>x</Text>
            )
          }
          if (option.value == 5) {
            return (
              <>
                <Text key={index} style={styles.Race5}>x</Text>
              </>
            )
          }
          if (option.value == 6) {
            return (
              <Text key={index} style={styles.Race6}>x</Text>
            )
          }
          if (option.value == 7) {
            return (
              <Text key={index} style={styles.Race7}>x</Text>
            )
          }
          if (option.value == 8) {
            return (
              <Text key={index} style={styles.Race8}>x</Text>
            )
          }
          if (option.value == 9) {
            return (
              <>
                <Text key={index} style={styles.Race9}>x</Text>
              </>
            )
          }
          if (option.value == 10) {
            return (
              <>
                <Text key={index} style={styles.Race10}>x</Text>
                <div style={styles.Race10Word}>
                  <Text key={index}>{option.inputValue}</Text>
                </div>
              </>
            )
          }
          if (option.value == 11) {
            return (
              <>
                <Text key={index} style={styles.Race11}>x</Text>
              </>
            )
          }
          if (option.value == 12) {
            return (
              <>
                <Text key={index} style={styles.Race12}>x</Text>
              </>
            )
          }
          if (option.value == 13) {
            return (
              <>
                <Text key={index} style={styles.Race13}>x</Text>
              </>
            )
          }
          if (option.value == 14) {
            return (
              <>
                <Text key={index} style={styles.Race14}>x</Text>
                <div style={styles.Race14Word}>
                  <Text key={index}>{option.inputValue}</Text>
                </div>
              </>
            )
          }
          if (option.value == 15) {
            return (
              <>
                <Text key={index} style={styles.Race15}>x</Text>
                <div style={styles.Race15Word}>
                  <Text key={index}>{option.inputValue}</Text>
                </div>
              </>
            )
          }
          if (option.value == 16) {
            return (
              <>
                <Text key={index} style={styles.Race16}>x</Text>
              </>
            )
          }
          if (option.value == 17) {
            return (
              <>
                <Text key={index} style={styles.Race17}>x</Text>
              </>
            )
          }
          if (option.value == 18) {
            return (
              <>
                <Text key={index} style={styles.Race18}>x</Text>
              </>
            )
          }
        })}

        {occupationData["placeOfDeathOption"] == 1 && (<Text style={styles.placeOfDeath1}>x</Text>)}
        {occupationData["placeOfDeathOption"] == 2 && (<Text style={styles.placeOfDeath2}>x</Text>)}
        {occupationData["placeOfDeathOption"] == 3 && (<Text style={styles.placeOfDeath3}>x</Text>)}
        {occupationData["placeOfDeathOption"] == 4 && (<Text style={styles.placeOfDeath4}>x</Text>)}
        {occupationData["placeOfDeathOption"] == 5 && (<Text style={styles.placeOfDeath5}>x</Text>)}
        {occupationData["placeOfDeathOption"] == 6 && (<Text style={styles.placeOfDeath6}>x</Text>)}
        {occupationData["placeOfDeathOption"] == 7 && (<Text style={styles.placeOfDeath7}>x</Text>)}
        {occupationData["placeOfDeathOption"] == 8 && (<Text style={styles.placeOfDeath8}>x</Text>)}
        {occupationData["placeOfDeathOption"] == 9 && (
          <>
            <div>
              <Text style={styles.placeOfDeath9}>x</Text>
              <Text style={styles.placeOfDeathWord}>{occupationData["placeOfDeathValue"]}</Text>
            </div>
          </>)}

        <Image
          src="/forms/form1/form1_page_02.jpg"
          alt="Page 2"
          style={styles.image}
          wrap={false}
          cache={true}
        />

      </Page>
      <Page size="Letter" orientation="landscape" >
        <div style={styles.placeOfDeathFacilityAddress}>
          <Text>{occupationData["placeOfDeathFacilityAddress"]}</Text>
        </div>
        <div style={styles.otherInstitutionAddress}>
          <Text>{occupationData["otherInstitutionAddress"]}</Text>
        </div>
        <div style={styles.certifierLicenseNumber}>
          <Text>{certifierData["certifierLicenseNumber"]}</Text>
        </div>
        <div style={styles.certifierName}>
          <Text>{certifierData["certifierName"]}</Text>
        </div>


        {certifierData["certifierType"] === 1 && <Text style={styles.certifierType1}>x</Text>}
        {certifierData["certifierType"] === 2 && <Text style={styles.certifierType2}>x</Text>}
        {certifierData["certifierType"] === 3 && <Text style={styles.certifierType3}>x</Text>}
        {certifierData["certifierType"] === 4 && <Text style={styles.certifierType4}>x</Text>}
        {certifierData["certifierType"] === 5 && <Text style={styles.certifierType5}>x</Text>}
        {certifierData["certifierType"] === 6 && <Text style={styles.certifierType6}>x</Text>}

        {certifierTitle(certifierData["certifierTitleOption"])}

        <div style={styles.certifierAddress}>
          <Text>{certifierData["certifierAddress"]}</Text>
        </div>
        <div style={styles.certifierZipCode}>
          <Text>{certifierData["certifierZipCode"]}</Text>
        </div>
        <div style={styles.certifierCity}>
          <Text>{certifierData["certifierCity"]}</Text>
        </div>
        <div style={styles.certifierState}>
          <Text>{certifierData["certifierState"]}</Text>
        </div>
        <div style={styles.certifierCountry}>
          <Text>{certifierData["certifierCountry"]}</Text>
        </div>
        <div style={styles.certifierNameAlternate}>
          <Text>{certifierData["certifierNameAlternate"]}</Text>
        </div>
        <div style={styles.certifierTelephone}>
          <Text>{certifierData["certifierTelephone"]}</Text>
        </div>
        <div style={styles.certifierFax}>
          <Text>{certifierData["certifierFax"]}</Text>
        </div>
        <div style={styles.certifierEmail}>
          <Text>{certifierData["certifierEmail"]}</Text>
        </div>
        {renderSignature("Director")}
        <div style={styles.divDate}>
          <Text>{directorSignatureInfo?.date}</Text>
        </div>
        <Image
          src="/forms/form1/form1_page_03.jpg"
          alt="Page 3"
          style={styles.image}
          wrap={false}
          cache={true}
        />
      </Page>
    </Document>
  );
};

const styles = {
  certifierTitleWord10: {
    width: 450,

    position: "absolute",
    textAlign: "center",
    left: 290,
    top: 172,
  },
  certifierTitleOption10: {
    position: "absolute",
    left: 215,
    top: 172,
  },
  certifierTitleOption9: {
    position: "absolute",
    left: 35,
    top: 172,
  },
  certifierTitleOption8: {
    position: "absolute",
    left: 576,
    top: 161,
  },
  certifierTitleOption7: {
    position: "absolute",
    left: 396,
    top: 161,
  },
  certifierTitleOption6: {
    position: "absolute",
    left: 215,
    top: 161,
  },
  certifierTitleOption5: {
    position: "absolute",
    left: 35,
    top: 161,
  },
  certifierTitleOption4: {
    position: "absolute",
    left: 576,
    top: 149,
  },
  certifierTitleOption3: {
    position: "absolute",
    left: 396,
    top: 149,
  },
  certifierTitleOption2: {
    position: "absolute",
    left: 215,
    top: 149,
  },
  certifierTitleOption1: {
    position: "absolute",
    left: 35,
    top: 149,
  },

  certifierType6: {
    position: "absolute",
    left: 458,
    top: 94,
  },
  certifierType5: {
    position: "absolute",
    left: 380,
    top: 94,
  },
  certifierType4: {
    position: "absolute",
    left: 276,
    top: 94,
  },
  certifierType3: {
    position: "absolute",
    left: 185,
    top: 94,
  },
  certifierType2: {
    position: "absolute",
    left: 91,
    top: 94,
  },
  certifierType1: {
    position: "absolute",
    left: 35,
    top: 94,
  },
  certifierEmail: {
    width: 300,

    position: "absolute",
    textAlign: "center",
    left: 40,
    top: 300,
  },
  certifierFax: {
    width: 150,

    position: "absolute",
    textAlign: "center",
    left: 620,
    top: 255,
  },
  certifierTelephone: {
    width: 150,

    position: "absolute",
    textAlign: "center",
    left: 440,
    top: 255,
  },
  certifierNameAlternate: {
    width: 380,

    position: "absolute",
    textAlign: "center",
    left: 40,
    top: 255,
  },
  certifierCountry: {
    width: 170,

    position: "absolute",
    textAlign: "center",
    left: 600,
    top: 225,
  },
  certifierZipCode: {
    width: 150,

    position: "absolute",
    textAlign: "center",
    left: 620,
    top: 197,
  },
  certifierAddress: {
    width: 560,

    position: "absolute",
    textAlign: "center",
    left: 40,
    top: 197,
  },
  certifierState: {
    width: 130,

    position: "absolute",
    textAlign: "center",
    left: 450,
    top: 225,
  },
  certifierCity: {
    width: 390,

    position: "absolute",
    textAlign: "center",
    left: 40,
    top: 225,
  },
  certifierName: {
    width: 480,

    position: "absolute",
    textAlign: "center",
    left: 280,
    top: 118,
  },
  certifierLicenseNumber: {
    width: 220,

    position: "absolute",
    textAlign: "center",
    left: 40,
    top: 118,
  },

  placeOfDeathWord: {
    position: "absolute",
    left: 110,
    top: 537,
    width: 270,
    textAlign: "center",

  },
  placeOfDeath9: {
    position: "absolute",
    left: 35,
    top: 537,
  },
  placeOfDeath8: {
    position: "absolute",
    left: 83,
    top: 527,
  },
  placeOfDeath7: {
    position: "absolute",
    left: 35,
    top: 527,
  },
  placeOfDeath6: {
    position: "absolute",
    left: 158,
    top: 516,
  },
  placeOfDeath5: {
    position: "absolute",
    left: 83,
    top: 516,
  },
  placeOfDeath4: {
    position: "absolute",
    left: 35,
    top: 516,
  },
  placeOfDeath3: {
    position: "absolute",
    left: 167.5,
    top: 506,
  },
  placeOfDeath2: {
    position: "absolute",
    left: 110,
    top: 506,
  },
  placeOfDeath1: {
    position: "absolute",
    left: 35,
    top: 506,
  },









  Race18: {
    position: "absolute",
    left: 606,
    top: 451,
  },
  Race17: {
    position: "absolute",
    left: 606,
    top: 442,
  },

  Race16: {
    position: "absolute",
    left: 606,
    top: 433,
  },

  Race15: {
    position: "absolute",
    left: 606,
    top: 415,
  },
  Race15Word: {
    width: 110,
    position: "absolute",
    textAlign: "center",

    left: 606,
    top: 424,
  },
  Race14Word: {
    width: 100,
    position: "absolute",
    textAlign: "center",
    left: 363,
    top: 450,
  },
  Race14: {
    position: "absolute",
    left: 359,
    top: 442,
  },
  Race13: {
    position: "absolute",
    left: 359,
    top: 433,
  },
  Race12: {
    position: "absolute",
    left: 359,
    top: 424,
  },
  Race11: {
    position: "absolute",
    left: 359,
    top: 415,
  },
  Race10Word: {
    width: 100,
    position: "absolute",
    textAlign: "center",
    left: 235,
    top: 476,
  },
  Race10: {
    position: "absolute",
    left: 229,
    top: 460,
  },
  Race9: {
    position: "absolute",
    left: 229,
    top: 451,
  },
  Race8: {
    position: "absolute",
    left: 229,
    top: 442,
  },
  Race7: {
    position: "absolute",
    left: 229,
    top: 433,
  },
  Race6: {
    position: "absolute",
    left: 229,
    top: 424,
  },
  Race5: {
    position: "absolute",
    left: 229,
    top: 415,
  },
  Race4: {
    position: "absolute",
    left: 35,
    top: 469,
  },
  Race3Word: {
    width: 100,
    position: "absolute",
    textAlign: "center",
    left: 100,
    top: 450,
  },
  Race3: {
    position: "absolute",
    left: 35,
    top: 433,
  },
  Race2: {
    position: "absolute",
    left: 35,
    top: 424,
  },
  Race1: {
    position: "absolute",
    left: 35,
    top: 415,
  },
  Hispanic9Word: {
    width: 220,

    position: "absolute",
    textAlign: "center",
    left: 535,
    top: 386,
  },
  Hispanic9: {
    position: "absolute",
    left: 456,
    top: 390,
  },
  Hispanic8: {
    position: "absolute",
    left: 365,
    top: 390,
  },
  Hispanic7: {
    position: "absolute",
    left: 178.5,
    top: 390,
  },
  Hispanic6: {
    position: "absolute",
    left: 35,
    top: 390,
  },
  Hispanic5Word: {
    width: 200,

    position: "absolute",
    textAlign: "center",
    left: 540,
    top: 370,
  },
  Hispanic5: {
    position: "absolute",
    left: 532,
    top: 356.5,
  },
  Hispanic4: {
    position: "absolute",
    left: 456,
    top: 356.5,
  },
  Hispanic3: {
    position: "absolute",
    left: 365,
    top: 356.5,
  },
  Hispanic2: {
    position: "absolute",
    left: 178.5,
    top: 356.5,
  },
  Hispanic1: {
    position: "absolute",
    left: 35,
    top: 356.5,
  },
  education12: {
    position: "absolute",
    left: 536,
    top: 335,
  },
  education11: {
    position: "absolute",
    left: 427,
    top: 335,
  },
  education10: {
    position: "absolute",
    left: 343,
    top: 335,
  },
  education9: {
    position: "absolute",
    left: 256,
    top: 335,
  },
  education8: {
    position: "absolute",
    left: 256,
    top: 325,
  },
  education7: {
    position: "absolute",
    left: 256,
    top: 315,
  },
  education6: {
    position: "absolute",
    left: 605,
    top: 305,
  },

  education5: {
    position: "absolute",
    left: 436,
    top: 305,
  },
  education4: {
    position: "absolute",
    left: 256,
    top: 305,
  },
  education3: {
    position: "absolute",
    left: 605,
    top: 295,
  },
  education2: {
    position: "absolute",
    left: 436,
    top: 295,
  },
  education1: {
    position: "absolute",
    left: 256,
    top: 295,
  },
  otherInstitutionAddress: {
    width: 330,

    position: "absolute",
    textAlign: "center",
    left: 440,
    top: 50,
  },
  placeOfDeathFacilityAddress: {
    width: 380,

    position: "absolute",
    textAlign: "center",
    left: 40,
    top: 50,
  },
  placeOfDeathFacility: {
    width: 350,

    position: "absolute",
    textAlign: "center",
    left: 420,
    top: 525,
  },
  decedemtIndustry: {
    width: 200,

    position: "absolute",
    textAlign: "center",
    left: 40,
    top: 328,
  },
  decendentOccupation: {
    width: 200,

    position: "absolute",
    textAlign: "center",
    left: 40,
    top: 298,
  },
  methodDispositionOptions17: {
    position: "absolute",
    left: 88,
    top: 166,
  },
  methodDispositionOptions16: {
    position: "absolute",
    left: 35,
    top: 166,
  },
  methodDispositionOptions15: {
    position: "absolute",
    left: 544,
    top: 146,
  },
  methodDispositionOptions14: {
    position: "absolute",
    left: 443,
    top: 146,
  },
  methodDispositionOptions13: {
    position: "absolute",
    left: 360,
    top: 146,
  },
  methodDispositionOptions12: {
    position: "absolute",
    left: 292,
    top: 146,
  },
  methodDispositionOptions11: {
    position: "absolute",
    left: 239,
    top: 146,
  },
  methodDispositionOptions10: {
    position: "absolute",
    left: 180,
    top: 146,
  },
  methodDispositionOptions9: {
    position: "absolute",
    left: 137,
    top: 146,
  },
  methodDispositionOptions8: {
    position: "absolute",
    left: 76,
    top: 146,
  },
  methodDispositionOptions7: {
    position: "absolute",
    left: 559,
    top: 126,
  },
  methodDispositionOptions6: {
    position: "absolute",
    left: 442,
    top: 126,
  },
  methodDispositionOptions5: {
    position: "absolute",
    left: 341,
    top: 126,
  },
  methodDispositionOptions4: {
    position: "absolute",
    left: 250,
    top: 126,
  },
  methodDispositionOptions3: {
    position: "absolute",
    left: 174,
    top: 126,
  },
  methodDispositionOptions2: {
    position: "absolute",
    left: 98,
    top: 126,
  },
  methodDispositionOptions1: {
    position: "absolute",
    left: 35,
    top: 126,
  },
  otherResponsableRelationship: {
    width: 350,

    position: "absolute",
    textAlign: "center",
    left: 410,
    top: 268,
  },
  addressFuneralHome: {
    width: 340,

    position: "absolute",
    textAlign: "center",
    left: 40,
    top: 268,
  },
  nameFuneralHome: {
    width: 330,

    position: "absolute",
    textAlign: "center",
    left: 430,
    top: 235,
  },
  licenceNumber: {
    width: 100,

    position: "absolute",
    textAlign: "center",
    left: 315,
    top: 235,
  },
  nameFuneralDirector: {
    width: 250,

    position: "absolute",
    textAlign: "center",
    left: 40,
    top: 235,
  },
  placeDispositionSecondFacility: {
    width: 350,

    position: "absolute",
    textAlign: "center",
    left: 400,
    top: 200,
  },
  placeDispositionFirstFacility: {
    width: 330,

    position: "absolute",
    textAlign: "center",
    left: 40,
    top: 200,
  },
  dateDisposition: {
    width: 100,

    position: "absolute",
    textAlign: "center",
    left: 670,
    top: 140,
  },
  methodDisposition: {
    width: 450,

    position: "absolute",
    textAlign: "center",
    left: 160,
    top: 165,
  },
  informantMailing: {
    width: 350,

    position: "absolute",
    textAlign: "center",
    left: 40,
    top: 80,
  },
  informantPhone: {
    width: 250,

    position: "absolute",
    textAlign: "center",
    left: 515,
    top: 40,
  },
  informantEmail: {
    width: 290,

    position: "absolute",
    textAlign: "center",
    left: 210,
    top: 40,
  },
  informantRelationship: {
    width: 170,

    position: "absolute",
    textAlign: "center",
    left: 30,
    top: 40,
  },
  informantName: {
    width: 145,

    position: "absolute",
    textAlign: "center",
    left: 30,
    top: 555,
  },
  informantMiddle: {
    width: 210,

    position: "absolute",
    textAlign: "center",
    left: 180,
    top: 555,
  },
  informantLastname: {
    width: 260,

    position: "absolute",
    textAlign: "center",
    left: 400,
    top: 555,
  },
  informantSuffix: {
    width: 100,

    position: "absolute",
    textAlign: "center",
    left: 670,
    top: 555,
  },

  motherName: {
    width: 145,

    position: "absolute",
    textAlign: "center",
    left: 30,
    top: 520,
  },
  motherMiddle: {
    width: 210,

    position: "absolute",
    textAlign: "center",
    left: 180,
    top: 520,
  },
  motherLastname: {
    width: 260,

    position: "absolute",
    textAlign: "center",
    left: 400,
    top: 520,
  },
  motherSuffix: {
    width: 100,

    position: "absolute",
    textAlign: "center",
    left: 670,
    top: 520,
  },
  fatherName: {
    width: 145,

    position: "absolute",
    textAlign: "center",
    left: 30,
    top: 485,
  },
  fatherMiddle: {
    width: 210,

    position: "absolute",
    textAlign: "center",
    left: 180,
    top: 485,
  },
  fatherLastname: {
    width: 260,

    position: "absolute",
    textAlign: "center",
    left: 400,
    top: 485,
  },
  fatherSuffix: {
    width: 100,

    position: "absolute",
    textAlign: "center",
    left: 670,
    top: 485,
  },
  maritalName: {
    width: 145,

    position: "absolute",
    textAlign: "center",
    left: 30,
    top: 450,
  },
  maritalMiddle: {
    width: 160,

    position: "absolute",
    textAlign: "center",
    left: 180,
    top: 450,
  },
  maritalLastname: {
    width: 180,

    position: "absolute",
    textAlign: "center",
    left: 345,
    top: 450,
  },
  maritalSuffix: {
    width: 65,

    position: "absolute",
    textAlign: "center",
    left: 528,
    top: 450,
  },
  maritalSurviving: {
    width: 175,

    position: "absolute",
    textAlign: "center",
    left: 598,
    top: 450,
  },
  maritalStatus1: {
    position: "absolute",
    left: 138,
    top: 409,
  },
  maritalStatus2: {
    position: "absolute",
    left: 195.5,
    top: 409,
  },
  maritalStatus3: {
    position: "absolute",
    left: 257.5,
    top: 409,
  },
  maritalStatus4: {
    position: "absolute",
    left: 317.5,
    top: 409,
  },
  maritalStatus5: {
    position: "absolute",
    left: 401.5,
    top: 409,
  },
  maritalStatus6: {
    position: "absolute",
    left: 518.5,
    top: 409,
  },
  maritalStatus7: {
    position: "absolute",
    left: 605.5,
    top: 409,
  },
  maritalStatus8: {
    position: "absolute",
    left: 670,
    top: 409,
  },
  residedTribalCommunityUnknown: {
    position: "absolute",
    left: 533,
    top: 360,
  },
  residedTribalCommunityUnknown: {
    position: "absolute",
    left: 533,
    top: 360,
  },
  residedTribalCommunityValue: {
    position: "absolute",
    left: 525,
    top: 370,
    width: 240,

  },
  residedTribalCommunityNo: {
    position: "absolute",
    left: 747,
    top: 348,
  },
  residedTribalCommunityYes: {
    position: "absolute",
    left: 717,
    top: 348,
  },
  howLongInArizonaOptionUnknown: {
    position: "absolute",
    left: 462,
    top: 369,
  },
  howLongInArizonaOptionInTransit: {
    position: "absolute",
    left: 407.5,
    top: 369,
  },
  howLongInArizonaOptionWeeks: {
    position: "absolute",
    left: 366,
    top: 369,
  },
  howLongInArizonaOptionWeeks: {
    position: "absolute",
    left: 366,
    top: 369,
  },
  howLongInArizonaOptionMonths: {
    position: "absolute",
    left: 319,
    top: 369,
  },
  howLongInArizonaOptionYears: {
    position: "absolute",
    left: 484,
    top: 358,
  },
  howLongInArizonaOptionMinutes: {
    position: "absolute",
    left: 441,
    top: 358,
  },
  howLongInArizonaOptionHours: {
    position: "absolute",
    left: 404,
    top: 358,
  },
  howLongInArizonaOptionDays: {
    position: "absolute",
    left: 372,
    top: 358,
  },

  howLongInArizonaValue: {
    width: 45,
    position: "absolute",
    textAlign: "center",
    left: 320,
    top: 358,
  },

  inCityLimitsYes: {
    position: "absolute",
    left: 193,
    top: 369,
  },
  inCityLimitsNo: {
    position: "absolute",
    left: 227,
    top: 369,
  },
  inCityLimitsUnknown: {
    position: "absolute",
    left: 256,
    top: 369,
  },
  streetAddressResidence: {
    width: 280,
    textAlign: "center",
    position: "absolute",
    left: 30,
    top: 330,
  },
  streetZipCode: {
    width: 70,
    textAlign: "center",
    position: "absolute",
    left: 320,
    top: 330,
  },
  streetResidenceCity: {
    width: 120,
    textAlign: "center",
    position: "absolute",
    left: 395,
    top: 330,
  },
  streetResidenceConty: {
    width: 125,
    textAlign: "center",
    position: "absolute",
    left: 520,
    top: 330,
  },
  streetResidenceState: {
    width: 125,
    textAlign: "center",
    position: "absolute",
    left: 650,
    top: 330,
  },
  streetResidenceCountry: {
    width: 150,
    textAlign: "center",
    position: "absolute",
    left: 30,
    top: 370,
  },
  document: {
    fontSize: 9,
    textTransform: "uppercase",
  },
  firstName: {
    width: 390,
    position: "absolute",
    textAlign: "center",

    left: 30,
    top: 165,
  },
  middleName: {
    width: 350,
    position: "absolute",
    textAlign: "center",

    left: 430,
    top: 165,
  },
  lastName: {
    width: 390,
    position: "absolute",
    textAlign: "center",

    left: 30,
    top: 195,
  },
  suffix: {
    width: 210,
    position: "absolute",
    textAlign: "center",

    left: 430,
    top: 195,
  },
  aka: {
    width: 120,
    position: "absolute",
    textAlign: "center",

    left: 655,
    top: 195,
  },
  sexMale: {
    position: "absolute",
    left: 91,
    top: 225,
  },
  sexFamale: {
    position: "absolute",
    left: 35,
    top: 225,
  },
  sexUnknown: {
    position: "absolute",
    left: 35,
    top: 236,
  },
  usNumberNone: {
    position: "absolute",
    left: 148,
    top: 225,
  },
  usNumberUnknown: {
    position: "absolute",
    left: 148,
    top: 236,
  },
  usNumberHave: {
    width: 120,
    position: "absolute",
    textAlign: "center",
    left: 200,
    top: 236,
  },
  dateDeath: {
    width: 80,
    position: "absolute",
    textAlign: "center",

    left: 325,
    top: 236,
  },
  dateBirth: {
    width: 70,
    position: "absolute",
    textAlign: "center",

    left: 470,
    top: 236,
  },
  ageYears: {
    width: 25,
    position: "absolute",
    textAlign: "center",

    left: 610,
    top: 225,
  },
  ageMonths: {
    width: 30,
    position: "absolute",
    textAlign: "center",

    left: 664,
    top: 225,
  },
  ageDays: {
    width: 23,
    position: "absolute",
    textAlign: "center",

    left: 728,
    top: 225,
  },
  ageHours: {
    width: 25,
    position: "absolute",
    textAlign: "center",

    left: 610,
    top: 236,
  },
  ageMinutes: {
    width: 30,
    position: "absolute",
    textAlign: "center",

    left: 664,
    top: 236,
  },
  birthCity: {
    width: 170,
    position: "absolute",
    textAlign: "center",

    left: 35,
    top: 265,
  },
  birthCounty: {
    width: 175,
    position: "absolute",
    textAlign: "center",

    left: 210,
    top: 265,
  },
  birthState: {
    width: 185,
    position: "absolute",
    textAlign: "center",
    left: 390,
    top: 265,
  },
  birthCountry: {
    width: 200,
    position: "absolute",
    textAlign: "center",

    left: 580,
    top: 265,
  },
  armyYes: {
    position: "absolute",
    left: 35,
    top: 291,
  },
  armyNo: {
    position: "absolute",
    left: 69,
    top: 291,
  },
  armyUnknown: {
    position: "absolute",
    left: 100,
    top: 291,
  },
  firstMirage: {
    width: 365,
    textAlign: "center",

    position: "absolute",
    left: 210,
    top: 294,
  },
  hrrfYes: {
    position: "absolute",
    left: 589,
    top: 291,
  },
  hrrfNo: {
    position: "absolute",
    left: 614,
    top: 291,
  },
  image: {
    width: "100%",
    marginBottom: 0,
    zIndex: 1,
  },
  signature: {
    height: "30px",
    position: "absolute",
    width: "60px",
    marginBottom: 291,
    zIndex: 100,
  },
  divSignature: {
    top: "280px",
    left: "350px"
  },
  divDate: {
    position: "absolute",
    top: "300px",
    left: "676px"
  },
  divDate1: {
    position: "absolute",
    top: "300px",
    left: "600px"
  },
  sign1:{
    position: "absolute",
    top: "-210px",
    left: "100px"
  }

};

export default MasterForm1;