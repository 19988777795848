import React from "react";
import { DropdownButton, ButtonGroup, Dropdown } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import api from "../../../services/api";
import { toast } from "react-toastify";
import GetUsersFromCookies from "../../../components/GetUserFromCookies";

const AddDocumentation = ({ folio,recargarDocumentos,recargaDocumentos}) => {
  const addDocumentoCreado = async (num_form) => {
    const data = {
      num_form: num_form,
      id_usuario: GetUsersFromCookies().id_usuario,
      folio: folio,
    };

    try {
      const response = await api.post(
        'archive/profile/DocumentosCreadosController.php',
        data,
        { params: { action: "POST" } }
      );
      console.log(response)
      recargaDocumentos(true);
      toast.success("Document created.", {
        className: "toast-success",
        theme: "colored",
      });
    } catch (error) {
      console.error("Error adding archive", error);
      toast.error("Error creating document", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };

  return (
    <div style={{ margin: "10px" }}>
      <DropdownButton
        as={ButtonGroup}
        title={
          <>
            <FaPlus />
            <span style={{ marginLeft: "5px" }}>Add file</span>
          </>
        }
        id="bg-nested-dropdown"
        variant="secondary"
      >
        <Dropdown.Item eventKey="1" onClick={() => addDocumentoCreado(1)}>
          Death registration worksheet
        </Dropdown.Item>
        <Dropdown.Item eventKey="2" onClick={() => addDocumentoCreado(2)}>
          Invoice
        </Dropdown.Item>
        <Dropdown.Item eventKey="3" onClick={() => addDocumentoCreado(3)}>
          Authorization of release / Embalming
        </Dropdown.Item>
        <Dropdown.Item eventKey="7" onClick={() => addDocumentoCreado(7)}>
          Embaling Letter
        </Dropdown.Item>
        <Dropdown.Item eventKey="4" onClick={() => addDocumentoCreado(4)}>
          Purchase Receipt
        </Dropdown.Item>
        <Dropdown.Item eventKey="5" onClick={() => addDocumentoCreado(5)}>
          Notice to purchaser
        </Dropdown.Item>
        <Dropdown.Item eventKey="6" onClick={() => addDocumentoCreado(6)}>
          Crematory Authorization
        </Dropdown.Item>
        <Dropdown.Item eventKey="8" onClick={() => addDocumentoCreado(8)}>
          Tucson Cremation Services
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
};

export default AddDocumentation;
