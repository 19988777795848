import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import api from "../../../services/api";
import PDFicon from "../../../components/icons/PDFicon";
import Folder from "../../../components/icons/Folder";
const AllCreatedData = () => {
  const [data, setData] = useState([]);
  const [foliosCreados, setfoliosCreados] = useState("");
  const [documentosCreados, setdocumentosCreados] = useState("");

  useEffect(() => {
    getAllCreatedData();
  }, []);

  const getAllCreatedData = async () => {
    try {
      const response = await api.get(`dashboard/AllCreatedData.php`, {
        params: { action: "GET" },
      });
      const responseData = response.data;
      if (responseData != 0) {
        console.log(response);
        setfoliosCreados(responseData[0].folios_creados);
        setdocumentosCreados(responseData[0].documentos_creados);
      }
    } catch (error) {
      console.log("Error getting info", error);
    }
  };

  return (
    <>
      <Col>
        <div className="main-card" style={{ textAlign: "center" }}>
        <Row>
        <Col> <Folder color={"#f0d4a3"} /></Col>
         <Col>
         <p style={{ marginBottom: "5px" }}>All Folios</p>
          <span style={{ fontWeight: "bolder", fontSize: "42px" }}>
            {foliosCreados}
          </span>
         </Col>
        </Row>
          
        </div>
      </Col>
      <Col>
        <div className="main-card" style={{ textAlign: "center" }}>
          <Row>
          <Col>
          <PDFicon />
          </Col>
          <Col>
          <p style={{ marginBottom: "5px" }}>All Documents</p>
          <span style={{ fontWeight: "bolder", fontSize: "42px" }}>
            {documentosCreados}
          </span>
          </Col>
          </Row>
        </div>
      </Col>
    </>
  );
};

export default AllCreatedData;
