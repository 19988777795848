import api from "../../../../services/api";

const GetInfoForm2 = async ({ folio }) => {
  try {
    const response = await api.get(
      `documents/form2/InvoiceController.php?id=${folio}`
    );
    const invoiceData = response.data[0];
    return {
      folio: invoiceData?.folio || "",
      consecutivo: invoiceData?.secuencia || "",
      Date: invoiceData?.Date || "",
      AccountName: invoiceData?.AccountName || "",
      DetailedExplanation: invoiceData?.DetailedExplanation || "",
      Amount: invoiceData?.Amount || "",
      Total: invoiceData?.Total || "",
      accountBankMexico: invoiceData?.accountBankMexico || "",
      accountNameBankUSA: invoiceData?.accountNameBankUSA || "",
      accountNumberUSA: invoiceData?.accountNumberUSA || "",
      bankNameUSA: invoiceData?.bankNameUSA || "",
      bankNameMexico: invoiceData?.bankNameMexico || "",
      clabeBankMexico: invoiceData?.clabeBankMexico || "",
      emailAddress: invoiceData?.emailAddress || "",
      faxNumber: invoiceData?.faxNumber || "",
      funeralDirectorName: invoiceData?.funeralDirectorName || "",
      funeralHomeAddress: invoiceData?.funeralHomeAddress || "",
      funeralHomeName: invoiceData?.funeralHomeName || "",
      licenceNumber: invoiceData?.licenceNumber || "",
      nameBankAccountMexico: invoiceData?.nameBankAccountMexico || "",
      phoneNumber: invoiceData?.phoneNumber || "",
      routingNumberUSA: invoiceData?.routingNumberUSA || "",
      Wires: invoiceData?.Wires || "",
      Zelle: invoiceData?.Zelle || "",
    };
  } catch (error) {
    console.error("Error fetching form data:", error);
    return {};
  }
};

export default GetInfoForm2;
