import React, { useState } from "react";
import { Form } from "react-bootstrap";

const DefaulLabel = ({numberRow, type, placeholder,onChange}) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <Form.Group className="mt-2 mb-2">
      <Form.Label style={{fontWeight:"700"}}>{numberRow}</Form.Label>
      <Form.Label>{placeholder}</Form.Label>
    </Form.Group>
  );
};

export default DefaulLabel;
