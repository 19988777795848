import React, { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";
import { SlOptionsVertical } from "react-icons/sl";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const Navbar = ({ toggleSidebar, className }) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const getUserDataFromCookies = () => {
    const userDataString = Cookies.get("userData");
    if (userDataString) {
      try {
        const userData = JSON.parse(userDataString);
        return userData;
      } catch (error) {
        return null;
      }
    } else {
      return null;
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const capitalizeFullName = (name) => {
    return name.split(' ').map(capitalizeFirstLetter).join(' ');
  };

  const userData = getUserDataFromCookies();

  useEffect(() => {
    if (!userData) {
      navigate("/login");
    }

    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [userData, navigate]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className={`navbar-top ${className}`}>
      <button className="toggle-sidebar-btn" onClick={toggleSidebar}>
        ☰
      </button>
      {userData && (
        <a className="user-button">
          Welcome back, {capitalizeFullName(userData.nombre)} {capitalizeFullName(userData.apellido)}
        </a>
      )}
      <div className="user-container" ref={menuRef}>
        <a className="settings-button" onClick={toggleMenu}>
          <SlOptionsVertical />
        </a>
        {menuOpen && (
          <div className="user-menu">
            <Link className="item" to={"/settings/account"}>Edit profile</Link>
            <Link className="item" to={"/login"}>Log out</Link>
          </div>
        )}
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default Navbar;
