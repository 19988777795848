import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PDFicon from "./icons/PDFicon";
import PreviewDocument from "../pages/archive/ProfileComponents/PreviewDocuments";
import SignDocument from "../pages/documents/Sign";
const FormLayout = ({ text, url, folioID, consecutivo, numForm }) => {
  return (
    <>
      <div
        style={{ padding: "10px", margin: "10px", cursor: "pointer" }}
      >
        <PDFicon />
      </div>
      <div
        style={{
          backgroundColor: "#f0f0f0",
          width: "100%",
          display: "flex",
          justifyContent: "center", // Centra el texto horizontalmente
          padding: "10px",
          borderRadius:"0 0 10px 10px"

        }}
      >
        <p
          className="text-center"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100%",
          }}
        >
          {text}
        </p>
      </div>

    </>
  );
};

export default FormLayout;
