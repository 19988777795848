import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { Chart as ChartJS, ArcElement, Legend, Tooltip } from "chart.js";
import { Pie } from "react-chartjs-2";
import api from "../../../services/api";

const AllCountrysPieChart = () => {
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  });

  ChartJS.register(ArcElement, Tooltip, Legend);

  useEffect(() => {
    getPieInfo();
  }, []);

  const getPieInfo = async () => {
    try {
      const response = await api.get(`dashboard/AllCountryPieChart.php`, {
        params: { action: "GET" },
      });
      const responseData = response.data;
      const labels = responseData.map((item) => item.country_name);
      const dataValues = responseData.map((item) => item.count_records);
      const backgroundColors = responseData.map((item) => item.country_color);

      setData({
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor: backgroundColors,
          },
        ],
      });
    } catch {
      console.log("Error get info");
    }
  };

  const options = {};

  return (
    <>
        <Row>
          <h5>Countries of the Deceased</h5>
        </Row>
        <Row>
          <p>
            This chart shows the distribution of deceased individuals across
            different countries.
          </p>
        </Row>

        <Row>
          <div className="p-4">
            <Pie data={data} options={options}></Pie>
          </div>
        </Row>
    </>
  );
};

export default AllCountrysPieChart;
