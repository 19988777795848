import React, { useState } from "react";
import { Button, Col, Row, Modal } from "react-bootstrap";
import DecedentInfo from "./decedentInfo/decedent_info";
import MasterForm1 from "./PDF_Form1";
import { PDFViewer } from "@react-pdf/renderer";
import RecidenceInfo from "./recidenceInfo/recidence_info";
import SpouseInfo from "./spouceInfo/spouse_info";
import MotherInfo from "./motherInfo/mother_info";
import FatherInfo from "./fatherInfo/father_info";
import InformantInfo from "./infromantInfo/informant_info";
import FuneralInfo from "./funeralInfo/funeral_info";
import DecedentOccupationInfo from "./decedentOccupationInfo/decedent_occupation";
import CeretifierInfo from "./certifierInfo/certifier_info";
import { useParams, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Tab, Tabs, Box, Typography } from '@mui/material';

// Función auxiliar para renderizar los tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const DeathRegistrationForm = () => {
  const { folioID } = useParams();
  const navigate = useNavigate();
  const { consecutivo } = useParams();

  const [pdfKey, setPdfKey] = useState(0);
  var dataDecedent = {
    firstName: "",
    middleName: "",
    lastName: "",
    suffix: "",
    aka: "",
    sex: "",
    usSecurityNumOptions: "",
    usSecurityNumValue: "",
    dateOfDeath: "",
    dateOfBirth: "",
    ageIn: "",
    years: "",
    months: "",
    days: "",
    hours: "",
    minutes: "",
    birthCityOrTown: "",
    birthCounty: "",
    birthState: "",
    birthCountry: "",
    armedForces: "",
    namePriorToMarriage: "",
    hrrf: "",
  };
  var dataRecidence = {
    streetAdress: "",
    zipCode: "",
    residenceCity: "",
    residenceConty: "",
    residenceState: "",
    residenceCountry: "",
    inCityLimits: "",
    howLongInArizonaValue: "",
    howLongInArizonaOption: "",
    residedTribalCommunity: "",
  };

  var dataSpouse = {
    maritalStatus: "",
    firstNameSpouse: "",
    middleNameSpouse: "",
    lastNameSpouse: "",
    suffixSpouse: "",
    lastNameSurvivingSpouse: "",
  };
  var dataFather = {
    firstNameFather: "",
    middleNameFather: "",
    lastNameFather: "",
    suffixFather: "",
  };
  var dataMother = {
    firstNameMother: "",
    middleNameMother: "",
    lastNameMother: "",
    suffixMother: "",
  };

  var dataInformant = {
    firstNameInformant: "",
    middleNameInformant: "",
    lastNameInformant: "",
    suffixInformant: "",
    relationshipInfromant: "",
    emailInformant: "",
    phoneInformant: "",
    mailingAddress: "",
  };
  var dataFuneral = {
    methodDispositionOptions: "",
    methodDisposition: "",
    dateDisposition: "",
    placeDispositionFirstFacility: "",
    placeDispositionSecondFacility: "",
    nameFuneralDirector: "",
    licenceNumber: "",
    nameFuneralHome: "",
    addressFuneralHome: "",
    otherResponsableRelationship: "",
  };

  var dataOccupation = {
    decendentOccupation: "",
    education: "",
    decedemtIndustry: "",
    decedentOriginOption: [],
    decedentRaceOption: [],
    placeOfDeathOption: "",
    placeOfDeathValue: "",
    placeOfDeathFacility: "",
    placeOfDeathFacilityAddress: "",
    otherInstitutionAddress: "",
  };

  var dataCertifier = {
    certifierType: "",
    certifierLicenseNumber: "",
    certifierName: "",
    certifierTitleOption: "",
    certifierAddress: "",
    certifierZipCode: "",
    certifierCity: "",
    certifierState: "",
    certifierCountry: "",
    certifierNameAlternate: "",
    certifierTelephone: "",
    certifierFax: "",
    certifierEmail: "",
  };

  var signature = {
    timeSign: "",
    signature: ""
  }
  const [decedentData, setDecedentData] = useState(dataDecedent);
  const [recidenceData, setRecidenceData] = useState(dataRecidence);
  const [spouseData, setSpouseData] = useState(dataSpouse);
  const [fatherData, setFatherData] = useState(dataFather);
  const [motherData, setMotherData] = useState(dataMother);
  const [informantData, setInformantData] = useState(dataInformant);
  const [funeralData, setFuneralData] = useState(dataFuneral);
  const [occupationData, setOccupationData] = useState(dataOccupation);
  const [certifierData, setCertifierData] = useState(dataCertifier);


  const reloadPdf = () => {
    setPdfKey((prevKey) => prevKey + 1);
  };

  const handleDecedentDataChange = (data) => {
    setDecedentData(data);
  };
  const handleRecidenceDataChange = (data) => {
    setRecidenceData(data);
  };
  const handleSpouseDataChange = (data) => {
    setSpouseData(data);
  };
  const handleFatherDataChange = (data) => {
    setFatherData(data);
  };
  const handleMotherDataChange = (data) => {
    setMotherData(data);
  };
  const handleInformantDataChange = (data) => {
    setInformantData(data);
  };
  const handleFuneralDataChange = (data) => {
    setFuneralData(data);
  };
  const handleOccupationDataChange = (data) => {
    setOccupationData(data);
  };
  const handleCertifierDataChange = (data) => {
    setCertifierData(data);
  };

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };


  const [activeForm, setActiveForm] = useState('decedent');

  const handleFormChange = (form) => {
    setActiveForm(form);
  };
  const handleSignDocument = () => {
    navigate(`/documents/sign-document/${folioID}/1`, { state: { decedentData, recidenceData, spouseData, fatherData, motherData, informantData, funeralData, occupationData, certifierData } });
  };

  return (
    <>
      <div className="mb-3 d-flex justify-content-between">
        <h2 className="f-cel">{folioID}</h2>
        <h3 className="f-cel">DEATH REGISTRATION FORM</h3>
        <div><button className="btn btn-primary" onClick={handleShowModal}>View Document</button></div>
      </div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Decedent Info" />
          <Tab label="Recidence Info" />
          <Tab label="Spouse Info" />
          <Tab label="Father Info" />
          <Tab label="Mother Info" />
          <Tab label="Informant Info" />
          <Tab label="Funeral Info" />
          <Tab label="Decedent Occupation Info" />
          <Tab label="Certifier Info" />
        </Tabs>
      </Box>
      <TabPanel value={activeTab} index={0}>
        <DecedentInfo onDecedentDataChange={handleDecedentDataChange} folio={consecutivo} folioId={folioID} />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <RecidenceInfo onRecidenceDataChange={handleRecidenceDataChange} folio={consecutivo} folioId={folioID} />
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        <SpouseInfo onSpouseDataChange={handleSpouseDataChange} folio={consecutivo} folioId={folioID} />
      </TabPanel>
      <TabPanel value={activeTab} index={3}>
        <FatherInfo onFatherDataChange={handleFatherDataChange} folio={consecutivo} folioId={folioID} />
      </TabPanel>
      <TabPanel value={activeTab} index={4}>
        <MotherInfo onMotherDataChange={handleMotherDataChange} folio={consecutivo} folioId={folioID} />
      </TabPanel>
      <TabPanel value={activeTab} index={5}>
        <InformantInfo onInformantDataChange={handleInformantDataChange} folio={consecutivo} folioId={folioID} />
      </TabPanel>
      <TabPanel value={activeTab} index={6}>
        <FuneralInfo onFuneralDataChange={handleFuneralDataChange} folio={consecutivo} folioId={folioID} />
      </TabPanel>
      <TabPanel value={activeTab} index={7}>
        <DecedentOccupationInfo onOccupationChange={handleOccupationDataChange} consecutivo={consecutivo} folio={folioID} />
      </TabPanel>
      <TabPanel value={activeTab} index={8}>
        <CeretifierInfo onCertifierDataChange={handleCertifierDataChange} consecutivo={consecutivo} folio={folioID} />
      </TabPanel>
      <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>DEATH REGISTRATION WORKSHEET</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PDFViewer style={{ width: "100%", height: "600px" }} key={pdfKey}>
            <MasterForm1
              data={decedentData}
              dataResidence={recidenceData}
              spouseData={spouseData}
              fatherData={fatherData}
              motherData={motherData}
              informantData={informantData}
              funeralData={funeralData}
              occupationData={occupationData}
              certifierData={certifierData}
              signature={signature || null}
            />
          </PDFViewer>
        </Modal.Body>
        <Modal.Footer>
          {/*<Button variant="primary" onClick={handleSignDocument}>
            Sign Document
          </Button>*/}
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default DeathRegistrationForm;