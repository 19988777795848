import React, { useState,useEffect} from "react";
import { Button, Col, Row} from "react-bootstrap";
import DefaultInput from "../../../../components/Elements/DefaultInput";
import GerUsersFromCookies from "../backend/GetUserFromCookies"
import api from "../../../../services/api";
import { toast } from "react-toastify";

const InformantInfo = ({ onInformantDataChange,folioId,folio }) => {
  const [formData, setFormData] = useState({
    secuencia :folio,
    folio :folioId,
    usuario_id : GerUsersFromCookies().id_usuario,
    
    firstNameInformant: "",
    middleNameInformant: "",
    lastNameInformant: "",
    suffixInformant: "",
    relationshipInfromant:"",
    emailInformant:"",
    phoneInformant:"",
    mailingAddress:"",
  });
  const handleInputChange = (name, value) => {
    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    setFormData(updatedFormData);
    onInformantDataChange(updatedFormData);
  };
  const handleSubmit = () => {
    add_informantInfo(formData);
  };

  const add_informantInfo = async (formData) => {
    try {
      const response = await api.post(
        "documents/form1/InformantController.php",
        formData,
        { params: { action: "POST" } }
      );
      toast.success("Informant info successfully added", {
        className: "toast-success",
        theme: "colored",
      });
      
    } catch (error) {
      toast.error("Error adding informant info", {
        className: "toast-error",
        theme: "colored",
      });

      
    }
  };
  useEffect(() => {
    get_informant_info_with_ID(folioId);
  }, []);
  const get_informant_info_with_ID = async (folio) =>{
    try{
      const response = await api.get(
        `documents/form1/InformantController.php?id=${folio}`
      );
      const informantInfo = response.data[0];
      if(response.data[0] != null){
        setFormData(prevFormData => ({
          ...prevFormData,
          firstNameInformant: informantInfo.name,
          middleNameInformant: informantInfo.middle,
          lastNameInformant: informantInfo.lastname,
          suffixInformant: informantInfo.suffix,
          relationshipInfromant: informantInfo.relationship,
          emailInformant:informantInfo.email,
          phoneInformant:informantInfo.phone,
          mailingAddress:informantInfo.mailing,
      }));
      }
    }
    catch{

    }
  }
  useEffect(() => {
    if (Object.keys(formData).some(key => formData[key] !== "")) {
      onInformantDataChange(formData);
    }
  }, [formData, onInformantDataChange]);
  return (
    <>
      <div className="main-card">
        <DefaultInput
          numberRow={"18A.-"}
          placeholder={"INFORMANT’S FIRST NAME"}
          type={"text"}
          onChange={(value) => handleInputChange("firstNameInformant", value)}
          valueData={formData.firstNameInformant}
        />
        <DefaultInput
          numberRow={"18B.-"}
          placeholder={"INFORMANT MIDDLE NAME"}
          type={"text"}
          onChange={(value) => handleInputChange("middleNameInformant", value)}
          valueData={formData.middleNameInformant}
        />
        <DefaultInput
          numberRow={"18C.-"}
          placeholder={"INFORMANT LAST NAME"}
          type={"text"}
          onChange={(value) => handleInputChange("lastNameInformant", value)}
          valueData={formData.lastNameInformant}

        />
        <DefaultInput
          numberRow={"18D.-"}
          placeholder={"SUFFIX"}
          type={"text"}
          onChange={(value) => handleInputChange("suffixInformant", value)}
          valueData={formData.suffixInformant}
        />
        <DefaultInput
          numberRow={"18E.-"}
          placeholder={"RELATIONSHIP TO DECEDENT"}
          type={"text"}
          onChange={(value) => handleInputChange("relationshipInfromant", value)}
          valueData={formData.relationshipInfromant}

        />
        <DefaultInput
          numberRow={"18F.-"}
          placeholder={"INFORMANT'S EMAIL ADDRESS"}
          type={"text"}
          onChange={(value) => handleInputChange("emailInformant", value)}
          valueData={formData.emailInformant}

        />
        <DefaultInput
          numberRow={"18G.-"}
          placeholder={"INFORMANT'S PHONE NUMBER"}
          type={"text"}
          onChange={(value) => handleInputChange("phoneInformant", value)}
          valueData={formData.phoneInformant}

        />
        <DefaultInput
          numberRow={"18H.-"}
          placeholder={"INFORMANT'S MAILING ADDRESS"}
          type={"mail"}
          onChange={(value) => handleInputChange("mailingAddress", value)}
          valueData={formData.mailingAddress}

        />
      </div>
      <div className="d-flex justify-content-end mt-3">
        <Button onClick={handleSubmit}>SAVE INFORMANT INFO</Button>
      </div>
    </>
  );
};

export default InformantInfo;
