import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { toast } from "react-toastify";
import DefaultInput from "../../components/Elements/DefaultInput";
import DefaultSelect from "../../components/Elements/DefaultSelect";
import GetUserFromCookies from "./Form1/backend/GetUserFromCookies";
import api from "../../services/api";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { IoIosInformationCircle } from "react-icons/io";

const CreateFolioForm = forwardRef((props, ref) => {
  const userData = GetUserFromCookies();
  const [tempFolio, setTempFolio] = useState();
  const [countries, setCountries] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    folio: "",
    usuario_id: userData.id_usuario,
    name: "",
    middle: "",
    last: "",
    birth: "",
  });

  const getTempFolio = async () => {
    try {
      const response = await api.get("documents/CreateFolioController.php", { params: { action: "GET" } });
      setTempFolio(response.data[0].temp_folio);
    } catch (error) {
      toast.error("Error when searching for folios", { className: "toast-error", theme: "colored" });
    }
  };

  const getCountries = async () => {
    try {
      const response = await api.get("documents/form1/loadDecedentInfoController.php", { params: { action: "GET" } });
      const formattedData = response.data.map((item) => ({ label: item.Country, value: item.ID, id: item.ID }));
      setCountries(formattedData);
    } catch (error) {
      toast.error("Error when searching for countries", { className: "toast-error", theme: "colored" });
    }
  };

  useEffect(() => {
    getTempFolio();
    getCountries();
  }, []);

  useEffect(() => {
    if (tempFolio) {
      setFormData((prevData) => ({
        ...prevData,
        folio: tempFolio,
      }));
    }
  }, [tempFolio]);

  const validateForm = () => {
    const errors = {};
    if (!formData.folio.trim()) errors.folio = "Folio is required";
    if (!formData.name.trim()) errors.name = "Name is required";

    if (!formData.last.trim()) errors.last = "Last name is required";
    if (!formData.birth) errors.birth = "Country is required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCreateFolio = async () => {
    if (!validateForm()) {
        toast.error("Please fill in all required fields", { className: "toast-error", theme: "colored" });
        return false;
    }
    try {
        console.log("revisa si existe");
        const response = await api.post("documents/DocumentsController.php", formData, { params: { action: "POST" } });
        if (response.data && response.data.includes("The folio already exists")) {
            toast.error("The folio already exists", { className: "toast-error", theme: "colored" });
            return false;
        }
        toast.success("Folio created successfully", { className: "toast-success", theme: "colored" });
        console.log(response);
        console.log(formData);
        return true;
    } catch (error) {
        toast.error("Error when creating the folio", { className: "toast-error", theme: "colored" });
        return false;
    }
};


  useImperativeHandle(ref, () => ({
    handleCreateFolio,
  }));

  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      You can edit the folio that will be worked on, however if the folio
      already exists it will not let you create it, the format is RF-[YEAR] - #
      Folio, by default it will create the consecutive folio.
    </Tooltip>
  );
  return (
    <>
      <div>
      <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ flex: 1 }}>
        <DefaultInput
          valueData={formData.folio}
          placeholder={"Folio*"}
          onChange={(value) => handleInputChange("folio", value)}
          style={{ width: "100%" }}
        />
        {formErrors.folio && (
          <span style={{ color: "red", fontSize: "0.8rem" }}>
            {formErrors.folio}
          </span>
        )}
      </div>

      <div style={{ marginLeft: "5px" }}>
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <a>
            <IoIosInformationCircle
              style={{ fontSize: "22px", color: "#007bff" }}
            />
          </a>
        </OverlayTrigger>
      </div>
    </div>
      </div>
      <DefaultInput
        placeholder={"Name*"}
        onChange={(value) => handleInputChange("name", value)}
      />
      {formErrors.name && (
        <span style={{ color: "red", fontSize: "0.8rem" }}>
          {formErrors.name}
        </span>
      )}
      <DefaultInput
        placeholder={"Middle name*"}
        onChange={(value) => handleInputChange("middle", value)}
      />
      {formErrors.middle && (
        <span style={{ color: "red", fontSize: "0.8rem" }}>
          {formErrors.middle}
        </span>
      )}
      <DefaultInput
        placeholder={"Last name*"}
        onChange={(value) => handleInputChange("last", value)}
      />
      {formErrors.last && (
        <span style={{ color: "red", fontSize: "0.8rem" }}>
          {formErrors.last}
        </span>
      )}
      <DefaultSelect
        options={countries}
        label={"Country*"}
        defaultValue={"Select..."}
        onChange={(value) => handleInputChange("birth", value)}
      />
      {formErrors.birth && (
        <span style={{ color: "red", fontSize: "0.8rem" }}>
          {formErrors.birth}
        </span>
      )}
    </>
  );
});

export default CreateFolioForm;
