import React, { useState, useEffect } from "react";
import Folder from "../../components/icons/Folder";
import { Link } from "react-router-dom";
import api from "../../services/api";

const ArchivePage = () => {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    getAllCountries();
  }, []);

  const getAllCountries = async () => {
    try {
      const response = await api.get(
        'archive/ArchiveController.php',
        { params: { action: "GET" } }
      );
      if (response.data != null) {
        setCountries(response.data);
      } else {
        setCountries([]);
      }
    } catch (error) {
      setError(true);
      console.error("Error al buscar países:", error);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className="archive-container">
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <h2>No files found</h2>
      ) : countries.length === 0 ? (
        <p>No files found</p>
      ) : (
        countries.map((country, index) => (
          <div key={index} className="archive-icon-folder">
            <Link to={`/archive/${country.name.toLowerCase()}/${country.archive_id}`}>
              <Folder color={country.color} />
              <div className="d-flex justify-content-center align-items-center">
                <span>{country.name}</span>
              </div>
            </Link>
          </div>
        ))
      )}
    </div>
  );
};

export default ArchivePage;
