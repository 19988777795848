import React from "react";
import { Document, Page, Image, Text } from "@react-pdf/renderer";
const PDF_Form2 = (data) => {
  return (
    <Document style={styles.document} title={"INVOICE " + data.data.folio}>
      <Page size="Letter">
        <div style={styles.lines}></div>
        <div
          style={{
            flexDirection: "row",
            marginLeft: "40px",
            marginTop: "40px",
          }}
        >
          <Image
            source={"/img/logo/logo.png"}
            style={{ width: " 120px ", height: "120px" }}
          ></Image>
          <div
            style={{
              textAlign: "center",
              color: "#2f5496",
              flex: "1",
              alignItems: "center",
              marginTop: "30px",
              marginLeft: "-100px",
            }}
          >
            <Text>Ramirez Funeral Home LLC</Text>
            <Text>4595 S Palo Verde Rd. Suite 503, Tucson Arizona 85714</Text>
            <Text>Email: ramirezfuneralhome@gmail.com</Text>
            <Text>Phone: (520) 372-2479 Fax: (520) 842-3889</Text>
          </div>
        </div>
        <div style={styles.container}>
          <Text style={styles.title}>Invoice</Text>
          <div style={{ flexDirection: "row" }}>
            <div>
              <Text
                style={{
                  position: "absolute",
                  marginTop: "0px",
                  marginLeft: "120px",
                }}
              >
                {data.data.folio}
              </Text>
              <Text
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                Payment Voucher No:
              </Text>
            </div>
            <div>
              <Text
                style={{
                  position: "absolute",
                  marginTop: "0px",
                  marginLeft: "230px",
                  fontSize: "10px",
                  width: "200px",
                }}
              >
                {data.data.Date}
              </Text>
              <Text
                style={{
                  marginTop: "2px",
                  color: "#000",
                  fontSize: "10px",
                  fontWeight: "normal",
                }}
              >
                _________________________________
              </Text>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <Text
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                Date:
              </Text>
            </div>
            <div>
              <Text
                style={{
                  color: "#000",
                  marginTop: "2px",
                  fontSize: "10px",
                  fontWeight: "normal",
                }}
              >
                ______________________________________
              </Text>
            </div>
          </div>

          <Text style={styles.mt30}>Account Name:</Text>
          <Text style={styles.mt10}>{data.data.AccountName}</Text>
          <Text
            style={{
              color: "#000",
              marginTop: "-10px",
              fontSize: "10px",
              fontWeight: "normal",
            }}
          >
            _______________________________________________________________________________________________________
          </Text>

          <Text style={styles.mt30}>Detailed Explanation for Claim:</Text>
          <Text style={styles.mt10}>{data.data.DetailedExplanation}</Text>
          <Text
            style={{
              color: "#000",
              marginTop: "-10px",
              fontSize: "10px",
              fontWeight: "normal",
            }}
          >
            _______________________________________________________________________________________________________
          </Text>
          <div style={{ flexDirection: "row", marginTop: "30px" }}>
            <div>
              <Text
                style={{
                  position: "absolute",
                  marginTop: "0px",
                  marginLeft: "50px",
                }}
              >
                {"$" + data.data.Total}
              </Text>
              <Text
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                Amount:
              </Text>
            </div>
            <div>
              <Text
                style={{
                  position: "absolute",
                  marginTop: "0px",
                  marginLeft: "240px",
                  width: "200px",
                }}
              >
                {"$" + data.data.Amount}
              </Text>
              <Text
                style={{
                  marginTop: "2px",
                  color: "#000",
                  fontSize: "10px",
                  fontWeight: "normal",
                }}
              >
                __________________________________________
              </Text>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <Text
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                Total:
              </Text>
            </div>
            <div>
              <Text
                style={{
                  color: "#000",
                  marginTop: "2px",
                  fontSize: "10px",
                  fontWeight: "normal",
                }}
              >
                __________________________________________
              </Text>
            </div>
          </div>
          <div style={{ flexDirection: "row", marginTop: "40px" }}>
            <Text style={{ fontWeight: "bold" }}>Bank Of America USA:</Text>
            <Text style={{ fontWeight: "normal", marginLeft: "10px" }}>
              {data.data.accountNameBankUSA}
            </Text>
          </div>
          <div style={{ flexDirection: "row", marginTop: "5px" }}>
            <Text style={{ fontWeight: "bold" }}> Account Number:</Text>
            <Text style={{ fontWeight: "normal", marginLeft: "10px" }}>
              {data.data.accountNumberUSA}
            </Text>
          </div>
          <div style={{ flexDirection: "row", marginTop: "5px" }}>
            <Text style={{ fontWeight: "bold" }}>Routing Number:</Text>
            <Text style={{ fontWeight: "normal", marginLeft: "10px" }}>
              {data.data.routingNumberUSA}
            </Text>
          </div>
          <div style={{ flexDirection: "row", marginTop: "5px" }}>
            <Text style={{ fontWeight: "bold" }}>Wires:</Text>
            <Text style={{ fontWeight: "normal", marginLeft: "10px" }}>
              {data.data.Wires}
            </Text>
          </div>
          <div style={{ flexDirection: "row", marginTop: "5px" }}>
            <Text style={{ fontWeight: "bold" }}>Zelle:</Text>
            <Text style={{ fontWeight: "normal", marginLeft: "10px" }}>
              {data.data.Zelle}
            </Text>
          </div>
          <div style={{ flexDirection: "row", marginTop: "5px" }}>
            <Text style={{ fontWeight: "bold" }}>Banamex en Mexico:</Text>
            <Text style={{ fontWeight: "normal", marginLeft: "10px" }}>
              {data.data.nameBankAccountMexico}
            </Text>
          </div>
          <div style={{ flexDirection: "row", marginTop: "5px" }}>
            <Text style={{ fontWeight: "bold" }}>Cuenta Bancaria:</Text>
            <Text style={{ fontWeight: "normal", marginLeft: "10px" }}>
              {data.data.accountBankMexico}
            </Text>
          </div>
          <div style={{ flexDirection: "row", marginTop: "5px" }}>
            <Text style={{ fontWeight: "bold" }}>Cuenta CLABE:</Text>
            <Text style={{ fontWeight: "normal", marginLeft: "10px" }}>
              {data.data.clabeBankMexico}
            </Text>
          </div>
        </div>
      </Page>
    </Document>
  );
};
const styles = {
  title: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: "bold",
  },
  signInformation: {
    display: "flex",
    justifyContent: "space-between !important",
  },
  sign: {
    marginTop: 30,
  },
  lineSign: {
    width: "102%",
    border: "1px solid #000",
  },
  line1: {
    position: "absolute",
    width: "30%",
    marginTop: 52,
    marginLeft: 110,
    border: "1px solid #000",
  },
  line2: {
    position: "absolute",
    width: "24%",
    marginTop: 52,
    marginLeft: 365,
    border: "1px solid #000",
  },
  line4: {
    position: "absolute",
    width: "40%",
    marginTop: 278,
    marginLeft: 46,
    border: "1px solid #000",
  },
  line3: {
    position: "absolute",
    width: "45%",
    marginTop: 278,
    marginLeft: 270,
    border: "1px solid #000",
  },
  line: {
    width: "105%",
    marginTop: 0,
    marginLeft: 0,
    border: "1px solid #000",
  },
  lines: {
    position: "absolute",
    border: "2px solid #2f5496",
    width: "90%",
    height: "90%",
    margin: 30,
  },
  mt100: {
    marginTop: 20,
    fontWeight: "bold",
  },
  mt10: {
    marginTop: 10,
  },

  mt30: {
    marginTop: 40,
    fontWeight: "bold",
  },
  container: {
    marginTop: 50,
    marginRight: 80,
    marginLeft: 80,
  },
  document: {
    fontSize: 11,
    lineHeight: 1.5,
    textAlign: "justify",
    fontFamily: "Roboto",
  },
  header: {
    color: "#2f5496",
    textAlign: "center",
    marginTop: 80,
  },
  black: {
    fontWeight: 700,
  },
  mt50: {
    marginTop: 60,
  },

  mt5: {
    marginTop: 8,
  },
};
export default PDF_Form2;
