import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import DefaultInput from "../../../../components/Elements/DefaultInput";
import DefaultRadioButtons from "../../../../components/Elements/DefaultRadioButtons";
import DefaultCalendar from "../../../../components/Elements/DefaultCalendar";
import DefaulLabel from "../../../../components/Elements/DefaultLabel";
import DefaultSelect from "../../../../components/Elements/DefaultSelect";
import GerUsersFromCoockies from "../backend/GetUserFromCookies";
import api from "../../../../services/api";
import { toast } from "react-toastify";

const DecedentInfo = ({ onDecedentDataChange, folioId, folio}) => {
  const sex = [
    { label: "Female", value: "1", id: "sex" },
    { label: "Male", value: "2", id: "sex" },
    { label: "Not Yet Determined", value: "3", id: "sex" },
  ];
  const us_security_num = [
    { label: "None", value: "1", id: "ssn" },
    { label: "Unknown", value: "2", id: "ssn" },
  ];

  const army_forces = [
    { label: "Yes", value: "1", id: "army_forces" },
    { label: "No", value: "2", id: "army_forces" },
    { label: "Unknown", value: "3", id: "army_forces" },
  ];

  const HRRF = [
    { label: "Yes", value: "1", enableInput: false },
    { label: "No", value: "2", enableInput: false },
  ];

  const initialFormData = {
    secuencia: parseInt(folio),
    folio: folioId,
    usuario_id: GerUsersFromCoockies().id_usuario,

    firstName: "",
    middleName: "",
    lastName: "",
    suffix: "",
    aka: "",
    sex: "", // Puede ser necesario inicializar con un valor predeterminado que no sea null
    usSecurityNumOptions: "", // Puede ser necesario inicializar con un valor predeterminado que no sea null
    usSecurityNumValue: "", // Puede ser necesario inicializar con un valor predeterminado que no sea null
    dateOfDeath: "",
    dateOfBirth: "",
    years: "", // Asegúrate de convertir estos valores a entero si son necesarios
    months: "",
    days: "",
    hours: "",
    minutes: "",
    birthCityOrTown: "",
    birthCounty: "",
    birthState: "",
    birthCountry: "", // Puede ser necesario inicializar con un valor predeterminado que no sea null
    armedForces: "", // Puede ser necesario inicializar con un valor predeterminado que no sea null
    namePriorToMarriage: "",
    hrrf: "", // Puede ser necesario inicializar con un valor predeterminado que no sea null
  };

  const [formData, setFormData] = useState(initialFormData);
  const [countrys, setCountrys] = useState([]);

  useEffect(() => {
    GetCountry();
    get_decedent_info_with_ID(folioId);
  }, []);

  const handleInputChange = (name, value) => {
    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    setFormData(updatedFormData);
    onDecedentDataChange(updatedFormData);
  };

  const handleSubmit = () => {
    add_decedent_Info(formData);
  };

  const add_decedent_Info = async (formData) => {
    try {
      const response = await api.post(
        "documents/form1/DecedentInfoController.php",
        formData,
        { params: { action: "POST" } }
      );
      console.log(response)
      toast.success("Decedent info successfully added", {
        className: "toast-success",
        theme: "colored",
      });
    } catch (error) {
      toast.error("Error adding decedent info", {
        className: "toast-error",
        theme: "colored",
      });

    }
  };

  const get_decedent_info_with_ID = async (folio) => {
    try {
      const response = await api.get(
        `documents/form1/DecedentInfoController.php?id=${folio}`
      );
      console.log(response)
      const decedentInfo = response.data[0];
      if (response.data[0] != null) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          firstName: decedentInfo.name || "",
          middleName: decedentInfo.middle || "",
          lastName: decedentInfo.lastname || "",
          suffix: decedentInfo.suffix || "",
          aka: decedentInfo.aka || "",
          sex: decedentInfo.sex || "",

          usSecurityNumOptions: decedentInfo.usSSNOption || "",
          usSecurityNumValue: decedentInfo.usSSNValue || "",

          dateOfDeath: decedentInfo.dateOfDeath || "",
          dateOfBirth: decedentInfo.dateOfBirth || "",
          years: decedentInfo.yearsAge || "",
          months: decedentInfo.monthsAge || "",
          days: decedentInfo.daysAge || "",
          hours: decedentInfo.hoursAge || "",
          minutes: decedentInfo.minutesAge || "",
          birthCityOrTown: decedentInfo.birthCity || "",
          birthCounty: decedentInfo.birthCounty || "",
          birthState: decedentInfo.birthState || "",
          birthCountry: decedentInfo.CountryNameId || "",
          armedForces: decedentInfo.ArmedForces || "",
          namePriorToMarriage: decedentInfo.nameMirriage || "",
          hrrf: decedentInfo.hrrf || "",
        }));
      }
    } catch (error) {
    }
  };

  const GetCountry = async () => {
    try {
      const response = await api.get(
        "documents/form1/loadDecedentInfoController.php",
        { params: { action: "GET" } }
      );

      const formattedData = response.data.map((item) => ({
        label: item.Country,
        value: item.Country,
        id: "archivecountry" + item.ID,
      }));
      setCountrys(formattedData);
    } catch (error) {
    }
  };
  return (
    <>
        <div className="main-card">
        <DefaultInput numberRow={"1A.-"} placeholder={"DECEDENT'S LEGAL FIRST NAME"} type={"text"} 
        onChange={(value) => handleInputChange("firstName", value)} valueData={formData.firstName}/>

        <DefaultInput numberRow={"1B.-"} placeholder={"DECEDENT'S LEGAL MIDDLE NAME"} type={"text"} 
        onChange={(value) => handleInputChange("middleName", value)} valueData={formData.middleName}/>
        
        <DefaultInput numberRow={"1C.-"} placeholder={"DECEDENT'S LEGAL LAST NAME"} type={"text"} 
        onChange={(value) => handleInputChange("lastName", value)} valueData={formData.lastName}/>

        <DefaultInput numberRow={"1D.-"} placeholder={"SUFFIX (Jr,II,etc)"} type={"text"} 
        onChange={(value) => handleInputChange("suffix", value)} valueData={formData.suffix}/>

        <DefaultInput numberRow={"1E.-"} placeholder={"AKA'S IF ANY"} type={"text"} 
        onChange={(value) => handleInputChange("aka", value)} valueData={formData.aka}/>

        <DefaultRadioButtons numberRow={"2.-"} label={"SEX"} options={sex} 
        onChange={(value) => handleInputChange("sex", value)} valueData={formData.sex}/>

        <DefaultRadioButtons numberRow={"3.-"} label={"U.S. SOCIAL SECURITY NUMBER"} options={us_security_num} 
        onChange={(value) => handleInputChange("usSecurityNumOptions", value)} valueData={formData.usSecurityNumOptions}/>

        <DefaultInput placeholder={"Have"}
        onChange={(value) => handleInputChange("usSecurityNumValue", value)} valueData={formData.usSecurityNumValue}/>




        <DefaultCalendar numberRow={"4.-"} placeholder={"DATE OF DEATH"}  
        onChange={(value) => handleInputChange("dateOfDeath", value)}  valueData={formData.dateOfDeath}/>

        <DefaultCalendar numberRow={"5A.-"} placeholder={"DATE OF BIRTH"} 
        onChange={(value) => handleInputChange("dateOfBirth", value)} valueData={formData.dateOfBirth}/>

        <DefaulLabel numberRow={"5B.-"} placeholder={"AGE IN"}/>
        <DefaultInput placeholder={"Years"} type={"number"} 
        onChange={(value) => handleInputChange("years", value)} valueData={formData.years}/>

        <DefaultInput placeholder={"Months"} type={"number"} 
        onChange={(value) => handleInputChange("months", value)} valueData={formData.months}/>

        <DefaultInput placeholder={"Days"} type={"number"} 
        onChange={(value) => handleInputChange("days", value)} valueData={formData.days}/>

        <DefaultInput placeholder={"Hours"} type={"number"} 
        onChange={(value) => handleInputChange("hours", value)} valueData={formData.hours}/>

        <DefaultInput placeholder={"Minutes"} type={"number"}
        onChange={(value) => handleInputChange("minutes", value)} valueData={formData.minutes}/>

        <DefaultInput numberRow={"6A.-"} placeholder={"DECEDENT'S BIRTH CITY OR TOWN"} type={"text"} 
        onChange={(value) => handleInputChange("birthCityOrTown", value)} valueData={formData.birthCityOrTown}/>
        <DefaultInput numberRow={"6B.-"} placeholder={"DECEDENT'S BIRTH COUNTY"} type={"text"} 
        onChange={(value) => handleInputChange("birthCounty", value)} valueData={formData.birthCounty}/>
        <DefaultInput numberRow={"6C.-"} placeholder={"DECEDENT'S BIRTH STATE"} type={"text"} 
        onChange={(value) => handleInputChange("birthState", value)} valueData={formData.birthState}/>

        <DefaultSelect options={countrys}  numberRow={"6D.-"} label={"DECEDENT'S BIRTH COUNTRY"} defaultValue={"Select..."} 
        onChange={(value) => handleInputChange("birthCountry", value)} valueData={formData.birthCountry}/>

        <DefaultRadioButtons numberRow={"7.-"} label={"EVER IN U.S. ARMED FORCES?"} options={army_forces} 
        onChange={(value) => handleInputChange("armedForces", value)} valueData={formData.armedForces}/>

        <DefaultInput numberRow={"8.-"} placeholder={"DECEDENT’S NAME PRIOR TO FIRST MARRIAGE"} type={"text"} 
        onChange={(value) => handleInputChange("namePriorToMarriage", value)} valueData={formData.namePriorToMarriage}/>

        <DefaultRadioButtons numberRow={"9.-"} label="HRRF (Human Remains Release Form)" options={HRRF} 
        onChange={(value) => handleInputChange("hrrf", value)} valueData={formData.hrrf}/>
    </div>
    <div className="d-flex justify-content-end mt-3">
        <Button onClick={handleSubmit}>SAVE DECEDENT'S INFO</Button>
    </div>
    </>
  );
};

export default DecedentInfo;
