import React, { useState, useEffect } from "react";
import { Col, Row, Button, Modal } from "react-bootstrap";
import axios from "axios";
import PDFicon from "../../../components/icons/PDFicon";
import IMGicon from "../../../components/icons/IMGicon";
import DOCicon from "../../../components/icons/DOCicon";
import FormLayout from "../../../components/FormsLayout";
import GetDocumentView from "../../sign-document/GetDocumentView"
import { IoEllipsisHorizontalCircleOutline } from "react-icons/io5";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { LiaPenFancySolid } from "react-icons/lia";
import { SlPencil } from "react-icons/sl";
import { MdOutlineLink } from "react-icons/md";
import { LuEye } from "react-icons/lu";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdOutlineCloudDownload } from "react-icons/md";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { Link } from "react-router-dom";

const ViewFiles = ({
  folio,
  consecutivo,
  recargarDocumentos,
  recargaDocumentos,
}) => {
  const [files, setFiles] = useState([]);
  const [folioID, setFolioID] = useState([]);
  const [documentosCreados, setDocumentosCreados] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isDeleteFile, setIsDeleteFile] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isShowDocument, setShowDocument] = useState(false);
  const [folioToDocument, setFolioToDocument] = useState(false);
  const [consecutivoToDocument, setConsecutivoToDocument] = useState(false);
  const [numFormToDocument, setNumFormToDocument] = useState(false);
  const [copied, setCopied] = useState(false);

  const formLayouts = {
    1: {
      text: "Death Registration Worksheet",
      url: "death-registration-worksheet",
      numForm:"1",
      haveSign:true
    },
    2: {
      text: "Invoice",
      url: "invoice",
      numForm:"2",
      haveSign:false
    },
    3: {
      text: "Authorization of Release / Embalming",
      url: "authorization-release-embalming",
      numForm:"3",
      haveSign:true
    },
    4: {
      text: "Purchase Receipt",
      url: "purchase-receipt",
      numForm:"4",
      haveSign:false
    },
    5: {
      text: "Notice to Purchaser",
      url: "notice-purchaser",
      numForm:"5",
      haveSign:true
    },
    6: {
      text: "Crematory Authorization",
      url: "crematory-authorization",
      numForm:"6",
      haveSign:true

    },
    7: {
      text: "Embaling Letter",
      url: "authorization-release-embalming",
      numForm:"7",
      haveSign:true

    },
    8: {
      text: "Tucson Cremation Services",
      url: "tucson-cremation-services",
      numForm:"8",
      haveSign:true

    },
  };
  useEffect(() => {
    if (recargarDocumentos) {
      console.log("Recargando documentos...");
      getDocumentosCreados(folio);
    }
  }, [recargarDocumentos]);
  useEffect(() => {
    fetchFiles();
    getDocumentosCreados(folio);
    setFolioID(folio);
  }, []);
  const fetchFiles = async () => {
    try {
      //const folderPath = `../../documents/${folio}/`;
      const response = await api.get(
        `archive/getFiles.php?folio=${folio}`
      );
      if (!response.data.error) {
        const filesData = Object.entries(response.data).map(
          ([index, name]) => ({
            index: index,
            url: `../../../../documents/${folio}/${name}`,
            deleteUrl: `../../documents/${folio}/${name}`,
            name: name,
          })
        );
        setFiles(filesData);
      }
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const handleFileUpload = async (file, folio) => {
    console.log(folio)
    try {
      const formData = new FormData();
      const cleanFileName = file.name
        .replace(/[^\w\s.-]/gi, "")
        .replace(/\s+/g, "_");
      formData.append("file", file, cleanFileName);
      formData.append("folio",folio);
      const response = await api.post(
        `archive/uploadFiles.php`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      console.log(response);
      toast.success("File added successfully", {
        className: "toast-success",
        theme: "colored",
      });
      fetchFiles();
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Error adding file", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };
  const getDocumentosCreados = async (folio) => {
    try {
      const response = await api.get(
        `archive/profile/DocumentosCreadosController.php?id=${folio}`
      );
      setDocumentosCreados(response.data);
      recargaDocumentos(false);
    } catch (error) {
      console.error("Error fetching created documents:", error);
    }
  };
  const toggleMenu = (value) => {
    setMenuOpen(value);
  };
  const dowloadFile = (value) => {
    handleDownload(value);
  };
  const handleDownload = async (file) => {
    try {
      const response = await axios.get(file.url, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.name);
      document.body.appendChild(link);
      link.click();
      toast.success("Downloaded successfully", {
        className: "toast-success",
        theme: "colored",
      });
    } catch (error) {
      console.error("Error al descargar el archivo:", error);
      toast.error("Error downloading file", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };
  const previewFile = (value) => {
    handleShowModal(value);
  };
  const handleShowModal = (file) => {
    setSelectedFile(file);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedFile(null);
    setShowDocument(false);
    setModalArchiveDetail(false);
  };
  const handleDeleteFile = (value) => {
    handleShowModal(value);
    setIsDeleteFile(true);
    setFileToDelete(value);

  };
  const deleteFile = () => {
    handleCloseModal();
    handleFileDelete(fileToDelete);
  };
  const handleFileDelete = async (file) => {
    try {
        const urlFile = file.deleteUrl;
        console.log(urlFile);

        const response = await api.post(
            'archive/deleteFile.php',
            {
                url: urlFile
            }
        );
        console.log(response);
        fetchFiles();
        setIsDeleteFile(false);
        toast.success("File deleted successfully", {
            className: "toast-success",
            theme: "colored",
        });
    } catch (error) {
        toast.error("Error deleting file", {
            className: "toast-error",
            theme: "colored",
        });
        console.error("Error al eliminar el archivo:", error);
    }
};
  const deleteDocument =(folio,documentNum)=>{
    console.log(folio,"delete document", documentNum)
    deleteDocumentApi(folio,documentNum)
  }
  const deleteDocumentApi = async (folio,documentNum) => {
    try {
      const response = await api.delete(
        `archive/profile/DocumentosCreadosController.php?id=${folio}&numForm=${documentNum}`,
        { params: { action: "DELETE" } }
      );
      console.log(response)
      getDocumentosCreados(folio);
    } catch (error) {
      console.error("Error al buscar detalles del archivo:", error);
    }
  };
  const handleViewDocument = (folioID,consecutivo,numForm) => {
    setFolioToDocument(folioID)
    setConsecutivoToDocument(consecutivo)
    setNumFormToDocument(numForm)
    setShowDocument(true);
    handleShowModal();
  };
  const shareLink = (link) => {
    link = "https://www.ramirezfuneralhome.com" + link;
    //link = "http:/localhost:3000"+link;
    navigator.clipboard.writeText(link).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
      toast.success("🔗 Link copied successfully", {
        className: "toast-success",
        theme: "colored",
      });
    }).catch(err => {
      console.error('Error copying text: ', err);
      toast.error("Error retrieving link", {
        className: "toast-error",
        theme: "colored",
      });
    });
  };
  const [archiveDetail,setArchiveDetail] = useState({0:{folio:"",document_name:"",datetime_created:"",nombre_completo:""}});
  const [modalArchiveDetail,setModalArchiveDetail] = useState();
  const GetDetail = (num_form) => {
    console.log(num_form)
    setModalArchiveDetail(true);
    GetArchiveDetail(folio,num_form)
  };
  const GetArchiveDetail = async (folio,num_form) => {
    console.log(folio,num_form)
    try {
      const response = await api.get(
        `archive/profile/ObtenerDetalleArchivo.php?id=${folio}&numForm=${num_form}`,
        { params: { action: "GET" } }
      );
      if(response.data){
        console.log(response.data);
        setArchiveDetail(response.data);
        handleShowModal();
      }

    } catch (error) {
      console.error("Error al buscar detalles del archivo:", error);
    }
    
  };

  const [isDragging, setIsDragging] = useState(false);

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    handleFileUpload(file, folio);
  };
  
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsDragging(true);
    }
  };
  
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsDragging(false);
    }
  };
  return (
    <div
      className={`main-card ${isDragging ? "dragging" : ""}`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      style={{minHeight:"650px"}}
    >
      <Row>
        {documentosCreados.map((doc) => {
          if (doc.isCreate === 1) {
            const layout = formLayouts[doc.num_form];
            return (
              layout && (
                <Col
                  lg="2"
                  xs="3"
                  md="3"
                  className="icons"
                  key={doc.num_form}
                  style={{
                    border: "1px #f0f0f0 solid",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    margin: "10px",
                  }}
                  onMouseLeave={() => toggleMenu(false)}
                >
                  <div className="hover-icon">
                    <a
                      style={{ fontSize: "30px", color: "#4e4e4e" }}
                      onClick={() => toggleMenu(true)}
                    >
                      <IoEllipsisHorizontalCircleOutline />
                    </a>
                    {menuOpen && (
                      <div className="user-menu">
                        <a
                          className="item"
                          onClick={() =>
                            handleViewDocument(
                              folioID,
                              consecutivo,
                              layout.numForm
                            )
                          }
                        >
                          <LuEye style={{ marginRight: "5px" }} /> Preview
                        </a>
                        <a
                          className="item"
                          onClick={() => GetDetail(layout.numForm)}
                        >
                          <IoMdInformationCircleOutline
                            style={{ marginRight: "5px" }}
                          />
                          Detail
                        </a>
                        <Link
                          className="item"
                          to={`/documents/${layout.url}/${folioID}/${consecutivo}`}
                        >
                          <SlPencil style={{ marginRight: "5px" }} /> Edit
                        </Link>
                        {layout.haveSign && (
                          <Link
                            className="item"
                            to={`/sign-document/${folioID}/${consecutivo}/${layout.numForm}`}
                          >
                            <LiaPenFancySolid style={{ marginRight: "5px" }} />{" "}
                            Sign
                          </Link>
                        )}
                        <a
                          className="item"
                          onClick={() =>
                            shareLink(
                              `/sign-document/${folioID}/${consecutivo}/${layout.numForm}`
                            )
                          }
                        >
                          <MdOutlineLink style={{ marginRight: "5px" }} /> Share
                        </a>
                        <div
                          style={{ borderBottom: "2px solid #f0f0f0" }}
                        ></div>
                        <a
                          className="item"
                          onClick={() => deleteDocument(folio, layout.numForm)}
                        >
                          <FaRegTrashAlt style={{ marginRight: "5px" }} />{" "}
                          Delete file
                        </a>
                      </div>
                    )}
                  </div>
                  <FormLayout
                    text={layout.text}
                    folioID={folio}
                    consecutivo={consecutivo}
                    numForm={layout.numForm}
                  />
                </Col>
              )
            );
          }
          return null;
        })}
        {Array.isArray(files) && files.length > 0 && (
          <>
            {files.map((file, index) => (
              <Col
                key={index}
                lg="2"
                xs="3"
                md="3"
                className="icons"
                style={{
                  border: "1px #f0f0f0 solid",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  margin: "10px",
                }}
                onMouseLeave={() => toggleMenu(false)}
              >
                <div className="hover-icon">
                  <a
                    style={{ fontSize: "30px", color: "#4e4e4e" }}
                    onClick={() => toggleMenu(true)}
                  >
                    <IoEllipsisHorizontalCircleOutline />
                  </a>
                  {menuOpen && (
                    <div className="user-menu">
                      <a className="item" onClick={() => previewFile(file)}>
                        <LuEye style={{ marginRight: "5px" }} /> Preview
                      </a>
                      <a className="item" onClick={() => dowloadFile(file)}>
                        <MdOutlineCloudDownload
                          style={{ marginRight: "5px" }}
                        />{" "}
                        Download
                      </a>
                      <div style={{ borderBottom: "2px solid #f0f0f0" }}></div>
                      <a
                        className="item"
                        onClick={() => handleDeleteFile(file)}
                      >
                        <FaRegTrashAlt style={{ marginRight: "5px" }} /> Delete
                        file
                      </a>
                    </div>
                  )}
                </div>
                <div style={{ padding: "10px", margin: "10px" }}>
                  {file.name && file.name.toLowerCase().includes(".pdf") ? (
                    <PDFicon />
                  ) : file.name && file.name.toLowerCase().includes(".doc") ? (
                    <DOCicon />
                  ) : (
                    <IMGicon />
                  )}
                </div>
                <div
                  style={{
                    backgroundColor: "#f0f0f0",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px",
          borderRadius:"0 0 10px 10px"

                  }}
                >
                  <p
                    className="text-center"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100%",
                    }}
                  >
                    {file.name}
                  </p>
                </div>
              </Col>
            ))}
          </>
        )}
      </Row>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        size="xl"
        style={{ height: "100vh" }}
      >
        {modalArchiveDetail && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Document detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <span style={{ fontWeight: "bold" }}>Document name: </span>
              <span >{archiveDetail[0].document_name}</span>
              <br></br>
              <span style={{ fontWeight: "bold"}}>Date of create: </span>
              <span>{archiveDetail[0].datetime_created}</span>
              <br></br>
              <span style={{ fontWeight: "bold" }}>Created by: </span>
              <span>{archiveDetail[0].nombre_completo}</span>
            </Modal.Body>
          </>
        )}
        {isShowDocument && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>{folioToDocument}</Modal.Title>
            </Modal.Header>
            <GetDocumentView
              folio={folioToDocument}
              consecutivo={consecutivoToDocument}
              numForm={numFormToDocument}
            />
          </>
        )}
        {!isDeleteFile ? (
          <>
            <Modal.Body>
              {selectedFile && (
                <>
                  {selectedFile.name &&
                  selectedFile.name.toLowerCase().includes(".pdf") ? (
                    <iframe
                      src={selectedFile.url}
                      title="PDF Document"
                      width={"100%"}
                      height={"800px"}
                    />
                  ) : (
                    <img
                      src={selectedFile.url}
                      alt="Elemento seleccionado"
                      style={{ width: "100%" }}
                    />
                  )}
                </>
              )}
            </Modal.Body>
          </>
        ) : (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete the file?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={deleteFile}>
                Delete
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </div>
  );
};
export default ViewFiles;
