import React, { useEffect, useState } from "react";
import DefaultInput from "../../../../components/Elements/DefaultInput";
import DefaultCalendar from "../../../../components/Elements/DefaultCalendar";
import DefaultRadioButtons from "../../../../components/Elements/DefaultRadioButtons";
import DefaulLabel from "../../../../components/Elements/DefaultLabel"
import { Row, Button } from "react-bootstrap";
import api from "../../../../services/api";
import { toast } from "react-toastify";
const CrematoryTucsonForm = ({
  onAuthorizationRelease,
  folioID,
  consecutivo,
  usuarioId,
}) => {
  const dispositionOptions = [
    { label: "I/We do NOT have actual knowledge of any living person who has a superior right to act as Authorizing Agent(s)", value: "1", id: "disposition" },
    { label: "There is/are another/other person(s) who has/have a superior OR equal right to act as Authorizing Agent, but he/she/they is/are also signing below OR has/have given me written permission to serve as Authorizing Agent.", value: "2", id: "disposition" },
    { label: "There is/are another/other person(s) who has/have a superior OR equal right to act as Authorizing Agent. I have made all reasonable efforts to contactsuch person(s), but have been unable to do so. I have no reason to believe that such person(s) would object to cremation.", value: "3", id: "disposition" },
  ];
  const mechanicDevice = [
    { label: "Yes", value: "1", id: "mechanicDevice" },
    { label: "No", value: "2", id: "mechanicDevice" },
  ];
  const [formData, setFormData] = useState({
    consecutivo: consecutivo,
    folio: folioID,
    usuario_id: usuarioId,

    decreasedName: "",
    dateOfDeath: "",
    funeralEstablishment: "",
    stateRelationship:"",
    knowledgeOption: "",
    otherEqualAuthority: "",
    mechanicalDeviceOption :"",

    printedName_1: "",
    relationship_1: "",
    email_1: "",
    phone_1: "",

    printedName_2: "",
    relationship_2: "",
    email_2: "",
    phone_2: "",

    printedName_3: "",
    relationship_3: "",
    email_3: "",
    phone_3: "",

    funeralHomeRep: "",
    printedName_fh: "",
    date_fh: "",
  });
  const handleInputChange = (name, value) => {
    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    setFormData(updatedFormData);
    onAuthorizationRelease(updatedFormData);
  };

 
  useEffect(() => {
    if (formData.decreasedName) {
      onAuthorizationRelease(formData);
    }
  }, [formData]);
  const get_decedent_info = async (consecutivo) => {
    try {
      const response = await api.get(
        `documents/form1/GetDecreasedInfo.php?id=${consecutivo}`
      );
      console.log(response)
      const haveData = response.data[0];
      if (haveData) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          decreasedName: response.data[0].Name,
          dateOfDeath: response.data[0].DateOfDeath,
        }));
      }
    } catch (error) {
    }
  };
  const get_company_info = async () => {
    try {
      const response = await api.get(
        `company/CompanyController.php`
      );
      const haveData = response.data[0];
      if (haveData) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          funeralEstablishment: response.data[0]['Funeral home name'],
        }));
      }
    } catch (error) {
    }
  };
  useEffect(() => {
    get_crematory_info(folioID);
  }, []);
  const get_crematory_info = async (folioId) => {
    try {
      const response = await api.get(
        `documents/form8/CrematoryTucsonController.php?id=${folioId}`
      );
      console.log(response)
      const haveData = response.data[0];
      if (haveData) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          dateOfDeath: response.data[0].dateOfDeath,
          date_fh: response.data[0].date_fh,
          decreasedName: response.data[0].decreasedName,
          email_1: response.data[0].email_1,
          email_2: response.data[0].email_2,
          email_3: response.data[0].email_3,
          funeralEstablishment: response.data[0].funeralEstablishment,
          funeralHomeRep: response.data[0].funeralHomeRep,
          knowledgeOption: response.data[0].knowledgeOption,
          mechanicalDeviceOption: response.data[0].mechanicalDeviceOption,
          otherEqualAuthority: response.data[0].otherEqualAuthority,
          phone_1: response.data[0].phone_1,
          phone_2: response.data[0].phone_2,
          phone_3: response.data[0].phone_3,
          printedName_1: response.data[0].printedName_1,
          printedName_2: response.data[0].printedName_2,
          printedName_3: response.data[0].printedName_3,
          printedName_fh: response.data[0].printedName_fh,
          relationship_1: response.data[0].relationship_1,
          relationship_2: response.data[0].relationship_2,
          relationship_3: response.data[0].relationship_3,
          stateRelationship: response.data[0].stateRelationship,
        }));
      } else {
        get_decedent_info(consecutivo);
        get_company_info();

      }
    } catch (error) {
    }
  };
  const handleSubmit = () => {
    console.log(formData);
    add_cremation_authorization(formData);
  };

  const add_cremation_authorization = async (formData) => {
    try {
      const response = await api.post(
        "documents/form8/CrematoryTucsonController.php",
        formData,
        { params: { action: "POST" } }
      );
      console.log(response)
      toast.success("Crematory Tucson Form info successfully added", {
        className: "toast-success",
        theme: "colored",
      });
    } catch (error) {
      toast.error("Error adding Crematory Authorization info", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };
  return (
    <>
      <div className="main-card">
        <DefaultInput
          placeholder={"DECEASED NAME"}
          onChange={(value) => handleInputChange("decreasedName", value)}
          valueData={formData.decreasedName}
        />
        <DefaultCalendar
          placeholder={"DATE OF DEATH"}
          onChange={(value) => handleInputChange("dateOfDeath", value)}
          valueData={formData.dateOfDeath}
        />
        <DefaultInput
          placeholder={"FUNERAL ESTABLISHMENT"}
          onChange={(value) => handleInputChange("funeralEstablishment", value)}
          valueData={formData.funeralEstablishment}
        />
        <DefaultInput
          placeholder={"STATE RELATIONSHIP"}
          onChange={(value) => handleInputChange("stateRelationship", value)}
          valueData={formData.stateRelationship}
        />
        <DefaultRadioButtons
          label={"Authority and power to axecute this cremation authorization."}
          options={dispositionOptions}
          onChange={(value) => handleInputChange("knowledgeOption", value)}
          valueData={formData.knowledgeOption}
        />
        <br></br>
        <DefaultInput
          placeholder={"OTHER PERSON OF EQUAL/GREATER AUTHORITY"}
          onChange={(value) => handleInputChange("otherEqualAuthority", value)}
          valueData={formData.otherEqualAuthority}
        />
        <div style={{ borderBottom: "4px solid #d9d9d9" }}></div>
        <DefaultRadioButtons
          label={"I/We certify the remains do [ ] do NOT [ ] contain any type of mechanical device. All Pacemakers Must Be Removed.".toUpperCase()}
          options={mechanicDevice}
          onChange={(value) =>
            handleInputChange("mechanicalDeviceOption", value)
          }
          valueData={formData.mechanicalDeviceOption}
        />
        <br></br>
        <div style={{ borderBottom: "4px solid #d9d9d9" }}></div>
        <DefaulLabel placeholder={"Responsible #1"}></DefaulLabel>
        <DefaultInput
          placeholder={"Printed Name"}
          onChange={(value) => handleInputChange("printedName_1", value)}
          valueData={formData.printedName_1}
        />
        <DefaultInput
          placeholder={"Relationship"}
          onChange={(value) => handleInputChange("relationship_1", value)}
          valueData={formData.relationship_1}
        />
        <DefaultInput
          placeholder={"Email"}
          onChange={(value) => handleInputChange("email_1", value)}
          valueData={formData.email_1}
        />
        <DefaultInput
          placeholder={"Phone"}
          onChange={(value) => handleInputChange("phone_1", value)}
          valueData={formData.phone_1}
        />
        <br></br>
        <div style={{ borderBottom: "4px solid #d9d9d9" }}></div>
        <DefaulLabel placeholder={"Responsible #2"}></DefaulLabel>
        <DefaultInput
          placeholder={"Printed Name"}
          onChange={(value) => handleInputChange("printedName_2", value)}
          valueData={formData.printedName_2}
        />
        <DefaultInput
          placeholder={"Relationship"}
          onChange={(value) => handleInputChange("relationship_2", value)}
          valueData={formData.relationship_2}
        />
        <DefaultInput
          placeholder={"Email"}
          onChange={(value) => handleInputChange("email_2", value)}
          valueData={formData.email_2}
        />
        <DefaultInput
          placeholder={"Phone"}
          onChange={(value) => handleInputChange("phone_2", value)}
          valueData={formData.phone_2}
        />
        <br></br>
        <div style={{ borderBottom: "4px solid #d9d9d9" }}></div>
        <DefaulLabel placeholder={"Responsible #3"}></DefaulLabel>
        <DefaultInput
          placeholder={"Printed Name"}
          onChange={(value) => handleInputChange("printedName_3", value)}
          valueData={formData.printedName_3}
        />
        <DefaultInput
          placeholder={"Relationship"}
          onChange={(value) => handleInputChange("relationship_3", value)}
          valueData={formData.relationship_3}
        />
        <DefaultInput
          placeholder={"Email"}
          onChange={(value) => handleInputChange("email_3", value)}
          valueData={formData.email_3}
        />
        <DefaultInput
          placeholder={"Phone"}
          onChange={(value) => handleInputChange("phone_3", value)}
          valueData={formData.phone_3}
        />
        <br></br>
        <div style={{ borderBottom: "4px solid #d9d9d9" }}></div>
{/*
        <DefaultInput
          placeholder={"Funeral Home Rep"}
          onChange={(value) => handleInputChange("funeralHomeRep", value)}
          valueData={formData.funeralHomeRep}
        />*/}
        <DefaultInput
          placeholder={"Printed Name"}
          onChange={(value) => handleInputChange("printedName_fh", value)}
          valueData={formData.printedName_fh}
        />
        {/*
        <DefaultInput
          placeholder={"Date"}
          onChange={(value) => handleInputChange("date_fh", value)}
          valueData={formData.date_fh}
        />*/}
      </div>
      <Row>
        <div className="d-flex justify-content-end">
          <Button onClick={handleSubmit}>SAVE CREMATORY AUTHORIZATION</Button>
        </div>
      </Row>
    </>
  );
};

export default CrematoryTucsonForm;
