import React, { useState, useEffect, useRef } from "react";

const Comments = ({ data, folioID, userID, deleteFunction }) => {
  const [detailData, setDetailData] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      setDetailData(data);
    } else {
      setDetailData([]);
    }
  }, [data]);

  const commentRef = useRef(null);

  useEffect(() => {
    if (commentRef.current) {
      commentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [detailData]);

  const handleDeleteClick = (comment) => {
    deleteFunction(comment.id_comment);
  };
  if (!detailData || detailData.length === 0) {
    return (<div style={{marginTop:"30px"}}><h4>No comments to show</h4></div>);
  }

  return (
    <>
      <div className="mt-3">
        <h5>Comments</h5>
        {detailData.map((comment, index) => (
          <div key={index} className="mt-3">
            <div className="d-flex justify-content-between">
              <span style={{ fontWeight: "lighter", fontSize: "12px" }}>
                {comment.Name}
              </span>
              <span style={{ fontWeight: "lighter", fontSize: "12px" }}>
                {comment.Date}
              </span>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <p>{comment.Comment}</p>
            </div>
            <div
              style={{
                borderBottom: "1px solid rgb(211, 211, 211)",
                width: "100%",
              }}
              className="mt-3 mb-3"
            ></div>
          </div>
        ))}
      </div>
    </>
  );
};
export default Comments;
