import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const DefaultSelect = ({ options, label, defaultValue, onChange, numberRow, valueData }) => {
  const [selectedOption, setSelectedOption] = useState("");

  // useEffect para seleccionar el valor predeterminado
  useEffect(() => {
    if (valueData) {
      const defaultOption = options.find(option => option.value === valueData);
      if (defaultOption) {
        setSelectedOption(defaultOption.value);
        onChange(defaultOption.value);
      }
    }
  }, [valueData]);

  const handleChange = (event) => {
    const valueData = event.target.value;
    setSelectedOption(valueData);
    onChange(valueData);
  };

  return (
    <Form.Group className="mt-2 mb-2">
      <Form.Label style={{ fontWeight: "700" }}>{numberRow}</Form.Label>
      <Form.Label>{label}</Form.Label>
      <Form.Select aria-label={label} onChange={handleChange} value={selectedOption}>
        <option value="">{defaultValue}</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};

export default DefaultSelect;
