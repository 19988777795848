import React from "react";
import { Form } from "react-bootstrap";

const DefaultRadioButtonsNoLabel = ({
  numberRow,
  options,
  onChange,
  valueData,
}) => {
  const handleChange = (value) => {
    onChange(value);
    console.log(value);
  };

  const generateUniqueId = () => {
    return `${Math.random().toString(36).substr(2, 9)}`;
  };

  return (
    <Form.Group>
      {options.map((option, index) => (
        <div key={index}>
          <Form.Check
            type="radio"
            label={option.label}
            id={generateUniqueId()}
            name={`${numberRow}-radioGroup`} // Asegurar que todos los radio buttons en el mismo grupo tengan el mismo nombre
            checked={parseInt(valueData) === parseInt(option.value)} // Convertir a enteros
            onChange={() => handleChange(option.value)}
          />
        </div>
      ))}
    </Form.Group>
  );
};

export default DefaultRadioButtonsNoLabel;
