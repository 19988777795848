import * as React from "react";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const navigate = useNavigate();
  const Login = () => navigate("/Login");

  return (
    <div>
      <button type="button" onClick={Login}>
        Inicio de sesion.
      </button>

    </div>
  );
};

export default LandingPage;
