import React, { useState, useEffect } from "react";
import { Row, Table, Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../services/api";
import DefaulInput from "../../../components/Elements/DefaultInput";
import DefaultSelect from "../../../components/Elements/DefaultSelect";
import DefaultSwitch from "../../../components/Elements/DefaultSwitch";
import GetUserFormCookies from "../../../components/GetUserFromCookies"
const Users = () => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const defaultUser = {
    id_usuario: 0,
    nombre: "",
    apellido: "",
    contraseña: "",
    usuario: "",
    telefono: "",
    activo: 1,
    rol_name: 0,
  };
  const [item, setItem] = useState(defaultUser);
  const [itemToEdit, setItemEdit] = useState(defaultUser);
  const [savingItem, setSavingItem] = useState(false);
  const [editingItem, setEditingItem] = useState(false);
  const [permisos, setPermisos] = useState([]);

  const title = [
    "User ID",
    "Name",
    "Last name",
    "Username",
    "Phone",
    "DateCreate",
    "Permissions",
  ];

  useEffect(() => {
    getOneUser();
  }, []);


  const getOneUser = async () => {
    try {
      const response = await api.get(
        `settings/users/GetOneUser.php?id=${GetUserFormCookies().id_usuario}`,
        {
          params: { action: "GET" },
        }
      );
      const oneUserInfo = response.data[0];
      if (response.data[0] != null) {
        setItemEdit((itemToEdit) => ({
          ...itemToEdit,
          id_usuario: oneUserInfo.id_usuario,
          nombre: oneUserInfo.nombre,
          apellido: oneUserInfo.apellido,
          usuario: oneUserInfo.usuario,
          contraseña: oneUserInfo.contraseña,
          telefono: oneUserInfo.telefono,
          activo: oneUserInfo.activo,
          permisos: oneUserInfo.permisos,
          rol_name: oneUserInfo.rol_name,
        }));
      }
    } catch (error) {
      toast.error("Error al buscar usuarios", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };
  const EditUser = async () => {
    try {
      const updatedUserData = {
        id_usuario: itemToEdit.id_usuario,
        nombre: itemToEdit.nombre,
        apellido: itemToEdit.apellido,
        usuario: itemToEdit.usuario,
        telefono: itemToEdit.telefono,
        contraseña: itemToEdit.contraseña,
        activo: itemToEdit.activo,
        rol_name: itemToEdit.rol_name,
      };

      const response = await api.put(
        "settings/users/UsersController.php",
        updatedUserData,
        { params: { action: "PUT" } }
      );
      console.log(response);
      toast.success("User updated successfully", {
        className: "toast-success",
        theme: "colored",
      });
      getOneUser()
    } catch (error) {
      toast.error("Error al actualizar usuario", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };
  const saveItem = () => {
    EditUser();
  };

  const handleInputChange = (name, value) => {
    const updatedFormData = {
      ...itemToEdit,
      [name]: value,
    };
    setItemEdit(updatedFormData);
  };

  return (
    <>
    <div className="main-card" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ flex: '1 0 auto' }}>
        <DefaulInput
          placeholder={"Name"}
          onChange={(value) => handleInputChange("nombre", value)}
          valueData={itemToEdit.nombre}
        />
        <DefaulInput
          placeholder={"Last name"}
          onChange={(value) => handleInputChange("apellido", value)}
          valueData={itemToEdit.apellido}
        />
        <DefaulInput
          placeholder={"Username"}
          onChange={(value) => handleInputChange("usuario", value)}
          valueData={itemToEdit.usuario}
        />
        <DefaulInput
          placeholder={"Password"}
          type={"password"}
          onChange={(value) => handleInputChange("contraseña", value)}
          valueData={itemToEdit.contraseña}
        />
        <DefaulInput
          placeholder={"Phone"}
          onChange={(value) => handleInputChange("telefono", value)}
          valueData={itemToEdit.telefono}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 'auto' }}>
        <Button variant="success" onClick={saveItem}>
          Save changes
        </Button>
      </div>
    </div>
  </>
  );
};

export default Users;
