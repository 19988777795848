import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PDF_Form5 from "./PDF_Form5";
import { Button, Modal } from "react-bootstrap";
import getUserDataFromCookies from "../Form1/backend/GetUserFromCookies";
import DecreacedInfo from "./Form5/DeceasedInfo";
import { PDFViewer } from "@react-pdf/renderer";
import PackageSelected from "./Form5/PackageSelected";
import ResponsibleParty from "./Form5/ResponsibleParty";
import { Tabs, Tab, Box, Typography } from '@mui/material';

// Función auxiliar para renderizar los tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const NoticePurchaser = () => {
  const { folioID } = useParams();
  const { consecutivo } = useParams();
  const navigate = useNavigate();
  var userID = getUserDataFromCookies().id_usuario;

  var packagesInfo = {
    folioid: folioID,
    secuencia: consecutivo,
    usuarioID: userID,
    PackageSelected: "",
    basicService: "",
    embalming: "",
    refigeration: "",
    cosmetics: "",
    preservation: "",
    regriferation: "",
    washing: "",
    otherBasic: "",
    viewing: "",
    ceremony: "",
    equimentCeremony: "",
    otherLocation: "",
    memorialService: "",
    gravesideService: "",
    transferFuneralHome: "",
    transferAirport: "",
    hearse: "",
    serviceVehicle: "",
    additionalMileage: "",
    cremation: "",
    packing: "",
    cremainsDelivered: "",
    umPresonalization: "",
    Jewelry: "",
    JewelryCount: "",
    urn: "",
    archival: "",
    accountSetup: "",
    Other: "",
    deathCertificate: " ",
    deathCertificateCount: " ",
    statePermit: " ",
    pimaMedicalExaminers: " ",
    consulateFee: " ",
    apostilleDeathCertificate: " ",
    decentAirTransportation: " ",
    prayerCards: " ",
    prayerCardsCount: " ",
    flowersCrucifixBook: " ",
    casket: " ",
    description: " ",
    otherBurial: " ",
    otherCharges1: " ",
    otherCharges2: " ",
    TotalValue: " ",
    downPaymentAdjustmentsDescription: " ",
    downPaymentAdjustmentsNumber: " ",
    typePayment: " ",
    payment: " ",
    blanceDue: " ",
    Field1Text: " ",
  };

  const [dataPackages, setDataPackages] = useState(packagesInfo);

  const handlePackagesDataChange = (data) => {
    setDataPackages(data);
  };

  const infoResponsableParty = {
    NameOfResponsibleParty_1: " ",
    Relationship_1: " ",
    Address_1: " ",
    Phone_1: " ",
    NameOfResponsibleParty_2: " ",
    Relationship_2: " ",
    Address_2: " ",
    Phone_2: " ",
    signatureOfResposibleParty: " ",
    dateSignatureResposibleParty: " ",
    funeralDirectorSign: " ",
    dateFuneralDirectorSign: " ",
    funeralArrangementCounselor: " ",
    dateFuneralArrangementCounselor: " ",
  };

  const [dataResponsibleParty, setResponsibleParty] = useState(infoResponsableParty);
  const handleResponsibleDataChange = (data) => {
    setResponsibleParty(data);
  };

  const [pdfKey, setPdfKey] = useState(0);

  var nameOfdecreased = {
    folio: folioID,
    secuencia: parseInt(consecutivo),
    usuarioID: userID,
    nameDeceased: "",
    age: "",
    todayDate: "",
    dateOfBirth: "",
    dateOfDeath: "",
    ssn: "",
    placeDeath: "",
    weight: "",
    height: "",
    sex: "",
  };

  const [dataDecreased, setCertifierData] = useState(nameOfdecreased);

  const handleDeceasedDataChange = (data) => {
    setCertifierData(data);
  };

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSignDocument = () => {
    navigate(`/documents/sign-document/${folioID}/5`, { state: { dataDecreased, dataPackages, dataResponsibleParty } });
  };

  return (
    <>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <h2 className="f-cel">{folioID}</h2>
        <h3 className="f-cel">NOTICE TO PURCHASER</h3>
        <div><button className="btn btn-primary" onClick={handleShowModal}>View Document</button></div>
      </div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Decedent Info" />
          <Tab label="Packages Info" />
          <Tab label="Responsible Party" />
        </Tabs>
      </Box>
      <TabPanel value={activeTab} index={0}>
        <DecreacedInfo onDecreacedInfo={handleDeceasedDataChange} secuencia={consecutivo} folioId={folioID} usuarioId={userID}/>
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <PackageSelected onPackagesInfo={handlePackagesDataChange} consecutivo={consecutivo} folioId={folioID} usuarioId={userID}/>
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        <ResponsibleParty onResponsibleData={handleResponsibleDataChange} consecutivo={consecutivo} folioId={folioID} usuarioId={userID}/>
      </TabPanel>
      <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Body>
          <PDFViewer style={{ width: "100%", height: "800px" }}>
            <PDF_Form5 DecreacedInfo={dataDecreased} PackageInfo={dataPackages} ResponsibleInfo={dataResponsibleParty} />
          </PDFViewer>
        </Modal.Body>
        <Modal.Footer>
          {/*<Button variant="primary" onClick={handleSignDocument}>
            Sign Document
          </Button>*/}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NoticePurchaser;
