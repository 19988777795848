import React, { useState, useEffect } from "react";
import { Row, Table } from "react-bootstrap";
import api from "../../../services/api";
import PDFIcon from "../../../components/icons/PDFicon";
import { useNavigate } from "react-router-dom";
const TopTeenLastDocuments = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const formLayouts = {
    1: {
      text: "Death Registration Worksheet",
      url: "death-registration-worksheet",
      numForm: "1",
      haveSign: true,
    },
    2: {
      text: "Invoice",
      url: "invoice",
      numForm: "2",
      haveSign: false,
    },
    3: {
      text: "Authorization of Release / Embalming",
      url: "authorization-release-embalming",
      numForm: "3",
      haveSign: true,
    },
    4: {
      text: "Purchase Receipt",
      url: "purchase-receipt",
      numForm: "4",
      haveSign: false,
    },
    5: {
      text: "Notice to Purchaser",
      url: "notice-purchaser",
      numForm: "5",
      haveSign: true,
    },
    6: {
      text: "Crematory Authorization",
      url: "crematory-authorization",
      numForm: "6",
      haveSign: true,
    },
    7: {
      text: "Embaling Letter",
      url: "authorization-release-embalming",
      numForm: "7",
      haveSign: true,
    },
  };
  useEffect(() => {
    getTopTenDocuments();
  }, []);

  const getTopTenDocuments = async () => {
    try {
      const response = await api.get(`dashboard/TopTeenLastDocuments.php`, {
        params: { action: "GET" },
      });
      const responseData = response.data;
      setData(responseData);
    } catch (error) {
      console.log("Error getting info", error);
    }
  };

  const CreateLinkToEdit = (doc) => {
    console.log(doc);
    switch (doc.num_form) {
      case 1:
        navigate(`/documents/${formLayouts[1].url}/${doc.folio}/${doc.consecutivo}`)
        break;
      case 2:
        navigate(`/documents/${formLayouts[2].url}/${doc.folio}/${doc.consecutivo}`)
        break;
      case 3:
        navigate(`/documents/${formLayouts[3].url}/${doc.folio}/${doc.consecutivo}`)
        break;
      case 4:
        navigate(`/documents/${formLayouts[4].url}/${doc.folio}/${doc.consecutivo}`)
        break;
      case 5:
        navigate(`/documents/${formLayouts[5].url}/${doc.folio}/${doc.consecutivo}`)
        break;
      case 6:
        navigate(`/documents/${formLayouts[6].url}/${doc.folio}/${doc.consecutivo}`)
        break;
      case 7:
        navigate(`/documents/${formLayouts[7].url}/${doc.folio}/${doc.consecutivo}`)
        break;
      case 8:
        navigate(`/documents/${formLayouts[8].url}/${doc.folio}/${doc.consecutivo}`)
        break;
    }
  };
  return (
    <>
      <Row>
        <h5>Quick access to the 10 most recent documents created.</h5>
      </Row>
      <Row style={{ padding: "10px" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Type</th>
              <th>Folio</th>
              <th>Document Name</th>
              <th>Creation Date</th>
            </tr>
          </thead>
          <tbody>
            {data.map((doc) => (
              <tr
                key={doc.documentos_creados}
                onClick={() => CreateLinkToEdit(doc)}
                style={{cursor:"pointer"}}
              >
                <td>
                  <PDFIcon />
                </td>
                <td>{doc.folio}</td>
                <td>{doc.document_name}</td>
                <td>{doc.datetime_created}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </>
  );
};

export default TopTeenLastDocuments;
