import { React, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PDF_Form3 from "./PDF_Form3";
import PDF_Form3_1 from "./PDF_Form3_1";
import { Button, Col, Row, Modal } from "react-bootstrap";
import getUserDataFromCookies from "../Form1/backend/GetUserFromCookies";
import EmbalmingForm from "./Form3/form";
import { PDFViewer } from "@react-pdf/renderer";
const AutorizationEmbaling = () => {
  const { folioID, consecutivo } = useParams();
  const navigate = useNavigate();
  var userID = getUserDataFromCookies().id_usuario;

  var dataEmbalingInfo = {
    userCreatedID: userID,
    folioID: "",
    secuenciaID: "",

    witnessName: "",
    decreasedName: "",
    decreasedNameFormal: "",
    transportTo: "",
  };

  const [showModal, setShowModal] = useState(false);
  const [whatFrom, setWhatForm] = useState(false);
  const [embalingInfo, setEmbalingData] = useState(dataEmbalingInfo);

  const handleCloseModal = () => {
    setShowModal(false);
    setWhatForm(true);
  };

  const handleShowModal = () => {
    setShowModal(true);
    setWhatForm(true);
  };

  const handleShowModalLetter = () => {
    setShowModal(true);
    setWhatForm(false);
  };

  const handleAuthorizationInfo = (data) => {
    setEmbalingData(data);
  };

  const handleSignDocument1 = () => {
    navigate(`/documents/sign-document/${folioID}/3`, {
      state: { embalingInfo },
    });
  };

  const handleSignDocument2 = () => {
    navigate(`/documents/sign-document/${folioID}/4`, {
      state: { embalingInfo },
    });
  };
  return (
    <>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <h2 className="f-cel">{folioID}</h2>
        <h3 className="f-cel">AUTHORIZATION OF RELEASE / EMBALMING</h3>
      </div>
      <Row className="showCel">
        <Button onClick={handleShowModal}>
          View Authorization of Release
        </Button>
      </Row>
      <Row className="mt-1 showCel">
        <Button onClick={handleShowModalLetter}>
          View Embalming letter
        </Button>
      </Row>
      <Row>
        <Col lg="10">
          <EmbalmingForm
            onAuthorizationRelease={handleAuthorizationInfo}
            folio={consecutivo}
            folioId={folioID}
          />
        </Col>
        <Col className="noShow">
          <Row>
            <Button onClick={handleShowModal}>
              View Authorization of Release
            </Button>
          </Row>
          <Row className="mt-3">
            <Button onClick={handleShowModalLetter}>
              View Embalming letter
            </Button>
          </Row>
        </Col>
      </Row>
      <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Body>
          {whatFrom ? (
            <PDFViewer style={{ width: "100%", height: "800px" }}>
              <PDF_Form3 data={embalingInfo} />
            </PDFViewer>
          ) : (
            <PDFViewer style={{ width: "100%", height: "800px" }}>
              <PDF_Form3_1 data={embalingInfo} />
              <PDF_Form3_1 data={embalingInfo} />
            </PDFViewer>
          )}
        </Modal.Body>
        <Modal.Footer>
          {/*whatFrom ? (
            <Button variant="primary" onClick={handleSignDocument1}>
              Sign Document
            </Button>
          ) : (
            <Button variant="primary" onClick={handleSignDocument2}>
              Sign Document
            </Button>
          )*/}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AutorizationEmbaling;