import React,{useState,useEffect} from "react";
import DefaultInput from "../../../../components/Elements/DefaultInput";
import getUserDataFromCookies from "../../Form1/backend/GetUserFromCookies";
import { Row, Button } from "react-bootstrap";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import DefaultCalendar from "../../../../components/Elements/DefaultCalendar"
const EmbalmingForm = ({consecutivo,folio,setPurchaseData}) => {
  const [formData, setFormData] = useState({
    consecutivo: parseInt(consecutivo),
    folio: folio,
    usuario_id: getUserDataFromCookies().id_usuario,
    Date: "",
    AccountNumber: "",
    DetailedClaim: "",
    Payment: "",
    BalanceDue: "",
  });
  var FoundData = null;
  const handleSubmit = () => {
    add_purchaseReceipt(formData);
    setPurchaseData(formData);
  };
  const add_purchaseReceipt = async (formData) => {
    try {
      const response = await api.post(
        "documents/form4/PurchaseReceipt.php",
        formData,
        { params: { action: "POST" } }
      );
      toast.success("Purchase receipt info added successfully", {
        className: "toast-success",
        theme: "colored",
      });
    } catch (error) {
      toast.error("Error to add purchase receipt info", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };
  useEffect(() => {
    get_all_info(folio);
  },[folio]);

  const GetTotalInvoice = async (secuencia) => {
    try {
      const response = await api.get(
        `documents/form5/PackageInfoController.php?id=${secuencia}`
      );
      const invoiceData = response.data[0];
      console.log(response)
      if (response.data[0] != null) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          DetailedClaim: invoiceData.packageSelected,
          Payment: invoiceData.Total,
          BalanceDue: invoiceData.Total,
        }));
      }
    } catch (error) {
      toast.error("Error when searching for company info", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };

  const get_all_info = async (folio) => {
    try {
      const response = await api.get(
        `documents/form4/PurchaseReceipt.php?id=${folio}`
      );
      const purchaseData = response.data[0];
      if (response.data[0] != null) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          Date: purchaseData.dateForm,
          AccountNumber: purchaseData.AccountNumber,
          DetailedClaim: purchaseData.DetailedClaim,
          Payment: purchaseData.Payment,
          BalanceDue: purchaseData.BalanceDue,
        }));
        FoundData = true;
      } else {
        GetTotalInvoice(consecutivo);
      }
    } catch {}
  };
  const handleInputChange = (name, value) => {
    const updatedFormData = {
      ...formData,
      [name]: value.toUpperCase(),
    };

    setFormData(updatedFormData);
  };
  useEffect(() => {
    if (Object.keys(formData).some(key => formData[key] !== "")) {
      setPurchaseData(formData);
    }
  }, [formData, setPurchaseData]);
  return (
    <>
      <div className="main-card">
        <DefaultCalendar
         placeholder={"Date"}
         onChange={(value) => handleInputChange("Date", value)}
         valueData={formData.Date}
        />
        <DefaultInput
          placeholder={"Account Name"}
          onChange={(value) => handleInputChange("AccountNumber", value)}
          valueData={formData.AccountNumber}
        />
        <DefaultInput
          placeholder={"Detailed Explanation for Claim"}
          onChange={(value) => handleInputChange("DetailedClaim", value)}
          valueData={formData.DetailedClaim}
        />
        <DefaultInput
          placeholder={"Payment"}
          onChange={(value) => handleInputChange("Payment", value)}
          valueData={formData.Payment}
          type={"number"}
          type2={"money"}
        />
        <DefaultInput
          placeholder={"Balance Due"}
          onChange={(value) => handleInputChange("BalanceDue", value)}
          valueData={formData.BalanceDue}
          type={"number"}
          type2={"money"}
        />
      </div>
      <Row>
        <div className="d-flex justify-content-end">
          <Button onClick={handleSubmit}>SAVE AUTORIZATION OF RELEASE</Button>
        </div>
      </Row>
    </>
  );
};

export default EmbalmingForm;
