import React, { useState, useEffect } from "react";
import { Col, Row, Table, Button, Form, Modal } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import { SlPencil } from "react-icons/sl";
import { SketchPicker } from 'react-color';

function CustomTable({ data, perPage, currentPage, onPageChange, onDelete, onEdit, onAdd,haveControl }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [deletingItemId, setDeletingItemId] = useState(null);
  const [newUserData, setNewUserData] = useState({});

  const totalPages = Math.ceil(data && data.length ? data.length / perPage : 0);

  useEffect(() => {
    if (editingItem !== null) {
      // Si estamos editando un elemento, insertar su información en los inputs
      setNewUserData(editingItem);
    } else {
      // Si no estamos editando, limpiar los inputs
      setNewUserData({});
    }
  }, [editingItem]);

  const goToPage = (page) => {
    onPageChange(page);
  };

  const filteredData = data
    ? data.filter((item) =>
        Object.values(item).some((value) =>
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
    : [];

  const editItem = (item) => {
    setEditingItem(item);
    setShowModal(true);
  };

  const deleteItem = (itemId) => {
    setDeletingItemId(itemId);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setDeletingItemId(null);
    setNewUserData({});
    setEditingItem(null); // Limpiar el elemento en edición
  };

  const handleSaveChanges = () => {
    if (deletingItemId !== null) {
      console.log("Delete:", deletingItemId);
      onDelete(deletingItemId); // Llamar a la función onDelete con el ID del elemento a eliminar
    } else if (editingItem) {
      console.log("Save changes:", editingItem);
      onEdit(newUserData); // Enviar los datos editados al componente principal
    } else {
      console.log("Add item:", newUserData);
      onAdd(newUserData); // Enviar los datos del nuevo usuario al componente principal
    }
    setShowModal(false);
    setDeletingItemId(null);
    setNewUserData({});
  };

  const generateAddUserForm = () => {
    const keys = data && data.length ? Object.keys(data[0]) : [];
    return keys.map((key) => {
      if (key !== "ID") {
        if (key.toLowerCase().includes("color")) { // Si la clave contiene la palabra "color"
          return (
            <Form.Group key={key} controlId={key}>
              <Form.Label>{key}</Form.Label>
              <div style={{ backgroundColor: newUserData[key] || '#ffffff', width: '40px', height: '40px', border: '1px solid #ced4da' }}></div>
              <SketchPicker
                color={newUserData[key] || "#ffffff"} // Valor inicial del color, blanco por defecto
                onChange={(color) => {
                  setNewUserData((prevData) => ({
                    ...prevData,
                    [key]: color.hex // Actualizar el estado con el color en formato hexadecimal
                  }));
                }}
              />
            </Form.Group>
          );
        } else {
          return (
            <Form.Group key={key} controlId={key}>
              <Form.Label>{key}</Form.Label>
              <Form.Control
                type="text"
                value={newUserData[key] || ""}
                onChange={(e) => {
                  setNewUserData((prevData) => ({
                    ...prevData,
                    [key]: e.target.value
                  }));
                }}
              />
            </Form.Group>
          );
        }
      }
      return null;
    });
  };

  return (
    <div>
      <Row>
        <Col className="d-flex">
          <Button className="ms-auto" onClick={() => {
            setEditingItem(null); // Limpiar el elemento en edición
            setShowModal(true);
          }}>+ Add</Button>
        </Col>
      </Row>
      <Col sm="6" className="mb-2">
        <Form.Group controlId="search">
          <Form.Control
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Form.Group>
      </Col>

      <Table striped bordered hover>
        <thead>
          <tr>
            {data &&
              data.length > 0 &&
              Object.keys(data[0])
                .slice(1)
                .map((key, index) => <th key={index}>{key}</th>)}
            <th>Control</th>
          </tr>
        </thead>
        <tbody>
          {filteredData
            .slice((currentPage - 1) * perPage, currentPage * perPage)
            .map((item, index) => {
              const itemIndex = index + 1 + (currentPage - 1) * perPage;
              return (
                <tr key={index}>
                  {Object.keys(item)
                    .slice(1)
                    .map((key, index) => (
                      <td key={index}>
                        {key.toLowerCase().includes("color") ? (
                          <div style={{ backgroundColor: item[key], width: '40px', height: '40px', border: '1px solid #ced4da' }}></div>
                        ) : (
                          item[key]
                        )}
                      </td>
                    ))}
                  <td>
                    <Button
                      variant="info"
                      onClick={() => editItem(item)}
                      className="table_button"
                    >
                      <SlPencil color="#fff" />
                    </Button>
                    {/*<Button
                      variant="danger"
                      onClick={() => deleteItem(item["ID"])}
                      className="table_button"
                    >
                      <FaIcons.FaRegTrashAlt />
                    </Button>*/}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <div className="pagination">
        <Button
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        {Array.from({ length: totalPages }, (_, i) => (
          <Button
            key={i}
            onClick={() => goToPage(i + 1)}
            disabled={currentPage === i + 1}
          >
            {i + 1}
          </Button>
        ))}
        <Button
          onClick={() => goToPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {deletingItemId !== null
              ? "Confirm"
              : editingItem
              ? "Edit item"
              : "Add item"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deletingItemId !== null ? (
            <p>Are you sure you want to delete this item?</p>
          ) : editingItem ? (
            generateAddUserForm()
          ) : (
            generateAddUserForm()
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            variant={deletingItemId !== null ? "light" : "primary"}
            onClick={handleSaveChanges}
          >
            {deletingItemId !== null
              ? "Delete"
              : editingItem
              ? "Save changes"
              : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CustomTable;
