import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import Cookies from "js-cookie";
import { SidebarItem } from "./SidebarItem";
import GetUserFromCookies from "../../components/GetUserFromCookies";
import "../../styles/styles.css"
import { IoMdCloseCircle } from "react-icons/io";
const Sidebar = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null);
  const userData = GetUserFromCookies();
  const [rolID, setRolID] = useState(0);

  useEffect(() => {
    if (userData) {
      setRolID(userData.rol_id);
    }
  }, [userData]);

  const handleItemClick = (href) => {
    if (href === "/login") {
      Cookies.remove("userData");
    }
    navigate(href);
  };

  const handleSubMenuClick = (index) => {
    setOpenSubMenuIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  // Filtra los elementos del sidebar según el rol del usuario
  const filteredSidebarItems = SidebarItem.filter((menuItem) => menuItem.rol >= rolID);

  return (
    <div className={isOpen ? "sidebar open" : "sidebar"}>
      <div style={{ width: "100%", paddingLeft: "20px", paddingRight: "20px", marginBottom: "20px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <img src="/img/logo/logo.png" style={{ width: "100%" }} alt="Logo" />
        <button id="closeButton" onClick={toggleSidebar} style={{ background: "none", border: "none", cursor: "pointer" }}>
          <IoMdCloseCircle color="#afafaf" fontSize={"32px"}/>
        </button>
      </div>
      <ul className="menu">
        {filteredSidebarItems.map((menuItem, index) => (
          <li
            key={index}
            className={menuItem.href === location.pathname ? "active" : ""}
          >
            <div
              className="side-nav-item"
              onClick={() => {
                if (!menuItem.subNav) {
                  handleItemClick(menuItem.href);
                  toggleSidebar(); 
                }
              }}
            >
              <div className="side-item-container" onClick={() => handleSubMenuClick(index)}>
                <span>{menuItem.icon}</span>
                <span>{menuItem.title}</span>
                {menuItem.subNav && (
                  <span>
                    {openSubMenuIndex === index ? (
                      <RiArrowUpSFill />
                    ) : (
                      <RiArrowDownSFill />
                    )}
                  </span>
                )}
              </div>
            </div>
            {menuItem.subNav && (
              <ul
                className={`submenu ${
                  openSubMenuIndex === index ? "open" : ""
                }`}
              >
                {menuItem.subNav
                  .filter((subNavItem) => subNavItem.rol >= rolID)
                  .map((subNavItem, subIndex) => (
                    <div
                      className={
                        subNavItem.href === location.pathname
                          ? "submenu-button active"
                          : "submenu-button"
                      }
                      key={subIndex}
                    >
                      <li onClick={() => handleItemClick(subNavItem.href)}>
                        <div>{subNavItem.title}</div>
                      </li>
                    </div>
                  ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
