import { React, useState, useEffect } from "react";
import { Document, Page, Image, Text, Font } from "@react-pdf/renderer";
import RobotoRegular from "../../../styles/fonts/Roboto-Regular.ttf";
import RobotoBold from "../../../styles/fonts/Roboto-Bold.ttf";
import getUserDataFromCookies from "../Form1/backend/GetUserFromCookies";
import api from "../../../services/api";
Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoRegular, fontWeight: "normal" },
    { src: RobotoBold, fontWeight: "bold" },
  ],
});

const PDF_Form3_1 = ({ data }) => {
  console.log(data);
  const folioID = data.folio;
  const userID = getUserDataFromCookies().id_usuario;

  const dataSignatureInfo = {
    usuario_id: userID,
    folio: folioID,
    num_form: "",
    documento: "",
    nameSign: "",
  };

  const [signatureInfo, setSignatureData] = useState(dataSignatureInfo);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  };

  useEffect(() => {
    const fetchSignatures = async () => {
      try {
        const response = await api.get(
          `sign/SignController.php?folio=${folioID}&num_form=7`
        );
        const data = response.data;
        console.log(data)
        setSignatureData(data);
      } catch (error) {
        console.error("Error fetching signatures info:", error);
        setSignatureData(prevState => ({ ...prevState, signatures: [] }));
      }
    };

    fetchSignatures();
  }, [folioID]);

  const renderSignature = () => {
    if (signatureInfo && Array.isArray(signatureInfo)) {
      for (let i = 0; i < signatureInfo.length; i++) {
        const signature = signatureInfo[i];
        if (signature.nameSign === "Marison Ramirez") {
          return (
            <div style={styles.flex}>
              <Image src={`data:image/png;base64,${signature.documento}`} style={styles.signature} />
            </div>
          );
        }
      }
    }
    return null;
  };

  return (
    <Document style={styles.document}>
      <Page size="Letter">
        <div style={styles.lines}></div>
        <div
          style={{
            flexDirection: "row",
            marginLeft: "40px",
            marginTop: "40px",
          }}
        >
          <Image
            source={"/img/logo/logo.png"}
            style={{ width: " 120px ", height: "120px" }}
          ></Image>
          <div
            style={{
              textAlign: "center",
              color: "#2f5496",
              flex: "1",
              alignItems: "center",
              marginTop: "30px",
              marginLeft: "-100px",
            }}
          >
            <Text>Ramirez Funeral Home LLC</Text>
            <Text>4595 S Palo Verde Rd. Suite 503, Tucson Arizona 85714</Text>
            <Text>Email: ramirezfuneralhome@gmail.com</Text>
            <Text>Phone: (520) 372-2479 Fax: (520) 842-3889</Text>
          </div>
        </div>
        <div style={styles.container}>
          <Text style={styles.title}>EMBALMING LETTER</Text>
          <Text style={styles.mt50}>February 14, 2024</Text>
          <Text style={styles.mt30}>To Whom It May Concern:</Text>
          <Text style={styles.mt10}>
            This is a letter to certify that{" "}
            <Text style={{ fontFamily: "Roboto", fontWeight: "bold" }}>
              {data["decreasedNameFormal"]}
            </Text>
            , did not die of contagious or infectious disease.{" "}
            <Text style={{ fontFamily: "Roboto", fontWeight: "bold" }}>
              {data["decreasedName"]}
            </Text>
            has been embalmed according to the ordinances and statutes of the
            State of Arizona regulations. Will be transported to{" "}
            <Text style={{ fontFamily: "Roboto", fontWeight: "bold" }}>
              {data["transportTo"]}
            </Text>
            for burial.
          </Text>
          <Text style={styles.mt50}>Sincerely:</Text>
          {renderSignature()}
          <Text style={styles.mt50}>Marison Ramirez B-0922</Text>
        </div>
      </Page>
    </Document>
  );
};

const styles = {
  title: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: "bold",
  },

  mt100: {
    marginTop: 10,
  },
  container: {
    marginTop: 50,
    marginRight: 80,
    marginLeft: 80,
  },
  document: {
    fontSize: 11,
    lineHeight: 1.5,
    textAlign: "justify",
    fontFamily: "Roboto",
  },
  lines: {
    position: "absolute",
    border: "2px solid #2f5496",
    width: "90%",
    height: "90%",
    margin: 30,
  },
  header: {
    color: "#5070a7",
    textAlign: "center",
    marginTop: 80,
  },

  mt50: {
    marginTop: 60,
  },
  mt30: {
    marginTop: 30,
  },
  mt5: {
    marginTop: 8,
  },
  signature: {
    position: "absolute",
    width: "80px",
    height: "50px",
    top: "0px"
  },
};

export default PDF_Form3_1;
