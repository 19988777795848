import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import getUserDataFromCookies from "../../pages/documents/Form1/backend/GetUserFromCookies";
import { PDFViewer } from "@react-pdf/renderer";
import PDF_Form1 from "./Form1/PDF_Form1";
import PDF_Form3 from "./Form3-Embalming/PDF_Form3";
import PDF_Form4 from "./Form3-Embalming/PDF_Form3_1";
import PDF_Form5 from "./Form5-NoticePurchaser/PDF_Form5";
import PDF_Form6 from "./Form6-CrematoryAuthorization/PDF_Form6";
import api from "../../services/api";
import SignDocumentModalBox from "../../components/ComponentSign/SignDocumentModalBox";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignDocument = () => {
  const userID = getUserDataFromCookies().id_usuario;
  const { folioID, documentNumber } = useParams();
  const location = useLocation();
  const sigCanvas = useRef({});
  const { embalingInfo, decedentData, recidenceData, spouseData, fatherData, motherData, informantData, funeralData, occupationData, certifierData,
    dataDecreased, dataPackages, dataResponsibleParty, cremationInfo
  } = location.state || {};

  const [signatures, setSignatures] = useState([]);

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const saveSignature = () => {
    const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
  };

  useEffect(() => {
    const fetchSignatures = async () => {
      try {
        const response = await api.get(`sign/SignController.php?folio=${folioID}&num_form=${documentNumber}`);
        const data = response.data;
        setSignatures(data);
      } catch (error) {
        console.error("Error fetching signatures info:", error);
        setSignatures([]);
      }
    };

    fetchSignatures();
  }, [folioID, documentNumber]);

  const getPDFComponent = () => {
    switch (documentNumber) {
      case '1':
        return (
          <PDF_Form1
            data={decedentData}
            dataResidence={recidenceData}
            spouseData={spouseData}
            fatherData={fatherData}
            motherData={motherData}
            informantData={informantData}
            funeralData={funeralData}
            occupationData={occupationData}
            certifierData={certifierData}
          />
        );
      case '3':
        return <PDF_Form3 data={embalingInfo} />;
      case '4':
        return <PDF_Form4 data={embalingInfo} />;
      case '5':
        return <PDF_Form5 DecreacedInfo={dataDecreased} PackageInfo={dataPackages} ResponsibleInfo={dataResponsibleParty} />
      case '6':
        return <PDF_Form6 data={cremationInfo} />
      default:
        return <div>Documento no reconocido</div>;
    }
  };

  const updateSignature = (newSignature) => {
    setSignatures(newSignature);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <h2>Sign Document</h2>
          <p>Folio ID: {folioID}</p>
          <p>Document Number: {documentNumber}</p>
          <SignDocumentModalBox
            folioID={folioID}
            documentNumber={documentNumber}
            userID={userID}
            onSignatureComplete={(signature, type) => {
              toast.success("Firma guardada exitosamente", {
                className: "toast-success",
                theme: "colored",
              });
            }}
          />
        </div>
        <div className="col-12">
          <PDFViewer style={{ width: "100%", height: "100vh" }}>
            {getPDFComponent()}
          </PDFViewer>
        </div>
      </div>
    </div>
  );
};

export default SignDocument;
