import React, { useState, useEffect } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import getUserDataFromCookies from "../documents/Form1/backend/GetUserFromCookies";
import {
  GetDecedentInfo,
  GetCertifierInfo,
  GetDecedentOcupationInfo,
  GetFatherInfo,
  GetFuneralInfo,
  GetInformantInfo,
  GetMotherInfo,
  GetRecindenceInfo,
  GetSpouceInfo
} from "./GetInfoDocuments/form1-death-worksheet/GetInfo_Form1";
import GetInfoForm2 from "./GetInfoDocuments/form2-invoice/GetInfo_Form2";
import GetInfoForm3 from "./GetInfoDocuments/form3-embalming/GetInfo_Form3";
import GetInfoForm4 from "./GetInfoDocuments/form4-purchase-receipt/GetInfo_Form4";
import GetInfoForm8 from "./GetInfoDocuments/form8-tucson-authorization/GetInfo_Form8"
// FORM 5
import GetInfo_Form5_DeceasedInfo from "./GetInfoDocuments/form5-notice-purchaser/GetInfo_Form5-DeceasedInfo";
import GetInfo_Form5_PackageSelected from "./GetInfoDocuments/form5-notice-purchaser/GetInfo_Form5-PackageSelected";
import GetInfo_Form5_Responsible from "./GetInfoDocuments/form5-notice-purchaser/GetInfo_Form5-Responsible";
import GetInfoForm6 from "./GetInfoDocuments/form6-crematory/GetInfo_Form6";
import PDF_Form1 from "../documents/Form1/PDF_Form1";
import PDF_Form2 from "../documents/Form2-Invoice/PDF_Form2";
import PDF_Form3 from "../documents/Form3-Embalming/PDF_Form3";
import PDF_Form4 from "../documents/Form4-PurchaseReceipt/PDF_Form4";
import PDF_Form5 from "../documents/Form5-NoticePurchaser/PDF_Form5";
import PDF_Form6 from "../documents/Form6-CrematoryAuthorization/PDF_Form6";
import PDF_Form3_1 from "../documents/Form3-Embalming/PDF_Form3_1";
import PDF_Form8 from "../documents/Form8-CrematoryTucson/PDF_Form8"

const GetDocumentView = ({ folio, consecutivo, numForm }) => {
  const [usuarioID, setUsuarioID] = useState(null);
  const [numDocument, setNumDocument] = useState(null);
  const [info, setInfo] = useState(null);
  const [form5_Responsible, setForm5_Responsible] = useState(null);
  const [form5_PackageSelected, setForm5_PackageSelected] = useState(null);
  const [form5_DeceasedInfo, setForm5_DeceasedInfo] = useState(null);
  const [certifierInfo, setCertifierInfo] = useState(null);
  const [decedentInfo, setDecedent] = useState(null);
  const [decedentOcupation, setDecedentOcupation] = useState(null);
  const [fatherInfo, setFatherInfo] = useState(null);
  const [funeralInfo, setFuneralInfo] = useState(null);
  const [informantInfo, setInformantInfo] = useState(null);
  const [recidenceInfo, setRecidenceInfo] = useState(null);
  const [spouceInfo, setSpouceInfo] = useState(null);
  const [motherInfo, setMotherInfo] = useState(null);

  useEffect(() => {
    setUsuarioID(getUserDataFromCookies().id_usuario);
    switch (numForm) {
      case "1":
        fetchDataForm1();
        break;
      case "2":
        fetchData(GetInfoForm2, 2);
        break;
      case "3":
        fetchDataSecuencia(GetInfoForm3, 3);
        break;
      case "4":
        fetchData(GetInfoForm4, 4);
        break;
      case "5":
        fetchDataForm5();
        break;
      case "6":
        fetchData(GetInfoForm6, 6);
        break;
      case "7":
        fetchDataSecuencia(GetInfoForm3, 7);
      case "8":
        fetchData(GetInfoForm8, 8);
      break;
    }
  }, [numForm]);

  const fetchDataForm1 = async () => {
    try {
      const results = await Promise.all([
        GetCertifierInfo({ folio }),
        GetDecedentInfo({ folio }),
        GetDecedentOcupationInfo({ folio,consecutivo }),
        GetFatherInfo({ folio }),
        GetFuneralInfo({ folio }),
        GetInformantInfo({ folio }),
        GetRecindenceInfo({ folio }),
        GetSpouceInfo({ folio }),
        GetMotherInfo({ folio })
      ]);

      const [
        certifierResult,
        decedentResult,
        occupationResult,
        fatherResult,
        funeralResult,
        informantResult,
        residenceResult,
        spouseResult,
        motherResult
      ] = results;
      if (results.every(result => result !== null && result !== undefined)) {
        setCertifierInfo(certifierResult);
        setDecedent(decedentResult);
        setDecedentOcupation(occupationResult);
        setFatherInfo(fatherResult);
        setFuneralInfo(funeralResult);
        setInformantInfo(informantResult);
        setRecidenceInfo(residenceResult);
        setSpouceInfo(spouseResult);
        setMotherInfo(motherResult);
        setNumDocument(1);
      } else {
        console.error("Uno o más fetch no devolvieron resultados válidos");
      }
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
  };

  const fetchData = async (formFetcher, docNumber) => {
    try {
      const result = await formFetcher({ folio });
      
      if (result && Object.keys(result).length > 0) {
        setNumDocument(docNumber);
        setInfo(result);
      } else {
        console.error("No se obtuvo un resultado válido del fetch");
      }
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }    
  };

  const fetchDataSecuencia = async (formFetcher, docNumber) => {
    try {
      const result = await formFetcher({ consecutivo });
      if (result) {
        setNumDocument(docNumber);
        setInfo(result);
      } else {
        console.error("No se obtuvo un resultado válido del fetch");
      }
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
  };

  const fetchDataForm5 = async () => {
    try {
      const DeceasedInfo = await GetInfo_Form5_DeceasedInfo({ consecutivo });
      const PackageSelected = await GetInfo_Form5_PackageSelected({ consecutivo });
      const ResponsibleParty = await GetInfo_Form5_Responsible({ folio });

      if (DeceasedInfo && PackageSelected && ResponsibleParty) {
        setForm5_DeceasedInfo(DeceasedInfo);
        setForm5_PackageSelected(PackageSelected);
        setForm5_Responsible(ResponsibleParty);
        setNumDocument(5);
      } else {
        console.error("Uno o más fetch no devolvieron resultados válidos");
      }
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
  };

  return (
    <PDFViewer style={{ width: "100%", height: "800px" }}>
      {numDocument === 1 && (
        <PDF_Form1 
          certifierData={certifierInfo} 
          data={decedentInfo} 
          dataResidence={recidenceInfo} 
          fatherData={fatherInfo}
          funeralData={funeralInfo}
          informantData={informantInfo}
          motherData={motherInfo}
          occupationData={decedentOcupation}
          spouseData={spouceInfo}
          folio={folio}
          usuarioID={usuarioID}
        />
      )}
      {numDocument === 2 && <PDF_Form2 data={info} />}
      {numDocument === 3 && <PDF_Form3 data={info} />}
      {numDocument === 4 && <PDF_Form4 data={info} />}
      {numDocument === 5 && (
        <PDF_Form5
          DecreacedInfo={form5_DeceasedInfo}
          PackageInfo={form5_PackageSelected}
          ResponsibleInfo={form5_Responsible}
        />
      )}
      {numDocument === 6 && <PDF_Form6 data={info} />}
      {numDocument === 7 && <PDF_Form3_1 data={info}/>}
      {numDocument === 8 && <PDF_Form8 data={info}/>}
    </PDFViewer>
  );
};

export default GetDocumentView;
