import api from "../../../../services/api";

const GetInfoForm6 = async ({ folio }) => {
  try {
    const response = await api.get(
      `documents/form6/CrematoryAuthorizationController.php?id=${folio}`
    );
    const haveData = response.data[0];

    return {
      folio: haveData?.folio || "",
      consecutivo: haveData?.secuencia || "",
      decreasedName: haveData?.decreasedName || "",
      dateOfDeath: haveData?.dateOfDeath || "",
      placeOfDeath: haveData?.placeOfDeath || "",
      sex: haveData?.sex || "",
      age: haveData?.age || "",
      haveInfectiuos: haveData?.haveInfectiuos || "",
      ifYesIndentify: haveData?.ifYesIndentify || "",
      prothesisOrRadoictive: haveData?.prothesisOrRadoictive || "",
      descritionProthesis: haveData?.descritionProthesis || "",
      secondaryDescriptionProthesis: haveData?.secondaryDescriptionProthesis || "",
      finalDispositionOneMark: haveData?.finalDispositionOneMark || "",
      finalDispositionOneDate: haveData?.finalDispositionOneDate || "",
      finalDispositionTwoMark: haveData?.finalDispositionTwoMark || "",
      finalDispositionTwoName: haveData?.finalDispositionTwoName || "",
      livingNextDeceases: haveData?.livingNextDeceases || "",
      capacityOf: haveData?.capacityOf || "",
      printedName1: haveData?.printedName1 || "",
      relationship1: haveData?.relationship1 || "",
      phone1: haveData?.phone1 || "",
      address1: haveData?.address1 || "",
      printedName2: haveData?.printedName2 || "",
      relationship2: haveData?.relationship2 || "",
      phone2: haveData?.phone2 || "",
      address2: haveData?.address2 || "",
    };
  } catch (error) {
    console.log("Error get info", error);
    return {
      folio: "",
      consecutivo: "",
      decreasedName: "",
      dateOfDeath: "",
      placeOfDeath: "",
      sex: "",
      age: "",
      haveInfectiuos: "",
      ifYesIndentify: "",
      prothesisOrRadoictive: "",
      descritionProthesis: "",
      secondaryDescriptionProthesis: "",
      finalDispositionOneMark: "",
      finalDispositionOneDate: "",
      finalDispositionTwoMark: "",
      finalDispositionTwoName: "",
      livingNextDeceases: "",
      capacityOf: "",
      printedName1: "",
      relationship1: "",
      phone1: "",
      address1: "",
      printedName2: "",
      relationship2: "",
      phone2: "",
      address2: "",
    };
  }
};

export default GetInfoForm6;
