import Cookies from "js-cookie";

const getUserDataFromCookies = () => {
    const userDataString = Cookies.get("userData");
    if (userDataString) {
      try {
        const userData = JSON.parse(userDataString);
        return userData;
      } catch (error) {
        console.error("Error parsing user data:", error);
        return null;
      }
    } else {
      return null;
    }
  };
  export default getUserDataFromCookies;