import * as React from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { Row, Col } from "react-bootstrap";
import AllCountrysPieChart from "./components/AllCountrysPieChart";
import TopTeenLastDocuments from "./components/TopTeenLastDocuments";
import AllCreatedData from "./components/AllCreatedData";
const Dashboard = () => {
  return (
    <>
      <Row>
        <Col>
          <div className="main-card">
            <AllCountrysPieChart />
          </div>
        </Col>
        <Col>
          
            <Row>
            <AllCreatedData/>
            </Row>

          <div className="main-card">
            <TopTeenLastDocuments />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
