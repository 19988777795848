import React,{useState,useEffect} from "react";
import DefaultInput from "../../../../components/Elements/DefaultInput";
import DefaultInputNoLabel from "../../../../components/Elements/DefaultInputNoLabel";
import DefaultRadioButtons from "../../../../components/Elements/DefaultRadioButtons"
import DefaulLabel from "../../../../components/Elements/DefaultLabel";
import { Col, Row, Button } from "react-bootstrap";
import DefaultCountBtn from "../../../../components/Elements/DefaultCountBtn";
import api from "../../../../services/api";
import { toast } from "react-toastify";
const PackageSelected = ({onPackagesInfo,folioId,consecutivo,usuarioId}) => {
  const [BalanceDueFinal, setBalanceDue] = useState(0);
  const [TotalFinal, setTotal] = useState(0);

  const [formData, setFormData] = useState({
    folioid : folioId,
    consecutivo :parseInt(consecutivo),
    usuarioID : usuarioId,
    PackageSelected:"",
    basicService:"",
    embalming:"",
    refigeration:"",
    cosmetics:"",
    preservation:"",
    regriferation:"",
    washing:"",
    otherBasic:"",
    viewing:"",
    ceremony:"",
    equimentCeremony:"",
    otherLocation:"",
    memorialService:"",
    gravesideService:"",
    transferFuneralHome:"",
    transferAirport:"",
    hearse:"",
    serviceVehicle:"",
    additionalMileage:"",
    cremation:"",
    packing:"",
    cremainsDelivered:"",
    umPresonalization:"",
    Jewelry:"",
    JewelryCount:"",
    urn:"",
    archival:"",
    accountSetup:"",
    Other:"",
    deathCertificate:"",
    deathCertificateCount:"",
    statePermit:"",
    pimaMedicalExaminers:"",
    consulateFee:" ",
    apostilleDeathCertificate:"",
    decentAirTransportation:"",
    prayerCards:"",
    prayerCardsCount:"",
    flowersCrucifixBook:"",
    casket:"",
    description:"",
    otherBurial:"",
    otherCharges1:"",
    otherCharges2:"",
    TotalValue:"",
    downPaymentAdjustmentsDescription:"",
    downPaymentAdjustmentsNumber:"",
    typePayment:"",
    payment:"",
    blanceDue:"",
    Field1Text:"",
});
  const typePayment = [
    { label: "CASH", value: "1",id:"typePayment"},
    { label: "CARD", value: "2",id:"typePayment"},
    { label: "INSURANCE", value: "3",id:"typePayment"}
];
const add_PackageInfo = async (data) => {
  try {

    const response = await api.post(
      "documents/form5/PackageInfoController.php",
      data,
      { params: { action: "POST" } }
    );
    console.log(data)
    console.log(response);
    get_package_info(consecutivo);
    toast.success("Package info successfully added", {
      className: "toast-success",
      theme: "colored",
    });
  } catch (error) {
    toast.error("Error adding package info", {
      className: "toast-error",
      theme: "colored",
    });




    console.error("Error adding archive", error);
  }
};
const handleInputChange = (name, value) => {
  const updatedFormData = {
    ...formData,
    [name]: value,
  };
  setFormData(updatedFormData);
  onPackagesInfo(updatedFormData);
};
function getNumericValue(value) {
  const num = parseFloat(value);
  return isNaN(num) ? 0 : num;
}
useEffect(() => {
  getTotal();
  
}, [formData]);
let TotalToRecipe = 0;
const getTotal = () => {
  
  TotalToRecipe = 0;
  TotalToRecipe += getNumericValue(formData["basicService"])
  TotalToRecipe += getNumericValue(formData["embalming"])
  TotalToRecipe += getNumericValue(formData["refigeration"])
  TotalToRecipe += getNumericValue(formData["cosmetics"])
  TotalToRecipe += getNumericValue(formData["preservation"])
  TotalToRecipe += getNumericValue(formData["regriferation"])
  TotalToRecipe += getNumericValue(formData["washing"])
  TotalToRecipe += getNumericValue(formData["otherBasic"])
  TotalToRecipe += getNumericValue(formData["viewing"])
  TotalToRecipe += getNumericValue(formData["ceremony"])
  TotalToRecipe += getNumericValue(formData["equimentCeremony"])
  TotalToRecipe += getNumericValue(formData["otherLocation"])
  TotalToRecipe += getNumericValue(formData["memorialService"])
  TotalToRecipe += getNumericValue(formData["gravesideService"])
  TotalToRecipe += getNumericValue(formData["transferFuneralHome"])
  TotalToRecipe += getNumericValue(formData["transferAirport"])
  TotalToRecipe += getNumericValue(formData["hearse"])
  TotalToRecipe += getNumericValue(formData["serviceVehicle"])
  TotalToRecipe += getNumericValue(formData["additionalMileage"])
  TotalToRecipe += getNumericValue(formData["cremation"])
  TotalToRecipe += getNumericValue(formData["packing"])
  TotalToRecipe += getNumericValue(formData["cremainsDelivered"])
  TotalToRecipe += getNumericValue(formData["umPresonalization"])
  let JewlryMultiply = getNumericValue(formData["Jewelry"]) * getNumericValue(formData["JewelryCount"]);
  TotalToRecipe += JewlryMultiply;
  TotalToRecipe += getNumericValue(formData["urn"])
  TotalToRecipe += getNumericValue(formData["archival"])
  TotalToRecipe += getNumericValue(formData["accountSetup"])
  TotalToRecipe += getNumericValue(formData["Other"])
  let DeathCertificateMultiply = getNumericValue(formData["deathCertificate"]) * getNumericValue(formData["deathCertificateCount"]);
  TotalToRecipe += DeathCertificateMultiply;
  TotalToRecipe += getNumericValue(formData["statePermit"])
  TotalToRecipe += getNumericValue(formData["pimaMedicalExaminers"])
  TotalToRecipe += getNumericValue(formData["consulateFee"])
  TotalToRecipe += getNumericValue(formData["apostilleDeathCertificate"])
  TotalToRecipe += getNumericValue(formData["decentAirTransportation"])
  TotalToRecipe += getNumericValue(formData["prayerCards"])
  TotalToRecipe += getNumericValue(formData["flowersCrucifixBook"])
  TotalToRecipe += getNumericValue(formData["casket"])
  TotalToRecipe += getNumericValue(formData["otherBurial"])
  TotalToRecipe += getNumericValue(formData["otherCharges1"])
  TotalToRecipe += getNumericValue(formData["otherCharges2"])
  setTotal(TotalToRecipe);
  setBalanceDue(TotalToRecipe)
  setBalanceDue(TotalToRecipe - getNumericValue(formData["downPaymentAdjustmentsNumber"]));
};
const saveData = () => {
  getTotal();
  add_PackageInfo(formData);
}
useEffect(() => {
  get_package_info(consecutivo);
}, []);
useEffect(() => {
  onPackagesInfo(formData);
}, [formData]);

const get_package_info = async (secuencia) => {
  try {
    const response = await api.get(
      `documents/form5/PackageInfoController.php?id=${secuencia}`
    );
    console.log(response)
    const packageInfo = response.data[0];
    if (packageInfo) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        PackageSelected: packageInfo.packageSelected,
        basicService: packageInfo.basicService,
        embalming: packageInfo.embalming,
        refigeration: packageInfo.regrigeration,
        cosmetics: packageInfo.cosmetics,
        preservation: packageInfo.temporaryPreservation,
        regriferation: packageInfo.regrigerationCleansing,
        washing: packageInfo.washingCleaning,
        otherBasic: packageInfo.otherPreparation,
        viewing: packageInfo.facilitiesStaff,
        ceremony: packageInfo.facilitiesCeremony,
        equimentCeremony: packageInfo.equipmentCeremony,
        otherLocation: packageInfo.anotherLocation,
        memorialService: packageInfo.facilitiesMemorial,
        gravesideService: packageInfo.equipmentGraveside,
        transferFuneralHome: packageInfo.transferFuneralHome,
        transferAirport: packageInfo.transferAirport,
        hearse: packageInfo.hearse,
        serviceVehicle: packageInfo.utilityVehicle,
        additionalMileage: packageInfo.additionalMileage,
        cremation: packageInfo.cremationFee,
        packing: packageInfo.packingUSPS,
        cremainsDelivered: packageInfo.delivered25miles,
        umPresonalization: packageInfo.umPersonalization,
        Jewelry: packageInfo.jewlry,
        JewelryCount: packageInfo.jewlryCount,
        urn: packageInfo.urn,
        archival: packageInfo.archivalFee,
        accountSetup: packageInfo.accountSetup,
        Other: packageInfo.otherCremation,
        deathCertificate: packageInfo.deathCertificate,
        deathCertificateCount: packageInfo.deathCertificateCount,
        statePermit: packageInfo.arizonaDisposition,
        pimaMedicalExaminers: packageInfo.pimaMedical,
        consulateFee: packageInfo.consulateFee,
        apostilleDeathCertificate: packageInfo.apostilleDeath,
        decentAirTransportation: packageInfo.transportationFee,
        prayerCards: packageInfo.prayerCards,
        prayerCardsCount: packageInfo.prayerCardsCount,
        flowersCrucifixBook: packageInfo.flowers,
        casket: packageInfo.casket,
        description: packageInfo.descriptionCash,
        otherBurial: packageInfo.otherBurialContainer,
        otherCharges1: packageInfo.otherCash1,
        otherCharges2: packageInfo.otherCash2,
        TotalValue: packageInfo.Total,
        downPaymentAdjustmentsDescription: packageInfo.downPaymentDescriprition,
        downPaymentAdjustmentsNumber: packageInfo.downPaymentNumber,
        typePayment: packageInfo.paymentType,
        payment: packageInfo.payment,
        blanceDue: packageInfo.BalanceDue,
        Field1Text: packageInfo.Field1Text,
      }));
    }
  } catch (error) {
    console.log("Error get info", error);
  }
};
  return (
    <>
    <Row className="main-card-packages">
      <Row style={{margin:"10px",borderRadius:"10px"}}>
      </Row>
      <Col sm="12" lg="6" md="12">
      <div style={{height:"calc(66vh - 50px)",overflow:"auto",padding:"20px"}}>
        <Row>
            <DefaultInput placeholder={"PackageSelected"} onChange={(value => handleInputChange("PackageSelected",value))}valueData={formData.PackageSelected}/>
            <DefaulLabel placeholder={"BASIC SERVICES"}/>
            <DefaultInput placeholder={"Basic Service of Funeral Director and Staff"} onChange={(value => handleInputChange("basicService",value))} type={"number"} type2={"money"}valueData={formData.basicService}/>
            <DefaultInput placeholder={"Embalming"} onChange={(value => handleInputChange("embalming",value))} type={"number"} type2={"money"} valueData={formData.embalming}/>
            <DefaultInput placeholder={"Refrigeration"} onChange={(value => handleInputChange("refigeration",value))} type={"number"} type2={"money"}valueData={formData.refigeration}/>
            <br></br>
            <div style={{borderBottom:"4px solid #d9d9d9"}}></div>
            <DefaulLabel placeholder={"OTHER PREPARATION OF THE BODY"}/>
            <DefaultInput placeholder={"Cosmetics, Dressing, or Casketing"} onChange={(value => handleInputChange("cosmetics",value))} type={"number"} type2={"money"}valueData={formData.cosmetics}/>
            <DefaultInput placeholder={"Temporary preservation procedures, i.e., out un-embalmedremain."} onChange={(value => handleInputChange("preservation",value))} type={"number"} type2={"money"}
            valueData={formData.preservation}/>
            <DefaultInput placeholder={"Refrigeration, Cleansing, handling, and care of un-embalmedremains (out/in-state)"} onChange={(value => handleInputChange("regriferation",value))} type={"number"} type2={"money"}valueData={formData.refigeration}/>
            <DefaultInput placeholder={"Washing and cleaning (required for ID viewing)"} onChange={(value => handleInputChange("washing",value))} type={"number"} type2={"money"}valueData={formData.washing}/>
            <DefaultInput placeholder={"Other"} onChange={(value => handleInputChange("otherBasic",value))} type={"number"} type2={"money"} valueData={formData.otherBasic}/>
            <br></br>
            <div style={{borderBottom:"4px solid #d9d9d9"}}></div>
            <DefaulLabel placeholder={"FACILITIES"}/>
            <DefaultInput placeholder={"Us Use of Facilities and Staff for Viewing"} onChange={(value => handleInputChange("viewing",value))} type={"number"} type2={"money"} valueData={formData.viewing}/>
            <DefaultInput placeholder={"Use of Facilities and Staff for Funeral Ceremony"} onChange={(value => handleInputChange("ceremony",value))} type={"number"} type2={"money"}valueData={formData.ceremony}/>
            <DefaultInput placeholder={"Use of Equipment and Staff to Funeral Ceremony"} onChange={(value => handleInputChange("equimentCeremony",value))} type={"number"} type2={"money"}valueData={formData.equimentCeremony}/>
            <DefaultInput placeholder={"At another location"} onChange={(value => handleInputChange("otherLocation",value))} type={"number"} type2={"money"}valueData={formData.otherLocation}/>
            <DefaultInput placeholder={"Use of Facilities and Staff for Memorial Service"} onChange={(value => handleInputChange("memorialService",value))} type={"number"} type2={"money"}valueData={formData.memorialService}/>
            <DefaultInput placeholder={"Use of Equipment and Staff for Graveside Service"} onChange={(value => handleInputChange("gravesideService",value))} type={"number"} type2={"money"}valueData={formData.gravesideService}/>
            <br></br>
            <div style={{borderBottom:"4px solid #d9d9d9"}}></div>
            <DefaulLabel placeholder={"AUTOMOTIVE"}/>
            <DefaultInput placeholder={"Transfer of Remains to Funeral Home"} onChange={(value => handleInputChange("transferFuneralHome",value))} type={"number"} type2={"money"}valueData={formData.transferFuneralHome}/>
            <DefaultInput placeholder={"Transfer of Remains to Airport"} onChange={(value => handleInputChange("transferAirport",value))} type={"number"} type2={"money"}valueData={formData.transferAirport}/>
            <DefaultInput placeholder={"Hearse"} onChange={(value => handleInputChange("hearse",value))} type={"number"} type2={"money"}valueData={formData.hearse}/>
            <DefaultInput placeholder={"Service/Utility Vehicle"} onChange={(value => handleInputChange("serviceVehicle",value))} type={"number"} type2={"money"}valueData={formData.serviceVehicle}/>
            <DefaultInput placeholder={"Additional mileage"} onChange={(value => handleInputChange("additionalMileage",value))} type={"number"} type2={"money"}valueData={formData.additionalMileage} />
            <br></br>
            <div style={{borderBottom:"4px solid #d9d9d9"}}></div>
            <DefaulLabel placeholder={"MICELLANEOUS CREMATION CHARGES"}/>
            <DefaultInput placeholder={"Cremation Fee"} onChange={(value => handleInputChange("cremation",value))} type={"number"} type2={"money"}valueData={formData.cremation}/>
            <DefaultInput placeholder={"Packing and Mailing of Cremains - USPS"} onChange={(value => handleInputChange("packing",value))} type={"number"} type2={"money"}valueData={formData.packing}/>
            <DefaultInput placeholder={"Cremains delivered (25-mile radius)"} onChange={(value => handleInputChange("cremainsDelivered",value))} type={"number"} type2={"money"} valueData={formData.cremainsDelivered}/>
            <DefaultInput placeholder={"Um personalization (engraving)"} onChange={(value => handleInputChange("umPresonalization",value))} type={"number"} type2={"money"} valueData={formData.umPresonalization}/>
            <DefaultInput placeholder={"Jewelry"} onChange={(value => handleInputChange("Jewelry",value))} type={"number"} type2={"money"}valueData={formData.Jewelry}/>
            <DefaultCountBtn placeholder={"Jewelry Quantity"} onChange={(value => handleInputChange("JewelryCount",value))}valueData={formData.JewelryCount} />
            <DefaultInput placeholder={"Urn"} onChange={(value => handleInputChange("urn",value))} type={"number"} type2={"money"} valueData={formData.urn} />
            <DefaultInput placeholder={"Archival Fee"} onChange={(value => handleInputChange("archival",value))} type={"number"} type2={"money"}valueData={formData.archival}/>
            <DefaultInput placeholder={"Account Set-Up Fee"} onChange={(value => handleInputChange("accountSetup",value))} type={"number"} type2={"money"}valueData={formData.accountSetup} />
            <DefaultInput placeholder={"Other"} onChange={(value => handleInputChange("Other",value))} type={"number"} type2={"money"}valueData={formData.Other}/>
            <br></br>
            <div style={{borderBottom:"4px solid #d9d9d9"}}></div>
            <DefaulLabel placeholder={"CASH ADVANCES"}/>
            <DefaultInput placeholder={"Death Certificate"} onChange={(value => handleInputChange("deathCertificate",value))} type={"number"} type2={"money"}valueData={formData.deathCertificate}
            /><DefaultCountBtn placeholder={"Death Certificate Quantity"} onChange={(value => handleInputChange("deathCertificateCount",value))}valueData={formData.deathCertificateCount}/>
            <DefaultInput placeholder={"Arizona State Disposition Permit"} onChange={(value => handleInputChange("statePermit",value))} type={"number"} type2={"money"}valueData={formData.statePermit}/>
            <DefaultInput placeholder={"Pima County Medical Examiners Cremation Fee"} onChange={(value => handleInputChange("pimaMedicalExaminers",value))} type={"number"} type2={"money"}valueData={formData.pimaMedicalExaminers}/>
            
            <DefaultInput placeholder={"Consulate Fee"} onChange={(value => handleInputChange("consulateFee",value))} type={"number"} type2={"money"}valueData={formData.consulateFee}/>
            
            <DefaultInput placeholder={"Apostille Death Certificate"} onChange={(value => handleInputChange("apostilleDeathCertificate",value))} type={"number"} type2={"money"}valueData={formData.apostilleDeathCertificate}/>
            <DefaultInput placeholder={"Decent air transportation Fee"} onChange={(value => handleInputChange("decentAirTransportation",value))} type={"number"} type2={"money"}valueData={formData.decentAirTransportation}/>
            <DefaultInput placeholder={"Prayer cards"} onChange={(value => handleInputChange("prayerCards",value))} type={"number"} type2={"money"}valueData={formData.prayerCards}/>
            <DefaultCountBtn placeholder={"Prayer Cards Quantity"} onChange={(value => handleInputChange("prayerCardsCount",value))}valueData={formData.prayerCardsCount} />
            <DefaultInput placeholder={"Flowers, Crucifix, or Register Book"} onChange={(value => handleInputChange("flowersCrucifixBook",value))} type={"number"} type2={"money"}valueData={formData.flowersCrucifixBook}/>
            <DefaultInput placeholder={"Casket or Alternative Container"} onChange={(value => handleInputChange("casket",value))} type={"number"} type2={"money"}valueData={formData.casket}/>



            <DefaultInput placeholder={"Description"} onChange={(value => handleInputChange("description",value))} type={"text"} valueData={formData.description}/>


            <DefaultInput placeholder={"Other Burial Container"} onChange={(value => handleInputChange("otherBurial",value))} type={"number"} type2={"money"}valueData={formData.otherBurial}/>
            <DefaultInput placeholder={"Other"} onChange={(value => handleInputChange("otherCharges1",value))} type={"number"} type2={"money"}valueData={formData.otherCharges1}/>
            <DefaultInput placeholder={"Other"} onChange={(value => handleInputChange("otherCharges2",value))} type={"number"} type2={"money"}valueData={formData.otherCharges2}/>
        </Row>
      </div>
      </Col>
      <Col sm="12" lg="6" md="12">
        <div style={{height:"calc(66vh - 50px)",overflow:"auto",padding:"20px"}}>
          <DefaultInput placeholder={"Total"} onChange={(value => handleInputChange("TotalValue",value))} type={"number"} type2={"money"} valueData={TotalFinal} />
          <DefaultInput placeholder={"DOWN PAYMENT / ADJUSTMENTS (Description):"} onChange={(value => handleInputChange("downPaymentAdjustmentsDescription",value))} type={"text"}valueData={formData.downPaymentAdjustmentsDescription}/>
          <DefaultInput placeholder={"DOWN PAYMENT / ADJUSTMENTS (Number):"} onChange={(value => handleInputChange("downPaymentAdjustmentsNumber",value))} type={"number"} type2={"money"}valueData={formData.downPaymentAdjustmentsNumber}/>
          <DefaultRadioButtons label={"PAYMENT TYPE"} options={typePayment} onChange={(value => handleInputChange("typePayment",value))}valueData={formData.typePayment}/>
          
          <Row style={{display:"flex"}}>
            <Col sm="2">
              <DefaulLabel placeholder={"OTHER"}></DefaulLabel>
            </Col>
            <Col sm="10">
              <DefaultInputNoLabel placeholder={"OTHER"} onChange={(value => handleInputChange("Field1Text",value))} valueData={formData.Field1Text}/>
            </Col>
          </Row>

          <DefaultInput placeholder={"PAYMENT:"} onChange={(value => handleInputChange("payment",value))} type={"number"} type2={"money"}valueData={formData.payment}/>
          <DefaultInput placeholder={"BALANCE DUE:"} onChange={(value => handleInputChange("blanceDue",value))} type={"number"} type2={"money"}valueData={BalanceDueFinal}/>
        </div>
      </Col>
    </Row>
      <Row>
        <div className="d-flex justify-content-end">
          <Button onClick={saveData}>SAVE NOTICE TO PURCHASER</Button>
        </div>
      </Row>
    </>
  );
};

export default PackageSelected;
