import React, { useState,useEffect } from "react";
import { Button } from "react-bootstrap";
import DefaultInput from "../../../../components/Elements/DefaultInput";
import DefaultRadioInput from "../../../../components/Elements/DefaultRadioInput";
import DefaultRadioButtons from "../../../../components/Elements/DefaultRadioButtons";
import DefaultCheckboxButtons  from "../../../../components/Elements/DefaultCheckboxButtons";
import api from "../../../../services/api"
import { toast } from "react-toastify";
const DecedentOccupationInfo = ({folio,consecutivo, onOccupationChange }) => {
  const [formData, setFormData] = useState({
    folio:folio,
    consecutivo:consecutivo,
    decendentOccupation: "",
    education: "",
    decedemtIndustry: "",
    decedentOriginOption: [],
    decedentRaceOption: [],
    placeOfDeathOption: "",
    placeOfDeathValue:"",
    placeOfDeathFacility: "",
    placeOfDeathFacilityAddress: "",
    otherInstitutionAddress: "",
  });

  const Education = [
    { label: "8th grade or less; none", value: "1", enableInput: false },
    { label: "9th through 12th grade, no diploma", value: "2", enableInput: false },
    { label: "High School graduate or GED completed", value: "3", enableInput: false },
    { label: "Some collage credit, but not a degree", value: "4", enableInput: false },
    { label: "Associate degree (e.g.: AA, AS)", value: "5", enableInput: false },
    { label: "Bachelor’s degree (e.g.: BA, AB, BS)", value: "6", enableInput: false },
    { label: "Master's degree (e.g.: MA, MS, MEng, MEd, MSW, MBA )", value: "7", enableInput: false },
    { label: "Doctorate (e.g.: PhD, EdD, or Professional Degree e.g.: MD, DDS, DVM, LLB, JB)", value: "8", enableInput: false },
    { label: "Unknown", value: "9", enableInput: false },
    { label: "Refused", value: "10", enableInput: false },
    { label: "Not Obtainable", value: "11", enableInput: false },
    { label: "Not Classifiable", value: "12", enableInput: false },
  ];
  const HispanicOrigin = [
    { label: "No, Not Spanish/Hispanic/Latino", value: "1", enableInput: false },
    { label: "Yes, Mexican, Mexican American, Chicano", value: "2", enableInput: false },
    { label: "Yes, Puerto Rican", value: "3", enableInput: false },
    { label: "Yes, Cuban", value: "4", enableInput: false },
    { label: "Yes, Other Spanish/Hispanic/Latino", value: "5", enableInput: true },
    { label: "Not Obtainable", value: "6", enableInput: false },
    { label: "Unknown", value: "7", enableInput: false },
    { label: "Refused", value: "8", enableInput: false },
    { label: "Other (Specify)", value: "9", enableInput: true },
  ];
  const Race = [
    { label: "White", value: "1",inputValue:"", enableInput: false },
    { label: "Black, African American", value: "2",inputValue:"", enableInput: false },
    { label: "American Indian/Alaska Native (Specify)",inputValue:"", value: "3", enableInput: true },
    { label: "Asian Indian", value: "4",inputValue:"", enableInput: false },
    { label: "Chinese", value: "5",inputValue:"", enableInput: false },
    { label: "Filipino", value: "6",inputValue:"", enableInput: false },
    { label: "Japanese", value: "7",inputValue:"", enableInput: false },
    { label: "Korean", value: "8",inputValue:"", enableInput: false },
    { label: "Vietnamese", value: "9",inputValue:"", enableInput: false },
    { label: "Other Asian (Specify)", value: "10",inputValue:"", enableInput: true },
    { label: "Native Hawaiian", value: "11",inputValue:"", enableInput: false },
    { label: "Guamanian or Chamorro", value: "12",inputValue:"", enableInput: false },
    { label: "Samoan", value: "13",inputValue:"", enableInput: false },
    { label: "Other Pacific Islander (Specify)", value: "14",inputValue:"", enableInput: true },
    { label: "Other (Specify)", value: "15",inputValue:"", enableInput: true },
    { label: "Refused", value: "16",inputValue:"", enableInput: false },
    { label: "Not Obtainable", value: "17", enableInput: false },
    { label: "Unknown", value: "18",inputValue:"", enableInput: false },
  ];
  const PlaceDeath = [
    { label: "Dead on Arrival", value: "1",inputValue:"", enableInput: false },
    { label: "Emergency", value: "2",inputValue:"", enableInput: false },
    { label: "ER/Outpatient", value: "3",inputValue:"", enableInput: false },
    { label: "Inpatient", value: "4",inputValue:"", enableInput: false },
    { label: "Not Classifiable", value: "5",inputValue:"", enableInput: false },
    { label: "Decedent’s Residence", value: "6",inputValue:"", enableInput: false },
    { label: "Hospice", value: "7",inputValue:"", enableInput: false },
    { label: "Nursing Home/Long Term Care", value: "8",inputValue:"", enableInput: false },
    { label: "Other (Specify)", value: "9", enableInput: false },


  ];
  const handleInputChange = (name, value) => {
    const updatedFormData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedFormData);
    onOccupationChange(updatedFormData);
  };

  useEffect(() => {
    getOccupationInfo(folio,consecutivo);
  }, []);

  const handleSubmit = () => {
    add_occupation_info(formData)
  };
  const getOccupationInfo = async (folio, consecutivo) => {
    try {
      const response = await api.get('documents/form1/DecedentOccupation.php', {
        params: {
          folio: folio,
          consecutivo: consecutivo
        }
      });
      const data = response.data;
      if (data.decedent_info[0] != null) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          decendentOccupation: data.decedent_info[0].decendentOccupation,
          decedemtIndustry: data.decedent_info[0].decedemtIndustry,
          education: data.decedent_info[0].education,
          otherInstitutionAddress: data.decedent_info[0].otherInstitutionAddress,
          placeOfDeathFacility:data.decedent_info[0].placeOfDeathFacility,
          placeOfDeathFacilityAddress: data.decedent_info[0].placeOfDeathFacilityAddress,
          placeOfDeathOption: data.decedent_info[0].placeOfDeathOption,
          placeOfDeathValue: data.decedent_info[0].placeOfDeathValue,
          decedentOriginOption: data.decedent_origin_options,
          decedentRaceOption: data.decedent_race_options,
        }));
    }

  } catch (error) {
      toast.error("Error retrieving occupation info", {
        className: "toast-error",
        theme: "colored",
      });
      console.error(error);
    }
  };

  const add_occupation_info = async (formData) => {
    try {
      const response = await api.post(
        "documents/form1/DecedentOccupation.php",
        formData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
    console.log(response)

      toast.success("Occupation info successfully added or updated", {
        className: "toast-success",
        theme: "colored",
      });
  
    } catch (error) {
      toast.error("Error adding occupation info", {
        className: "toast-error", 
        theme: "colored",
      });
    }
  };
  useEffect(() => {
    if (Object.keys(formData).some(key => formData[key] !== "")) {
      onOccupationChange(formData);
    }
  }, [formData, onOccupationChange]);
  return (
    <>
      <div className="main-card">
        <DefaultInput numberRow={"24A.-"} placeholder={"DECEDENT’S OCCUPATION"} type={"text"} 
        onChange={(value) => handleInputChange("decendentOccupation", value)} valueData={formData.decendentOccupation}/>

        <DefaultInput nnumberRow={"24B.-"} placeholder={"DECEDENT’S INDUSTRY"} type={"text"} 
        onChange={(value) => handleInputChange("decedemtIndustry", value)} valueData={formData.decedemtIndustry}/>

        <DefaultRadioButtons numberRow={"25.-"} label="EDUCATION (SELECT ONE)" options={Education} 
        onChange={(value) => handleInputChange("education", value)} valueData={parseInt(formData.education)}/>

        <DefaultCheckboxButtons numberRow={"26.-"}
          label={
            "DECEDENT’S HISPANIC ORIGIN (Check the boxes that best corresponds with the decedent’s ethnic identity as given by the informant)"
          }
          onChange={(value) => handleInputChange("decedentOriginOption", value)}
          options={HispanicOrigin}
          valueData={formData.decedentOriginOption}
        />

        <DefaultCheckboxButtons numberRow={"27.-"} label={"DECEDENT’S RACE (Select all that Apply)"} 
        onChange={(value) => handleInputChange("decedentRaceOption", value)} options={Race} 
        valueData={formData.decedentRaceOption}/>

        <DefaultRadioButtons numberRow={"28A.-"} label="PLACE OF DEATH (SELECT ONE)" options={PlaceDeath} 
        onChange={(value) => handleInputChange("placeOfDeathOption", value)} valueData={formData.placeOfDeathOption}/>
        <DefaultInput numberRow={""} placeholder={"Other; Specify"} type={"text"} 
        onChange={(value) => handleInputChange("placeOfDeathValue", value)} valueData={formData.placeOfDeathValue}/>

        <DefaultInput numberRow={"28B.-"} placeholder={"PLACE OF DEATH FACILITY NAME"} type={"text"} 
        onChange={(value) => handleInputChange("placeOfDeathFacility", value)} valueData={formData.placeOfDeathFacility}/>

        <DefaultInput numberRow={"28C.-"} placeholder={"PLACE OF DEATH FACILITY ADDRESS"} type={"text"} 
        onChange={(value) => handleInputChange("placeOfDeathFacilityAddress", value)} valueData={formData.placeOfDeathFacilityAddress}/>

        <DefaultInput numberRow={"28D.-"} placeholder={"SPECIFY OTHER INSTITUTION OR ADDRESS WHERE DEATH OCCURRED"} type={"text"} 
        onChange={(value) => handleInputChange("otherInstitutionAddress", value)} valueData={formData.otherInstitutionAddress}/>

      </div>
      <div className="d-flex justify-content-end mt-3">
        <Button onClick={handleSubmit}>SAVE OCCUPATION INFO</Button>
      </div>
    </>
  );
};

export default DecedentOccupationInfo;
