import React, { useState } from "react";
import { useParams } from "react-router-dom";
import InvoiceForm from "./Form2/InvoiceForm";
import { Button, Col, Row, Modal } from "react-bootstrap";
import getUserDataFromCookies from "../Form1/backend/GetUserFromCookies";
import { PDFViewer } from "@react-pdf/renderer";
import PDF_Form2 from "./PDF_Form2";

const Invoice = () => {
  const { folioID, consecutivo } = useParams();
  var userID = getUserDataFromCookies().id_usuario;
  const formData={
    folio: folioID,
    secuencia: parseInt(consecutivo),
    usuarioID: userID,
    paymentVoucher: "",
    Date: "",
    AccountName: "",
    DetailedExplanation: "",
    Amount: "",
    Total: "",
  };
  const [showModal, setShowModal] = useState(false);
  const [dataInvoice, setInvoiceData] = useState(formData);

  const handleInvoiceChange = (data) => {
    setInvoiceData(data);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <h2 className="f-cel">{folioID}</h2>
        <h3 className="f-cel">INVOICE</h3>
        <div><button className="btn btn-primary" onClick={handleShowModal}>View Document</button></div>
      </div>
      <Row>
        <Col lg="10">
          <InvoiceForm
            folioId={folioID}
            secuencia={consecutivo}
            usuarioId={userID}
            onInvoiceInfo={handleInvoiceChange}
          />
        </Col>
      </Row>
      <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Body>
          <PDFViewer style={{ width: "100%", height: "800px" }}>
            <PDF_Form2 data={dataInvoice} />
          </PDFViewer>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Invoice;
