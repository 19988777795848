import React from "react";
import { Form } from "react-bootstrap";

const DefaultRadioButtons = ({
  numberRow,
  options,
  label,
  onChange,
  valueData,
}) => {
  const handleChange = (value) => {
    const intValue = parseInt(value);
    onChange(intValue);
    console.log(`Selected value (integer): ${intValue}`);
  };

  const generateUniqueId = () => {
    return `${Math.random().toString(36).substr(2, 9)}`;
  };

  return (
    <Form.Group>
      <Form.Label style={{ fontWeight: "700" }}>{numberRow}</Form.Label>
      <Form.Label>{label}</Form.Label>
      {options.map((option, index) => (
        <div key={index}>
          <Form.Check
            type="radio"
            label={option.label}
            id={generateUniqueId()}
            name={generateUniqueId()}
            checked={parseInt(valueData) === parseInt(option.value)} // Convertir a enteros
            onChange={() => handleChange(option.value)}
          />
        </div>
      ))}
    </Form.Group>
  );
};

export default DefaultRadioButtons;
