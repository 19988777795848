import api from "../../../../services/api";

const GetInfoForm3 = async ({ consecutivo }) => {
  try {
    const response = await api.get(
      `documents/form3/EmbalmingController.php?id=${consecutivo}`
    );
    const decedent_info = response.data[0];
    return {
      folio: decedent_info?.folio || "",
      decreasedName: decedent_info?.decreasedName || "",
      decreasedNameFormal: decedent_info?.decreasedFormalName || "",
      witnessName: decedent_info?.witnessName || "",
      transportTo: decedent_info?.transportTo || "",
    };
  } catch (error) {
    console.log(error);
    return {};
  }
};

export default GetInfoForm3;
