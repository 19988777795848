import React, { useState, useEffect } from "react";
import { Col, Row, Table, Button, Form, Dropdown,Modal } from "react-bootstrap";
import { SlPencil } from "react-icons/sl";
import { SketchPicker } from "react-color";
import * as FaIcons from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
function CustomTable({ data, currentPage, onPageChange,onEdit,onDelete }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [perPage, setPerPage] = useState(10); // Estado para la cantidad por página
  const perPageOptions = [10, 15, 20, 50, 100, 150]; // Opciones del dropdown

  // Actualizar el estado de cantidad por página al cambiar la selección
  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    onPageChange(1); // Regresar a la primera página al cambiar la cantidad por página
  };

  const totalPages = Math.ceil(data && data.length ? data.length / perPage : 0);

  const goToPage = (page) => {
    onPageChange(page);
  };

  const filteredData = data
    ? data.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
    : [];

  const totalItems = filteredData.length;
  /*const editItem = (item) => {
    onEdit(item);
  };

  const deleteItem = (itemId) => {
    onDelete(itemId);
  };*/
  const navigate = useNavigate();
  const showFolder = (country, country_id, folio, name,middle,last) => {
    if (!country || !country_id || !folio || !name || !middle ||!last) {
      console.error("Alguno de los datos es undefined o null:", { country, country_id, folio, name });
      return;
    }
  
    const url = `../archive/${country}/${country_id}/${folio}/${name}${middle ? ' ' + middle : ''} ${last}`;
    navigate(url);
    console.log("show folder", country, country_id, folio, name, url);
  };
  
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  return (
    <>
      <Row>
        <Col className="d-flex"></Col>
      </Row>
      <Col sm="6" className="mb-2">
        <Form.Group controlId="search">
          <Form.Control
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Form.Group>
      </Col>

      <Table striped bordered hover>
        <thead>
          <tr>
            {data &&
              data.length > 0 &&
              Object.keys(data[0])
                .slice(2)
                .map((key, index) => <th key={index}>{key}</th>)}
            <th>Control</th>
          </tr>
        </thead>

        <tbody>
          {filteredData
            .slice((currentPage - 1) * perPage, currentPage * perPage)
            .map((item, index) => {
              return (
                <tr key={index}>
                  {Object.keys(item)
                    .slice(2)
                    .map((key, index) => (
                      <td key={index}>
                        {key.toLowerCase().includes("color") ? (
                          <div
                            style={{
                              backgroundColor: item[key],
                              width: "40px",
                              height: "40px",
                              border: "1px solid #ced4da",
                            }}
                          ></div>
                        ) : (
                          item[key]
                        )}
                      </td>
                    ))}
                  <td>
                    {/*
                    <Button
                      variant="info"
                      onClick={() => editItem({item})}
                      className="table_button"
                    >
                      <SlPencil color="#fff" />
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() =>
                        deleteItem(item["folio_id"])
                      }
                      className="table_button"
                    >
                      <FaIcons.FaRegTrashAlt />
                    </Button>
                    */}
                    <Button
                      className="table_button btn btn-primary"
                      onClick={() =>
                        showFolder(
                          item["Country"],
                          item["Country_ID"],
                          item["Folio"],
                          item["Name"],
                          item["Middle name"],
                          item["Last name"],
                        )
                      }
                    >
                      <FaIcons.FaEye />
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>

      <div className="pagination mt-2">
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="perPageDropdown">
            Per Page: {perPage}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {perPageOptions.map((option) => (
              <Dropdown.Item
                key={option}
                active={perPage === option}
                onClick={() => handlePerPageChange(option)}
              >
                {option}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Button
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        {Array.from({ length: totalPages }, (_, i) => (
          <Button
            key={i}
            onClick={() => goToPage(i + 1)}
            disabled={currentPage === i + 1}
          >
            {i + 1}
          </Button>
        ))}
        <Button
          onClick={() => goToPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="align-items-right">Total Items: {totalItems}</div>
      </div>
      
    <Modal show={showModal} onHide={handleCloseModal}>
    <Modal.Header closeButton>
      <Modal.Title>Confirm</Modal.Title>
    </Modal.Header>
    <Modal.Body>Do you want to create a new folio?</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleCloseModal}>
        Sure
      </Button>
    </Modal.Footer>
  </Modal>
  </>
  );
}

export default CustomTable;
