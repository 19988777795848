import React, { useState, useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { FaDollarSign } from "react-icons/fa";

const DefaultInput = ({
  numberRow,
  type,
  placeholder,
  onChange,
  valueData,
  disabled,
  type2,
}) => {
  const [inputValue, setInputValue] = useState(valueData);

  useEffect(() => {
    if (valueData === 0) {
      setInputValue("");
    } else {
      setInputValue(valueData);
    }
  }, [valueData]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    if (type === "number") {
      // Validar que el valor ingresado sea un número
      if (/^\d*\.?\d{0,2}$/.test(newValue)) {
        setInputValue(newValue);
        onChange(newValue);
      }
    } else {
      setInputValue(newValue);
      onChange(newValue);
    }
  };

  if (disabled == null) {
    disabled = false;
  }

  return (
    <Form.Group className="mt-2 mb-2">
      {type2 === "money" ? (
        <div>
          <Form.Label style={{ fontWeight: "700" }}>{numberRow}</Form.Label>
          <Form.Label>{placeholder}</Form.Label>
          <InputGroup>
            <InputGroup.Text id="basic-addon1">
              <FaDollarSign />
            </InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="00.00"
              value={inputValue}
              onChange={handleChange}
              disabled={disabled}
              aria-describedby="basic-addon1"
            />
          </InputGroup>
        </div>
      ) : (
        <div>
          <Form.Label style={{ fontWeight: "700" }}>{numberRow}</Form.Label>
          <Form.Label>{placeholder}</Form.Label>
          {type === "password" ? (
            <>
              <Form.Control
                type="password"
                onChange={handleChange}
                value={inputValue}
                disabled={disabled}
              />
            </>
          ) : (
            <>
              <Form.Control
                type="text"
                onChange={handleChange}
                value={inputValue}
                disabled={disabled}
              />
            </>
          )}
        </div>
      )}
    </Form.Group>
  );
};

export default DefaultInput;
