import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import api from "../../services/api";
import { toast } from "react-toastify";
import CustomTable from "../../components/Elements/TableDocuments";
import CreateFolioForm from "./CreateFolioForm";
import { Spinner } from "react-bootstrap";

const Documents = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const createFolioFormRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
      const response = await api.get("documents/DocumentsController.php", {
        params: { action: "GET" },
      });
      setData(response.data);
      setLoading(false);
    } catch (error) {
      toast.error("Error when searching for users", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };

  const handleCreateFolio = async () => {
    if (await createFolioFormRef.current.handleCreateFolio()) {
      setShowModal(false);
      fetchDocuments();
    }
  };

  return (
    <>
      <Row>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <button
            className="btn btn-primary"
            onClick={() => setShowModal(true)}
          >
            Create new folio
          </button>
        </div>
        <Col>
          <div className="main-card" >
            {loading ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Spinner />
              </div>
            ) : (
              <CustomTable
                data={data}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            )}
          </div>
        </Col>
      </Row>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <CreateFolioForm ref={createFolioFormRef} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCreateFolio}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Documents;
