import api from "../../../../services/api";

export const GetDecedentInfo = async ({ folio }) => {
  try {
    const response = await api.get(
      `documents/form1/DecedentInfoController.php?id=${folio}`
    );
    const decedentInfo = response.data[0];
    return {
      folio: decedentInfo?.folio || "",
      consecutivo: decedentInfo?.secuencia || "",
      firstName: decedentInfo?.name || "",
      middleName: decedentInfo?.middle || "",
      lastName: decedentInfo?.lastname || "",
      suffix: decedentInfo?.suffix || "",
      aka: decedentInfo?.aka || "",
      sex: decedentInfo?.sex || "",
      usSecurityNumOptions: decedentInfo?.usSSNOption || "",
      usSecurityNumValue: decedentInfo?.usSSNValue || "",
      dateOfDeath: decedentInfo?.dateOfDeath || "",
      dateOfBirth: decedentInfo?.dateOfBirth || "",
      years: decedentInfo?.yearsAge || "",
      months: decedentInfo?.monthsAge || "",
      days: decedentInfo?.daysAge || "",
      hours: decedentInfo?.hoursAge || "",
      minutes: decedentInfo?.minutesAge || "",
      birthCityOrTown: decedentInfo?.birthCity || "",
      birthCounty: decedentInfo?.birthCounty || "",
      birthState: decedentInfo?.birthState || "",
      birthCountry: decedentInfo?.CountryNameId || "",
      armedForces: decedentInfo?.ArmedForces || "",
      namePriorToMarriage: decedentInfo?.nameMirriage || "",
      hrrf: decedentInfo?.hrrf || "",
    };
  } catch (error) {
    console.error("Error fetching form data:", error);
    return {};
  }
};

export const GetCertifierInfo = async ({ folio }) => {
  try {
    const response = await api.get(
      `documents/form1/CertifierInfoController.php?id=${folio}`
    );
    const haveData = response.data[0];
    return {
      certifierType: haveData?.certifierType || "",
      certifierLicenseNumber: haveData?.certifierLicenseNumber || "",
      certifierName: haveData?.certifierName || "",
      certifierTitleOption: haveData?.certifierTitleOption || "",
      certifierTitleValue: haveData?.certifierTitleValue || "",
      certifierAddress: haveData?.certifierAddress || "",
      certifierZipCode: haveData?.certifierZipCode || "",
      certifierCity: haveData?.certifierCity || "",
      certifierState: haveData?.certifierState || "",
      certifierCountry: haveData?.certifierCountry || "",
      certifierNameAlternate: haveData?.certifierNameAlternate || "",
      certifierTelephone: haveData?.certifierTelephone || "",
      certifierFax: haveData?.certifierFax || "",
      certifierEmail: haveData?.certifierEmail || "",
    };
  } catch {
    return {};
  }
};

export const GetDecedentOcupationInfo = async ({ folio,consecutivo }) => {
  try {
    const response = await api.get('documents/form1/DecedentOccupation.php', {
      params: {
        folio: folio,
        consecutivo: consecutivo
      }
    });
    const data = response.data;
    if(response.data.decedent_info.length == 0 && response.data.decedent_origin_options.length == 0 && response.data.decedent_race_options == 0){
      return{
        decendentOccupation: "",
        decedemtIndustry: "",
        education:  "",
        otherInstitutionAddress: "",
        placeOfDeathFacility:"",
        placeOfDeathFacilityAddress: "",
        placeOfDeathOption: "",
        placeOfDeathValue: "",
        decedentOriginOption: [{0:{value:0,inputValue:""}}],
        decedentRaceOption: [{0:{value:0,inputValue:""}}],
      }
    }
    else{
      return {
        decendentOccupation: data?.decedent_info[0].decendentOccupation || "",
        decedemtIndustry: data?.decedent_info[0].decedemtIndustry || "",
        education: data?.decedent_info[0].education || "",
        otherInstitutionAddress: data?.decedent_info[0].otherInstitutionAddress || "",
        placeOfDeathFacility:data?.decedent_info[0].placeOfDeathFacility || "",
        placeOfDeathFacilityAddress: data?.decedent_info[0].placeOfDeathFacilityAddress || "",
        placeOfDeathOption: data?.decedent_info[0].placeOfDeathOption || "",
        placeOfDeathValue: data?.decedent_info[0].placeOfDeathValue || "",
        decedentOriginOption: data?.decedent_origin_options || {0:{value:0,inputValue:""}},
        decedentRaceOption: data?.decedent_race_options || {0:{value:0,inputValue:""}},
      };
    }
    
  } catch {
    return {};
  }
};

export const GetFatherInfo = async ({ folio }) => {
  try {
    const response = await api.get(
      `documents/form1/FatherController.php?id=${folio}`
    );
    const fatherInfo = response.data[0];

    return {
      firstNameFather: fatherInfo?.name || "",
      middleNameFather: fatherInfo?.middle || "",
      lastNameFather: fatherInfo?.lastname || "",
      suffixFather: fatherInfo?.suffix || "",
    };
  } catch {
    return {};
  }
};

export const GetFuneralInfo = async ({ folio }) => {
  try {
    const response = await api.get(
      `documents/form1/FuneralCompanyController.php?id=${folio}`
    );
    const funeral_info = response.data[0];

    return {
      methodDispositionOptions: funeral_info?.methodDispositionOptions || "",
      methodDispositionValue: funeral_info?.methodDispositionValue || "",
      dateDisposition: funeral_info?.dateDisposition || "",
      placeDispositionFirstFacility:
        funeral_info?.placeDispositionFirstFacility || "",
      placeDispositionSecondFacility:
        funeral_info?.placeDispositionSecondFacility || "",
      nameFuneralDirector: funeral_info?.nameFuneralDirector || "",
      licenceNumber: funeral_info?.licenceNumber || "",
      nameFuneralHome: funeral_info?.nameFuneralHome || "",
      addressFuneralHome: funeral_info?.addressFuneralHome || "",
      otherResponsableRelationship:
        funeral_info?.otherResponsableRelationship || "",
    };
  } catch {
    return {};
  }
};

export const GetInformantInfo = async ({ folio }) => {
  try {
    const response = await api.get(
      `documents/form1/InformantController.php?id=${folio}`
    );
    const informantInfo = response.data[0];
      return {
        firstNameInformant: informantInfo?.name || "",
        middleNameInformant: informantInfo?.middle || "",
        lastNameInformant: informantInfo?.lastname || "",
        suffixInformant: informantInfo?.suffix || "",
        relationshipInfromant: informantInfo?.relationship || "",
        emailInformant: informantInfo?.email || "",
        phoneInformant: informantInfo?.phone || "",
        mailingAddress: informantInfo?.mailing || "",
      };
  } catch {
    return {};
  }
};

export const GetMotherInfo = async ({ folio }) => {
  try {
    const response = await api.get(
      `documents/form1/MotherController.php?id=${folio}`
    );
    const motherInfo = response.data[0];
    return {
      firstNameMother: motherInfo?.name || "",
      middleNameMother: motherInfo?.middle || "",
      lastNameMother: motherInfo?.lastname || "",
      suffixMother: motherInfo?.suffix || "",
    };
  } catch {
    return {};
  }
};

export const GetRecindenceInfo = async ({ folio }) => {
  try {
    const response = await api.get(
      `documents/form1/RecidenceController.php?id=${folio}`
    );
    const recidenceInfo = response.data[0];

    return {
      streetAdress: recidenceInfo?.streetAddress || "",
      zipCode: recidenceInfo?.zipCode || "",
      residenceCity: recidenceInfo?.city || "",
      residenceConty: recidenceInfo?.county || "",
      residenceState: recidenceInfo?.state || "",
      residenceCountry: recidenceInfo?.country || "",
      inCityLimits: recidenceInfo?.inCityLimits || "",
      howLongArizonaValue: recidenceInfo?.howLongArizonaValue || "",
      howLongInArizonaOption: recidenceInfo?.howLongArizonaOption || "",
      residedTribalCommunity: recidenceInfo?.azTribal || "",
    };
  } catch {
    return {};
  }
};

export const GetSpouceInfo = async ({ folio }) => {
  try {
    const response = await api.get(
      `documents/form1/SpouceController.php?id=${folio}`
    );
    const spouceInfo = response.data[0];

    return {
      maritalStatus: spouceInfo?.maritalStatus || "",
      firstNameSpouse: spouceInfo?.name || "",
      middleNameSpouse: spouceInfo?.middle || "",
      lastNameSpouse: spouceInfo?.lastname || "",
      suffixSpouse: spouceInfo?.suffix || "",
      lastNameSurvivingSpouse: spouceInfo?.lastnameSurvivingSpouce || "",
    };
  } catch {
    return {};
  }
};
