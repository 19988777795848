import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";

const DefaultRadioInput = ({
  numberRow,
  options,
  label,
  inputType = "text",
  onChange,
  valueData,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState("");

  // useEffect para actualizar el estado cuando cambia el valueData
  useEffect(() => {
    if (valueData) {
      const selected = options.find((option) => option.value === valueData);
      if (selected) {
        setSelectedOption(selected.value);
        if (selected.enableInput) {
          setInputValue(valueData);
        } else {
          setInputValue("");
        }
        onChange(valueData);
      } else {
        const defaultOption = options.find((option) => option.enableInput);
        if (defaultOption) {
          setSelectedOption(defaultOption.value);
          setInputValue(valueData);
          onChange(valueData);
        }
      }
    }
  }, [valueData]);

  const handleOptionChange = (option) => {
    setSelectedOption(option.value);
    if (!option.enableInput) {
      setInputValue("");
    }
    onChange(option.value);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div>
      <Form.Group>
        <Form.Label style={{ fontWeight: "700" }}>{numberRow}</Form.Label>
        <Form.Label>{label}</Form.Label>
        {options.map((option, index) => (
          <Row key={index} className="align-items-center">
            <Col xs="auto" onClick={() => handleOptionChange(option)}>
              <Form.Check
                type="radio"
                label={option.label}
                id={`${numberRow}-${index}`}
                name={`${numberRow}-radioGroup`}
                checked={selectedOption === option.value}
                onChange={() => handleOptionChange(option)}
              />
            </Col>
            {selectedOption === option.value && option.enableInput && (
              <Col>
                <Form.Control
                  type={inputType}
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </Col>
            )}
          </Row>
        ))}
      </Form.Group>
    </div>
  );
};

export default DefaultRadioInput;
