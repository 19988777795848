import { React, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PDF_Form8 from "./PDF_Form8";
import { Button, Col, Row, Modal } from "react-bootstrap";
import getUserDataFromCookies from "../Form1/backend/GetUserFromCookies";
import CrematoryTucsonForm from "./Form8/form";
import { PDFViewer } from "@react-pdf/renderer";

const CrematoryTucson = () => {
  const { folioID } = useParams();
  const { consecutivo } = useParams();
  const navigate = useNavigate();
  var userId = getUserDataFromCookies().id_usuario;

  var dataCremationAuthorization = {
    consecutivo: consecutivo,
    folio: folioID,
    usuario_id: userId,

    decreasedName: "",
    dateOfDeath: "",
    funeralEstablishment: "",
    stateRelationship:"",
    knowledgeOption: "",
    otherEqualAuthority:"",
    mechanicalDeviceOption :"",

    printedName_1: "",
    relationship_1: "",
    email_1: "",
    phone_1: "",

    printedName_2: "",
    relationship_2: "",
    email_2: "",
    phone_2: "",

    printedName_3: "",
    relationship_3: "",
    email_3: "",
    phone_3: "",

    funeralHomeRep: "",
    printedName_fh: "",
    date_fh: "",
  };

  const [pdfKey, setPdfKey] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  const [cremationInfo, setCremationInfo] = useState(
    dataCremationAuthorization
  );

  const handleAuthorizationInfo = (data) => {
    setCremationInfo(data);
  };
  const handleSignDocument = () => {
    navigate(`/documents/sign-document/${folioID}/6`, { state: { cremationInfo } });
  };
  return (
    <>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <h2 className="f-cel">{folioID}</h2>
        <h3 className="f-cel">CREMATION AUTHORIZATION</h3>
        <div><button className="btn btn-primary" onClick={handleShowModal}>View Document</button></div>
      </div>

      <Row>
        <Col lg="10">
          <CrematoryTucsonForm
            onAuthorizationRelease={handleAuthorizationInfo}
            consecutivo={consecutivo}
            folioID={folioID}
            usuarioId={userId}
          />
        </Col>
      </Row>
      <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Body>
          <PDFViewer style={{ width: "100%", height: "800px" }}>
            <PDF_Form8 data={cremationInfo} />
          </PDFViewer>
        </Modal.Body>
        <Modal.Footer>
            {/*<Button variant="primary" onClick={handleSignDocument}>
              Sign Document
            </Button>*/}
          </Modal.Footer>
      </Modal>
    </>
  );
};

export default CrematoryTucson;
