import * as React from "react";
import { useNavigate } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { IoHomeOutline,IoClipboardOutline,IoArchiveOutline,IoCogOutline } from "react-icons/io5";

export const SidebarItem = [ // Cambia Navitem por SidebarItem
  {
    title: "Home",
    icon: <IoHomeOutline />,
    href: "/dashboard",
    rol: 3,
  },
  {
    title: "Documentation",
    icon: <IoClipboardOutline />,
    href: "/documents",
    rol: 2,
  },  {
    title: "Archive",
    icon: <IoArchiveOutline/>,
    href: "/archive",
    rol: 2,
  },
  {
    title: "Settings",
    icon: <IoCogOutline/>,
    href: "/settings",
    rol: 1,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Account",
        icon: "",
        href: "/settings/account",
        rol: 2,
      },
      {
        title: "Archive",
        icon: "",
        href: "/settings/archive",
        rol: 1,
      },
      {
        title: "Company Information",
        icon: "",
        href: "/settings/company",
        rol: 1,

      },
      {
        title: "Users",
        icon: "",
        href: "/settings/users",
        rol: 1,

      }
    ],
  },
];
