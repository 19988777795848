import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "./styles/styles.css";
import "bootstrap/dist/css/bootstrap.css";
import Login from "./pages/login/Login";
import LandingPage from "./pages/landingpage/LandingPage";
import Dashboard from "./pages/dashboard/Dashboard";
import Users from "./pages/settings/users/Users";
import Archive from "./pages/settings/archive/Archive";
import Cookies from "js-cookie";
import Layout from "./Layout";
import Company from "./pages/settings/company/company";
import ArchivePage from "./pages/archive/ArchivePage";
import ArchiveDetail from "./pages/archive/ArchiveDetail";
import Profile from "./pages/archive/Profile";
//import Documents from "./pages/documents/Documents";
import Documents from "./pages/documents/Documents_v2";
import DeathRegistrationForm from "./pages/documents/Form1/DeathRegistrationForm";
import AutorizationEmbaling from "./pages/documents/Form3-Embalming/AutorizationEmbaling"
import PurchaseReceipt from "./pages/documents/Form4-PurchaseReceipt/PurchaseReceipt";
import Invoice from "./pages/documents/Form2-Invoice/Invoice";
import SignDocument from "./pages/documents/Sign";
import NoticePurchaser from "./pages/documents/Form5-NoticePurchaser/NoticePurchaser";
import CremationAuthotization from "./pages/documents/Form6-CrematoryAuthorization/CrematoryAuthorization";
import Account from "./pages/settings/Account/Account";
import SelectDocument from "./pages/sign-document/SelectDocument";
import GetDocumentView from "./pages/sign-document/GetDocumentView";
import { ToastContainer } from "react-toastify";
import { Spinner } from 'react-bootstrap';
import CrematoryTucson from './pages/documents/Form8-CrematoryTucson/CrematoryTucson';

function App() {
  // Función para verificar si el usuario está autenticado
  const isAuthenticated = () => {
    const userDataString = Cookies.get("userData");
    return userDataString !== null && userDataString !== undefined;
  };
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Simula la finalización de la carga después de 2 segundos
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      <ToastContainer/>
      {loading ? (
        <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Spinner />
      </div>):(
      <Router>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/dashboard" element= {<Layout layout={<Dashboard/>}/> }/>

          {/*DOCUMENTOS*/}
          <Route exact path="/documents" element= {<Layout layout={<Documents/>}/> }/>

          {/*Form 1*/}
          <Route exact path="/documents/death-registration-worksheet/:folioID/:consecutivo" element= {<Layout layout={<DeathRegistrationForm/>}/> }/>
          {/*Form 2*/}
          <Route exact path="/documents/invoice/:folioID/:consecutivo" element= {<Layout layout={<Invoice/>}/> }/>
          {/*Form 3*/}
          <Route exact path="/documents/authorization-release-embalming/:folioID/:consecutivo" element= {<Layout layout={<AutorizationEmbaling/>}/> }/>
          {/*Form 4*/}
          <Route exact path="/documents/purchase-receipt/:folioID/:consecutivo" element= {<Layout layout={<PurchaseReceipt/>}/> }/>
          {/*Form 5*/}
          <Route exact path="/documents/notice-purchaser/:folioID/:consecutivo" element= {<Layout layout={<NoticePurchaser/>}/> }/>
          {/*Sign Document*/}
          <Route path="/documents/sign-document/:folioID/:documentNumber" element= {<Layout layout={<SignDocument/>}/> } />
          {/*Form 6 - Crematory Authorization*/}
          <Route exact path="/documents/crematory-authorization/:folioID/:consecutivo" element= {<Layout layout={<CremationAuthotization/>}/> }/>
          {/*Form 8 - Tucson Crematory*/}
          <Route exact path="/documents/tucson-cremation-services/:folioID/:consecutivo" element= {<Layout layout={<CrematoryTucson/>}/> }/>

          {/*DOCUMENTOS*/}
          <Route exact path="/archive" element= {<Layout layout={<ArchivePage/>}/> }/>
          <Route exact path="/archive/:name/:id" element= {<Layout layout={<ArchiveDetail/>}/> }/>
          <Route exact path="/archive/:name/:id/:folio/:nameOfdecreased" element= {<Layout layout={<Profile/>}/> }/>

          <Route exact path="/settings/users" element= {<Layout layout={<Users/>}/> }/>
          <Route exact path="/settings/account" element= {<Layout layout={<Account/>}/> }/>
          <Route exact path="/settings/archive" element= {<Layout layout={<Archive/>}/> }/>
          <Route exact path="/settings/company" element= {<Layout layout={<Company/>}/> }/>
          <Route exact path="/sign-document/:folio/:consecutivo/:numForm" element= {<SelectDocument/>}/>
          <Route path="*" element={<><h1>Page not found</h1> <LandingPage/></>}/>
        </Routes>
      </Router>)}
    </div>
  );
}

export default App;
