import React, { useState, useEffect } from "react";
import { Button, Row } from "react-bootstrap";
import DefaultInput from "../../../../components/Elements/DefaultInput";
import DefaultRadioButtons from "../../../../components/Elements/DefaultRadioButtons";
import DefaultRadioButtonsNoLabel from "../../../../components/Elements/DefaultRadioButtonsNoLabel";
import DefaulLabel from "../../../../components/Elements/DefaultLabel";
import DefaultRadioInput from "../../../../components/Elements/DefaultRadioInput";
import DefaultInputNoLabel from "../../../../components/Elements/DefaultInputNoLabel";
import GerUsersFromCookies from "../backend/GetUserFromCookies";
import api from "../../../../services/api";
import { toast } from "react-toastify";

const RecidenceInfo = ({
  onRecidenceDataChange,
  folioId,
  folio,
  onToastEvent,
}) => {
  const city_limits = [
    { label: "Yes", value: "1" },
    { label: "No", value: "2" },
    { label: "Unknown", value: "3" },
  ];
  const how_long_in_az = [
    { label: "Days", value: "1", enableInput: false },
    { label: "Hours", value: "2", enableInput: false },
    { label: "Minutes", value: "3", enableInput: false },
    { label: "Years", value: "4", enableInput: false },
    { label: "Months", value: "5", enableInput: false },
    { label: "In Transit", value: "6", enableInput: false },
    { label: "Unknown", value: "7", enableInput: false },
  ];
  const tribal_az = [
    { label: "Yes", value: "1", enableInput: true },
    { label: "No", value: "2", enableInput: false },
    { label: "Unknown", value: "3", enableInput: false },
  ];

  const [formData, setFormData] = useState({
    secuencia: folio,
    folio: folioId,
    usuario_id: GerUsersFromCookies().id_usuario,

    streetAdress: "",
    zipCode: "",
    residenceCity: "",
    residenceConty: "",
    residenceState: "",
    residenceCountry: "",
    inCityLimits: "",
    howLongArizonaValue: "",
    howLongInArizonaOption: "",
    residedTribalCommunity: "",
  });
  const handleInputChange = (name, value) => {
    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    setFormData(updatedFormData);
    onRecidenceDataChange(updatedFormData);
  };
  const handleSubmit = () => {
    add_recidenceInfo(formData);
  };

  const add_recidenceInfo = async (formData) => {
    try {
      const response = await api.post(
        "documents/form1/RecidenceController.php",
        formData,
        { params: { action: "POST" } }
      );
      toast.success("Recidence info successfully added", {
        className: "toast-success",
        theme: "colored",
      });
    } catch (error) {
      onToastEvent("Error adding recidence info", "error");
      toast.error("Error adding recidence info", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };
  useEffect(() => {
    get_recidence_info_with_ID(folioId);
  }, []);
  const get_recidence_info_with_ID = async (folio) => {
    try {
      const response = await api.get(
        `documents/form1/RecidenceController.php?id=${folio}`
      );
      const recidenceInfo = response.data[0];
      if (response.data[0] != null) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          streetAdress: recidenceInfo.streetAddress,
          zipCode: recidenceInfo.zipCode,
          residenceCity: recidenceInfo.city,
          residenceConty: recidenceInfo.county,
          residenceState: recidenceInfo.state,
          residenceCountry: recidenceInfo.country,
          inCityLimits: recidenceInfo.inCityLimits,
          howLongArizonaValue: recidenceInfo.howLongArizonaValue,
          howLongInArizonaOption: recidenceInfo.howLongArizonaOption,
          residedTribalCommunity: recidenceInfo.azTribal,
        }));
      }
    } catch {}
  };
  return (
    <>
      <div className="main-card">
        <DefaultInput
          numberRow={"10A.-"}
          placeholder={"DECEDENT’S RESIDENCE STREET ADDRESS"}
          type={"text"}
          onChange={(value) => handleInputChange("streetAdress", value)}
          valueData={formData.streetAdress}
        />
        <DefaultInput
          numberRow={"10B.-"}
          placeholder={"ZIP CODE"}
          type={"text"}
          onChange={(value) => handleInputChange("zipCode", value)}
          valueData={formData.zipCode}
        />
        <DefaultInput
          numberRow={"10C.-"}
          placeholder={"RESIDENCE CITY"}
          type={"text"}
          onChange={(value) => handleInputChange("residenceCity", value)}
          valueData={formData.residenceCity}
        />
        <DefaultInput
          numberRow={"10D.-"}
          placeholder={"RESIDENCE COUNTY"}
          type={"text"}
          onChange={(value) => handleInputChange("residenceConty", value)}
          valueData={formData.residenceConty}
        />
        <DefaultInput
          numberRow={"10E.-"}
          placeholder={"RESIDENCE STATE"}
          type={"text"}
          onChange={(value) => handleInputChange("residenceState", value)}
          valueData={formData.residenceState}
        />
        <DefaultInput
          numberRow={"10F.-"}
          placeholder={"RESIDENCE COUNTRY"}
          type={"text"}
          onChange={(value) => handleInputChange("residenceCountry", value)}
          valueData={formData.residenceCountry}
        />
        <DefaultRadioButtons
          numberRow={"11.-"}
          label={"IN CITY LIMITS"}
          options={city_limits}
          onChange={(value) => handleInputChange("inCityLimits", value)}
          valueData={formData.inCityLimits}
        />
        <DefaulLabel
          numberRow={"12.-"}
          placeholder={"HOW LONG IN THE STATE OF ARIZONA?"}
        />
        <DefaultInputNoLabel
          onChange={(value) => handleInputChange("howLongArizonaValue", value)}
          valueData={formData.howLongArizonaValue}
        />
        <DefaultRadioButtonsNoLabel
          options={how_long_in_az}
          onChange={(value) =>
            handleInputChange("howLongInArizonaOption", value)
          }
          numberRow={"12.-"}
          valueData={formData.howLongInArizonaOption}
        />

        <DefaultRadioInput
          numberRow={"13.-"}
          options={tribal_az}
          label="RESIDED IN AZ. TRIBAL COMMUNITY?"
          onChange={(value) =>
            handleInputChange("residedTribalCommunity", value)
          }
          valueData={formData.residedTribalCommunity}
        />
      </div>
      <div className="d-flex justify-content-end mt-3">
        <Button onClick={handleSubmit}>SAVE RESIDENCE INFO</Button>
      </div>
    </>
  );
};

export default RecidenceInfo;
