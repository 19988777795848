import React, { useState,useEffect } from "react";
import { Button, Col, Row} from "react-bootstrap";
import DefaultInput from "../../../../components/Elements/DefaultInput";
import GerUsersFromCookies from "../backend/GetUserFromCookies"
import api from "../../../../services/api";
import { toast } from "react-toastify";
const FatherInfo = ({ onFatherDataChange,folioId,folio }) => {
  const [formData, setFormData] = useState({
    secuencia :folio,
    folio :folioId,
    usuario_id : GerUsersFromCookies().id_usuario,

    firstNameFather: "",
    middleNameFather: "",
    lastNameFather: "",
    suffixFather: "",
  });
  const handleInputChange = (name, value) => {
    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    setFormData(updatedFormData);
    onFatherDataChange(updatedFormData);
  };
  const handleSubmit = () => {
    add_fatherInfo(formData);
  };
  const add_fatherInfo = async (formData) => {
    try {
      const response = await api.post(
        "documents/form1/FatherController.php",
        formData,
        { params: { action: "POST" } }
      );
      toast.success("Father info successfully added", {
        className: "toast-success",
        theme: "colored",
      });

    } catch (error) {
      toast.error("Error to add fuenral info", {
        className: "toast-error",
        theme: "colored",
      });


    }
  };
  useEffect(() => {
    get_father_info_with_ID(folioId);
  }, []);
  const get_father_info_with_ID = async (folio) =>{
    try{
      const response = await api.get(
        `documents/form1/FatherController.php?id=${folio}`
      );
      const fatherInfo = response.data[0];
      if(response.data[0] != null){
        setFormData(prevFormData => ({
          ...prevFormData,
          firstNameFather: fatherInfo.name,
          middleNameFather: fatherInfo.middle,
          lastNameFather: fatherInfo.lastname,
          suffixFather: fatherInfo.suffix,
      }));
      }
    }
    catch{

    }
  }
  useEffect(() => {
    if (Object.keys(formData).some(key => formData[key] !== "")) {
      onFatherDataChange(formData);
    }
  }, [formData, onFatherDataChange]);
  return (
    <>
      <div className="main-card">
        <DefaultInput
          numberRow={"16A.-"}
          placeholder={"FATHER’S FIRST NAME"}
          type={"text"}
          onChange={(value)=> handleInputChange("firstNameFather",value)}
          valueData={formData.firstNameFather}

        />
        <DefaultInput
          numberRow={"16B.-"}
          placeholder={"FATHER’S MIDDLE NAME"}
          type={"text"}
          onChange={(value)=> handleInputChange("middleNameFather",value)}
          valueData={formData.middleNameFather}


        />
        <DefaultInput
          numberRow={"16C.-"}
          placeholder={"FATHER’S LAST NAME"}
          type={"text"}
          onChange={(value)=> handleInputChange("lastNameFather",value)}
          valueData={formData.lastNameFather}

        />
        <DefaultInput
          numberRow={"16D.-"}
          placeholder={"SUFFIX"}
          type={"text"}
          onChange={(value)=> handleInputChange("suffixFather",value)}
          valueData={formData.suffixFather}
        />
      </div>
      <div className="d-flex justify-content-end mt-3">
        <Button onClick={handleSubmit}>SAVE FATHER INFO</Button>
      </div>
    </>
  );
};

export default FatherInfo;
