import api from "../../../../services/api";

const GetInfoForm4 = async ({ folio }) => {
  try {
    const response = await api.get(
      `documents/form4/PurchaseReceipt.php?id=${folio}`
    );
    const purchaseData = response.data[0];
    return {
      folio: purchaseData?.folio || "",
      Date: purchaseData?.dateForm || "",
      AccountNumber: purchaseData?.AccountNumber || "",
      DetailedClaim: purchaseData?.DetailedClaim || "",
      Payment: purchaseData?.Payment || "",
      BalanceDue: purchaseData?.BalanceDue || "",
    };
  } catch (error) {
    console.error("Error fetching form data:", error);
  }
};

export default GetInfoForm4;
