import api from "../../../../services/api";

const GetInfo_Form5_DeceasedInfo = async ({ consecutivo }) => {
  try {
    const response = await api.get(
      `documents/form5/NoticeDecedentController.php?id=${consecutivo}`
    );
    const decedent_info = response.data[0];

    return {
      nameDeceased: decedent_info?.decreasedName || "",
      folio: decedent_info?.folio || "",
      todayDate: decedent_info?.todayDate || "",
      age: decedent_info?.age || "",
      dateOfBirth: decedent_info?.dateBirth || "",
      dateOfDeath: decedent_info?.dateDeath || "",
      ssn: decedent_info?.SSN || "",
      placeDeath: decedent_info?.placeDeath || "",
      weight: decedent_info?.weight || "",
      height: decedent_info?.height || "",
      sex: decedent_info?.sex || "",
    };
  } catch (error) {
    console.log("Error get info", error);
    return {
      nameDeceased: "",
      folio: "",
      todayDate: "",
      age: "",
      dateOfBirth: "",
      dateOfDeath: "",
      ssn: "",
      placeDeath: "",
      weight: "",
      height: "",
      sex: "",
    };
  }
};

export default GetInfo_Form5_DeceasedInfo;
