import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Folder from "../../components/icons/Folder";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import api from "../../services/api";

const ArchiveDetail = () => {
  const { name } = useParams();
  const { id } = useParams();
  const [archiveDetail, setArchiveDetail] = useState([]);
  useEffect(() => {
    getArchiveDetail(id);
  }, []);

  const getArchiveDetail = async (id) => {
    try {
      const response = await api.get(
        `archive/ArchiveDetail.php?id=${id}`,
        { params: { action: "GET" } }
      );
      if (response.data != null) {
        setArchiveDetail(response.data);
      } else {
        setArchiveDetail([]);
      }
    } catch (error) {
      console.error("Error al buscar países:", error);
    }
  };

  return (
    <>
      <Row>
        <h1>{name.toLocaleUpperCase()}</h1>
      </Row>
      <Row>
        <div className="archive-container">
          {archiveDetail.map((archive, index) => (
            <Link
              key={index}
              className="archive-icon-folder"
              to={`/archive/${name}/${id}/${archive.folio}/${
                archive.name + " " + archive.lastname
              }`}
            >
              <Folder color={"#f0d4a3"}></Folder>
              <div className="archive-icon-text">
                <span className="folio">
                  {archive.folio.toLocaleUpperCase()}
                </span>
                <br></br>
                <span>{archive.name.toLocaleUpperCase()} </span>
                <span>{archive.lastname.toLocaleUpperCase()}</span>
              </div>
            </Link>
          ))}
        </div>
      </Row>
    </>
  );
};

export default ArchiveDetail;
